import { Directive } from '@angular/core';

export const commonControlValidation = (_, control) => {
  if (control && (control.value || control.touched)) {
    return control.valid;
  }
  return undefined;
};

@Directive()
export class CommonForm {
  commonControlValidation = commonControlValidation;
}
