<div class="menu-search-ot">
  <div class="columns align-vertical-center">
    <div class="column is-10">
        <div class="input-search">
            <input type="text" placeholder="Digite a O.T"
              #inputSearchOT
              autofocus
              (change)="changeOtNumber()" [(ngModel)]="searchOtNumber"
              (keyup.enter)="saveOt()"
            >
            <button (click)="saveOt()" data-gtag="menu_principal__btn_buscar_ots_salvas">Buscar</button>
        </div>
        <div class="is-clearfix"></div>
    </div>
    <div class="column is-2">
        <span class="ot-save-icon" (click)="onlySaveOt()" data-gtag="menu_principal__ots_favoritos">
            <img src="./../../../../assets/img/icon/ic_ot_save.svg" data-gtag="menu_principal__ots_favoritos" alt="" *ngIf="!isOrderSaved">
            <img src="./../../../../assets/img/icon/ic_ot_saved.svg" alt="" *ngIf="isOrderSaved">
        </span>
    </div>
  </div>
  <div class="columns">
    <div class="column is-12 menu-search-ot-results" (click)="goToSearchOtPage()">
        <span class="ot-save-icon">
            <img src="./../../../../assets/img/icon/ic_ot_save.svg" alt="">
        </span>
        <span class="ot-saved-list" data-gtag="menu_principal_ots_salvas">O.Ts salvas ({{ userOrdersCount }})</span>
    </div>
  </div>
</div>
