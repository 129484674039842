import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calculateDelay'
})
export class CalculateDelayPipe implements PipeTransform {

  transform(ending: any, method: string, ...args: any[]): string {
    if (ending) {
      const now = new Date();
      const endDate = new Date(ending);
      endDate.setUTCHours(endDate.getUTCHours() + 3);
      if (now > endDate) {
        switch (method) {
          case 'custom':
            return this.customDelayCalc(now, endDate);
          default:
            return this.defaultDelayCalc(now, endDate);
        }
      } else {
        switch (method) {
          case 'custom':
            return this.customDelayCalc(now, endDate);
          default:
            return this.defaultDelayCalc(now, endDate);
        }
      }
    }
  }

  defaultOnTimeCalc(now, endDate) {
    const min = Math.floor((endDate.getTime() - now.getTime()) / (60 * 1000) % 60);
    const hour = Math.floor((endDate.getTime() - now.getTime()) / (60 * 60 * 1000) % 24);
    const day = Math.floor((endDate.getTime() - now.getTime()) / (9 * 60 * 60 * 1000));
    if (day > 1) { return `${day} ${day > 1 ? 'dias' : 'dia'} de chegada`; }
    if (hour > 1) { return `${hour} ${hour > 1 ? 'horas' : 'hora'} de chegada`; }
    if (min > 1) { return `${min} min de chegada`; }
    return '';
  }

  customOnTimeCalc(now, endDate) {
    const min = Math.floor((endDate.getTime() - now.getTime()) / (60 * 1000) % 60);
    const hour = Math.floor((endDate.getTime() - now.getTime()) / (60 * 60 * 1000) % 9);
    const day = Math.floor((endDate.getTime() - now.getTime()) / (9 * 60 * 60 * 1000));
    if (day > 1) { return `${day} ${day > 1 ? 'dias' : 'dia'} de chegada`; }
    if (hour > 1) { return `${hour} ${hour > 1 ? 'horas' : 'hora'} de chegada`; }
    if (min > 1) { return `${min} min de chegada`; }
    return '';
  }

  customDelayCalc(now, endDate) {
    const min = Math.floor((now.getTime() - endDate.getTime()) / (60 * 1000) % 60);
    const hour = Math.floor((now.getTime() - endDate.getTime()) / (60 * 60 * 1000) % 9);
    const day = Math.floor((now.getTime() - endDate.getTime()) / (9 * 60 * 60 * 1000));
    if (day && day > 1) { return `${day} ${day > 1 ? 'dias' : 'dia'} de atraso`; }
    if (hour && hour > 1) { return `${hour} ${hour > 1 ? 'horas' : 'hora'} de atraso`; }
    if (min && min > 1) { return `${min} min de atraso`; }
    return '';
  }

  defaultDelayCalc(now, endDate) {
    const min = Math.floor((now.getTime() - endDate.getTime()) / (60 * 1000) % 60);
    const hour = Math.floor((now.getTime() - endDate.getTime()) / (60 * 60 * 1000) % 24);
    const day = Math.floor((now.getTime() - endDate.getTime()) / (24 * 60 * 60 * 1000));
    const month = Math.floor((now.getTime() - endDate.getTime()) / (30 * 24 * 60 * 60 * 1000));
    if (month && month > 1) { return `${month} ${month > 1 ? 'meses' : 'mês'} de atraso`; }
    if (day && day > 1) { return `${day} ${day > 1 ? 'dias' : 'dia'} de atraso`; }
    if (hour && hour > 1) { return `${hour} ${hour > 1 ? 'horas' : 'hora'} de atraso`; }
    if (min && min > 1) { return `${min} min de atraso`; }
    return '';
  }

}
