import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { AuthService } from '../core/auth/auth.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { finalize, catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApplicationMonitoringService } from '../shared/services/application-monitoring.service';
import { endpointsWithoutLoading } from './endpoints-without-loader';
import { externalDomains } from './external-domains';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {

  errorCounter = 0;

  constructor(
    public loaderService: LoaderService,
    public authService: AuthService,
    public router: Router,
    private apmService: ApplicationMonitoringService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headers: any = {
      Accept: 'application/json',
      'accept-language': navigator.language,
    };

    const isExternalDomain = externalDomains.some(d => req.url.includes(d));

    if (req.responseType && !isExternalDomain) {
      headers['Response-Type'] = req.responseType;
    }

    const withCredentials = !isExternalDomain;
    req = req.clone({
      setHeaders: headers,
      withCredentials
    });

    if(!endpointsWithoutLoading.some(endpoint => new RegExp(endpoint).test(req.url))) {
      this.loaderService.show();
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (!this.errorCounter && error.error?.code === 3029) {
          this.errorCounter++;
          if (this.authService.isLoggedUser) {
            this.handleTokenExpired();
          }
        }

        if (req.body) {
          const payload = this.transformAndRedactSensitiveInfo(req.body);
          this.apmService.captureError(error, { payload });
        } else {
          this.apmService.captureError(error);
        }

        return throwError(() => error);
      }),
      finalize(() => this.loaderService.hide())
    );
  }

  handleTokenExpired() {
    this.authService.setTokenAsExpired('true');
    this.router.navigate(['expired-token']);
  }

  transformAndRedactSensitiveInfo(payload) {
    const sanitizeKeys = ['pass', 'secret', 'token', 'senha'];
    
    const newPayload = {...payload};
    const payloadKeys = Object.keys(newPayload);

    const fieldsToSanitize = payloadKeys.filter(pk => sanitizeKeys.some(sk => pk.includes(sk)));
    fieldsToSanitize.forEach(field => newPayload[field] = '[REDACTED]');

    return newPayload;
  }
}
