<div class="email-confirmation-container full-width is-flex is-justify-content-start is-flex-direction-column">

  <!-- Titulo e subtitulo -->
  <b class="is-size-3">
    Informações preenchidas com sucesso!
  </b>

  <p class="is-size-5 mt-5">
    Agora precisamos validar seus dados, um email de confirmação foi enviado para o endereço abaixo. Acesse e <b>confirme seu cadastro.</b>
  </p>

  <p class="is-size-5 has-text-primary mt-5">
    {{ emailSent }}
  </p>

  <p class="is-size-5 mt-6">
    <b class="has-text-info">Atenção!</b> Após a ativação da sua conta, não se esqueça de cadastrar suas rotas atendidas para seu cadastro ficar disponível para os embarcadores.
  </p>

  <!-- Botões pós formulario -->
  <div class="full-width is-flex is-align-items-center is-justify-content-flex-end mt-5">
    <app-button
      title="Finalizar"
      [isDefault]="true"
      (click)="finish()"
    ></app-button>
  </div>
</div>
