import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HeaderModule } from 'src/app/core/header/header.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { CheckAuctionsRoutingModule } from './check-auction-routing.module';

import { CheckAuctionsComponent } from './check-auctions.component';
import { FilterCheckAuctionsComponent } from './filter-check-auctions/filter-check-auctions.component';
import { CheckerAuctionRowComponent } from './checker-auction-row/checker-auction-row.component';

@NgModule({
  declarations: [
    CheckAuctionsComponent,
    FilterCheckAuctionsComponent,
    CheckerAuctionRowComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HeaderModule,
    SharedModule,
    PipesModule,
    CheckAuctionsRoutingModule
  ]
})
export class CheckAuctionsModule { }
