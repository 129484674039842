import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MultipleSelectComponent } from 'src/app/shared/components/multiple-select/multiple-select.component';
import { EditProfileService } from 'src/app/features/auction/edit-profile/edit-profile.service';
import { UserService } from 'src/app/services/user/user.service';
import { Regions } from 'src/app/enums/regions';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {

  @ViewChild('multipleSelectRegions', { static: false }) multipleSelectRegionsComponent: MultipleSelectComponent;

  shippingCompanyName = null;
  cnpj = null;

  selectedRegions = [];
  vehicleTypes = [];
  regions = [];
  branches = [];

  isEditing = false;
  canSaveForm = false;

  constructor(private route: Router, private editProfileService: EditProfileService, private userService: UserService) { }

  async ngOnInit() {
    this.getProfileData();
  }

  goToEditPage() {
    this.route.navigate(['/perfil/transportadora']);
  }

  editRoute() {
    this.isEditing = !this.isEditing;
  }

  saveSelectedRegions(event) {
    this.selectedRegions = event;
    this.validateForm();
  }

  removeFilterOption(filter) {
    this.multipleSelectRegionsComponent.uncheckOption(filter);
    this.selectedRegions = this.selectedRegions.filter(region => region.value !== filter);
  }

  showSelectedRegions() {
    const arrLen = this.selectedRegions.length;
    if (arrLen > 3) {
      return this.selectedRegions.filter((item, idx) => idx > 2).map(item => item.label);
    }
  }

  saveSelectedVehicles(idx) {
    this.vehicleTypes[idx]['checked'] = !this.vehicleTypes[idx]['checked'];
    this.validateForm();
  }

  async saveConfig() {
    const data = {
      vehicleTypes: this.vehicleTypes.filter(i => i['checked']),
      cnpj: this.cnpj,
      routes: this.selectedRegions.flatMap(region => region.ufs)
    };
    await this.editProfileService.setConfiguration(data);
    const user = this.userService.getLoggedUser();
    if (user) {
      user.firstAccessAuction = false;
    }
    this.userService.setLoggedUser(user);
    this.route.navigate(['/leilao/transportadora/ot']);
  }

  async getVehicleTypes() {
    const response = await this.editProfileService.getVehicleTypes();
    if (response && response.object) {
      return response.object;
    }
  }

  validateForm() {
    this.canSaveForm = this.cnpj && this.vehicleTypes.some(i => i['checked']) && this.selectedRegions.length > 0;
  }

  getShippingCompanyInfo() {
    const user = this.userService.getLoggedUser();
    if (user) {
      return user?.shippingCompany;
    }
    return null;
  }

  async getProfileData() {
    const shippingCompany = this.getShippingCompanyInfo();
    const vehicles = await this.getVehicleTypes();
    const regions = Regions;

    const response = await this.editProfileService.getTransporterProfileData();
    if (response) {
      if (response.vehicleTypes && response.vehicleTypes.length > 0) {
        vehicles.forEach(vehicle => {
          const vehicleNames = response.vehicleTypes.map(vt => vt.name);
          vehicle['checked'] = vehicleNames.includes(vehicle.name);
        });
      }
      if (response.routes && response.routes.length > 0) {
        regions.map(region => {
          region['checked'] = response.routes.includes(...region.ufs);
          return region;
        });
      }
      if (response.shippingCompanies && response.shippingCompanies.length > 0) {
        this.branches = response.shippingCompanies;
      }
    }
    this.shippingCompanyName = shippingCompany?.name;
    this.cnpj = (response && response.cnpj) || shippingCompany?.cnpj;
    this.vehicleTypes = vehicles;
    this.regions = regions;
    this.selectedRegions = this.regions.filter(r => r.checked);
    this.validateForm();
  }

}
