<div class="columns">
  <div darkMode class="auction-card column is-12"
    [ngClass]="{'selected': moreDetailsToggle, 'check-auctions-card': tab === 'check'}">
    <div class="columns align-everything-on-center">
      <div class="column align-everything-on-center" *ngIf="tab !== 'check'" [ngClass]="{
                'is-6': !isMoreDetailsOpened,
                'is-12': isMoreDetailsOpened }">
        <div class="columns">
          <div class="column is-1 row-column">
            <label class="checkbox checkbox-toggle">
              <input type="checkbox" name="loadCheckbox" class="checkbox-input-load" [checked]="auction.checked"
                (change)="check($event)">
              <span class="label-toggle-content">
                &nbsp;
              </span>
            </label>
          </div>
          <ng-container
            *ngIf="lot && lot.lastExportDate && tab === 'ended' && !isMoreDetailsOpened; else notDownloaded">
            <div class="column is-1 row-column is-flex has-tooltip-arrow has-tooltip-multiline"
              [attr.data-tooltip]="lot.lastExportDate">
              <i class="material-icons is-medium has-text-primary">get_app</i>
            </div>
          </ng-container>
          <ng-template #notDownloaded>
            <div *ngIf="!isMoreDetailsOpened && tab === 'ended'" class="column row-column is-1 is-flex">
              <i class="material-icons is-medium">get_app</i>
            </div>
          </ng-template>
          <div class="column" *ngIf="!isMoreDetailsOpened">
            <p class="is-size-7">Categoria</p>
            <p>
              <strong class="has-text-primary is-flex">
                {{ lot.comboType === 'roadTrain' ? 'Rodotrem' : lot?.comboType === 'combo' ? 'Combo' : 'Simples' }}
                <i class="material-icons is-medium has-text-black ml-3 has-tooltip-arrow" [attr.data-tooltip]="lot.comboType && lot.comboType === 'combo' ? 'Leilão composto por mais de uma carga' :
                    lot.comboType === 'roadTrain' ? 'Leilão composto por duas ou mais cargas com a mesma origem e destino.':
                    'Leilão composto somente por uma carga.'">
                  error
                </i>
              </strong>
            </p>
          </div>
          <div class="column row-column">
            <p class="is-size-7">{{lot?.comboLots && lot?.comboLots.length > 1 ? 'O.Ts' : 'O.T'}}</p>
            <p>
              <strong>
                {{ lot?.comboLots && lot?.comboLots.length > 1 ? lot?.comboLots.length : lot?.ots }}
              </strong>
            </p>
          </div>
          <div class="column row-column">
            <p class="is-size-7">Origem/UF</p>
            <p>
              <strong>
                {{ lot?.origin ? lot.origin : '-' }}
              </strong>
            </p>
          </div>
          <div class="column row-column">
            <p class="is-size-7">Destino/UF</p>
            <p>
              <strong>
                {{ lot?.destination ? lot.destination : '-' }}
              </strong>
            </p>
          </div>
          <div class="column row-column">
            <p class="is-size-7">Volume</p>
            <p>
              <strong>
                {{ lot?.volume ? lot.volume + 'T' : '-' }}
              </strong>
            </p>
          </div>
          <div class="column is-flex is-justify-content-flex-end"
            *ngIf="isMoreDetailsOpened && auction?.comboType === 'simple'">
            <button class="button is-primary" (click)="hideDetails()" *ngIf="moreDetailsToggle && isMoreDetailsOpened">
              Ver menos
              <span class="material-icons">
                keyboard_arrow_left
              </span>
            </button>
            <button class="button button-thickness" (click)="showDetails()" *ngIf="!moreDetailsToggle">
              Ver mais
              <span class="material-icons">
                keyboard_arrow_right
              </span>
            </button>
          </div>
        </div>
      </div>

      <div class="columns check-auctions" *ngIf="!isMoreDetailsOpened && tab === 'check'">
        <div class="column is-1 row-column is-flex">
          <label class="checkbox checkbox-toggle">
            <input type="checkbox" name="loadCheckbox" class="checkbox-input-load" [checked]="auction.checked"
              (change)="check($event)">
            <span class="label-toggle-content">
              &nbsp;
            </span>
          </label>
        </div>
        <div class="column is-1 row-column">
          <p class="is-size-7">Identificador</p>
          <p>
            <strong>
              {{ auction.identifier ? auction.identifier : '-' }}
            </strong>
          </p>
          <p class="is-size-7">OT</p>
          <p>
            <strong>
              {{ auction?.ots && auction?.ots.length > 0 ? auction.ots.join(', ') : '-' }}
            </strong>
          </p>
        </div>
        <div class="column is-1 row-column">
          <p class="is-size-7">Origem/UF</p>
          <p>
            <strong>
              {{ auction.origin ? auction.origin: '-' }}
            </strong>
          </p>
          <p class="is-size-7">Destino/UF</p>
          <p>
            <strong>
              {{ auction.destination ? auction.destination : '-' }}
            </strong>
          </p>
        </div>
        <div class="column is-1 row-column">
          <p class="is-size-7">Tipo</p>
          <p>
            <strong>
              {{ auction.type && auction.type === 'reverse' ? 'Reverso' : '-' }}
            </strong>
          </p>
          <p class="is-size-7">Volume</p>
          <p>
            <strong>
              {{ auction.volume ? auction.volume : '-' }}T
            </strong>
          </p>
        </div>
        <div class="column is-1 row-column">
          <p class="is-size-7">Data início</p>
          <p>
            <strong>
              {{ auction.startDate | date: 'dd/MM/yyyy':'+0'}}
            </strong>
          </p>
          <p class="is-size-7">Data término</p>
          <p>
            <strong>
              {{ auction.endDate | date: 'dd/MM/yyyy':'+0'}}
            </strong>
          </p>
        </div>
        <div class="column is-1 row-column">
          <p class="is-size-7">Lances</p>
          <p>
            <strong>
              {{ auction.totalBids ? auction.totalBids : '-' }}
            </strong>
          </p>
          <p class="is-size-7">Último lance</p>
          <p>
            <strong>
              {{ auction.lastBid | currency: 'R$' }}
            </strong>
          </p>
        </div>
        <div class="column is-1 row-column">
          <p class="is-size-7">Valor inicial</p>
          <p>
            <strong>
              {{ auction.initialValue | currency: 'R$' }}
            </strong>
          </p>
          <p class="is-size-7">Valor final</p>
          <p>
            <strong>
              {{ auction.finalValue | currency: 'R$' }}
            </strong>
          </p>
        </div>
        <div class="column is-1 row-column">
          <p class="is-size-7">Veículo</p>
          <p>
            <strong>
              {{ auction.vehicle ? auction.vehicle : '-' }}
            </strong>
          </p>
          <p class="is-size-7">Delta</p>
          <p>
            <strong [ngClass]="auction.delta > 0 ? 'has-text-primary' : 'has-text-danger'">
              {{ auction.delta | currency: 'R$' }}
            </strong>
          </p>
        </div>
        <div class="column is-2 row-column">
          <p class="is-size-7">Transportadora</p>
          <p>
            <strong>
              {{ auction.shippingCompany ? auction.shippingCompany : '-' }}
            </strong>
          </p>
          <p class="is-size-7">Cód. Transportador</p>
          <p>
            <strong>
              {{ auction.transporter ? auction.transporter : '-' }}
            </strong>
          </p>
        </div>
        <div class="column is-2 row-column">
          <p class="is-size-7">Data da expedição</p>
          <p>
            <strong>
              {{ auction.shippingDate | date: 'dd/MM/yyyy':'+0'}}
            </strong>
          </p>
          <p class="is-size-7">Data de carregamento</p>
          <p>
            <strong>
              {{ auction.loadDate | date: 'dd/MM/yyyy':'+0'}}
            </strong>
          </p>
        </div>
      </div>

      <div class="column align-everything-on-center" *ngIf="!isMoreDetailsOpened && tab === 'executing'">
        <div class="columns">
          <div class="column is-2 row-column">
            <p class="is-size-7">Valor inicial</p>
            <p>
              <strong>
                {{ lot?.initialValue | currency: 'R$' }}/T
              </strong>
            </p>
          </div>
          <div class="column row-column">
            <p class="is-size-7">Lance atual</p>
            <p>
              <strong>
                {{ lot?.minBidValue | currency: 'R$' }}
              </strong>
            </p>
          </div>
          <div class="column row-column">
            <p class="is-size-7">Delta</p>
            <p>
              <strong>
                {{ lot?.delta | currency: 'R$' }}
              </strong>
            </p>
          </div>
          <div class="column row-column">
            <p class="is-size-7">Lances</p>
            <p>
              <strong>
                {{ lot?.totalBids }}
              </strong>
            </p>
          </div>
          <div class="column row-column is-flex is-align-items-center auction-time-left">
            <p *ngIf="pendingTime" class="pending-time has-tooltip-arrow" [attr.data-tooltip]="pendingTime">
              <span class="material-icons">
                access_time
              </span>
              <strong *ngIf="isEnding">
                {{ pendingTime }}
              </strong>
            </p>
          </div>
          <div class="column row-column is-flex is-justify-content-flex-end" *ngIf="auction?.comboType === 'simple'">
            <button class="button is-primary" (click)="hideDetails()" *ngIf="moreDetailsToggle && isMoreDetailsOpened">
              Ver menos
              <span class="material-icons">
                keyboard_arrow_left
              </span>
            </button>
            <button class="button button-thickness" (click)="showDetails()" *ngIf="!moreDetailsToggle">
              Ver mais
              <span class="material-icons">
                keyboard_arrow_right
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="column align-everything-on-center" *ngIf="!isMoreDetailsOpened && tab === 'pending'">
        <div class="columns">
          <div class="column is-2 row-column">
            <p class="is-size-7">Valor inicial</p>
            <p>
              <strong>
                {{ lot?.initialValue | currency: 'R$' }}/T
              </strong>
            </p>
          </div>
          <div class="column is-5 row-column auction-time-left">
            <p class="is-size-12">Início em {{ lot.startDate | date: 'dd/MM/yyyy':'+0' }} às
              {{ lot.startDate | date: 'HH:mm':'+0' }}hrs</p>
            <p class="is-size-7">
              <strong>
                até {{ lot.endDate | date: 'dd/MM/yyyy':'+0' }} às {{ lot.endDate | date: 'HH:mm':'+0' }}hrs
              </strong>
            </p>
          </div>
          <div class="column is-2 row-column is-flex is-justify-content-flex-end">
            <button class="btn-edit-auction" (click)="editAuction()">
              <span class="material-icons">
                create
              </span>
              Editar
            </button>
          </div>
          <div class="column row-column is-flex is-justify-content-flex-end" *ngIf="auction?.comboType === 'simple'">
            <button class="button is-primary" (click)="hideDetails()" *ngIf="moreDetailsToggle && isMoreDetailsOpened">
              Ver menos
              <span class="material-icons">
                keyboard_arrow_left
              </span>
            </button>
            <button class="button button-thickness" (click)="showDetails()" *ngIf="!moreDetailsToggle">
              Ver mais
              <span class="material-icons">
                keyboard_arrow_right
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="column align-everything-on-center" *ngIf="!isMoreDetailsOpened && tab === 'ended'">
        <div class="columns">
          <div class="column is-5 align-everything-on-center">
            <div class="columns">
              <div class="column is-4 row-column">
                <p class="is-size-7">Valor inicial</p>
                <p>
                  <strong>
                    {{ lot?.initialValue | currency: 'R$' }}/T
                  </strong>
                </p>
              </div>
              <div class="column row-column">
                <p class="is-size-7">Lance final</p>
                <p>
                  <strong>
                    {{lot?.lastBid | currency:'R$'}}/T
                  </strong>
                </p>
              </div>
              <div class="column row-column">
                <p class="is-size-7">Delta</p>
                <p>
                  <strong>
                    {{lot?.delta | currency:'R$'}}
                  </strong>
                </p>
              </div>
            </div>
          </div>
          <div class="column align-everything-on-center">
            <div class="columns">
              <div class="column is-3 row-column">
                <p class="is-size-7">Lances</p>
                <p>
                  <strong>
                    {{ lot.totalBids ? lot.totalBids : 0 }}
                  </strong>
                </p>
              </div>
              <div class="column row-column">
                <p class="is-size-7">Transportadora</p>
                <p>
                  <strong>
                    {{ lot?.shippingCompany || '-' }}
                  </strong>
                </p>
              </div>
            </div>
          </div>
          <div class="column is-2 is-flex row-column">
            <div *ngIf="lot && lot.integratedByPromisor">
              <span class="confirmed-tag has-background-primary">
                Confirmado
              </span>
            </div>
            <div *ngIf="!auction.canceled">
              <span class="confirmed-tag has-background-grey" [ngClass]="
                {'has-background-primary': lot.status === 'Confirmado',
                  'has-background-grey': lot.status === 'Não confirmado' || lot.status === 'Deletado' || lot.status === 'Integrando',
                  'has-background-danger': lot.status === 'Erro'}">
                {{ lot.status }}
              </span>
            </div>
            <div *ngIf="auction.canceled">
              <span class="confirmed-tag has-background-danger">
                Cancelado
              </span>
            </div>
          </div>
          <div class="column row-column is-flex is-justify-content-flex-end" *ngIf="auction?.comboType === 'simple'">
            <button class="button is-primary" (click)="hideDetails()" *ngIf="moreDetailsToggle && isMoreDetailsOpened">
              Ver menos
              <span class="material-icons">
                keyboard_arrow_left
              </span>
            </button>
            <button class="button button-thickness" (click)="showDetails()" *ngIf="!moreDetailsToggle">
              Ver mais
              <span class="material-icons">
                keyboard_arrow_right
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="columns">
  <div class="column is-12 show-auction-loads" *ngIf="auction?.ots.length > 1"
    [ngClass]="isShowLoads ? getRowHeigth(auction?.ots.length) : null">
    <div class="loads" *ngIf="isShowLoads" [ngClass]="{'fade-in': isShowLoads, 'fade-out': !isShowLoads}">
      <div class="load columns is-12" *ngFor="let combo of lot?.comboLots; let i = index">
        <div class="column is-1 row-column">
          <p class="is-size-7">Carga</p>
          <p>
            <strong>
              Nº {{ i + 1 }}
            </strong>
          </p>
        </div>
        <div class="column is-1 row-column">
          <p class="is-size-7">O.T</p>
          <p>
            <strong>
              {{ combo?.ots ? combo?.ots : null }}
            </strong>
          </p>
        </div>
        <div class="column is-2 row-column">
          <p class="is-size-7">Origem</p>
          <p>
            <strong>
              {{ combo.origin ? combo.origin : null }}
            </strong>
          </p>
        </div>
        <div class="column is-2 row-column">
          <p class="is-size-7">Destino</p>
          <p>
            <strong>
              {{ combo.destination ? combo.destination : null }}
            </strong>
          </p>
        </div>
        <div class="column is-1 row-column">
          <p class="is-size-7">Distância</p>
          <p>
            <strong>
              {{ combo.distance ? combo.distance : null }}Km
            </strong>
          </p>
        </div>
        <div class="column is-1 row-column">
          <p class="is-size-7">Volume</p>
          <p>
            <strong>
              {{ combo.finalWeight ? combo.finalWeight : null }}T
            </strong>
          </p>
        </div>
        <div class="column is-2 row-column">
          <p class="is-size-7">Data de expedição</p>
          <p>
            <strong>
              {{ combo.shippingDate | date: 'dd/MM/yyyy':'+0'}}
            </strong>
          </p>
        </div>
        <div class="column row-column is-flex is-justify-content-flex-end">
          <button class="button has-background-grey has-text-white" (click)="hideDetails()"
            *ngIf="moreDetailsToggle && isMoreDetailsOpened && selectedLot === combo._id">
            Ver menos
            <span class="material-icons">
              keyboard_arrow_left
            </span>
          </button>
          <button class="button button-thickness" (click)="showDetails(combo)" *ngIf="!moreDetailsToggle">
            Ver mais
            <span class="material-icons">
              keyboard_arrow_right
            </span>
          </button>
        </div>

      </div>
    </div>
    <span class="show-loads-button columns" (click)="showLoads()">
      <p>{{ isShowLoads ? "Ocultar cargas" : "Exibir cargas" }}</p>
      <i class="material-icons is-medium has-text-black arrow" [ngClass]="{'rotate': isShowLoads}">expand_more</i>
    </span>
  </div>
</div>