<app-data-item *ngIf="auction">
  <h6>Status</h6>
  <b
    *ngIf="!isSAPDeleted"
    class="app-auction-data-item"
    [ngClass]="
      {
        'app-auction-data-item__primary': auction?.status === 'Confirmado',
        'app-auction-data-item__gray': auction?.status === 'Não confirmado'
          || auction?.status === 'Deletado'
          || auction?.status === 'Integrando',
        'app-auction-data-item__danger': auction?.status === 'Erro'
          || auction?.canceled
      }
    "
  >
      {{ auction?.canceled ? 'Cancelado' : auction?.status }}
  </b>
  <b *ngIf="isSAPDeleted" class="app-auction-data-item__danger">
    Deletado SAP
  </b>
</app-data-item>
