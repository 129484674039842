export const Scores = {
  Ótimo: {
    min: 80,
    max: 100
  },
  Regular: {
    min: 50,
    max: 79
  },
  Insuficiente: {
    max: 49,
    min: 0
  }
};

export const translatedScore = (percent) => {
  let result;
  Object.entries(Scores).forEach(([score, range]) => {
    if (percent >= range.min && percent <= range.max) {
      result = score;
    }
  });
  return result;
};
