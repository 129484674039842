import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';
import { Tab } from 'src/app/shared/components/tabs/tabs.component';
import { GlobalNotificationService } from 'src/app/shared/services/global-notification.service';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent implements OnInit, OnDestroy {

  @Input() menuToggled = false;
  @Input() type: string;
  @Output() handleShow = new EventEmitter<any>();
  @Output() handleDelete = new EventEmitter<any>();
  @Input() notifications = [];
  countNotifications = 0;

  notificationsByModule = [];
  modules = ['leilão', 'monitoramento', 'programação', 'repasse', 'tracking'];
  searchedText = '';

  isCheckedAllNotifications = false;

  notificationsTabs: Tab[] = [
    { title: 'Novas', isActive: true, route: 'new' },
    { title: 'Todas', isActive: false, route: 'all' },
  ];


  constructor(
    private notificationService: GlobalNotificationService,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit() {
    this.composeNotifications();
  }

  composeNotifications() {
    this.composeMenu();
  }

  async composeMenu() {
    this.notificationsByModule = this.modules.map(module =>
      ({
        module,
        count: 0,
        total: 0,
        notifications: [],
        collapsed: false
       })
    );
    this.notificationsTabs.forEach(t => t.isActive = this.type === t.route);
    await this.getNotifications(0, 10, this.type, false);
  }

  async getNotifications(page = 0, limit = 10, type = 'all', searching = false) {
    if (searching) {
      this.notificationsTabs.forEach(nt => nt.isActive = nt.route === 'all');
    }
    await Promise.all(this.modules.map(async module => {
      const result = await this.notificationService.getNotifications(page, limit, type, module, searching ? this.searchedText : '');
      const index = this.notificationsByModule.findIndex(m => m.module === module);
      if (this.notificationsByModule[index]) {
        this.notificationsByModule[index].count = result.count;
        this.notificationsByModule[index].total = result.total;
        this.notificationsByModule[index].notifications = result.notifications;
        this.notificationsByModule[index].collapsed = !!(searching && this.searchedText && result.notifications.length);
      }
      return result;
    }));
    this.countNotifications = this.notificationService.getCountNotifications();
  }

  async getNotificationsByModule(page = 0, limit = 10, module = '') {
    const result = await this.notificationService.getNotifications(page, limit, 'all', module);
    const index = this.notificationsByModule.findIndex(m => m.module === module);
    if (this.notificationsByModule[index]) {
      this.notificationsByModule[index].count = result.count;
      this.notificationsByModule[index].total = result.total;
      this.notificationsByModule[index].notifications = result.notifications;
    }
  }

  handleToggle() {
    this.handleShow.emit();
  }

  async handlePagination({ module, page }) {

    await this.getNotificationsByModule(page, 10, module);
  }

  goToRoute(route) {
    if (route.startsWith('http')) {
      window.open(route, '_blank');
    } else {
      const paramsObj = {};
      const routeParams = route.split('?').length > 1 ? route.split('?')[1] : null;
      route = route.split('?')[0];
      if (routeParams) {
        routeParams.split('&').forEach(element => {
          if (element) {
            const prop = element.split('=')[0];
            const value = element.split('=')[1];
            paramsObj[prop] = value;
          }
        });
      }
      this.router.navigate([route, paramsObj]);
    }
  }

  async deleteNotifications({ id, module }) {
    if (id) {
      this.toggleCollapsible(module);
      await this.notificationService.deleteNotifications(id);
      await this.getNotificationsByModule(0, 10, module);
    } else {
      await this.notificationService.deleteAllNotificationsByModule(module);
      await this.getNotificationsByModule(0, 10, module);
    }
    const result = await this.notificationService.getNotifications(0, 50, 'all');
    if (result) {
      this.notificationService.setAllNotifications({
        notifications: result.notifications,
        count: result.count
      });
    }
  }

  ngOnDestroy() {
    const notifications = this.notificationsByModule.flatMap(nbm => nbm.notifications.map(n => n));
    this.notificationService.markNotificationsAsRead(notifications);
    this.userService.getNotifications();
  }

  toggleCollapsible(moduleName) {
    this.notificationsByModule.forEach(m => {
      m.collapsed = m.module === moduleName;
      return m;
    });

  }

  changeTab(tab: Tab) {
    if (tab && tab.route) {
      this.type = tab.route;
      this.getNotifications(0, 10, this.type, false);
    }
  }

  handleToggleCollapsible(idx: number) {
    this.notificationsByModule[idx].collapsed = !this.notificationsByModule[idx].collapsed;
  }

}
