<div class="card-notification" *ngIf="module">
  <div class="example-action-buttons">
      <div type="button" class="collapsible is-shadowed-small" [ngClass]="{'has-text-primary has-text-weight-bold': showNotifications}">
          <span class="is-clickable" (click)="toggleNotifications()">
            {{ (module | uppercase) }} ({{ total || 0 }})
          </span>
          <app-button title="Apagar todas" size="small" variant="link" color="danger"
          icon="delete" (handleClickEmitter)="deleteAll()" *ngIf="showNotifications">
          </app-button>
          <div id="selector" class="is-clickable" *ngIf="showNotifications" (click)="toggleNotifications()">
            <i class="material-icons">chevron_right</i>
          </div>
          <div id="selector2" class="is-clickable" *ngIf="!showNotifications" (click)="toggleNotifications()">
            <i class="material-icons">chevron_right</i>
          </div>
        </div>
      <div class="content notification-container" *ngIf="showNotifications">
        <ng-container *ngIf="notifications && notifications.length; else noNotifications">
          <ng-container *ngFor="let notification of notifications">
            <div class="notification-card is-flex is-justify-content-space-between is-align-items-center is-shadowed-small h-100 pl-5 mb-2 mx-2"
            [ngClass]="
            {
              'notification-border-left-warning': notification.type === 'warning',
              'notification-border-left-error': notification.type === 'error',
              'notification-border-left-success': notification.type === 'success'
            }">
              <div class="py-5">
                <p *ngIf="notification.subtitle" class="has-text-weight-bold">
                  {{ notification.subtitle }}
                </p>
                <span>
                  {{ notification.message }}
                </span>
              </div>
              <div class="is-flex is-flex-direction-column">
                <div class="is-flex">
                  <app-button *ngIf="notification && notification.route"
                  icon="chevron_right"
                  [isSecondary]="true"
                  [isBorderless]="true"
                  (handleClickEmitter)="goToRoute(notification.route)"></app-button>
                  <app-button
                  icon="close"
                  [isSecondary]="true"
                  [isBorderless]="true"
                  (handleClickEmitter)="delete(notification._id)"></app-button>
                </div>
                <div class="column has-text-right is-size-6">
                  {{ getNotificationTimeLabel(notification) }}
                </div>
              </div>
            </div>
          </ng-container>

          <div class="columns">
            <div class="column is-12 has-text-centered">
              <app-pagination (handleNextPage)="nextPage($event)" (handlePreviousPage)="previousPage($event)"
              [totalRegisters]="total" [pageSize]="10">
            </app-pagination>
            </div>
          </div>
        </ng-container>
        <ng-template #noNotifications>
          <div class="is-flex is-justify-content-center h-100 pl-5">
              <div class="py-5">
                <p class="has-text-weight-bold">
                  Não existem notificações de {{ module }}
                </p>
              </div>
            </div>
        </ng-template>
      </div>
  </div>
</div>
