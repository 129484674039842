import { AfterViewInit, Component, ContentChildren, ElementRef, EventEmitter, Input, Output, QueryList } from '@angular/core';

type NavigateSide = 'right' | 'left';

export type NavigateEvent = {
  side: NavigateSide;
  items: ElementRef<any>[];
  currentIndex: number;
  currentItem: ElementRef<any>;
  reachedLimit: boolean;
};

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements AfterViewInit {

  @Input() offsetLimit = 2;
  @Input() hasMore = true;
  @Output() hasNavigated = new EventEmitter<NavigateEvent>();

  @ContentChildren('item', { descendants: true }) queryItems: QueryList<ElementRef>;

  currentItem = 0;
  initialized = false;

  constructor() {
  }

  get items(): ElementRef<any>[] {
    return this.queryItems.toArray();
  }

  get maxItems(): number {
    return this.queryItems.length;
  }

  ngAfterViewInit(): void {
    this.setClasses();
  }

  setClasses() {
    this.queryItems?.changes.subscribe({
      next: () => {
        if (!this.initialized) {
          this.items[0].nativeElement.classList.add('current-item');
          this.initialized = true;
        } else {
          this.items[this.currentItem].nativeElement.classList.add('current-item');
        }
      }
    });
  }

  handleNavigate(side: NavigateSide) {

    if (this.currentItem === this.maxItems && !this.hasMore) {
      this.currentItem = 0;
    }

    this.items[this.currentItem]?.nativeElement.scrollIntoView({
      inline: 'center',
      behavior: 'smooth',
    });

    this.items.forEach(({ nativeElement }, idx) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      (this.currentItem === idx)
        ? nativeElement.classList.add('current-item')
        : nativeElement.classList.remove('current-item');
    });

    this.hasNavigated.emit({
      side,
      items: this.items,
      currentIndex: this.currentItem,
      currentItem: this.items[this.currentItem],
      reachedLimit: this.currentItem !== 0
        && this.currentItem >= this.offsetLimit
    });

  }

  navigateRight() {
    this.currentItem += 1;
    this.handleNavigate('right');
  }

  navigateLeft() {
    this.currentItem -= 1;
    this.handleNavigate('left');
  }
}
