import { Component, Input, OnDestroy, OnInit } from '@angular/core';
@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit, OnDestroy {

  @Input() size = '500px';
  @Input() slides;
  @Input() canLoop = false;
  @Input() noControls = false;
  @Input() noCover = false;
  @Input() transition;

  currentSlide = 0;
  transitionTimer = null;

  constructor() { }

  ngOnInit(): void {
    this.loop();
  }

  onPreviousClick() {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? (this.canLoop ? (this.slides.length - 1) : this.currentSlide) : previous;
  }

  onNextClick() {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.slides.length ? (this.canLoop ? 0 : this.currentSlide) : next;
  }

  changeSlide(slideIndex) {
    this.currentSlide = slideIndex;
    this.clearTransition();
    this.loop();
  }

  loop() {
    if (this.transition) {
      this.transitionTimer = setInterval(() => {
        this.onNextClick();
      }, this.transition);
    }
  }

  clearTransition() {
    clearInterval(this.transitionTimer);
  }

  ngOnDestroy(): void {
    this.clearTransition();
  }

}
