import { Component, Input } from '@angular/core';

type Bid = {
  value: string;
  createdAt: string;
  isUserBid?: boolean;
  partnerName?: string;
};

@Component({
  selector: 'app-auction-details-bids-list',
  templateUrl: './auction-details-bids-list.component.html',
  styleUrls: ['./auction-details-bids-list.component.scss']
})
export class AuctionDetailsBidsListComponent {
  @Input() bids: Bid[] = [];

  constructor() { }
}
