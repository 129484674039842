import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { DarkModeService } from '@services/dark-mode.service';

@Directive({
  selector: '[darkMode]'
})
export class DarkModeDirective { 

  constructor(
    private el: ElementRef, 
    private renderer: Renderer2, 
    private darkMode: DarkModeService) {
  }

  ngOnInit() {
    this.darkMode.toggled$.subscribe((isDarkMode: boolean) => {
      this.updateClass(isDarkMode);
    });
  }

  private updateClass(isDarkMode: boolean): void {
    if (isDarkMode) {
      this.renderer.addClass(this.el.nativeElement, 'dark');
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'dark');
    }
  }

}
