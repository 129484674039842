<div class="checkbox-container is-flex is-justify-content-start is-align-items-center">
  <label class="checkbox">
    <input type="checkbox" [attr.disabled]="isDisabled ? true: null" [(ngModel)]="value" [formControl]="control" (change)="toogleChecked()">
    <div></div>
  </label>

  <p class="is-size-5" *ngIf="label">
    {{ label }}
  </p>
</div>



