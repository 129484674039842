<div darkMode class="app-auction-details-container">
  <app-popup 
  title="Detalhes do leilão"
  [noHeader]="false"
  [isVisible]="true"
  [isSmall]="true"
  (handleClose)="handleClose()"
  >
    <div class="app-auction-details-container__wrapper">
      <header class="app-auction-details-container__header">
        <div class="app-auction-details-container__header-title">
          <app-auction-id-tag [_id]="_id"></app-auction-id-tag>
        </div>
      </header>
  
      <div class="app-auction-details-container__content">
        <ng-content></ng-content>
      </div>
    </div>
  </app-popup>
</div>
