<div class="banner-suggestion">
    <div class="banner-suggestion-information">
        <div class="span-group is-flex">
            <span class="span-intelligent is-flex is-align-items-center">
                <i class="material-icons-outlined" aria-hidden="true">
                    lightbulb
                </i>
                Cargas inteligentes
            </span>
            <span class="span-recommendation is-flex is-align-items-center">
                <i class="material-icons">
                    flash_on
                </i>
                Recomendadas para você
            </span>
        </div>
        <h2 class="is-size-2 mt-4 mb-3">
            A inteligência do Loadshark te ajuda a fechar o melhor negócio!
        </h2>
        <p class="is-size-6">
          Com base na localização dos veículos e histórico de rotas já realizadas, o LoadShark realiza a melhor recomendação de rotas do leilão.
          <br><br>
          As recomendações serão exibidas através de uma etiqueta <strong>RECOMENDADAS PARA VOCÊ</strong>.
        </p>
    </div>
</div>
