import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { Alert } from 'src/app/interfaces/alert';
import { APIDataRegister } from 'src/app/interfaces/ApiResponse';
import { environment } from 'src/environments/environment';

export type AlertPayload = {
  visible: boolean;
  content: any;
};

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  public alertGet: any;
  public visible$ = new BehaviorSubject<AlertPayload>({ visible: false, content: null });
  private _isToggled = false;

  constructor(private _http: HttpClient) { }

  public get isToggled(): Observable<AlertPayload> {
    return this.visible$;
  }

  getAlertCheck(): Observable<Alert[]> {
    return this._http.get<APIDataRegister<Alert[]>>(`${environment.api.meta.url}/user/v1/alert/check`).pipe(
      map(response => response.data)
    );
  }

  getAlert(): Observable<Alert[]> {
    return this._http.get<Alert[]>(`${environment.api.meta.url}/user/v1/alert/list`);
  }

  public postAlert(alert: []) {
    return this._http.post(`${environment.api.meta.url}/user/v1/alert/save`, alert);
  }

  toggleAlert(content) {
    this.visible$.next({ visible: !this._isToggled, content });
  }

}

