export const endpointsWithoutLoading = [
    'order/v1/hasmodified',
    'order/v1/getmodified',
    'user/v1/profile',
    'ses/v1/notification/markNotificationsAsRead',
    'transportation/v1/importTrackingOccurences',
    'unload/v1/unloadWindow/shipper',
    'unload/v1/unloadWindow/dashboard',
    'user/v1/shippingCompany/0/1000',
    'ses/v1/notification/getNotifications',
    'order/v1/userOrder',
    'auction/v1/bid/check/',
    'auction/v1/deal/integrate/setTransporter/',
    'auction/v1/lot/shippingcompany/finishedLotsNotIntegrated/',
    'auction/v1/deal/listAutomaticIntegratedByCNPJ/',
    'auction/v1/lot/shippingcompany/executinglots/',
    'auction/v1/bid/check/',
    'auction/v1/lot/getBidsUser/',
    'auction/v1/lot/shippingcompany/finishedlots/',
    'auction/v1/lot/getAuctionPending',
    'auction/v1/lot/getAuctionExecuting',
    'auction/v1/lot/getLotsModified',
    'auction/v1/lot/getAuctionEnded',
    'user/v1/preference/saveLastFilterByUser/auction',
    'extraload/v1/transfer/0/10',
    'extraload/v1/combinedtransfer/0/10',
    'extraload/v1/transfer/countEmbarcador',
    'user/v1/organizationConfiguration/getOwnOrganizationConfiguration'
];
