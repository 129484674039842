<h1 *ngIf="type === 'headline' && variant === 'first'">
  <ng-container *ngTemplateOutlet="text"></ng-container>
</h1>

<h2 *ngIf="type === 'headline' && variant === 'second'">
  <ng-container *ngTemplateOutlet="text"></ng-container>
</h2>

<h3 *ngIf="type === 'headline' && variant === 'third'">
  <ng-container *ngTemplateOutlet="text"></ng-container>
</h3>

<h4 *ngIf="type === 'headline' && variant === 'fourth'">
  <ng-container *ngTemplateOutlet="text"></ng-container>
</h4>

<h5 *ngIf="type === 'headline' && variant === 'fifth'">
  <ng-container *ngTemplateOutlet="text"></ng-container>
</h5>

<span [ngClass]="variant" *ngIf="type === 'subtitle'">
  <ng-container *ngTemplateOutlet="text"></ng-container>
</span>

<p [ngClass]="variant" *ngIf="type === 'paragraph'">
  <ng-container *ngTemplateOutlet="text"></ng-container>
</p>

<caption [ngClass]="variant" *ngIf="type === 'caption'">
  <ng-container *ngTemplateOutlet="text"></ng-container>
</caption>

<label *ngIf="type === 'label'">
  <ng-container *ngTemplateOutlet="text"></ng-container>
</label>

<span [ngClass]="variant" *ngIf="type === 'span'">
  <ng-container *ngTemplateOutlet="text"></ng-container>
</span>

<ng-template #text>
  <ng-content></ng-content>
</ng-template>
