<footer class="footer" *ngIf="footerVisible">
  <div class="columns">
    <div class="column">
      <h1 class="is-size-5 has-text-white has-text-weight-bold  ">
        Nossa Política de Privacidade
      </h1>
    <p class="is-size-6 has-text-white ">
      O site do Loadshark utiliza cookies para oferecer uma melhor experiência online. <br>
      Para mais informações, consulte a nossa <a (click)="openModal()">Política de Privacidade</a>
      </p>
  </div>
  <div class="column is-flex is-align-items-center">
    <app-button
    class="mr-2"
    title="FECHAR"
    [isSmall]="true"
    [isSecondary]="true"
    (handleClickEmitter)="closefooter()">
    </app-button>
    <app-button
    title="ACEITAR"
    [isSmall]="true"
    (handleClickEmitter)="acceptCookies()">
    </app-button>
  </div>
  </div>
</footer>
<app-popup [isVisible]="isVisible" (handleClose)="closeModal()">
  <app-privacy-policy-content></app-privacy-policy-content>
</app-popup>
