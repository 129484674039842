import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-auction-list',
  templateUrl: './auction-list.component.html',
  styleUrls: ['./auction-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuctionListComponent implements OnInit {

  @Input() expanded: boolean;

  constructor() { }

  ngOnInit() {
  }

}
