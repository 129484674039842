import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeroComponent implements OnInit {

  @Input() title = '';
  @Input() subtitle = '';
  @Input() imageUrl = '';
  @Input() gradientColorStr = 'rgba(0, 0, 0, 0.9) 25%, rgba(0, 0, 0, 0)';
  @Input() height = 150;
  @Input() buttonIcon = '';
  @Input() buttonTitle = '';
  @Input() buttonDisabled = false;
  @Input() textColor = '#fff';
  @Output() handleCTAEmitter = new EventEmitter();

  constructor() { }

  get background(): string {
    return `linear-gradient(to right, ${this.gradientColorStr}), url(${this.imageUrl})`;
  }

  ngOnInit(): void {
  }


}
