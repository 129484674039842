export enum deliveryStatus {
    'offered' = 'Ofertado',
    'accepted' = 'Aceito',
    'loadingOnTime' = 'Agendado - No horário',
    'loadingDelayed' = 'Agendado - Atrasado',
    'loadWaiting' = 'Aguar. carregamento',
    'loading' = 'Carregando',
    'ended' = 'Carregamento finalizado',
    'rescheduleAllowed' = 'Liberado reagendamento',
    'refused' = 'Recusado',
    'rejected' = 'Rejeitado',
    'scheduled' = 'Agendado',
    'notOffered' = 'Não ofertado',
    'onTime' = 'No horário',
    'withoutOtNumber' = 'Sem O.T',
    'scheduledOnTime' = 'No horário',
    'scheduleDelayed' = 'Atrasado',
    'endedLoading' = 'Finalizado',
    'deleted' = 'Deletado'
}
