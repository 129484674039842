import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GlobalNotificationService } from '../../shared/services/global-notification.service';
import { LoaderService } from '../../shared/services/loader.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(
    private http: HttpClient,
    private globalNotification: GlobalNotificationService,
    private loaderService: LoaderService
  ) { }

  async listOrders(date) {
    try {
      const result = await this.http.get(environment.api.meta.url + environment.api.orders + '?dateProgramming=' + date).toPromise();
      return result;
    } catch (e) {
      this.loaderService.hide();
      this.globalNotification.notification$.next({
        title: 'Falha ao carregar pedidos',
        type: 'error',
        message: e.message
      });
      return null;
    }
  }

  async updateOrder(orderId, params) {
    try {
      const result = await this.http.patch(environment.api.meta.url + environment.api.order + '/' + orderId, params).toPromise();
      this.globalNotification.notification$.next({
        title: 'Pedido atualizado',
        type: 'success',
        message: 'Pedido atualizado com sucesso!'
      });
      return result;
    } catch (e) {
      this.loaderService.hide();
      this.globalNotification.notification$.next({
        title: 'Falha ao atualizar pedido',
        type: 'error',
        message: e.message
      });
      return null;
    }
  }

  async getOrdersStatus(planningOrdersFilter) {
    try {
      const result = await this.http.post(environment.api.meta.url + environment.api.order + '/deliveryStatus', planningOrdersFilter).toPromise();
      return result && result['data'] ? result['data'] : [];
    } catch (e) {
      console.error(e.message);
      this.loaderService.hide();
      this.globalNotification.notification$.next({
        title: 'Não foi possível carregar o status de entrega do pedido',
        type: 'error',
        message: 'Recarregue a página. ' + e.message
      });
      return null;
    }
  }

  async getPlanningHash(dateProgramming, routes) {
    try {
      let url = environment.api.meta.url + environment.api.orderHash + '/hasmodified/' + dateProgramming + '/?';
      routes.forEach(o => {
        url += 'routes=' + o + '&';
      });
      const result = await this.http.get(url).toPromise();
      return result && result['data'] ? result['data'] : [];
    } catch (e) {
      console.error(e.message);
      this.loaderService.hide();
      return null;
    }
  }

  async getOrdersModifiedsResume(dateProgramming, routes, newerThanDate) {
    try {
      newerThanDate = newerThanDate ? `newerThanDate=${newerThanDate}&` : '';
      let url = environment.api.meta.url + environment.api.orderHash + '/getmodified/' + dateProgramming + '/?' + newerThanDate;
      routes.forEach(o => {
        url += 'routes=' + o + '&';
      });
      const result = await this.http.get(url).toPromise();
      return result && result['data'] ? result['data'] : [];
    } catch (e) {
      console.error(e.message);
      this.loaderService.hide();
      return null;
    }
  }

  async listUserOts() {
    try {
      const url = environment.api.meta.url + environment.api.userOrder;
      const result = await this.http.get(url).toPromise();
      this.setUserOrdersCountCached(result['data']['total'] ? result['data']['total'] : 0);
      return result && result['data'] && result['data']['userOrders'] ? result['data']['userOrders'] : [];
    } catch (e) {
      console.error(e.message);
      this.loaderService.hide();
      return null;
    }
  }

  private setUserOrdersCountCached(count) {
    localStorage.setItem('userOrdersCount', count);
  }

  async addUserOrder(otNumber) {
    try {
      const url = environment.api.meta.url + environment.api.userOrder + '/' + otNumber;
      const result = await this.http.post(url, {}).toPromise();
      await this.listUserOts();
      localStorage.setItem('lastOtSearch', otNumber);
      this.globalNotification.notification$.next({
        title: 'O.T salva',
        type: 'success',
        message: 'O.T salva na lista com sucesso!'
      });
      return result && result['data'] ? result['data'] : [];
    } catch (e) {
      console.error(e.message);
      if (e.error.errors.filter(e => e === 'Essa O.T já existe na lista do usuário.').length > 0) {
        this.globalNotification.notification$.next({
          title: 'O.T já existe',
          type: 'warning',
          message: 'A O.T ' + otNumber + ' já está na sua lista de O.Ts salvas'
        });
        return otNumber;
      }
      this.globalNotification.notification$.next({
        title: 'Falha ao buscar O.T',
        type: 'error',
        message: e.error && e.error.errors ? e.error.errors.join(', ') : e.message
      });
      this.loaderService.hide();
      return null;
    }
  }

  async deleteUserOrder(ots) {
    try {
      const url = environment.api.meta.url + environment.api.userOrder;
      const result: any = await this.http.request('delete', url, { body: ots }).toPromise();

      await this.listUserOts();
      if (result.data.length === 1) {
        this.globalNotification.notification$.next({
          title: 'O.T removida',
          type: 'success',
          message: 'A O.T ' + result.data[0].otNumber + ' foi removida da sua lista de O.Ts salvas.'
        });
      } else {
        this.globalNotification.notification$.next({
          title: 'O.Ts removidas',
          type: 'success',
          message: result.data.length + ' O.Ts foram removidas da sua lista de O.Ts salvas.'
        });
      }
      return result && result['data'] ? result['data'] : [];
    } catch (e) {
      console.error(e.message);
      this.loaderService.hide();
      this.globalNotification.notification$.next({
        title: 'Falha ao remover O.T',
        type: 'error',
        message: e.errors ? e.errors.join(', ') : e.message
      });
      return null;
    }
  }

  async getOrderByOtNumber(otNumber) {
    try {
      const url = environment.api.meta.url + environment.api.orderHash + '/searchByOtNumber/' + otNumber;
      const result = await this.http.get(url).toPromise();
      return result && result['data'] ? result['data'] : [];
    } catch (e) {
      console.error(e.message);
      this.loaderService.hide();
      this.globalNotification.notification$.next({
        title: 'Falha ao buscar O.T',
        type: 'error',
        message: e.errors ? e.errors.join(', ') : e.message
      });
      return null;
    }
  }

  async getOTStatusHistory(otNumber) {
    try {
      const url = environment.api.meta.url + environment.api.transportationStatus + '/' + otNumber;
      const result = await this.http.get(url).toPromise();
      return result && result['data'] ? result['data'] : [];
    } catch (e) {
      console.error(e.message);
      this.loaderService.hide();
      this.globalNotification.notification$.next({
        title: 'Falha ao buscar Histórico de status da O.T',
        type: 'error',
        message: e.errors ? e.errors.join(', ') : e.message
      });
      return null;
    }
  }

  async listAllShippingCompanies() {
    try {
      const url = environment.api.meta.url + environment.api.orderMain + 'listShippingCompany/0/1000';
      const result = await this.http.get(url).toPromise();
      return result && result['data'] && result['data']['records'] ? result['data']['records'] : [];
    } catch (e) {
      console.error(e.message);
      this.loaderService.hide();
      this.globalNotification.notification$.next({
        title: 'Falha ao listar transportadoras.',
        type: 'error',
        message: e.errors ? e.errors.join(', ') : e.message
      });
      return null;
    }
  }

  async updateTransportation(params) {
    try {
      const result = await this.http.put(environment.api.meta.url + environment.api.orderMain + 'update/transportation', params).toPromise();
      return result;
    } catch (e) {
      this.loaderService.hide();
      this.globalNotification.notification$.next({
        title: 'Falha ao atualizar entregas',
        type: 'error',
        message: e.message
      });
      return null;
    }
  }

  async getCentersByOrigin(queryFilter) {
    try {
      let url = environment.api.meta.url + environment.api.orderHash + '/distributionCenter/filter/0/50/?';
      url = queryFilter ? url + queryFilter : url;
      const result = await this.http.get(url).toPromise();
      return result;
    } catch (e) {
      this.loaderService.hide();
      this.globalNotification.notification$.next({
        title: 'Falha ao listar centros',
        type: 'error',
        message: e.message
      });
      return null;
    }
  }

  async getClosestDistributionCenters(coordinatesList) {
    try {
      const result = await this.http.post(environment.api.meta.url + environment.api.orderMain + 'distributionCenter/getClosest', { coordinatesList }).toPromise();
      return result['data'];
    } catch (e) {
      this.loaderService.hide();
      this.globalNotification.notification$.next({
        title: 'Falha ao buscar centros',
        type: 'error',
        message: e.error.message
      });
      return null;
    }
  }

  async getImportations() {
    try {
      const url = environment.api.meta.url + environment.api.importations;
      const result = await this.http.get(url).toPromise();
      return result && result['data'] ? result['data'] : [];
    } catch (e) {
      console.error(e.message);
      this.loaderService.hide();
      this.globalNotification.notification$.next({
        title: 'Falha ao buscar importações.',
        type: 'error',
        message: e.errors ? e.errors.join(', ') : e.message
      });
      return null;
    }
  }

  async getSchedulling(query) {
    try {
      const url = environment.api.meta.url + environment.api.orderMain + `scheduling/count?${query}`;
      const result = await this.http.get(url).toPromise();
      return result && result['data'] ? result['data'] : [];
    } catch (e) {
      console.error(e.message);
      this.loaderService.hide();
      this.globalNotification.notification$.next({
        title: 'Falha ao buscar importações.',
        type: 'error',
        message: e.errors ? e.errors.join(', ') : e.message
      });
      return null;
    }
  }

  async getSchedullingDetails(query) {
    try {
      const url = environment.api.meta.url + environment.api.orderMain + `scheduling/details/0/5000?${query}`;
      const result = await this.http.get(url).toPromise();
      return result && result['data'] ? result['data'] : [];
    } catch (e) {
      console.error(e.message);
      this.loaderService.hide();
      this.globalNotification.notification$.next({
        title: 'Falha ao buscar importações.',
        type: 'error',
        message: e.errors ? e.errors.join(', ') : e.message
      });
      return null;
    }
  }

  async listOrdersByPlanning(planningId) {
    try {
      const result = await this.http.get(`${environment.api.meta.url}${environment.api.orderMain}planning/${planningId}`).toPromise();
      return result && result['data'] && result['data']['plannings'] ? result['data']['plannings'] : [];
    } catch (e) {
      this.loaderService.hide();
      this.globalNotification.notification$.next({
        title: 'Falha ao carregar pedidos',
        type: 'error',
        message: e.message
      });
      return [];
    }
  }
}
