import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() totalRegisters: any;
  @Input() pageSize: any;
  @Input() actualPage = 1;

  @Output() handleNextPage = new EventEmitter<any>();
  @Output() handlePreviousPage = new EventEmitter<any>();

  countPages = 10;

  constructor() {}

  ngOnInit() {
    this.calculateTotalPages(this.totalRegisters);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['totalRegisters'] || changes['pageSize']) {
      this.calculateTotalPages(this.totalRegisters);
    }
  }

  calculateTotalPages(totalRegisters) {
    if (totalRegisters && this.pageSize && this.pageSize !== 0) {
      this.countPages = Math.ceil(totalRegisters / this.pageSize);
    } else {
      this.countPages = Math.ceil(totalRegisters / 10);
    }
  }

  previousPage() {
    if (this.actualPage > 1) {
      this.actualPage--;
      this.handlePreviousPage.emit(this.actualPage);
    }
  }

  previousPages(pages = 10) {
    this.actualPage = (this.actualPage - pages) > 1 ? this.actualPage - pages : 1;
    this.handlePreviousPage.emit(this.actualPage);
  }

  nextPage() {
    if (this.actualPage < this.countPages) {
      this.actualPage++;
      this.handleNextPage.emit(this.actualPage);
    }
  }

  nextPages(pages = 10) {
    if (this.actualPage < this.countPages) {
      this.actualPage = (this.actualPage + pages) < this.countPages ? this.actualPage + pages : this.countPages;
      this.handleNextPage.emit(this.actualPage);
    }
  }
}
