import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private userService: UserService,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.userService.getLoggedUser()) {
      this.router.navigate(['login']);
      return false;
    } else if (this.userNeededChangePassword()) {
      this.router.navigate(['suggest-password']);
      return false;
    } else if (!this.userCanAccessRoute(next)) {
      this.router.navigate(['404']);
      return false;
    }
    return true;
  }

  userNeededChangePassword(): boolean {
    return this.userService.getLoggedUser()?.changePassword > 1;
  }

  userCanAccessRoute(route: ActivatedRouteSnapshot) {
    const userProfiles = this.userService.getUserProfiles();
    let userCanAccess = false;

    if (route.data?.visibleOnlyWithSSO) {
      const user = this.userService.getLoggedUser();
      return user.authenticationType === 'SSO';
    }
    if (userProfiles && route.data && route.data.allowedProfiles && route.data.allowedProfiles.length > 0) {

      if (route.data.allowedProfiles.includes('*')) {
        return true;
      }
      route.data.allowedProfiles.forEach(profile => {
        if (userProfiles.filter(p => p === profile).length > 0) {
          userCanAccess = true;
        }
      });
    } else if (route.data.allowedProfiles.length === 0) {
      userCanAccess = true;
    }
    return userCanAccess;
  }
}
