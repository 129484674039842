import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimelineComponent } from './timeline.component';
import { TimelineItemComponent } from './components/timeline-item/timeline-item.component';
import { TimelineContentComponent } from './components/timeline-content/timeline-content.component';
import { TimelineSubitemComponent } from './components/timeline-subitem/timeline-subitem.component';

@NgModule({
  declarations: [
    TimelineComponent,
    TimelineItemComponent,
    TimelineContentComponent,
    TimelineSubitemComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TimelineComponent,
    TimelineItemComponent
  ]
})
export class TimelineModule { }
