<div class="hero is-fullheight-with-navbar">
  <app-side-image [pathImages]="pathImages">
    <div class="hero-body columns login">
      <div class="is-hidden-touch"></div>
      <section class="column is-8 login-body swipeInLeft">
        <main id="main">
          <ng-container *ngIf="(maintenance$ | async) as underMaintenance; else form">
            <ng-container *ngIf="underMaintenance && !underMaintenance.activated; else maintenanceMessage">
              <ng-container *ngTemplateOutlet="form"></ng-container>
            </ng-container>
            <ng-template #maintenanceMessage>
              <div class="is-flex is-flex-direction-column">
                <div class="field ml-1">
                  <a [routerLink]="['/']">
                    <app-public-logo></app-public-logo>
                  </a>
                </div>
                <div class="is-clearfix"></div>

                <div class="field ml-1">
                  <p class="login-subtitle">
                    Negociação inteligente de fretes
                  </p>
                </div>
                <span class="large-text has-text-warning has-text-weight-bold">
                  Estamos em manutenção trabalhando para melhor atendê-los. Estaremos de volta logo!
                </span>
              </div>
            </ng-template>
          </ng-container>
          <ng-template #form>
            <form [formGroup]="theForm" novalidate>
              <fieldset
                *ngIf="!stateIs('theForm', 'ideal') && !stateIs('theForm', 'validating') && !stateIs('theForm', 'validated') && !stateIs('theForm', 'cannotActivate')">
                <legend class="is-sr-only">Entrar na minha conta</legend>
                <div class="field ml-1">
                  <a [routerLink]="['/']">
                    <app-public-logo></app-public-logo>
                  </a>
                </div>
                <div class="is-clearfix"></div>

                <div class="field mb-6">
                  <p class="is-size-4 has-text-grey">
                    Negociação inteligente de fretes
                  </p>
                </div>

                <div class="field ml-1" *ngIf="checkedUser">
                  <p class="is-size-4">
                    Olá, {{ this.checkedUser.name }}, bem vindo(a) de volta!
                  </p>
                </div>
                <div class="is-clearfix"></div>

                <article class="message is-danger" *ngIf="stateIs('theForm', 'error')">
                  <div class="message-body">
                    <b>{{this.errorMessage}}</b>
                  </div>
                </article>

                <article class="message is-danger" *ngIf="stateIs('theForm', 'partial')">
                  <div class="message-body">
                    <b>Preencha corretamente seu login e senha</b>
                  </div>
                </article>

                <div class="field" [hidden]="checkedUser">
                  <app-input
                  leadingIcon="email"
                  placeholder="Digite seu e-mail"
                  [control]="email"
                  [validation]="commonControlValidation"
                  (keyup.enter)="checkUser()">
                  </app-input>
                </div>
                <div class="field" [hidden]="!checkedUser">
                  <app-input
                  [hidden]="checkedUser && checkedUser?.loginValidation === 'sendNewVerification'"
                  leadingIcon="lock"
                  [canClickTrailingIcon]="true"
                  [trailingIcon]="!passwordVisibility ? 'visibility' : 'visibility_off'"
                  (trailingIconClick)="passwordVisibility = !passwordVisibility"
                  [type]="!passwordVisibility ? 'password' : 'text'"
                  placeholder="Digite sua senha"
                  [control]="password">
                  </app-input>
                </div>

                <article class="message is-danger" *ngIf="checkedUser && checkedUser?.loginValidation === 'sendNewVerification'">
                <!-- <article class="message is-danger"> -->
                  <div class="message-body">
                    <b>O seu usuário ainda não está ativo.</b>
                    <p class="my-3">Verifique seu e-mail cadastrado e clique no link de ativação. Se não encontrar o e-mail de confirmação, confira na caixa de spam ou lixo eletrônico.</p>
                    <b class="is-clickable email-link" (click)="reSendEmail()">Reenviar e-mail de verificação</b>
                    <p class="mt-3">Fale com o suporte LoadShark: atendimento@loadshark.io</p>
                  </div>
                </article>

                <div class="controls my-6 mx-1" *ngIf="checkedUser && tenants?.length > 1 && password.value">
                  <span class="is-size-5">
                    Qual organização deseja ver?
                  </span>
                  <ng-select darkMode id="tenants" class="is-primary is-size-6 mt-3" placeholder="Selecione sua organização"
                    notFoundText="Nenhuma organização" formControlName="tenant" [items]="tenants" [clearable]="false"
                    (change)="handleTenant($event)">
                  </ng-select>
                </div>
                <div class="is-flex is-align-items-center is-justify-content-space-between mx-1" *ngIf="!checkedUser">
                  <span class="is-size-6">
                    É transportadora? Cadastre-se <a [routerLink]="['/cadastro/transportadora']">aqui</a>
                  </span>
                  <app-button [title]="'Próximo'" [isDisabled]="!email.valid" (click)="checkUser()">
                  </app-button>
                </div>
                <div class="is-flex controls is-align-items-center mx-1">
                  <div>
                    <div *ngIf="checkedUser && checkedUser?.loginValidation !== 'sendNewVerification'">
                      <span class="is-flex is-size-6 is-align-items-center">
                        Esqueceu a senha?
                        <a (click)="forgotPassword()" class="ml-1">
                          Clique aqui
                        </a>
                      </span>
                    </div>
                  </div>
                  <div class="is-flex is-flex-direction-row-reverse">
                    <app-button [title]="content.authByEmailCTAButton"
                      [isDisabled]="stateIs('theForm', 'loading') || password.invalid ||
                        (tenants?.length > 1 && !selectedTenant) || checkedUser?.loginValidation === 'sendNewVerification'"
                      (click)="theFormSubmit()" *ngIf="checkedUser"></app-button>
                    <app-button class="mr-3" [title]="'Voltar'" [isSecondary]="true"
                      (click)="goBack()" [isDisabled]="stateIs('theForm', 'loading')" *ngIf="checkedUser"></app-button>
                  </div>
                </div>
              </fieldset>
              <div darkMode *ngIf="stateIs('theForm', 'ideal')" class="box">
                <blockquote class="notification is-primary has-text-centered is-size-6" role="alert">
                  <b>Você está sendo redirecionado...</b>
                </blockquote>
              </div>
              <div *ngIf="stateIs('theForm', 'validating')">
                <legend class="login-subtitle has-text-centered has-text-weight-bold">Validando o token de usuário...
                </legend>
              </div>
              <div *ngIf="stateIs('theForm', 'cannotActivate')">
                <legend class="login-subtitle has-text-centered has-text-weight-bold">Não foi possível verificar o
                  usuário. Caso seu token tenha expirado, uma nova verificação foi enviada para o seu e-mail!</legend>
              </div>
              <div *ngIf="stateIs('theForm', 'validated')">
                <legend class="login-subtitle has-text-centered has-text-weight-bold">Usuário verificado com sucesso!
                </legend>
              </div>
            </form>
          </ng-template>
        </main>
      </section>
    </div>
    <footer *ngIf="!checkIfUserAcceptedCookies">
      <app-privacy-policy-footer></app-privacy-policy-footer>
    </footer>
  </app-side-image>
</div>