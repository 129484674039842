<div class="dropdown" [ngClass]="{'is-active': isOpen, 'is-right': isRight}">
  <div class="dropdown-trigger">
    <button class="button is-outlined is-secondary" aria-haspopup="true" (click)="handleDropdown()"
      aria-controls="dropdown-menu" [ngClass]="{'is-rounded': isRounded}">
      <span>{{initialText && !currentItem?.label ? initialText : currentItem?.label || 'Nenhum item selecionado'
        }}</span>
      <span class="icon is-small">
        <i class="material-icons arrow" [ngClass]="{'rotate': isOpen}">expand_more</i>
        <i class="fas fa-angle-down" aria-hidden="true"></i>
      </span>
    </button>
  </div>
  <div class="dropdown-menu" id="dropdown-menu" role="menu">
    <div class="dropdown-content" *ngIf="dropdownItems && dropdownItems.length > 0">
      <ng-container *ngFor="let item of dropdownItems">
        <span (click)="selectValue(item)" class="dropdown-item is-clickable" [ngClass]="{'dropdown-item_large': isLarge}">
          {{item.label}}
        </span>
      </ng-container>
    </div>
  </div>
</div>