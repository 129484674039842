import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {

  @Input() title;
  @Input() isVisible = false;
  @Input() isXsmall = false;
  @Input() isSmall = false;
  @Input() isMedium = true;
  @Input() isLarge = false;
  @Input() noScroll = false;
  @Input() noHeader = true;
  @Input() noClose = false;
  @Input() noPadding = false;
  @Input() hasTabs = false;
  @Output() handleClose = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  close() {
    this.handleClose.emit();
  }

}
