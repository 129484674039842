import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Profiles } from 'src/app/models/profiles';
import { UserService } from 'src/app/services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class EthanolGuardService {

  constructor(
    private userService: UserService,
    private router: Router
  ) { }

  canActivate(): boolean {
    const profiles = this.userService.getUserProfiles();
    const isShipper = [Profiles.Administrador, Profiles.EmbarcadorEtanol];
    const isCarrier = [Profiles.Administrador, Profiles.TransportadoraEtanol];
    const isOutsourced = [Profiles.Administrador, Profiles.OperadorEtanol, Profiles.GerenteOperadorEtanol];

    if (profiles.some(p => isShipper.includes(p))) {
      this.router.navigate(['/etanol/embarcador']);
      return false;
    }

    if (profiles.some(p => isCarrier.includes(p))) {
      this.router.navigate(['/etanol/transportadora']);
      return false;
    }

    if (profiles.some(p => isOutsourced.includes(p))) {
      this.router.navigate(['/etanol/terceirizado']);
      return false;
    }

    return false;
  }
}
