<div class="page">
    <app-modal [noHeader]="true" [noScroll]="true" size="large" (isOpenChanged)="navigateOnPlatform()">
        <div class="has-text-centered">
            <h3 class="modal-title">
                Leilão de cargas agora com lances automáticos!
            </h3>
        </div>
        <app-carousel #carousel h="60vh" [slides]="slides" [canLoop]="true" [noCover]="true" [noControls]="true">
        </app-carousel>
        <div class="is-flex is-justify-content-center mt-6 mb-3">
            <div class="mx-1" *ngIf="this.carousel.currentSlide > 0">
                <app-button title="Voltar" [isSecondary]="true" (click)="handlePrevious()"></app-button>
            </div>
            <div class="mx-1">
                <app-button [title]="ctaTitle" (click)="handleNext()">
                </app-button>
            </div>
        </div>
    </app-modal>
</div>