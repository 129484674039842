import { Pipe, PipeTransform } from '@angular/core';
import { deliveryStatus } from '../../enums/deliveryStatus';

@Pipe({
  name: 'statusFiori'
})
export class StatusFioriPipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    const translated = deliveryStatus[value];
    return translated ? translated : value;
  }

}

