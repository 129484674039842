import { Component, Input, OnInit } from '@angular/core';

type Type = 'body' | 'caption' | 'label';

type Size = 1 | 2;

type Style = 'inline' | 'block';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent implements OnInit {

  @Input()
  type: Type = 'body';

  @Input()
  size: Size = 1;

  @Input()
  style: Style = 'inline';

  constructor() { }

  ngOnInit(): void {
  }

}
