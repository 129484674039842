import { Pipe, PipeTransform } from '@angular/core';
import { ethanolStatus } from '../../enums/ethanolStatus';

@Pipe({
  name: 'ethanolStatus'
})
export class EthanolStatusPipe implements PipeTransform {

  transform(value) {
    if (!value) {
      return '';
    }
    if (!ethanolStatus[value]) {
      return value;
    }
    return ethanolStatus[value];
  }

}
