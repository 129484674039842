import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

  @Input() title;
  @Input() value = 0;
  @Input() max = 100;
  @Input() statusColor = 'is-primary';
  @Input() top = false;

  constructor() { }

  ngOnInit(): void {
  }

}
