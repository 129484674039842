<app-header [title]="'Conferência de leilões'">
  <div class="navbar-end">
    <div class="navbar-item">
      <div class="field">
        <div class="control">
          <app-button
          title="Atualizar status"
          icon="refresh"
          variant="outlined"
          size="small"
          (handleClickEmitter)="loadData()">
          </app-button>
        </div>
      </div>
    </div>
    <div class="navbar-item">
      <app-filter-check-auctions [auctions]="auctions" (handleFilters)="getAuctions($event)">
      </app-filter-check-auctions>
    </div>
    <div class="navbar-item">
      <div class="field">
        <div class="control">
          <div class="field">
            <app-input
            placeholder="Buscar O.T"
            leadingIcon="search"
            [trailingIcon]="searchOtInput ? 'close' : undefined"
            [canClickTrailingIcon]="true"
            (trailingIconClick)="clearFilter()"
            [(value)]="searchOtInput"
            (keyup.enter)="filterByOt($event.target.value)">
            </app-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-header>

<div>
  <h1 class="is-size-3 m-5" *ngIf="auctions.length">Leilões finalizados</h1>
  <div *ngIf="auctions && auctions.length > 0" class="is-padded-medium-horizontal is-flex is-justify-content-space-between mb-5">
    <div class="is-flex">
      <label class="checkbox checkbox-toggle mr-2" for="loadsAllChecked">
        <input type="checkbox" name="loadsAllChecked" [checked]="isCheckedAllLoads" (change)="toggleAllAuctions($event)">
        <span class="label-toggle-content">
          &nbsp;
          Todos ({{ auctions.length }})
        </span>
      </label>
      <app-button
      title="Baixar"
      icon="save_alt"
      size="small"
      variant="link"
      [disabled]="!hasSomeCheckedAuctions()"
      (handleClickEmitter)="downloadCheckedAuctions()">
      </app-button>
    </div>

    <app-order-button [items]="orderOptions" [canReverseOrder]="true" [canClearOrder]="true" aligned="right"
      (orderEmitter)="handleOrder($event)" (reverseOrder)="handleReverseOrder($event)"
      (clearOrder)="handleClearOrder($event)">
    </app-order-button>
  </div>
  <div class="column-body vh-90 padding-bottom-content is-padded-medium-horizontal">
    <div class="columns vh-70">
      <div class="column overflow-auto is-12">
        <div *ngIf="auctions && auctions.length > 0">
          <div class="auction-section" *ngFor="let auction of auctions; let x=index">
            <app-checker-auction-row [auction]="auction" (handleCheck)="validateCheckedAllAuctions($event)"
              [tab]="'check'">
            </app-checker-auction-row>
          </div>

          <div class="is-flex is-justify-content-center">
            <app-pagination (handleNextPage)="nextPage($event)" (handlePreviousPage)="previousPage($event)"
              [totalRegisters]="countTotalAuctions" [pageSize]="50">
            </app-pagination>
          </div>
        </div>
        <div *ngIf="!isLoading && !isGettingData  && !auctions.length">
          <div class="no-auctions mt-6">
            <div class="content">
              <h3>
                Não existem leilões finalizados para o filtro selecionado.
              </h3>
            </div>
          </div>
        </div>
        <div *ngIf="isLoading && isGettingData">
          <div class="no-auctions mt-6">
            <div class="content">
              <h3>
                Procurando leilões finalizados...
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
