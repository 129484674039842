import { Component, OnInit } from '@angular/core';

type NotificationInput = {
 title: string;
 message: string;
 type: string;
 count?: number;
};

@Component({
  selector: 'app-global-notification',
  templateUrl: './global-notification.component.html',
  styleUrls: ['./global-notification.component.scss']
})
export class GlobalNotificationComponent implements OnInit {

  showNotification = true;

  data: NotificationInput = {
    title: null,
    message: null,
    type: null,
    count: 0
  };

  constructor() { }

  ngOnInit() {
  }

  closeNotification() {
    this.showNotification = false;
  }

}
