<div
[ngClass]="{'page-container': withScroll}">
    <h2>Aviso de Privacidade</h2>
    <p class="italic">
        Esta Política de Privacidade foi atualizada pela última vez em 01 de Abril de 2022.
    </p>
    <p>
        A privacidade dos visitantes do nosso site é muito importante para nós, e estamos
        comprometidos em protegê-la.
    </p>
    <p>
        A Braskem compromete-se a garantir a privacidade do usuário, na utilização de seus
        canais de comunicação, de seu site e de seus serviços, conforme os termos abaixo.
    </p>
    <p>
        Para a Braskem é de extrema importância garantirmos a proteção dos dados que nos
        são confiados por nossos integrantes, clientes, fornecedores, comunidades, moradores
        assistidos, visitantes do site e todos aqueles com quem nos comunicamos. Por isso,
        estamos comprometidos em manter os mais altos padrões de privacidade e proteção de
        dados.
    </p>
    <p>
        Este Aviso de Privacidade dá transparência sobre como a Braskem coleta, trata,
        compartilha e armazena seus dados pessoais e dá acesso aos vários direitos que você
        possui em relação aos seus dados pessoais.
    </p>
    <h2>Controlador</h2>
    <br>
    <h3>BRASKEM S.A.</h3>
    <p>
        ESCRITÓRIO SP - SEDE <br>
        Rua Lemos Monteiro, 120, 22º andar <br>
        Edifício Pinheiros One, Butantã <br>
        São Paulo - SP, CEP: 05501-050 <br>
        Tel: 55 11 3576-9000 / Fax: 55 11 3576-9073
    </p>
    <h2>Dados Pessoais</h2>
    <p>
        A Braskem coletará informações a partir do registro do usuário no cadastro disponível
        no site loadshark.io e por meio de aplicativo de mensagem. No registro, a Braskem
        pergunta obrigatoriamente as seguintes informações:
    </p>

    <ul>
        <li>Dados da empresa: CNPJ, nome da empresa;</li>
        <li>Dados do usuário: Nome e telefone corporativo;</li>
        <li>Dados da conta: e-mail e senha;</li>
    </ul>
    <p>
        Outras informações pessoais do usuário podem ser fornecidas por você e à medida em
        que forem solicitadas, possibilitando uma personalização do seu contato.
    </p>
    <p>
        Os dados pessoais que tratamos podem incluir:
    </p>
    <ul>
        <li>
            Dados relacionados à(s) sua(s) visita(s) em nosso site, dados relacionados ao dispositivo
            que você usa para visitar nosso site e outras informações, exigidas por lei, como data e
            hora de acesso e endereço IP (artigo 15, Marco Civil da Internet - Lei nº 12.965/2014);
        </li>
        <li>
            Informações como nome (ou iniciais) e sobrenome, título, cargo e/ou empresa em que
            trabalha;
        </li>
        <li>
            Dados de cadastro e detalhes de contato, como seu endereço postal, bairro de
            residência, endereço de e-mail e número(s) de telefone, se você nos tiver fornecido ao
            preencher o formulário de contato em nosso site, portal ou aplicativo de mensagens;
        </li>
        <li>
            Dados pessoais coletados por meio de cookies (veja abaixo na seção “Cookies” e em
            nosso Aviso de Cookies).
        </li>
    </ul>
    <br>
    <h2>Coleta de dados pessoais</h2>
    <p>Coletamos os dados pessoais nas seguintes situações:</p>
    <ul>
        <li>
            Quando você nos fornece seus dados pessoais ou quando interage diretamente conosco
            (ao se registrar no site ou lista de transmissão de aplicativo de mensagens, por
            exemplo).
        </li>
        <li>
            Quando se inscreve em nossos boletins informativos, se indicar que está interessado em
            receber ofertas ou informações da Braskem e seus parceiros, a Braskem e seus parceiros
            poderão enviar mensagens via e-mail sobre produtos e serviços que possam interessar
            ao usuário.
        </li>
        <li>
            Quando monitoramos nossas ferramentas e serviços de tecnologia, incluindo visitas ao
            nosso site e e-mails enviados para e da Braskem e/ou empresas do grupo;
        </li>
    </ul>
    <br>
    <h2>Finalidades do tratamento de dados pessoais</h2>
    <p>Tratamos os dados pessoais definidos acima para as seguintes finalidades:</p>
    <ul>
        <li>
            Realizar o relacionamento contratual com você ou com a empresa em que trabalha;
        </li>
        <li>
            Para cumprir as leis e regulamentos aplicáveis e nossas obrigações decorrentes, em
            especial a Lei 13.709/2018, comumente conhecida como Lei Geral de Proteção de Dados
            (LGPD);

        </li>
        <li>
            Em relação a qualquer processo judicial em andamento ou potencial;
        </li>
        <li>
            Para estabelecer, exercer ou defender nossos direitos legais (incluindo fornecer
            informações a terceiros para fins de prevenção de fraudes e redução do risco de
            crédito);
        </li>
        <li>
            Para estabelecer, exercer ou defender nossos direitos legais (incluindo fornecer
            informações a terceiros para fins de prevenção de fraudes e redução do risco de
            crédito);
        </li>
        <ul class="insideList">
            <li>
                Gerenciar e administrar nosso site e nosso relacionamento com você;
            </li>
            <li>
                Fornecer e melhorar nosso site e canais de comunicação, gerando estatísticas sobre
                o uso;
            </li>
            <li>
                Realizar pesquisas (estatísticas);
            </li>
            <li>
                Realizar a segurança do site e evitar fraudes.
            </li>
        </ul>
        <li>
            Podemos tratar seus dados para outros fins específicos após a obtenção do seu
            consentimento.
        </li>
    </ul>
    <br>
    <h2>Cookies</h2>
    <p>
        Nosso site usa cookies. Cookies são pequenos arquivos de texto que armazenam
        informações sobre a utilização do site. Coletamos dados por meio de cookies, nos
        termos de nosso <a href="https://www.braskem.com.br/aviso-de-cookies" target="_blank"><strong>Aviso de Cookies</strong></a> e
        com essas informações conseguimos tornar as visitas
        ao nosso site mais agradáveis, bem como obter informações sobre como o site é usado
        e otimizá-lo para nossos visitantes. Quando você acessa nosso site pela primeira vez,
        possui a opção de consentir com o uso de cookies de acordo com os termos desta
        política e nos permite usar cookies toda vez que você acessa nosso site.

    </p>
    <br>
    <h2>Compartilhamento de dados pessoais</h2>
    <p>
        Podemos compartilhar seus dados pessoais com:
    </p>
    <ul>
        <li>
            Entidades do grupo de empresas Braskem, como as afiliadas e subsidiárias da Braskem:
            Braskem Netherlands BV, Braskem Netherlands Inc. BV, Braskem Netherlands Finance
            BV (Holanda), Braskem Europe GmbH (Alemanha), Braskem America Inc (EUA) e
            Braskem Idesa SAPI (México), controladores, holding, agentes e nossos representantes
            legais conforme necessário para os fins estabelecidos nesta política;
        </li>
        <li>
            Nossos funcionários, executivos, seguradoras, consultores profissionais, agentes,
            fornecedores ou subcontratados conforme necessário para os fins estabelecidos nesta
            política;
        </li>
        <li>
            Prestadores de serviços para executar contratos firmados com você e/ou com sua
            empresa;
        </li>
        <li>
            Nossos auditores;
        </li>
        <li>
            Autoridades nacionais de proteção de dados;
        </li>
        <li>
            Quaisquer tribunais e autoridades competentes.
        </li>
    </ul>
    <p>
        A Braskem também poderá compartilhar informações sobre o usuário do site e outros
        canais de comunicação quando necessário para identificar, entrar em contato ou agir
        legalmente contra alguém que possa estar violando (intencionalmente ou não) direitos
        da Braskem ou de terceiros prejudicados, ou contrariando o <strong>
        Código de Conduta da Braskem</strong>. A Braskem poderá divulgar informações sobre o usuário quando exigido por
        lei, a critério exclusivo da Braskem ou das autoridades competentes.
    </p>
    <br>
    <h2>Transferências Internacionais</h2>
    <p>
        Nas atividades que exigem o compartilhamento de dados pessoais, seus dados podem
        ser armazenados, processados e transferidos entre qualquer um dos países em que
        operamos, a fim de nos permitir usar as informações de acordo com esta política. A
        Braskem tem procedimentos internos para garantir a conformidade com as leis de
        proteção de dados aplicáveis para proteger seus dados pessoais independentemente de
        onde eles estejam localizados.
    </p>
    <p>
        Para a transferência de dados pessoais dentro do grupo Braskem, as empresas
        relevantes do grupo firmaram um contrato de transferência de dados com base nas
        Cláusulas Contratuais Padrão da UE (GDPR), sendo que só realizamos transferências
        internacionais de acordo com as hipóteses permitidas pelas leis aplicáveis.
    </p>
    <br>
    <h2>Segurança dos dados pessoais</h2>
    <p>
        A Braskem adota medidas de segurança técnicas e administrativas adequadas para
        proteger os dados pessoais de acessos não autorizados e de situações acidentais ou
        ilícitas de destruição, perda, alteração, comunicação ou qualquer forma de tratamento
        inadequado ou ilícito, nos termos da <strong>Diretriz de Segurança da Informação da Braskem</strong>.
    </p>
    <p>
        Dentre essas medidas, destacamos que:
    </p>
    <ul>
        <li>
            Apenas as pessoas que necessitem das informações para realizar suas atividades
            profissionais deverão ter acesso a elas (princípio da necessidade).
        </li>
        <li>
            Os dados pessoais informados por você são armazenados de forma segura em nossos
            servidores (protegidos por senha e firewall) com restrição de acesso apenas a pessoas
            limitadas e devidamente autorizadas.
        </li>
        <li>
            Nas áreas de acesso restrito, devem ser observadas as regras de nível de acesso da
            <strong>Diretriz de Segurança da Informação da Braskem.</strong>
        </li>
    </ul>
    <p>
        Recomendamos que você também contribua para a segurança de seus dados pessoais
        e não os compartilhe com terceiros. Essas informações são pessoais e intransferíveis e
        estão sob sua responsabilidade.
    </p>
    <h2>Retenção de dados</h2>
    <p>
        Somente reteremos dados pessoais sobre você pelo tempo necessário para cumprir a
        finalidade para a qual foram coletados, de acordo com legislação aplicável ou a vigência
        de quaisquer contratos com a Braskem.
    </p>
    <p>
        O período de retenção para cada categoria de dados pessoais é baseado nos requisitos
        legais aplicáveis e no objetivo para o qual as informações são coletadas e usadas.
    </p>
    <br>
    <h2>Direito dos titulares</h2>
    <p>
        Os titulares dos dados têm direitos sobre seus próprios dados pessoais. Por isso, você
        pode solicitar:
    </p>
    <ul>
        <li>
            <strong>Acesso</strong>. Solicitar informações sobre o tratamento de seus dados pessoais e
            acesso a seus dados pessoais.
        </li>
        <li>
            <strong>Confirmação</strong>. Confirmar a existência do tratamento de dados.
        </li>
        <li>
            <strong>Correção</strong>. Atualizar ou corrigir inconsistências nos seus dados pessoais.
        </li>
        <li>
            <strong>Exclusão</strong>. Excluir seus dados pessoais em determinadas circunstâncias (caso
            desnecessários, excessivos ou tratados em desconformidade com a legislação).
        </li>
        <li>
            <strong>Informação sobre compartilhamento</strong>. Solicitar informações sobre as entidades
            com as quais compartilhamos seus dados pessoais.
        </li>
        <li>
            <strong>Portabilidade</strong>. Exportar seus dados pessoais para terceiros.
        </li>
        <li>
            <strong>Anonimização ou bloqueio</strong>. Restringir o tratamento de seus dados pessoais, por
            anonimização ou bloqueio, em determinadas circunstâncias.
        </li>
        <li>
            <strong>Objeção</strong>. Opor-se ao tratamento, caso haja descumprimento da lei.
        </li>
        <li>
            <strong>Revogação do consentimento</strong>. Revogar o consentimento dado anteriormente.
        </li>
    </ul>
    <br>
    <h2>Encarregado pelo tratamento de dados pessoais</h2>
    <p>
        A Braskem possui um <strong>
        Encarregado pelo Tratamento de Dados Pessoais (DPO – Data
        Protection Officer)
        </strong> indicado para as melhores orientações e práticas sobre o tratamento
        de dados pessoais. Para solicitações de direitos de titulares, esclarecimentos ou
        reclamações sobre a coleta e/ou o tratamento de (seus) dados pessoais ou sobre este
        aviso de privacidade, entre em contato com o responsável pela proteção de dados da
        Braskem através do e-mail <a href="mailto:data_protection@braskem.com" target="_blank">data_protection@braskem.com</a>.
    </p>
    <br>
    <h2>Alterações</h2>
    <p>
        Podemos atualizar esta política periodicamente, através da publicação de uma nova
        versão em nosso site. Você deve verificar esta página ocasionalmente para garantir
        que compreende quaisquer alterações nesta política. Podemos notificá-lo sobre
        alterações nesta política por e-mail ou através do sistema de mensagens privadas em
        nosso site.
    </p>
</div>
