export const timelineStatus = {
  created: 'Carga adicionada',
  accepted: 'Carga aceita pela transportadora',
  canceled: 'Carga cancelada pela transportadora',
  allowed: 'Agendamento permitido',
  backToTransfer: 'Carga retornou ao repasse',
  rejected: 'Agendamento rejeitado',
  pending: 'Agente de frete não foi atualizado no SAP',
  RFC3: 'Agente de frete em integração',
  RFC5: 'Agente de frete em integração',
  'integration-error': 'Agente de frete não foi atualizado no SAP',
  'integration-success': 'Agente de frete atualizado no SAP',
}
