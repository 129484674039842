<div class="increment-numbers is-flex is-align-items-center">
  <button class="button is-outlined is-size-5 has-text-weight-bold" (click)="decrementNumber()"  [disabled]="minNumber && initialNumber === minNumber || isDisabled || isUnlimited || initialNumber === 0">
    <i class="material-icons is-not-clickable" aria-hidden="true">remove</i>
  </button>
  <span class="is-size-4 mr-3 ml-3">
    <span *ngIf="!isUnlimited && initialNumber !== 0">{{ initialNumber }}</span>
    <span *ngIf="isUnlimited || initialNumber === 0">Ilimitado</span>
  </span>
  <button class="button is-outlined is-size-5 has-text-weight-bold" (click)="incrementNumber()" [disabled]="maxNumber && initialNumber === maxNumber || isDisabled">
    <i class="material-icons is-not-clickable" aria-hidden="true">add</i>
  </button>
  <p class="has-text-grey ml-4">
    <!-- {{ unit || 'Minuto(s)'}} -->
    {{ unit }}
  </p>
</div>
