import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-auction-autobid-toggle',
  templateUrl: './auction-autobid-toggle.component.html',
  styleUrls: ['./auction-autobid-toggle.component.scss']
})
export class AuctionAutobidToggleComponent implements OnInit {

  @Input() canShow: boolean;
  @Input() canEdit: boolean;
  @Input() isActive: boolean;
  @Input() isDisabled: boolean;
  @Output() toggleAutomaticBidEmitter = new EventEmitter<any>();
  @Output() editAutomaticBidConfigEmitter = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  toggleAutomaticBid(event) {
    this.toggleAutomaticBidEmitter.emit(event);
  }

  editAutomaticBidConfig() {
    this.editAutomaticBidConfigEmitter.emit();
  }

}
