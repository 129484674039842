import { Component, Input } from '@angular/core';

type AuctionStatus =
  | 'Confirmado'
  | 'Não confirmado'
  | 'Deletado'
  | 'Integrando'
  | 'Erro';

type AuctionData = {
  status: AuctionStatus;
  canceled: boolean;
};

@Component({
  selector: 'app-auction-status',
  templateUrl: './auction-status.component.html',
  styleUrls: ['./auction-status.component.scss']
})
export class AuctionStatusComponent {
  @Input() auction: AuctionData;
  @Input() isSAPDeleted: boolean;

  constructor() { }
}
