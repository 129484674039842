<div class="score-wrapper is-clickable">
  <span
  *ngIf="!isEmpty; else emptyState"
  [title]="translatedScore + ' - ' + percent + '%'"
  class="is-size-5 has-text-weight-bold">
    {{ translatedScore  }} - {{ percent }}%
  </span>

  <ng-template #emptyState>
    <span class="is-size-5 has-text-weight-bold">
      {{ emptyText }}
    </span>
  </ng-template>

  <progress *ngIf="!isEmpty" class="progress mt-2"
  [ngClass]="{
    'is-success': translatedScore === 'Ótimo',
    'is-warning': translatedScore === 'Regular',
    'is-danger': translatedScore === 'Insuficiente',
    'is-clickable': !isEmpty
  }" [value]="percent"
  max="100">{{ percent }}%</progress>
</div>
