import { Directive, ElementRef, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: '[appGtag]'
})
export class GtagDirective implements OnInit {

  constructor(private el: ElementRef, private render: Renderer2) {
  }

  createHash(text: string) {
    let hash = 0;
    if (text.length === 0) { return hash; }
    for (let i = 0; i < text.length; i++) {
      const char = text.charCodeAt(i);
      hash = ((hash << 5) - hash) + char;
      hash = hash & hash;
    }
    return hash;
  }

  ngOnInit() {
    const element = this.el.nativeElement;
    const hash = this.createHash(element.classList.value + element.parentElement.classList.value)
      .toString()
      .replace('-', '')
      .replace(' ', '');
    const name =  element.innerText;
    this.render.setAttribute(element, 'data-gtag', hash + '-' + name);
  }
}
