<div
[ngClass]="{'hidden': !toggleMenuVisible}"
[ngStyle]="{ 'left.px': contextMenuX, 'top.px': contextMenuY }"
(clickOutside)="handleClickOutside()"
class="context-menu box">
  <div class="menu">
    <ng-container *ngFor="let menu of ctxMenuConfig">
      <p *ngIf="menu.label" class="menu-label">
        {{menu.label}}
      </p>

      <ul *ngIf="menu.items" class="menu-list">
        <li *ngFor="let item of menu.items">
          <a *ngIf="item" (click)="handleCtxMenuClick(item.onClick)">
            {{item.label || '-'}}
          </a>
        </li>
      </ul>
    </ng-container>
  </div>
</div>
