import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoaderService } from '../../../shared/services/loader.service';
import { GlobalNotificationService } from '../../../shared/services/global-notification.service';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { downloadFile, removeDuplicates } from 'src/app/shared/utils';
import moment from 'moment';
import { UserService } from '../../../services/user/user.service';
import { APIDataRegister } from 'src/app/interfaces/ApiResponse';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuctionRedirectService {

  // messages coming from socket to produce real time data
  messages = [];
  private readonly API = `${environment.api.meta.url}${environment.api.auctionLot}`;
  private readonly API_AUCTION_MAIN = `${environment.api.meta.url}${environment.api.auctionMain}`;

  public auctionEvents$ = new Subject<any>();

  constructor(
    private http: HttpClient,
    private loaderService: LoaderService,
    private notificationService: GlobalNotificationService,
    private userService: UserService
  ) { }

  async acceptAuctionTerm() {
    try {
      const response = await this.http.patch(environment.api.meta.url + `/auction/v1/configuration/user/auctionTerm`, { auctionTermAccepted: true }).toPromise();
      return response && response['data'] ? response['data'] : {};
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao aceitar termo de consentimento do leilão',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  isFirstAccess() {
    const user = this.userService.getLoggedUser();
    return user.firstAccessAuction;
  }

  async checkAuctionTermStatus() {
    try {
      const { auctionTermAccepted } = this.userService.getLoggedUser();
      return auctionTermAccepted;
    } catch (error) {
      console.error(error);
      return error;
    }
  }
}