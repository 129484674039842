import { Pipe, PipeTransform } from '@angular/core';
import { HiringTypes } from '../../enums/auctionTypes';

@Pipe({
  name: 'auctionHiringType'
})
export class AuctionHiringTypePipe implements PipeTransform {

  transform(value: any, ...args: any[]): unknown {
    if (!value) { return null; }
    return HiringTypes[value];
  }

}
