import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { APIDataRegister } from 'src/app/interfaces/ApiResponse';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfirmTermsService {

  private apiUrl = `${environment.api.meta.url}${environment.api.users}`;

  private observer$ = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
  }

  get visible$() {
    return this.observer$;
  }

  toggleModal() {
    this.observer$.next(!this.observer$.value);
  }

  confirmedTerms(userId: string, terms: any) {
    return this.http.patch<APIDataRegister<any>>(`${this.apiUrl}confirmedTerms/${userId}`, terms).pipe(
      map(response => response.data)
    );
  }
}
