import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-increment-numbers',
  templateUrl: './increment-numbers.component.html',
  styleUrls: ['./increment-numbers.component.scss']
})
export class IncrementNumbersComponent implements OnInit {

  @Input() isUnlimited = false;
  @Input() initialNumber = 1;
  @Input() maxNumber: number;
  @Input() minNumber: number;
  @Input() isDisabled: boolean;
  @Input() unit: string;

  @Output() handleNumber = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.unit = this.unit ?? 'Minuto(s)';
  }

  incrementNumber() {
    this.isUnlimited = false;
    if (this.maxNumber && this.initialNumber < this.maxNumber) {
      this.initialNumber += 1;
    } else if (!this.maxNumber) {
      this.initialNumber += 1;
    }
    this.handleNumber.emit(this.initialNumber);
  }

  decrementNumber() {
    if (this.minNumber && this.initialNumber > this.minNumber) {
      this.initialNumber -= 1;
    } else if (!this.minNumber) {
      if (this.initialNumber === 1) {
        this.initialNumber -= 1;
        this.isUnlimited = true;
      } else if (this.initialNumber === 0) {
        return;
      } else {
        this.initialNumber -= 1;
      }
    }
    this.handleNumber.emit(this.initialNumber);
  }
}
