import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-alert-notification',
  templateUrl: './alert-notification.component.html',
  styleUrls: ['./alert-notification.component.scss']
})
export class AlertNotificationComponent implements OnInit {

  isVisibleForm = false;
  
  user = this.userService.getLoggedUser();

  constructor(
    private userService: UserService,
    private router: Router) { }

  closeForm() {
    this.isVisibleForm = false;
  }

  ngOnInit(): void {
    const profiles: string[] = this.userService.getUserProfiles();

    if (profiles.map(p => p.toLowerCase()).includes('transportadora')) {
      this.isVisibleForm = true;
    }
  }
}
