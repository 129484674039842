import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DarkModeService } from '@services/dark-mode.service';

@Component({
  selector: 'app-public-logo',
  templateUrl: './public-logo.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicLogoComponent { 

  constructor(private darkMode: DarkModeService) { }

  get isDarkMode() {
    return this.darkMode.isDark;
  }

}
