<div class="buttons is-marginless dropdown is-hoverable is-clickable" [ngClass]="{'is-right': isRight}">
    <div class="is-text is-decorationless is-marginless dropdown-trigger"
    (mouseenter)="changeToggleIcon()" (mouseleave)="changeToggleIcon()">
        <span *ngIf="label" class="is-size-7 is-block has-text-grey">
            {{label}}
        </span>
        <div class="is-flex is-clickable">
            <strong *ngIf="dropdownItems && dropdownItems.length > 0; else noDataTitle"
                class="dropdown-item-selected">
                {{currentItem?.label || 'Selecione'}}
            </strong>
            <i class="is-size-4 material-icons is-not-clickable is-align-items-center" aria-hidden="true">
                {{toggleIcon}}
            </i>
        </div>
        <ng-template #noDataTitle>
            <strong class="dropdown-item-selected">
                Nenhum
            </strong>
        </ng-template>
    </div>
    <!-- DROPDOWN MENU -->
    <ng-container *ngIf="dropdownItems && dropdownItems.length > 0">
        <div class="dropdown-menu" role="menu">
            <div class="dropdown-content is-padded-medium is-20" *ngIf="!isSingleSelect">
                <ng-container *ngIf="isMulticheck; else allSingleOpt">
                    <label class="checkbox is-clickable is-flex is-align-items-center dropdown-item" for="dropdown-menu">
                        <input id="dropdown-menu" type="checkbox" (change)="selectAllValues($event.target.checked)">
                        <span>Todos</span>
                    </label>
                </ng-container>
                <ng-template #allSingleOpt>
                    <label class="is-clickable is-flex is-align-items-center">
                        <span (click)="selectAllValues(true)">Todos</span>
                    </label>
                </ng-template>
                <span class="box-divider is-marginless-horizontal is-margined-small-vertical"></span>
                <ng-container *ngFor="let item of dropdownItems; let i=index; let last=last">
                    <ng-container *ngIf="isMulticheck; else singleOption">
                        <label class="checkbox is-clickable is-flex is-align-items-center dropdown-item"
                        for="{{label}}{{i}}">
                        <input [name]="label" id="{{label}}{{i}}" type="checkbox"
                        [checked]="item.checked" (change)="selectValue(item, $event.target.checked)">
                        <div class="is-flex is-align-items-center">
                            <img class="dropdown-item-icon" *ngIf="item.icon" [src]="item.icon" [alt]="item.label">
                            <span class="is-text-nowrap">{{item.label}}</span>
                        </div>
                    </label>
                    </ng-container>
                    <ng-template #singleOption>
                        <div class="is-flex is-align-items-center" (click)="selectValue(item, true)">
                            <img class="dropdown-item-icon" *ngIf="item.icon" [src]="item.icon" [alt]="item.label">
                            <span class="is-text-nowrap is-text-overflow">{{item.label}}</span>
                        </div>
                    </ng-template>
                    <span *ngIf="!last"
                    class="box-divider is-marginless-horizontal is-margined-small-vertical"></span>
                </ng-container>
            </div>
            <div class="dropdown-content is-padded-medium is-20" *ngIf="isSingleSelect">
                <ng-container *ngFor="let item of dropdownItems; let i=index; let last=last">
                    <ng-container *ngIf="isMulticheck; else singleOption">
                        <label class="checkbox is-clickable is-flex is-align-items-center dropdown-item"
                        for="{{label}}{{i}}">
                        <input [name]="label" id="{{label}}{{i}}" type="checkbox"
                        [checked]="item.checked" (change)="selectValue(item, $event.target.checked)">
                        <div class="is-flex is-align-items-center">
                            <img class="dropdown-item-icon" *ngIf="item.icon" [src]="item.icon" [alt]="item.label">
                            <span class="is-text-nowrap">{{item.label}}</span>
                            <span *ngFor="let icon of icons">
                                <span class="material-icons ml-1">{{ icon }}</span>
                            </span>
                        </div>
                    </label>
                    </ng-container>
                    <ng-template #singleOption>
                        <div class="is-flex is-align-items-center is-justify-content-space-between">
                            <span (click)="selectValue(item, true)">
                                <img class="dropdown-item-icon" *ngIf="item.icon" [src]="item.icon" [alt]="item.label">
                                <span class="is-text-nowrap is-text-overflow">{{item.label}}</span>
                            </span>
                            <span>
                                <span *ngFor="let icon of icons; let j = index">
                                    <span (click)="clickIcon(i, j)" class="material-icons ml-1">{{ icon }}</span>
                                </span>
                            </span>
                        </div>
                    </ng-template>
                    <span *ngIf="!last"
                    class="box-divider is-marginless-horizontal is-margined-small-vertical"></span>
                </ng-container>
                <span *ngIf="link"
                    class="box-divider is-marginless-horizontal is-margined-small-vertical"></span>
                <div *ngIf="link" (click)="clickLink()" class="dropdown-item-icon link">{{ link }}</div>
            </div>
        </div>
    </ng-container>
</div>
