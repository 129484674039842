import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PluginsService {

  private readonly API = `${environment.api.meta.url}`;

  constructor(private http: HttpClient) { }

  getCarrierByCompanyId(companyId: string) {
    return this.http.get(`${this.API}${environment.api.userMain}/${companyId}`);
  }

  getUsersOfCarrier(companyId: string) {
    return this.http.get(`${this.API}${environment.api.userMain}/${companyId}`).pipe(
      map((response: any) => response.data)
    );
  }

  saveUsersOfCarrier(companyId: string, users: Array<any>) {
    return this.http.post(`${this.API}${environment.api.userMain}/${companyId}`, users).pipe(
      map((response: any) => response.data)
    );
  }

  saveMatrixData(companyId: string, payload: any) {
    return this.http.post(`${this.API}${environment.api.userMain}/${companyId}`, payload);
  }
}
