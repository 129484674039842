import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'getValueFromField'
})
export class GetValueFromFieldPipe implements PipeTransform {

  transform(data: any, field: string): any {
    if (!data || !field) {
      return { value: null, action: null };
    }
    return this.extractValueFromField(data, field);
  }

  extractValueFromField(data: any, field: string) {
    if (!field.includes('.')) {
      if (typeof data[field] === 'object' && Object.keys(data[field]).length && 'value' in data[field]) {
        const { value, format, formatOptions, action } = _.get(data, field);
        if (format) {
          return { value: format.transform(value, formatOptions), action };
        }
        return { value, action };
      }
      return { value: _.get(data, field), action: null };
    }
    return { value: _.get(data, field), action: null };
  }

}
