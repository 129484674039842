<div class="ls-radio-options-input">
  <input
    darkMode
    class="ls-radio-options-input__field"
    type="text"
    placeholder="Selecione ou digite"
    (keyup)="filterOptions($event)"
  >
  <div class="ls-radio-options-input__list-container">
    <fieldset class="ls-radio-options-input__list-content" [id]="name">
      <ng-container *ngIf="filteredOptions && filteredOptions.length; else emptyList">
        <div
          class="ls-radio-options-input__item"
          *ngFor="let option of filteredOptions"
        >
          <app-radio
            [name]="name"
            [value]="option.value"
            [label]="option.label"
            [control]="control"
            (changeSelect)="changeSelect.emit($event)"
          ></app-radio>
        </div>
      </ng-container>
    </fieldset>
  </div>
</div>

<ng-template #emptyList>
  <p class="is-size-6">Nenhum dado encontrado</p>
</ng-template>

