import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-auction-details-container',
  templateUrl: './auction-details-container.component.html',
  styleUrls: ['./auction-details-container.component.scss']
})
export class AuctionDetailsContainerComponent {
  @Input() _id: string;
  @Input() isVisible: boolean;
  @Output() handleCloseEmitter = new EventEmitter();

  constructor() { }

  handleClose() {
    this.handleCloseEmitter.emit();
  }
}
