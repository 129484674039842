import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Alert } from 'src/app/interfaces/alert';
import { AlertPayload, AlertService } from 'src/app/services/alert/alert.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-new-alert-notification',
  templateUrl: './new-alert-notification.component.html',
  styleUrls: ['./new-alert-notification.component.scss']
})
export class NewAlertNotificationComponent implements OnInit {

  public check: boolean;
  public closePermitter: boolean;
  public closeButton: boolean;
  public text: string;
  public alertGet: any;
  public isShippingCompany = this.userService.isUserShippingCompany();
  public isEmbarcador = this.userService.isUserEmbarcador();

  alertTipe = [
    { value: 'warning', name: 'Warning' },
    { value: 'success', name: 'Success' },
    { value: 'info', name: 'Info' },
    { value: 'danger', name: 'Danger' }
  ];
  public selectedAlert: string;

  visionTipe = [

    { value: 'shipper', name: 'Embarcador' },
    { value: 'carrier', name: 'Transportadora' },
    { value: 'both', name: 'Ambos' }
  ];
  public selectedVision: string;

  isToggled = false;
  alert;

  isToggled$: Subscription;
  getAlert$: Subscription;

  constructor(private _alertService: AlertService, private userService: UserService, private authService: AuthService) {
  }

  ngOnInit(): void {
    this.authService.isLoggedUser.subscribe({
      next: (isLoggedUser: boolean) => {
        if (isLoggedUser) {
          this.checkIsToggled();
          this.getCheckAlert();
        } else {
          this.isToggled$?.unsubscribe();
          this.getAlert$?.unsubscribe();
        }
      }
    });
  }

  checkIsToggled() {
    this.isToggled$ = this._alertService.isToggled.subscribe({
      next: ({ visible, content }) => {
        this.isToggled = visible;
        this.alert = content;
      }
    });
  }

  getAlert() {
    this.getAlert$ = this._alertService.getAlert().subscribe((alerts) => {
      this.alertGet = alerts;
      for (const item of this.alertGet.data) {
        if (item.enabled == true) {
          this.selectedVision = item.viewedBy;
          this.closeButton = item.closable;
          this.selectedAlert = item.type;
          this.text = item.content;
        }
      }
    });
  }
  getCheckAlert() {
    this._alertService.getAlert().subscribe((alerts) => {
      this.alertGet = alerts;
      for (const item of this.alertGet.data) {
        if (item.enabled == true) {
          if (item.viewedBy == 'shipper' && this.isShippingCompany == true) {
            this.check = true;
            this.getAlert();
          }
          else if (item.viewedBy == 'carrier' && this.isEmbarcador == true) {
            this.check = true;
            this.getAlert();
          }
          else if (item.viewedBy == 'both') {
            this.check = true;
            this.getAlert();
          } else {
            return null;
          }
        }
      }
    });
  }
  closeAlerts() {
    this.alert = null;
  }
}
