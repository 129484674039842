import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { Globals } from 'src/app/core/globals';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatNativeDateModule } from '@angular/material/core';
import localePt from '@angular/common/locales/pt';

import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { LoaderSpinnerComponent } from './loader-spinner/loader-spinner.component';
import { GlobalNotificationComponent } from './shared/components/global-notification/global-notification.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HeaderModule } from './core/header/header.module';
import { ClientRestrictionsModule } from './features/client/client-restrictions/client-restrictions.module';
import { SharedModule } from './shared/shared.module';
import { PushNotificationComponent } from './shared/components/push-notification/push-notification.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NotFoundComponent } from './features/not-found/not-found.component';
import { PipesModule } from './shared/pipes/pipes.module';
import { AuctionModule } from './features/auction/auction.module';
import { CheckAuctionsModule } from './features/auction/check-auctions/check-auctions.module';
import { AuthModule } from './features/auth/auth.module';
import { RegisterOldCarriersModalComponent } from './plugins/register-old-carriers-modal/register-old-carriers-modal.component';
import { TermsOfUseComponent } from './plugins/register-old-carriers-modal/components/terms-of-use/terms-of-use.component';
import { ActiveCarrierUsersComponent } from './plugins/register-old-carriers-modal/components/active-carrier-users/active-carrier-users.component';
import { ConfirmDataCarrierComponent } from './plugins/register-old-carriers-modal/components/confirm-data-carrier/confirm-data-carrier.component';
import { ApmModule } from '@elastic/apm-rum-angular';
import { PwaService } from './shared/services/pwa.service';
import { ConfirmTermsModalModule } from './plugins/confirm-terms-modal/confirm-terms-modal.module';
import { NewAlertNotificationModule } from './plugins/new-alert-notification/new-alert-notification.module';
import { WebsocketConnectionStatusComponent } from './plugins/websocket-connection-status/websocket-connection-status.component';
import { MenuModule } from './menu/menu.module';
import { ConnectionStatusComponent } from './plugins/connection-status/connection-status.component';
import { LoadChunkErrorHandler } from './load-chunk-error-handler';
import { NewVersionCheckerComponent } from './plugins/new-version-checker/new-version-checker.component';
import { TabMenuComponent } from './tab-menu/tab-menu.component';

registerLocaleData(localePt);

@NgModule({
    declarations: [	
        AppComponent,
        LoaderSpinnerComponent,
        GlobalNotificationComponent,
        PushNotificationComponent,
        NotFoundComponent,
        RegisterOldCarriersModalComponent,
        TermsOfUseComponent,
        ActiveCarrierUsersComponent,
        ConfirmDataCarrierComponent,
        WebsocketConnectionStatusComponent,
        TabMenuComponent
   ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        MatSnackBarModule,
        NgbDatepickerModule,
        ReactiveFormsModule,
        AuthModule,
        HeaderModule,
        AuctionModule,
        CheckAuctionsModule,
        ClientRestrictionsModule,
        SharedModule,
        NgxSkeletonLoaderModule,
        PipesModule,
        ApmModule,
        MatNativeDateModule,
        ConfirmTermsModalModule,
        NewAlertNotificationModule,
        MenuModule,
        ConnectionStatusComponent,
        NewVersionCheckerComponent,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.environment !== 'local',
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    providers: [
        PwaService,
        Globals,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: LOCALE_ID,
            useValue: navigator.language
        },
        {
            provide: ErrorHandler,
            useClass: LoadChunkErrorHandler
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
