import { ShipperTrackingService } from '../../../../features/tracking/shipper-tracking-view/shipper-tracking.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-imported-data-modal',
  templateUrl: './imported-data-modal.component.html',
  styleUrls: ['./imported-data-modal.component.scss']
})
export class ImportedDataModalComponent implements OnInit {

  @Output() closeModalEmitter = new EventEmitter();

  importations: any;

  constructor(private shipperService: ShipperTrackingService) { }

  ngOnInit(): void {
    this.listImportations();
  }

  closeModal() {
    this.closeModalEmitter.emit(false);
  }

  downloadErrors(link) {
    window.open(link, '_blank');
    this.closeModal();
  }

  async listImportations() {
    const response = await this.shipperService.listImportations();

    if (response) {
      this.importations = response.importations;
    }

  }

}
