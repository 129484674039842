export enum LoadsharkColors {
  'load-primary' = '#4d3de2',
  'load-red' = '#F9395A',
  'load-orange' = '#FFA900',
  'load-grey' = '#868686',
  'load-success' = '#00B74A',
  'load-info' = '#39C0ED',
  'load-accent' = '#B244EE',
  'load-complementary' = '#7DE8E3',
  'load-primary-light' = '#4d3de117',
  'load-red-light' = '#f398ab',
  'load-orange-light' = '#ffd685',
  'load-success-light' = '#85dca8',
  'load-black' = '#000',
  'load-white' = '#fff',
  'load-dark' = 'hsl(0deg, 0%, 21%)',
  'load-darkest' = 'hsl(0deg, 0%, 14%)',

}
