import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { MAIN_CAROUSEL } from 'src/app/data/main-carousel';
import { OrderService } from 'src/app/services/order/order.service';
import { OrganizationService } from 'src/app/services/organization/organization.service';
import { UserService } from 'src/app/services/user/user.service';
import { CommonForm } from 'src/app/shared/common-form';
import { AnalyiticsService } from 'src/app/shared/services/analyitics.service';
import { MaintenanceService } from 'src/app/shared/services/maintenance.service';
import { RegisterCarrierService } from '../../register/register-carrier/services/register-carrier.service';
import { DarkModeService } from '@services/dark-mode.service';
@Component({
  selector: 'app-auth-by-email',
  templateUrl: './auth-by-email.component.html',
  styleUrls: [
    './auth-by-email.component.scss'
  ]
})

export class AuthByEmailComponent extends CommonForm implements OnInit {
  @ViewChild('passwordInput') passwordInput: ElementRef;
  passVisibility = false;
  state = {};

  pathImages = MAIN_CAROUSEL;

  content = {
    title: 'Loadshark',
    authByEmailCTAButton: 'Entrar'
  };

  theForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    password: new UntypedFormControl('', [Validators.required]),
    tenant: new UntypedFormControl('', []),
  });

  errorMessage = null;
  passwordVisibility = false;
  passwordChangeCount = null;
  checkedUser = null;
  tenants = [];
  selectedTenant = null;

  redirect$: Observable<any>;

  maintenance$ = null;

  constructor(
    private auth: AuthService,
    private router: Router,
    private analytics: AnalyiticsService,
    private userService: UserService,
    private organizationService: OrganizationService,
    private orderService: OrderService,
    private route: ActivatedRoute,
    private maintenanceService: MaintenanceService,
    private registerCarrierService: RegisterCarrierService,
    private darkMode: DarkModeService
  ) {
    super();
  }

  get hasError() { return this.stateIs('theForm', 'error') || this.stateIs('theForm', 'partial'); }

  get email() { return this.theForm.get('email'); }

  get password() { return this.theForm.get('password'); }

  get checkIfUserAcceptedCookies() {
    return document.cookie.split(';').find(c => c.includes('accepted-cookies'));
  }

  get isDarkMode() {
    return this.darkMode.isDark;
  }

  ngOnInit() {
    this.maintenance$ = this.maintenanceService.isUnderMaintenance();
    this.goBack();
    this.setState('theForm', 'blank');
    this.redirectHandle();
  }

  redirectHandle() {
    this.redirect$ = this.route.queryParams;
    this.redirect$.subscribe(async (data) => {
      if (data && data.token) {
        const { token } = data;
        this.setState('theForm', 'validating');
        this.validateUser(token);
      }
      if (data && data.auth) {
        this.SSOAuthentication();
      }
    });
  }

  forgotPassword() {
    this.router.navigate(['/forgot-password'], { state: { data: { email: this.email.value || '' } } });
  }

  setState(scope: string, state: string) {
    this.state[scope] = state; // blank / loading / partial / error / ideal
  }

  stateIs(scope: string, state: string) {
    return state === this.state[scope];
  }

  theFormSubmit() {
    if (this.theForm.valid && !this.stateIs('theForm', 'loading')) {
      this.setState('theForm', 'loading');
      this.theFormAsyncSubmit();
    } else {
      this.setState('theForm', 'partial');
    }
  }

  async getAllUserPreferences() {
    await this.userService.getAllUserPreferences();
  }

  reSendEmail(){
    this.userService.sendNewEmailVerification(this.checkedUser?.email);
    this.goBack();
  }

  redirectUser() {
    const passwordCount = this.userService.userPasswordCount();
    if (this.userService.userPasswordCount() > 0) {
      this.router.navigate(['/suggest-password']);
      return;
    }
    this.userService.handleDefaultRedirect();
  }

  redirectToFillProfile(){
    const user = this.auth.getLoggedUser();
    if (user) {
      this.auth.logout({ withoutRedirect: true });
      const cnpj = user.head?.cnpj;
      this.registerCarrierService.getCarrierByCNPJ(cnpj).subscribe({
        next: carrier => {
          if (carrier) {
            this.navigateToRegisterCarrier(carrier);
          } else {
            console.error('Transportadora não encontrada');
            this.router.navigate(['/login']);
          }
        },
        error: e => {
          if (e?.error?.code === 3089) {
            console.error(e.error?.message || 'Ocorreu um erro ao recuperar dados da transportadora');
          }
        }
      });
    } else{
      this.goBack();
    }

  }

  navigateToRegisterCarrier(carrier) {
    this.router.navigate(['/cadastro/transportadora'], { queryParams: { edit: true }, state: { carrier } });
  }

  theFormAsyncSubmit() {
    this.analytics.eventEmitter('Login', 'Entrar');
    this.auth.login({ email: this.theForm.value.email, password: this.theForm.value.password, tenancy: this.selectedTenant }).subscribe({
      next: _ => {
        this.setState('theForm', 'ideal');
        (window as any).gtag('event', 'login', {
          method: 'auth-by-email'
        });
        (window as any).gtag('event', 'Login por e-mail', {
          event_category: 'Autenticação',
          event_label: 'Válido',
        });
      },
      error: e => {
        this.errorMessage = e.error?.errors?.[0].message ?? 'Ocorreu um erro na autenticação';
        this.setState('theForm', 'error');
        (window as any).gtag('event', 'Login por e-mail', {
          event_category: 'Autenticação',
          event_label: 'Invalido',
        });
      },
      complete: () => {
        if(this.checkedUser?.loginValidation === 'fillProfile'){
          this.redirectToFillProfile();
        } else{
          this.afterAuthenticate();
        }
      },
    });
  }

  async saveOrganizations() {
    const result = await this.organizationService.listOrganizations(0, 1000, '');
    if (result && result['data'] && result['data']['organization']) {
      localStorage.setItem('organizations', JSON.stringify(result['data']['organization']));
    }
  }

  formFocus = () => {
    if (this.hasError) {
      this.theForm.get('email').setValue('');
      this.theForm.get('password').setValue('');
      this.setState('theForm', 'blank');
    }
  };

  async afterAuthenticate() {
    if (this.stateIs('theForm', 'ideal')) {
      await this.saveOrganizations();
      if (this.userService.isUserAdmin() || this.userService.isUserEmbarcador()) {
        await this.orderService.listUserOts();
      }
      this.redirectUser();
      this.getAllUserPreferences();
      this.sendOrganizationToGa();
      this.userService.getNotifications();
    }
  }

  SSOAuthentication() {
    try {
      this.auth.mountUserAfterAuthenticate();
      this.setState('theForm', 'ideal');
      this.afterAuthenticate();
    } catch (e) {
      this.errorMessage = e.error?.errors && e.error?.errors.length > 0 && e.error.errors[0].message ? e.error.errors[0].message : 'Ocorreu um erro na autenticação';
      this.setState('theForm', 'error');
      (window as any).gtag('event', 'Login por SSO', {
        event_category: 'Autenticação',
        event_label: 'Invalido',
      });
    }
  }

  sendOrganizationToGa() {
    const user = this.userService.getLoggedUser();
    const organizations = JSON.parse(localStorage.organizations);

    const userOrganizationName = organizations.find(org => org._id === user.organization);

    (window as any).gtag('event', 'Login por e-mail', {
      event_category: 'Autenticação',
      event_label: `email: ${user.email}, organizationName: ${userOrganizationName ? userOrganizationName.name : ''}, organizationId: ${user.organization}`,
    });
  }

  checkUser() {
    this.goBack();
    this.email.markAsDirty();
    if (this.email.invalid) {
      return;
    }
    const email = this.email.value;

    this.userService.checkUser({email}).subscribe({
      next: (response: any) => {
        if (response && response.redirect) {
          window.location.href = response.redirect;
          return;
        }
        if (response && response.user) {
          this.checkedUser = response.user;
          this.checkedUser.tenancy.forEach(tenancy => {
            if (!this.tenants.find(t => t.value === tenancy._id)) {
              this.tenants.push({
                label: tenancy.name,
                value: tenancy._id
              });
            }
          });
          this.selectedTenant = this.tenants[0].value;
          if (this.tenants.length > 1) {
            this.theForm.get('tenant').setValue(this.tenants[0]);
          }
          this.setState('theForm', 'blank');
        } else {
          this.setState('theForm', 'error');
          this.errorMessage = response || 'Erro ao tentar autenticar na plataforma.';
        }
      },
      error: e => {
        this.setState('theForm', 'error');
        this.errorMessage = e.error?.message || 'Erro ao tentar autenticar na plataforma.';
      }
    });
  }

  goBack() {
    this.checkedUser = null;
    this.tenants = [];
    this.selectedTenant = null;
    this.setState('theForm', 'blank');
    this.errorMessage = null;
  }

  handleTenant(tenant) {
    this.selectedTenant = tenant?.value;
  }

  validateUser(token) {
    this.userService.validateUser(token).subscribe((data) => {
      if (data && data.code === 200) {
        this.setState('theForm', 'validated');
      } else {
        this.setState('theForm', 'cannotActivate');
      }
      setTimeout(() => {
        this.setState('theForm', 'blank');
      }, 4500);
    });
  }

}
