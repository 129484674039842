import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-expired-token-modal',
  templateUrl: './expired-token-modal.component.html',
  styleUrls: ['./expired-token-modal.component.scss']
})
export class ExpiredTokenModalComponent implements OnInit {

  public user;

  constructor(
    private authService: AuthService,
    private userService: UserService) { }

  ngOnInit() {
    this.user = this.userService.getLoggedUser();
    this.authService.removeTokenAsExpired();
    this.authService.logout({ withoutRedirect: true });
  }

  isTokenExpired() {
    return this.authService.isTokenExpired();
  }

  goToLogin() {
    location.reload();
  }

}
