<div *ngIf="globals.client?.meta.state === 'loading'" class="placeholder has-placeholder-small"></div>
<dl *ngIf="!clientRestriction && globals.client?.meta.state === 'ideal'">
  <dt><strong>Janela de recebimento</strong></dt>
  <dd *ngIf="globals.client.data.deliveryWindow?.monday.length">
    Segunda-feira:
    {{ globals.client.data.deliveryWindow?.monday.join(', ') }}
  </dd>
  <dd *ngIf="globals.client.data.deliveryWindow?.tuesday.length">
    Terça-feira:
    {{ globals.client.data.deliveryWindow?.tuesday.join(', ') }}
  </dd>
  <dd *ngIf="globals.client.data.deliveryWindow?.wednesday.length">
    Quarta-feira:
    {{ globals.client.data.deliveryWindow?.wednesday.join(', ') }}
  </dd>
  <dd *ngIf="globals.client.data.deliveryWindow?.thursday.length">
    Quinta-feira:
    {{ globals.client.data.deliveryWindow?.thursday.join(', ') }}
  </dd>
  <dd *ngIf="globals.client.data.deliveryWindow?.friday.length">
    Sexta-feira:
    {{ globals.client.data.deliveryWindow?.friday.join(', ') }}
  </dd>
  <dd *ngIf="globals.client.data.deliveryWindow?.saturday.length">
    Sábado:
    {{ globals.client.data.deliveryWindow?.saturday.join(', ') }}
  </dd>
  <dd *ngIf="globals.client.data.deliveryWindow?.sunday.length">
    Domingo:
    {{ globals.client.data.deliveryWindow?.sunday.join(', ') }}
  </dd>
  <dt *ngIf="!!globals.client.data?.allowedVehicleTypes && globals.client.data?.allowedVehicleTypes.length"
    class="is-padded-small-top">
    <strong>Veículos aceitos</strong>
  </dt>
  <dd *ngIf="!!globals.client.data?.allowedVehicleTypes && globals.client.data?.allowedVehicleTypes.length">
    {{ translateAllowedVehicleTypes(globals.client.data.allowedVehicleTypes).join(', ') }}
  </dd>
  <dt *ngIf="!!globals.client.data?.allowedCargoTypes && globals.client.data?.allowedCargoTypes.length"
    class="is-padded-small-top">
    <strong>Tipos de carga aceitas</strong></dt>
  <dd *ngIf="!!globals.client.data?.allowedCargoTypes && globals.client.data?.allowedCargoTypes.length">
    {{ translateAllowedCargoTypes(globals.client.data.allowedCargoTypes).join(', ') }}
  </dd>
</dl>

<dl *ngIf="clientRestriction">
  <dt><strong>Janela de recebimento</strong></dt>
  <dd *ngIf="clientRestriction.deliveryWindow?.monday.length">
    Segunda-feira:
    {{ clientRestriction.deliveryWindow?.monday.join(', ') }}
  </dd>
  <dd *ngIf="clientRestriction.deliveryWindow?.tuesday.length">
    Terça-feira:
    {{ clientRestriction.deliveryWindow?.tuesday.join(', ') }}
  </dd>
  <dd *ngIf="clientRestriction.deliveryWindow?.wednesday.length">
    Quarta-feira:
    {{ clientRestriction.deliveryWindow?.wednesday.join(', ') }}
  </dd>
  <dd *ngIf="clientRestriction.deliveryWindow?.thursday.length">
    Quinta-feira:
    {{ clientRestriction.deliveryWindow?.thursday.join(', ') }}
  </dd>
  <dd *ngIf="clientRestriction.deliveryWindow?.friday.length">
    Sexta-feira:
    {{ clientRestriction.deliveryWindow?.friday.join(', ') }}
  </dd>
  <dd *ngIf="clientRestriction.deliveryWindow?.saturday.length">
    Sábado:
    {{ clientRestriction.deliveryWindow?.saturday.join(', ') }}
  </dd>
  <dd *ngIf="clientRestriction.deliveryWindow?.sunday.length">
    Domingo:
    {{ clientRestriction.deliveryWindow?.sunday.join(', ') }}
  </dd>
  <dt *ngIf="clientRestriction?.allowedVehicleTypes && clientRestriction?.allowedVehicleTypes.length"
    class="is-padded-small-top">
    <strong>Veículos aceitos</strong>
  </dt>
  <dd *ngIf="clientRestriction?.allowedVehicleTypes && clientRestriction?.allowedVehicleTypes.length">
    {{ translateAllowedVehicleTypes(clientRestriction.allowedVehicleTypes).join(', ') }}
  </dd>
  <dt *ngIf="clientRestriction?.allowedCargoTypes && clientRestriction?.allowedCargoTypes.length"
    class="is-padded-small-top">
    <strong>Tipos de carga aceitas</strong></dt>
  <dd *ngIf="!!clientRestriction?.allowedCargoTypes && clientRestriction?.allowedCargoTypes.length">
    {{ translateAllowedCargoTypes(clientRestriction.allowedCargoTypes).join(', ') }}
  </dd>
</dl>
