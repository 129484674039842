import { ISideImage } from 'src/app/shared/components/side-image/side-image.component';

export const MAIN_CAROUSEL: ISideImage[] = [
  {
    src: '/assets/img/backgrounds/truck4.webp',
    legend: 'Centenas de cargas disponíveis em leilões e repasses'
  },
  {
    src: '/assets/img/backgrounds/truck5.webp',
    legend: 'Centenas de cargas disponíveis em leilões e repasses'
  },
  {
    src: '/assets/img/backgrounds/truck6.webp',
    legend: 'Centenas de cargas disponíveis em leilões e repasses'
  },
];
