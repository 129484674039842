<app-popup [isVisible]="isVisible" [isSmall]="true" [noClose]="true" (handleClose)="close()">

  <ng-container *ngIf="status === 'confirm'; else emailSent">
    <div class="full-width is-flex is-justify-content-start is-flex-direction-column">

      <!-- Titulo e subtitulo -->
      <b class="is-size-3 mb-5">
        Atenção! Leia e confirme!
      </b>

      <section class="terms-container is-flex is-justify-content-start is-flex-direction-column">
        <div class="is-flex is-justify-content-start is-align-items-center">
          <label class="checkbox">
            <input type="checkbox" [(ngModel)]="infoConfirmed">
            <div></div>
          </label>

          <p class="is-size-5">
            Confirmo que todas as informações fornecidas são <b>reais e verdadeiras</b> no momento do preenchimento desse cadastro.
          </p>
        </div>

        <div class="is-flex is-justify-content-start is-align-items-center">
          <label class="checkbox">
            <input type="checkbox" [(ngModel)]="accordingTo">
            <div></div>
          </label>

          <p class="is-size-5">
            Estou ciente que o preenchimento do cadastro <b>não gera compromisso</b> das empresas embarcadoras de realizar minha contratação imediata.
          </p>
        </div>

        <div class="is-flex is-justify-content-start is-align-items-center">
          <label class="checkbox">
            <input type="checkbox" [(ngModel)]="readAndAccepted">
            <div></div>
          </label>

          <p class="is-size-5">
            Li e aceito as <span (click)="renderTerms()" class="has-text-link is-underlined is-clickable">políticas de privacidade de dados</span>.
          </p>

        </div>
      </section>

      <div class="my-4" [hidden]="!termsIsVisible">
        <object class="pdf" #pdfview
        [data]=''
        type="application/pdf"
        width="100%"
        height="800px">
        </object>
      </div>

      <!-- Botões pós formulario -->
      <div class="full-width is-flex is-align-items-center is-justify-content-center mt-5">
        <app-button
          *ngIf="termsIsVisible"
          class="mr-2"
          title="Baixar políticas"
          icon="download"
          [isDefault]="true"
          [isSecondary]="true"
          (handleClickEmitter)="downloadFile()">
        </app-button>

        <app-button
          title="Concluir"
          [isDefault]="true"
          [isDisabled]="!infoConfirmed || !accordingTo || !readAndAccepted"
          (handleClickEmitter)="confirmedTerms()"
        ></app-button>
      </div>
    </div>
  </ng-container>
  <ng-template #emailSent>
    <app-email-confirmation-sent
    [emailSent]="user.email"
    (handleFinish)="close()">
    </app-email-confirmation-sent>
  </ng-template>

</app-popup>


