import { Injectable } from '@angular/core';
import { ApmService } from '@elastic/apm-rum-angular';
import { ApmBase } from '@elastic/apm-rum';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApplicationMonitoringService {

  private _instance: ApmBase;

  constructor(private service: ApmService) { }

  init() {
    console.log('instantiating elastic apm...');
    this._instance = this.service.init({
      serviceName: 'webclient',
      serverUrl: environment.elasticApiUrl,
      environment: environment.environment,
      logLevel: 'info'
    });
  }

  setContext(context: Record<string, string>) {
    this._instance.setUserContext(context);
  }

  captureError(err, customData?) {
    if (customData) {
      this._instance.setCustomContext(customData);
    } 
    this._instance.captureError(err);
  }

  sendError(message: string) {
    const err = new Error(message);
    this._instance.captureError(err);
  }

  getAPM() {
    return this.service.apm;
  }
}
