import { Directive, ElementRef, Renderer2, AfterViewInit, NgZone } from '@angular/core';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[appGenerateIdentifiers]'
})
export class GenerateIdentifiersDirective implements AfterViewInit {

  inspectTags = ['span', 'input', 'button', 'textarea', 'ul', 'li', 'label'];
  placeholders = [];

  constructor(private el: ElementRef, private _renderer: Renderer2) { }


  ngAfterViewInit(): void {
    // setTimeOut para casos de *ngFor na qual o elemento só é carregado depois
    setTimeout(() => {
      this.setIdInTags();
    }, 1500);
  }

  setIdInTags() {
    this.inspectTags.forEach((tagName) => {
      const tags = (this.el?.nativeElement ?? this.el).querySelectorAll(tagName);
      tags.forEach((e) => {
        if (!e.hasAttribute('id')) {
          const placeholder = this.removeEmptySpaces(e?.getAttribute('placeholder'));
          const formControlName = this.removeEmptySpaces(e?.getAttribute('formcontrolname'));
          const text = this.removeEmptySpaces(e?.innerText);

          // tslint:disable-next-line:no-unused-expression
          placeholder && this.removeDuplicatePlaceholders(placeholder);

          this._renderer.setAttribute(e, 'id', `${tagName}${formControlName ?? placeholder ?? text }`);
        }
      });
    });
  }

  removeEmptySpaces(word) {
    return word?.split(' ')?.join('');
  }

  removeDuplicatePlaceholders(placeholder) {
    placeholder += this.placeholders.includes(placeholder) ? '1' : '';
    this.placeholders.push(placeholder);
  }
}
