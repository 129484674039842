export enum AuctionTypes {
    'reverse' = 'Reverso',
    'priceQuotation' = 'Tomada de preço',
    'vendaDireta' = 'Venda direta',
}

export enum HiringTypes {
    'trip' = 'Por viagem',
    'volume' = 'Por volume'
}
