import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-auction-row',
  templateUrl: './auction-row.component.html',
  styleUrls: ['./auction-row.component.scss']
})
export class AuctionRowComponent {
  @Input() isSelected = false;
  @Input() isCombo = false;

  constructor() { }
}
