import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extraLoadStatus'
})
export class ExtraLoadStatusPipe implements PipeTransform {

  transform(key: unknown, ...args: unknown[]): unknown {
    status = '-';
    switch (key) {
      case 'created':
        status = 'Carga adicionada';
        break;
      case 'accepted':
        status = 'Carga aceita pela transportadora';
        break;
      case 'canceled':
        status = 'Carga cancelada pela transportadora';
        break;
      case 'allowed':
        status = 'Agendamento permitido';
        break;
      case 'rejected':
        status = 'Agendamento rejeitado';
        break;
      case 'backToTransfer':
        status = 'Carga retornou ao repasse';
        break;
      case 'integration-error':
        status = 'Agente de Frete não foi atualizado no SAP';
        break;
      case 'integration-success':
        status = 'Agente de Frete atualizado no SAP';
        break;
      case 'confirmed':
        status = '';
        break;
      case 'pending':
        status = 'Agente de Frete não atualizado no SAP';
        break;
      case null:
        status = 'Agente de Frete não selecionado';
        break;
      case 'RFC3':
        status = 'Agente de Frete em Integração';
        break;
      case 'RFC5':
        status = 'Agente de Frete em Integração';
        break;
      case 'error':
        status = '';
        break;
      default:
        break;
    }
    return status;
  }

}
