import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  update$ = new BehaviorSubject<void>(null);

  constructor() { }

  get updatesChanges$(): Observable<void> {
    return this.update$;
  }

  update() {
    this.update$.next(null);
  }
}
