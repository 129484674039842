<div class="notification-list-container" [ngClass]="{'toggled': menuToggled }">
  <div darkMode class="notification-list">
    <div class="columns is-multiline">
      <div class="column is-12">
        <div darkMode class="menu-item title-divider">
          <i class="material-icons-outlined">
            notifications
          </i>
          <span>Notificações</span>
        </div>
      </div>
      <div class="column is-12">
        <button darkMode class="menu-item is-clickable"
        (click)="handleToggle()">
          <i class="material-icons-outlined">
            close
          </i>
          <span>Fechar</span>
        </button>
      </div>
      <div class="column is-12 px-6">
        <app-input
          placeholder="Pesquise por palavra-chave"
          trailingIcon="search"
          [(value)]="searchedText"
          (keyup.enter)="getNotifications(0, 10, 'all', true)"
        >
        </app-input>
      </div>
      <div class="column is-12">
        <app-tabs
        [tabs]="notificationsTabs"
        (changeTabEmitter)="changeTab($event)"></app-tabs>
      </div>
      <div class="column is-12">
        <div *ngFor="let module of notificationsByModule; let i=index">
          <app-notifications-collapsible [module]="module.module" [notifications]="module.notifications"
            [total]="module.total" [showNotifications]="module.collapsed" (toggleEmitter)="handleToggleCollapsible(i)"
            [total]="module.total" [showNotifications]="module.collapsed" (paginationEmitter)="handlePagination($event)"
            (deleteEmitter)="deleteNotifications($event)"
            (redirectEmitter)="goToRoute($event)">
          </app-notifications-collapsible>
        </div>
      </div>
    </div>
  </div>
</div>
