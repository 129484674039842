import { Injectable } from '@angular/core';
import { Profiles } from 'src/app/models/profiles';
import { UserService } from 'src/app/services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class RBACService {

  constructor(private userService: UserService) { }

  can(roles: Profiles[]) {
    const userProfiles = this.userService.getUserProfiles();
    return userProfiles.some(p => roles.includes(p) || p === Profiles.Administrador);
  }
}
