import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string, searchScope?: string[]): any[] {
    if (!items) {
      return [];
    }

    if (!searchText) {
      return items;
    }

    searchText = searchText.toLowerCase();

    return items.filter(item => {
      let result = false;
      if (!searchScope) {
        result = item.toString().toLowerCase().includes(searchText);
      } else {
        searchScope.forEach(scope => {
          if (result) {
            return result;
          }

          const path = scope ? scope.split('.') : [];

          switch (path.length) {
            case 1:
              result =
                item[path[0]]
                  ?
                  item[path[0]].toLowerCase().includes(searchText)
                  :
                  false;
              break;
            case 2:
              result =
                item[path[0]]
                  &&
                  item[path[0]][path[1]]
                  ?
                  item[path[0]][path[1]].toLowerCase().includes(searchText)
                  :
                  false;
              break;
            case 3:
              result =
                item[path[0]]
                  &&
                  item[path[0]][path[1]]
                  &&
                  item[path[0]][path[1]][path[2]]
                  ?
                  item[path[0]][path[1]][path[2]].toLowerCase().includes(searchText)
                  :
                  false;
              break;
            case 4:
              result =
                item[path[0]]
                  &&
                  item[path[0]][path[1]]
                  &&
                  item[path[0]][path[1]][path[2]][path[3]]
                  ?
                  item[path[0]][path[1]][path[2]][path[3]].toLowerCase().includes(searchText)
                  :
                  false;
              break;
            default:
              result = item.toLowerCase().includes(searchText);
              break;
          }
        });
      }

      return result;
    });
  }
}
