<app-popup [isVisible]="!isRegistrationComplete" [noClose]="true">
    <div>
        <header>
          <h2 class="has-text-centered has-text-weight-bold is-size-2">
            Atenção! Você precisa atualizar seu cadastro.
          </h2>
        </header>

        <div class="card-content">
          <div class="content is-size-5">
            Para continuar usando o Loadshark e participando das negociações, é necessário atualizar seu perfil com as seguintes informações:
          </div>
          <div class="content is-size-4 has-text-primary">
            1. Atualizar telefone de contato, com padrão (XX) XXXXX-XXXX
          </div>
          <div class="content is-size-4 has-text-primary">
            2. Cadastrar pelo menos 20 rotas
          </div>
          <div class="content is-size-4 has-text-primary">
            3. Adicionar no mínimo 1 veículos (as configurações do repasse e leilão irão se basear nessa informação)
          </div>
          <div class="content is-size-4 has-text-primary">
            4. Informar quais tipos de cargas você transporta
          </div>
          <div class="content is-size-4 has-text-primary">
            5. Adicionar no mínimo uma área de atuação da transportadora
          </div>
        </div>

        <div class="is-flex is-justify-content-center mt-4">
          <app-button
          title="ATUALIZAR PERFIL"
          (handleClickEmitter)="onRedirect()">
          </app-button>
        </div>
    </div>
</app-popup>
