import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-order-button',
  templateUrl: './order-button.component.html',
  styleUrls: ['./order-button.component.scss']
})
export class OrderButtonComponent implements OnInit {

  selectedItem = null;

  @Input() tooltipText = 'Ordenar';
  @Input() items = [];
  @Input() aligned = 'left';
  @Input() canReverseOrder = false;
  @Input() canClearOrder = false;

  @Output() orderEmitter = new EventEmitter();
  @Output() reverseOrder = new EventEmitter();
  @Output() clearOrder = new EventEmitter();

  isExpanded = false;
  isReverseOrder = false;

  constructor() { }

  ngOnInit() {
  }

  handleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  handleOrder = item => {
    this.handleExpand();
    this.selectedItem = item.label;
    this.orderEmitter.emit(item.value);
  }

  handleReverseOrder() {
    if (!this.selectedItem) {
      return;
    }

    this.reverseOrder.emit();
    this.handleCheckExpanded();
    this.isReverseOrder = !this.isReverseOrder;
  }

  handleClearOrder() {
    this.clearOrder.emit();
    this.selectedItem = null;
    this.handleCheckExpanded();
  }

  handleCheckExpanded() {
    if (this.isExpanded) {
      this.isExpanded = false;
    }
  }

}
