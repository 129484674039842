import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-global-alert',
  templateUrl: './global-alert.component.html',
  styleUrls: ['./global-alert.component.scss']
})
export class GlobalAlertComponent {

  title: string;
  display = false;

  constructor() { }

  onClick = () => { };
}
