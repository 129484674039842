import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PopupBase } from 'src/app/models/popup';

@Component({
  selector: 'app-privacy-policy-footer',
  templateUrl: './privacy-policy-footer.component.html',
  styleUrls: ['./privacy-policy-footer.component.scss']
})
export class PrivacyPolicyFooterComponent implements OnInit {

  @Output() handleCloseEmitter = new EventEmitter();
  isVisible: boolean;
  footerVisible = true;

  constructor() {}

  ngOnInit(): void {
  }

  openModal(){
    this.isVisible = true;
  }

  closeModal() {
    this.handleCloseEmitter.emit();
    this.isVisible = false;
  }

  closefooter(){
    this.footerVisible = false;
  }

  acceptCookies() {
    try {
      const thirtyDays = new Date();
      thirtyDays.setDate(thirtyDays.getDate() + 30);
      document.cookie = `accepted-cookies=; expires=${thirtyDays}`;
      (window as any).gtag('consent', 'update', {
        ad_storage: 'granted'
      });
    } catch (e) {
      console.log('Não foi possível aceitar os cookies desse site. Por favor, confirme se o seu navegador não os bloqueou.');
    }
  }

}
