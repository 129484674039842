import { Component, Input, OnInit } from '@angular/core';
import { DarkModeService } from '@services/dark-mode.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() title: string;
  @Input() isDark = false;

  clickCount = 0;
  clickDisabled = false;

  constructor(private darkMode: DarkModeService) { }

  ngOnInit() {
    this.darkMode.toggled$.subscribe({
      next: isDarkMode => this.isDark = isDarkMode
    }); 
  }

  verifyClicks() {
    if (this.clickDisabled) { return; }
    this.clickCount++;
    if (this.clickCount === 20) {
      this.clickDisabled = true;
      console.log('%c%s',
        'color: #47cdbd; font-size: 24px;', window.atob('VGhhbmtzIGV2ZXJ5b25lIHdobyBoZWxwZWQgYnVpbGQgTG9hZFNoYXJrIDop'));
      setTimeout(() => {
        this.clickCount = 0;
        this.clickDisabled = false;
      }, 2000);
    }
  }

}
