import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, tap } from 'rxjs/operators';
import { APIDataRegister } from 'src/app/interfaces/ApiResponse';
import { GlobalNotificationService } from 'src/app/shared/services/global-notification.service';
import { environment } from 'src/environments/environment';
import CarrierDetails from 'src/app/features/register/register-carrier/models/CarrierDetails.model';
import { Terms } from 'src/app/features/register/register-carrier/models/Terms.model';
import CreateCompany from '../models/CreateCompany.model';
import MatrixDataPayload from '../models/Matrix-data-payload.model';
import PreRegistrationPayload from '../models/Pre-registration-payload.model';
import ProfilePayload from '../models/Profile-payload.model';
import { of } from 'rxjs';
import { UserService } from 'src/app/services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class RegisterCarrierService {

  private readonly API = `${environment.api.meta.url}${environment.api.transporter}`;
  private readonly API_COMPANY = `${environment.api.meta.url}${environment.api.company}`;
  private readonly API_USER = `${environment.api.meta.url}${environment.api.userMain}/`;
  private readonly API_USER_Main = `${environment.api.meta.url}${environment.api.users}/`;

  constructor(private http: HttpClient, private notificationService: GlobalNotificationService, private userService: UserService) { }

  uploadPhoto(companyId, data) {
    return this.http.post<APIDataRegister<any>>(`${this.API_COMPANY}uploadProfileImage/${companyId}`, data).pipe(
      map(response => {
        this.sendNotification('success', 'Foto salva com sucesso.');

        return response.data[0].file;
      }),
      catchError((error): any => {
        this.sendNotification('error', error.message);
        return of(null);
      })
    );
  }

  getCarrierById(id: string) {
    return this.http.get<APIDataRegister<CarrierDetails>>(`${this.API}profile/${id}`).pipe(
      map(response => response.data)
    );
  }

  getCarrierByCNPJ(cnpj: string) {
    return this.http.get<APIDataRegister<CarrierDetails>>(`${this.API}getByCnpj/${cnpj}`).pipe(
      map(response => response.data)
    );
  }

  getCompanyByCNPJ(cnpj: string) {
    return this.http.get<APIDataRegister<CarrierDetails>>(`${this.API_COMPANY}get/${cnpj}`).pipe(
      map(response => response.data)
    );
  }

  createCarrier(data: PreRegistrationPayload) {
    return this.http.post<APIDataRegister<any>>(`${this.API}`, data).pipe(
      map(response => {
        this.sendNotification('success', 'Dados salvos com sucesso.');
        return { ...response.data, cnpj: data.cnpj };
      })
    );
  }

  updatedCarrier(id: string, data: ProfilePayload) {
    return this.http.patch<APIDataRegister<any>>(`${this.API}/edit/${id}`, data).pipe(
      map(response => {
        this.sendNotification('success', 'Dados salvos com sucesso.');

        return response.data;
      })
    );
  }

  confirmedTerms(userId: string, terms: any) {
    return this.http.patch<APIDataRegister<any>>(`${this.API_USER_Main}confirmedTerms/${userId}`, terms).pipe(
      map(response => {
        this.sendNotification('success', 'Dados salvos com sucesso.');

        return response.data;
      })
    );
  }

  uploadLicenseFiles(data, companyId: string){
    const isLogged = this.userService.getLoggedUser();
    if (isLogged) {
      return this.http.patch<APIDataRegister<any>>(`${this.API}createNewLicenses/auth/${companyId}`, data).pipe(
        map(response => {
          this.sendNotification('success', 'Dados salvos com sucesso.');
          return response.data;
        })
      );
    } else {
      return this.http.patch<APIDataRegister<any>>(`${this.API}createNewLicenses/${companyId}`, data).pipe(
        map(response => {
          this.sendNotification('success', 'Dados salvos com sucesso.');
          return response.data;
        })
      );
    }
  }

  listLicenseFiles(companyId: string){
    return this.http.get<APIDataRegister<any>>(`${this.API}listNewLicenses/auth/${companyId}`).pipe(
      map(response => {
        this.sendNotification('success', 'Dados salvos com sucesso.');
        return response.data;
      })
    );
  }

  deleteLicenseFiles(licenseId: string, companyId: string){
    const isLogged = this.userService.getLoggedUser();
    if (isLogged) {
      return this.http.delete<APIDataRegister<any>>(`${this.API}deleteNewLicense/auth/${companyId}/${licenseId}`).pipe(
        map(response => {
          this.sendNotification('success', 'Dados salvos com sucesso.');
        })
      );
    } else {
      return this.http.delete<APIDataRegister<any>>(`${this.API}deleteNewLicense/${companyId}/${licenseId}`).pipe(
        map(response => {
          this.sendNotification('success', 'Dados salvos com sucesso.');
        })
      );
    }
  }

  updatedCarrierAuth(id: string, data: ProfilePayload) {
    return this.http.patch<APIDataRegister<any>>(`${this.API}auth/${id}`, data).pipe(
      tap((response: any) => {
        if (response && response.data && 'registrationComplete' in response.data) {
          const user = this.userService.getLoggedUser();
          if (user && user.transporter && 'registrationComplete' in user.transporter) {
            user.transporter.registrationComplete = response.data.registrationComplete;
            this.userService.setLoggedUser(user);
          }
        }
      }),
      map(response => {
        this.sendNotification('success', 'Dados salvos com sucesso.');
        return response.data;
      })
    );
  }

  createCompany(cnpj: string, data: CreateCompany) {
    return this.http.post<APIDataRegister<any>>(`${this.API_COMPANY}post/${cnpj}`, data).pipe(
      map(response => {
        this.sendNotification('success', 'Dados salvos com sucesso.');

        return response.data;
      })
    );
  }

  createCompanyAuth(cnpj: string, data: CreateCompany) {
    return this.http.post<APIDataRegister<any>>(`${this.API_COMPANY}post/auth/${cnpj}`, data).pipe(
      map(response => {
        this.sendNotification('success', 'Dados salvos com sucesso.');

        return response.data;
      })
    );
  }

  updatedCompany(id: string, data: MatrixDataPayload) {
    return this.http.patch<APIDataRegister<any>>(`${this.API_COMPANY}update/${id}`, data).pipe(
      tap((response: any) => {
        if (response && response.data && 'registrationComplete' in response.data) {
          const user = this.userService.getLoggedUser();
          if (user && user.transporter && 'registrationComplete' in user.transporter) {
            user.transporter.registrationComplete = response.data.registrationComplete;
            this.userService.setLoggedUser(user);
          }
        }
      }),
      map(response => {
        this.sendNotification('success', 'Dados salvos com sucesso.');

        return response.data;
      })
    );
  }

  updateCompanyCNPJ(id: string, data: { cnpj: string }) {
    return this.http.patch<APIDataRegister<any>>(`${this.API_COMPANY}updateCnpj/${id}`, data).pipe(
      map(response => response)
    );
  }

  sendNotification(type: 'success' | 'error', message: string) {
    this.notificationService.notification$.next({
      title: 'Cadastro Transportadora',
      type,
      message
    });
  }

  downloadPrivacyPolicy() {
    return this.http.get<APIDataRegister<Terms>>(`${environment.api.meta.url}${environment.api.userMain}/privatePolicy/downloadPrivatePolicy`).pipe(
      map(response => response.data)
    );
  }

  getBranchesByOrigins() {
    return this.http.get<APIDataRegister<any>>(`${this.API}getBranchesByOrigins`).pipe(
      map(response => response.data)
    );
  }

  getListBranchesCNPJ() {
    return this.http.get<APIDataRegister<any>>(`${this.API}listBranchesCNPJ`).pipe(
      map(response => response.data)
    );
  }

  updateBranchByOrigins(payload) {
    return this.http.patch<APIDataRegister<any>>(`${this.API}updateBranchByOrigins`, payload).pipe(
      map(response => response.data)
    );
  }

  createMultiRoute(data) {
    return this.http.post<APIDataRegister<any>>(`${this.API_USER}routeWithMesoRegion/createMultiRoutes`, data).pipe(
      tap((response: any) => {
        if (response && response.data && 'registrationComplete' in response.data) {
          const user = this.userService.getLoggedUser();
          if (user && user.transporter && 'registrationComplete' in user.transporter) {
            user.transporter.registrationComplete = response.data.registrationComplete;
            this.userService.setLoggedUser(user);
          }
        }
      }),
      map(response => response.data),
      catchError((error): any => {
        this.sendNotification('error', error.message);
        return of(null);
      })
    );
  }
}
