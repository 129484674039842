import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Profiles } from 'src/app/models/profiles';
import { AuctionRedirectService } from 'src/app/features/auction/auction-redirect/auction-redirect.service';

@Component({
  selector: 'app-auction-redirect',
  templateUrl: './auction-redirect.component.html',
  styleUrls: ['./auction-redirect.component.scss']
})
export class AuctionRedirectComponent implements OnInit {

  canEditProfile = false;
  auctionTermAccepted = true;
  firstAccess = false;

  selectedRoute$ = null;
  chosenRoute = '';
  profiles = [];

  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private auctionRedirectService: AuctionRedirectService
  ) { }

  ngOnInit(): void {
    this.profiles = this.userService.getUserProfiles();
    this.selectedRoute$ = this.route.paramMap;
    this.selectedRoute$.subscribe(params => {
      const selectedTab = params.get('route');
      if (selectedTab) {
        this.chosenRoute = selectedTab;
      }
    });
    this.redirectUser();
  }

  async redirectUser() {
    const isAdmin = this.userService.isUserAdmin();
    const isTransporter = this.userService.isUserAuctionTransporter();
    const canCarrierAccess = await this.canCarrierAccess();

    // usuario transp e nao tem acesso
    if (isTransporter && !canCarrierAccess) {
      return;
    }

    // usuario com dois perfis tentando acessar visao transp e nao tem acesso
    if ((this.hasBothProfiles() || isAdmin) && !canCarrierAccess) {
      return;
    }

    this.handleNavigation();
  }

  isFirstAccess() {
    return this.auctionRedirectService.isFirstAccess();
  }

  async isAuctionTermAccepted() {
    const response = await this.auctionRedirectService.checkAuctionTermStatus();
    return response;
  }

  async onAcceptTerm() {
    const response = await this.auctionRedirectService.acceptAuctionTerm();
    const user = this.userService.getLoggedUser();

    if (user && response && response.auctionTermAccepted) {
      user.auctionTermAccepted = response.auctionTermAccepted;
      this.userService.setLoggedUser(user);
    }

    if (!this.firstAccess) {
      this.handleNavigation();
    }
    this.auctionTermAccepted = true;
    this.canEditProfile = true;
  }

  handleNavigation = () => {
    if (this.userService.isUserAdmin() || this.hasBothProfiles()) {
      this.router.navigate([this.chosenRoute || 'leilao/embarcador/ot/dashboard']);
    } else if (this.profiles.includes(Profiles.EmbarcadorLeilao)) {
      this.router.navigate(['leilao/embarcador/ot/dashboard']);
    } else if (this.profiles.includes(Profiles.TransportadoraLeilao)) {
      this.router.navigate(['leilao/transportadora/ot']);
    } else if (this.profiles.includes(Profiles.EmbarcadorLeilaoRotas)) {
      this.router.navigate(['leilao/transportadora/rota']);
    } else if (this.profiles.includes(Profiles.TransportadoraLeilaoRotas)) {
      this.router.navigate(['leilao/transportadora/rota']);
    } else {
      this.router.navigate([this.chosenRoute]);
    }
  };

  async canCarrierAccess() {
    this.auctionTermAccepted = await this.isAuctionTermAccepted();
    this.firstAccess = this.isFirstAccess();

    // Termo aceito, mas é primeiro acesso -> editar perfil transportadora
    if (this.auctionTermAccepted && this.firstAccess) {
      this.canEditProfile = true;
      return false;
    }

    // Não redirecionar se for primeiro acesso ou o termo não tiver aceito
    if (this.firstAccess || !this.auctionTermAccepted) {
      return false;
    }

    return true;
  }

  hasBothProfiles() {
    return this.profiles.includes(Profiles.TransportadoraLeilao) && this.profiles.includes(Profiles.EmbarcadorLeilao);
  }

}
