<div class="field">
  <div class="control">
    <div class="field">
      <div class="control">
        <app-input
        placeholder="Buscar O.T"
        leadingIcon="search"
        [trailingIcon]="value ? 'close' : undefined"
        [canClickTrailingIcon]="true"
        (trailingIconClick)="clearSearch()"
        [(value)]="value"
        (keyup.enter)="updateValues($event.target.value)">
        </app-input>
      </div>
    </div>
  </div>
</div>
