import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.scss']
})
export class PushNotificationComponent implements OnInit {

  notification = null;
  showNotification = true;
  isRead = true;

  constructor(
    public router: Router
  ) { }

  ngOnInit(): void {
  }

  closeNotification() {
    this.showNotification = false;
    this.isRead = false;
  }

  goToRoute() {
    if (this.notification.route.startsWith('http')) {
      window.open(this.notification.route, '_blank');
    } else {
      this.showNotification = false;
      const routeParams = this.notification.route.split('?').length > 1 ? this.notification.route.split('?')[1] : null;
      this.notification.route = this.notification.route.split('?')[0];
      const paramsObj = {};
      if (routeParams) {
        routeParams.split('&').forEach(element => {
          if (element) {
            const prop = element.split('=')[0];
            const value = element.split('=')[1];
            paramsObj[prop] = value;
          }
        });
      }
      this.router.navigate([this.notification.route, paramsObj]);
    }
  }

}
