import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {

  inputId: string;

  fileName = '';
  fileIsValid = false;
  isShowingOptions = false;
  isImportedDataModalOpened = false;

  @Input() title: string;
  @Input() acceptedFiles: string;
  @Output() uploadFileEmitter = new EventEmitter();

  constructor() {
    this.inputId = Math.random().toString(36).substring(3);
  }

  ngOnInit(): void {
  }

  updateFilename(event?: any) {
    const target = event && event.target as HTMLInputElement;

    if (!target) { return; }

    if (target['files'] && target.files.length > 0 && (this.acceptedFiles.includes(target['files'][0].type))) {
      const file = target.files[0];
      this.fileIsValid = true;
      this.fileName = target['files'][0].name;
      this.uploadFile(file);
    } else {
      this.fileIsValid = false;
      this.fileName = '';
      target.value = '';
    }
  }

  uploadFile(file) {
    const formData = new FormData();
    formData.append('xlsx', file);
    this.uploadFileEmitter.emit(formData);
  }
}
