import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-multiple-select',
  templateUrl: './multiple-select.component.html',
  styleUrls: ['./multiple-select.component.scss']
})
export class MultipleSelectComponent implements OnInit {

  @Input() options: any;
  @Input() name: any;
  @Input() isRadio: boolean;
  @Output() handleCheckedOptions = new EventEmitter<any>();
  @Output() handleChangeOptions = new EventEmitter<any>();
  @Output() handleCheckedAll = new EventEmitter<any>();
  @Output() handleCheckedOne = new EventEmitter<any>();

  checkOptions = [];
  filteredOptions = [];

  isCheckedAll = false;
  isCheckedOne = false;

  constructor() { }

  ngOnInit() {
    this.creatingComponent();
  }

  setIsCheckedAll = () => this.isCheckedAll = this.checkOptions.every(o => o.checked);

  setIsCheckedOne = () => this.isCheckedOne = this.checkOptions.some(o => o.checked);

  resetFilteredOptions() {
    this.filteredOptions = [];
    this.checkOptions.forEach(option => {
      this.filteredOptions.push(option);
    });
    this.setIsCheckedAll();
    this.setIsCheckedOne();
    this.emitHasChecked();
  }

  uncheckOption(optionValue) {
    let option = this.filteredOptions.find(o => o.value === optionValue);
    option.checked = false;
    option = this.checkOptions.find(o => o.value === optionValue);
    option.checked = false;
    this.setIsCheckedAll();
    this.handleChangeOptions.emit(this.filteredOptions);
  }

  uncheckAllOptions() {
    this.filteredOptions.map(o => {
      o.checked = false;
      if (this.isRadio) {
        o.disabled = false;
      }
    });
    this.checkOptions.map(o => {
      o.checked = false;
      if (this.isRadio) {
        o.disabled = false;
      }
    });
    this.setIsCheckedAll();
    this.setIsCheckedOne();
    this.emitHasChecked();
    this.handleChangeOptions.emit();
  }

  checkOption(event, option) {
    const checked = event.target.checked;
    if (option === 'TODOS') {
      this.filteredOptions.map(o => o.checked = checked);
    } else {
      option.checked = checked;
    }
    if (this.isRadio) {
      this.filteredOptions = this.filteredOptions.map(o => ({ ...o, disabled: checked ? option.value !== o.value : false }));
    }
    const checkedOptions = this.filteredOptions.filter(o => o.checked);
    this.handleCheckedOptions.emit(checkedOptions);
    this.handleChangeOptions.emit(this.filteredOptions);
    this.setIsCheckedAll();
    this.setIsCheckedOne();
    this.emitHasChecked();
  }

  filterOptions(event) {
    if (event && event.target && event.target.value) {
      this.filteredOptions = this.checkOptions.filter(o => o.label.toLowerCase().includes(event.target.value.toLowerCase()));
    } else {
      this.resetFilteredOptions();
    }
    this.setIsCheckedAll();
    this.setIsCheckedOne();
    this.emitHasChecked();
  }

  mountOptions() {
    if (this.options && this.options.length > 0) {
      this.options.forEach(o => {
        this.checkOptions.push({ ...o, checked: o.checked ? o.checked : false });
      });
    }
  }

  creatingComponent() {
    this.checkOptions = [];
    this.mountOptions();
    this.setIsCheckedAll();
    this.resetFilteredOptions();
  }

  updateOptions(source) {
    this.options = source;
    this.creatingComponent();
  }

  emitHasChecked() {
    this.handleCheckedOne.emit(this.isCheckedOne);
    this.handleCheckedAll.emit(this.isCheckedAll);
  }

}
