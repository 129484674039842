import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuctionHiringTypePipe } from './auction-hiring-type.pipe';
import { AuctionTypePipe } from './auction-type.pipe';
import { CalculateDelayPipe } from './calculate-delay.pipe';
import { CanViewAuctionPipe } from './can-view-auction.pipe';
import { CnpjPipe } from './cnpj.pipe';
import { DateAgoPipe } from './date-ago.pipe';
import { DeliveryStatusPipe } from './delivery-status.pipe';
import { DisplayPropPipe } from './display-prop.pipe';
import { EthanolStatusPipe } from './ethanol-status.pipe';
import { ExtraLoadStatusPipe } from './extra-load-status.pipe';
import { ExtraloadTimelinePipe } from './extraload-timeline.pipe';
import { FilterPipe } from './filter.pipe';
import { LimitItemsPipe } from './limit-items.pipe';
import { LoadStatePipe } from './load-state.pipe';
import { RoundValuePipe } from './round-value.pipe';
import { StatusFioriPipe } from './status-fiori.pipe';
import { TruckSelectedPipe } from './truck-selected.pipe';
import { OtCheckersPipe } from './ot-checkers.pipe';

@NgModule({
  declarations: [
    StatusFioriPipe,
    CalculateDelayPipe,
    RoundValuePipe,
    DeliveryStatusPipe,
    LoadStatePipe,
    TruckSelectedPipe,
    FilterPipe,
    DateAgoPipe,
    AuctionTypePipe,
    DisplayPropPipe,
    CnpjPipe,
    ExtraLoadStatusPipe,
    CanViewAuctionPipe,
    AuctionHiringTypePipe,
    EthanolStatusPipe,
    LimitItemsPipe,
    ExtraloadTimelinePipe,
    OtCheckersPipe
  ],
  exports: [
    StatusFioriPipe,
    CalculateDelayPipe,
    RoundValuePipe,
    DeliveryStatusPipe,
    LoadStatePipe,
    TruckSelectedPipe,
    FilterPipe,
    DateAgoPipe,
    AuctionTypePipe,
    DisplayPropPipe,
    CnpjPipe,
    ExtraLoadStatusPipe,
    CanViewAuctionPipe,
    AuctionHiringTypePipe,
    EthanolStatusPipe,
    LimitItemsPipe,
    ExtraloadTimelinePipe,
    OtCheckersPipe
  ],
  imports: [
    CommonModule
  ]
})
export class PipesModule { }
