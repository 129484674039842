import { Component, Input } from '@angular/core';
import { DarkModeService } from '@services/dark-mode.service';

@Component({
  selector: 'app-ot-map-point',
  templateUrl: './ot-map-point.component.html',
  styleUrls: ['./ot-map-point.component.scss']
})
export class OtMapPointComponent {
  @Input() data: any = null;
  @Input() type: string;

  TYPE_MAPS = {
    transbordo: 'TRB',
    trocaDeNota: 'TNF'
  };

  TYPE_LABEL = {
    transbordo: 'Transbordo em ',
    trocaDeNota: 'Troca de Nota em '
  };

  constructor(private darkMode: DarkModeService) { }

  get isDarkMode() {
    return this.darkMode.isDark;
  }
}
