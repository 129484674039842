<ng-container *ngIf="!showTerms">
  <fieldset class="is-flex is-flex-direction-column full-width my-5" *ngIf="!showTerms">
    <app-input
      class="mr-5"
      label="Empresa"
      [(value)]="nameCompany"
      [validationMask]="false"
      [isDisabled]="true"
    >
    </app-input>

    <app-select
      class="flex-1 mr-5"
      label="CNPJ da Matriz"
      name="CNPJ da Matriz"
      [items]="branchesList"
      [(value)]="cnpj"
      bindValue="initials"
      placeholder="Selecione o cnpj da matriz"
    >
    </app-select>

    <app-input
      class="mr-5"
      label="Telefone"
      [(value)]="phone"
      [validationMask]="false"
    >
    </app-input>

    <app-checkbox
      label="Eu concordo com os termos de uso"
      [(value)]="acceptTerms"
      (change)="toogleShowTerms()"
    >
    </app-checkbox>
  </fieldset>

  <div class="is-flex is-align-items-center is-justify-content-center">
    <app-button
      title="Confirmar Dados"
      class="ml-5"
      [isDefault]="true"
      [isDisabled]="!cnpj || !phone || !acceptTerms"
      (handleClickEmitter)="onSubmit()"
    ></app-button>
  </div>
</ng-container>

<ng-container *ngIf="showTerms">
  <app-terms-of-use (confirm)="toogleConfirmTerms()"></app-terms-of-use>
</ng-container>
