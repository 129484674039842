import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PwaService } from '@shared/services/pwa.service';
import { SharedModule } from '@shared/shared.module';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    SharedModule
  ],
  selector: 'app-new-version-checker',
  templateUrl: './new-version-checker.component.html',
  styleUrls: ['./new-version-checker.component.scss']
})
export class NewVersionCheckerComponent implements OnInit {

  constructor(private pwaService: PwaService) { }

  get isNewVersionAvailable() {
    return this.pwaService.isNewVersionAvailable;
  }
  
  ngOnInit() {
  }

  update() {
    this.pwaService.applyUpdate();
  }

}
