<div class="ls-radio">
  <input
    class="ls-radio__input"
    type="radio"
    [id]="_id"
    [name]="name"
    [formControl]="control"
    [value]="value"
    (change)="changeSelect.emit(value)"
  >
  <label class="ls-radio__label" [for]="_id">
    {{label}}
  </label>
</div>

