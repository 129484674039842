import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { concatMap, distinctUntilKeyChanged, map, tap, timer } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {

  maintenanceStartDate = '2000-10-07T22:50:00.000Z';
  maintenanceEndDate = '2000-10-10T11:00:00.000Z';

  constructor(private http: HttpClient) {}

  getMaintenanceFromClient() {
    this.maintenanceStartDate = '2022-10-07T22:50:00.000Z';
    this.maintenanceEndDate = '2022-10-10T11:00:00.000Z';
  }

  getMaintenanceFromServer() {
    return this.http.get<any>(`${environment.api.meta.url}${environment.api.userMain}/maintenance`).pipe(
      map(response => response.data),
    );
  }

  isUnderMaintenance() {
    return this.getMaintenanceFromServer().pipe(
      concatMap(data => {
        if (data) {
          this.maintenanceStartDate = data.startDate;
          this.maintenanceEndDate = data.endDate;
        }
        return this.transformedData();
      })
    );
  }

  transformedData() {
    return timer(1000, 1000 * 1).pipe(
      map(() => ({activated: (new Date().toISOString() >= this.maintenanceStartDate) && (new Date().toISOString() <= this.maintenanceEndDate)})),
      distinctUntilKeyChanged('activated'),
    );
  }

}
