import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ExpiredTokenGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.isTokenExpired()) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }

  isTokenExpired() {
    const tokenExpired = this.authService.isTokenExpired();
    return tokenExpired && tokenExpired === 'true';
  }
}
