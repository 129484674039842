import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import * as moment from 'moment';
import { inputTimeFormat, inputDateFormat } from '../../utils';

@Component({
  selector: 'app-datetimepicker',
  templateUrl: './datetimepicker.component.html',
  styleUrls: ['./datetimepicker.component.scss']
})
export class DatetimepickerComponent implements OnInit {

  @Input() isOutlined = false;
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() value;
  @Input() initialDate;
  @Input() endingDate;
  @Input() type = null;
  @Input() min;
  @Output() handleDateChange = new EventEmitter();
  @Input() control: AbstractControl = new UntypedFormControl();

  @ViewChild('datepicker') datepicker: ElementRef;

  defaultInitialDate;
  defaultEndingDate;

  constructor() { }

  ngOnInit(): void {
    this.defaultInitialDate = this.type && this.type === 'date' ? inputDateFormat() : inputTimeFormat();
    this.defaultEndingDate = this.type && this.type === 'date' ? inputDateFormat(moment().add(1, 'year')) : inputTimeFormat(moment().add(1, 'year'));
    if (!this.value) {
      this.value = this.defaultInitialDate;
    }
    if (!this.initialDate) {
      this.initialDate = this.defaultInitialDate;
    }
    if (!this.endingDate) {
      this.endingDate = this.defaultEndingDate;
    }
  }

  getDate = event => {
    let date = event.target.value;
    if (event.target.validationMessage) {
      this.datepicker.nativeElement.value = this.value;
      date = this.datepicker.nativeElement.value;
    }
    this.value = date;
    this.handleDateChange.emit(this.value);
  };

  setValue(value) {
    this.value = value;
  }

}
