import { Pipe, PipeTransform } from '@angular/core';
import { timelineStatus } from 'src/app/enums/extraloadTimeline';

@Pipe({
  name: 'extraloadTimeline'
})
export class ExtraloadTimelinePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value && value.extraloadHistory && value.extraloadHistory.length) {
      const statusHistory = value.extraloadHistory;
      return statusHistory.map(item => ({
        label: item.status ? timelineStatus[item.status] ? timelineStatus[item.status] : '' : 'Agente de frete não selecionado',
        status: item.status,
        date: item.date || '-',
        user: item.user?.name || '-',
        organization: item.user?.shippingCompany?.name || '-',
        integration: item.integration ? {
          state: item.integration?.shippingCompanyIntegration?.originState,
          transporterCode: item.integration?.shippingCompanyIntegration?.transporter,
          cnpj: item.integration?.shippingCompanyIntegration?.cnpj
        } : null,
        reason: item.status === "rejected" ? item.reason : ''
      }));
    }
    return null;
  }

}
