import { Pipe, PipeTransform } from '@angular/core';
import { TruckService } from '../../services/truck/truck.service';

@Pipe({
  name: 'truckSelected'
})
export class TruckSelectedPipe implements PipeTransform {


  constructor(private truckService: TruckService) {}

  transform(array: any[], item: any, status: string): any[] {
    if (status && array) {
      if (status === 'default') { return array; }
      return this.truckService.filterByDeliveryStatus(array, status);
    }
    return item ? array.filter(obj => obj.tractorUnit._id === item.tractorUnit._id) : array;
  }
}
