<ls-input-field
  *ngIf="validWithMask"
  [id]="id"
  [label]="label"
  [type]="rawType"
  [helperText]="helperText"
  [variant]="variant"
  [validation]="validation"
  [disabled]="disabled"
  [(value)]="value"
  [control]="control"
  [placeholder]="placeholder"
  [mask]="maskPattern"
  [step]="step"
  [min]="min"
  [max]="max"
  [minLength]="minLength"
  [maxLength]="maxLength"
  [dropSpecialCharacters]="dropSpecialCharacters"
  [prefix]="prefix"
  [separatorLimit]="separatorLimit"
  [thousandSeparator]="thousandSeparator"
  [decimalMarker]="decimalMarker"
  [leadingIcon]="leadingIcon"
  [trailingIcon]="trailingIcon"
  [canClickTrailingIcon]="canClickTrailingIcon"
  [canClickLeadingIcon]="canClickLeadingIcon"
  [size]="size"
  [isDark]="isDark"
  (blur)="handleBlur.emit()"
  (keyup)="handleKeyup.emit($event)"
  (trailingIconClick)="trailingIconClick.emit($event)"
  (leadingIconClick)="leadingIconClick.emit($event)">
</ls-input-field>

<ls-input-field
  *ngIf="!validWithMask"
  [id]="id"
  [label]="label"
  [type]="rawType"
  [helperText]="helperText"
  [variant]="variant"
  [validation]="validation"
  [disabled]="disabled"
  [(value)]="value"
  [control]="control"
  [placeholder]="placeholder"
  [step]="step"
  [min]="min"
  [max]="max"
  [minLength]="minLength"
  [maxLength]="maxLength"
  [leadingIcon]="leadingIcon"
  [trailingIcon]="trailingIcon"
  [canClickTrailingIcon]="canClickTrailingIcon"
  [canClickLeadingIcon]="canClickLeadingIcon"
  [size]="size"
  [isDark]="isDark"
  (blur)="handleBlur.emit()"
  (keyup)="handleKeyup.emit($event)"
  (trailingIconClick)="trailingIconClick.emit($event)"
  (leadingIconClick)="leadingIconClick.emit($event)">
</ls-input-field>
