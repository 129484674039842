import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Profiles } from '@models/profiles';

export interface Tab {
  title: string;
  isActive: boolean;
  route?: string;
  data?: any;
  value?: string;
  profiles?: Profiles[];
  icon?: string;
}

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {

  @Input() tabs: Tab[];
  @Input() noBorder: boolean;
  @Input() onlyIcon: boolean;
  @Input() isDark = false;
  @Input() isLarge = false;
  @Output() changeTabEmitter = new EventEmitter();

  @ViewChild('tabsEl', { static: false }) tabsEl: ElementRef<HTMLUListElement>;

  show = true;
  isDragging = false;
  isDraggingClass = false;

  constructor() { }

  get canScroll() {
    return (this.tabsEl?.nativeElement?.scrollWidth || 0) - (this.tabsEl?.nativeElement?.clientWidth || 0) > 0;
  }

  get deltaScroll() {
    return (this.tabsEl?.nativeElement?.scrollWidth || 0) - (this.tabsEl?.nativeElement?.clientWidth || 0);
  }
  
  get scrollLeft() {
    return Math.round(this.tabsEl?.nativeElement?.scrollLeft || 0);
  }
  
  get scrollLeftWidth() {
    return this.deltaScroll - this.tabsEl?.nativeElement?.scrollLeft > 0;
  }

  ngOnInit(): void {
  }

  changeTab(actualTab) {
    this.show = false;
    this.handleActiveTab(actualTab);
    this.changeTabEmitter.emit(actualTab);
    this.show = true;
  }

  handleActiveTab(actualTab) {
    this.tabs.forEach(t => t.isActive = t.title === actualTab.title);
  }

  handleDragging(e: MouseEvent) {
    if (!this.isDragging) { return; }
    this.tabsEl.nativeElement.scrollLeft -= e.movementX;
  }

  navigateRight() {
    if ((this.tabsEl.nativeElement.scrollLeft + 60) <= this.deltaScroll) {
      this.tabsEl.nativeElement.scrollLeft += 60;
    } else {
      this.tabsEl.nativeElement.scrollLeft += (this.deltaScroll - this.tabsEl.nativeElement.scrollLeft);
    }
  }

  navigateLeft() {
    if ((this.tabsEl.nativeElement.scrollLeft - 60) >= 0) {
      this.tabsEl.nativeElement.scrollLeft -= 60;
    } else {
      this.tabsEl.nativeElement.scrollLeft -= this.tabsEl.nativeElement.scrollLeft;
    }
  }
  
  handleTouchMove(e: TouchEvent) {
    if (!this.isDragging) { return; }
    this.tabsEl.nativeElement.scrollLeft = e.touches[0].clientX;
  }

  stopDragging() {
    this.isDragging = false;
  }

}
