<div class="buttons is-marginless dropdown is-hoverable is-clickable">
  <div class="dropdown-trigger">
    <app-button size="small" [isOutlined]="!filtersSelecteds" [isMarginless]="true" title="Filtros"
      icon="filter_list">
    </app-button>
  </div>

  <div class="dropdown-large dropdown-menu dropdown-filter" role="menu">
    <div darkMode class="dropdown-content is-padded-medium dropdown-medium">
      <div class="columns is-marginless-vertical">
        <div class="column">
          <h6 class="is-padded-small-bottom">
            <strong>
              UF origem
            </strong>
          </h6>
          <ul class="is-uppercase is-size-7">
            <app-multiple-select #multipleSelectOrigin [options]="filters.origin"
              (handleCheckedOptions)="saveSelectedOptions($event, 'origin')"
              (handleCheckedOne)="handleAllChecked($event, 'origin')">
            </app-multiple-select>
          </ul>
        </div>
        <hr class="box-divider column">
        <div class="column">
          <h6 class="is-padded-small-bottom">
            <strong>
              UF destino
            </strong>
          </h6>
          <ul class="is-uppercase is-size-7">
            <app-multiple-select #multipleSelectDestination [options]="filters.destination"
              (handleCheckedOptions)="saveSelectedOptions($event, 'destination')"
              (handleCheckedOne)="handleAllChecked($event, 'destination')">
            </app-multiple-select>
          </ul>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="is-pulled-right">
            <app-button [isMarginless]="true" title="Filtrar" (click)="filter()">
            </app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
