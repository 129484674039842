import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-auction-category',
  templateUrl: './auction-category.component.html',
  styleUrls: ['./auction-category.component.scss'],
})
export class AuctionCategoryComponent {
  @Input() comboType: string;
  @Input() checked: boolean;
  @Input() checkboxCondition = false;
  @Input() hasLogo = false;

  @Output() handleClick = new EventEmitter();

  constructor() {}

  onCheck(event) {
    this.handleClick.emit(event);
  }
}
