import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Profiles } from 'src/app/models/profiles';
import { RBACService } from '../services/rbac.service';

/********************************************************************
**  Usage: you should pass an array of Profiles (enum) keys as string.
**
**  i.e: *hasRole=['TransportadoraLeilaoRotas']
**
********************************************************************/
@Directive({
  selector: '[hasRole]'
})
export class HasRoleDirective {

  roles: Profiles[];

  constructor(
    private rbacService: RBACService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) { }

  @Input()
  set hasRole(val) {
    const roles = Array.isArray(val) ? val : [val];
    this.roles = roles.map(r => Profiles[r]);
    this.updateView();
  }

  updateView() {
    const canView = this.rbacService.can(this.roles);

    if (!canView) {
      return this.viewContainer.clear();
    }

    this.viewContainer.createEmbeddedView(this.templateRef);
  }

}
