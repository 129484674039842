<ng-container *ngIf="isVisible">
  <div class="backdrop" *ngIf="hasBackdrop"></div>
  <div class="popover-wrapper">
    <div class="is-flex is-justify-content-end">
      <button *ngIf="!noClose"
      class="delete"
      aria-label="close"
      (click)="handleClose()">
      </button>
    </div>
    <ng-content></ng-content>
  </div>
</ng-container>

