<app-modal class="volume-modal" [noHeader]="true" (isOpenChanged)="closeModal()">
  <div class="volume-modal_content is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
    <p class="is-size-3 pb-4 has-text-weight-bold">Importação de cargas</p>

    <ng-container *ngFor="let importation of importations">
      <div class="is-flex is-align-items-center mb-4">
        <i class="material-icons is-size-3 mr-3 has-text-primary has-tooltip-arrow" [attr.data-tooltip]="'Download de erros de importação'" (click)="downloadErrors(importation?.errorFile?.url)">report</i>
        <p class="is-size-5">Importação realizada <strong>{{ importation?.createdAt | date: 'dd/MM/yyyy' }}</strong> por {{ importation?.user?.name }}</p>
      </div>
    </ng-container>

  </div>
</app-modal>
