import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Chart, ChartOptions, defaults } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export type LabelCallback = typeof defaults.plugins.tooltip.callbacks.label;

const labelFn = evt => evt.formattedValue;

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})
export class DoughnutChartComponent implements OnInit {

  @Input() data;
  @Input() title: string;
  @Input() subTitle: string;
  @Input() width: number;
  @Input() labelAlwaysVisible = true;
  @Input() labelCallback: LabelCallback = labelFn;
  @Input() dataLabelCallback;
  @Output() handleChartClick = new EventEmitter();

  chartOptions: ChartOptions;
  pluginsOptions: any[];

  get styles(): string {
    return this.width ? `--w:${this.width}px;` : '--w: auto;';
  }

  get titleStyle(): string {
    return this.width ? `font-size: calc(var(--w) * 0.1);` : 'font-size: 3rem;';
  }

  get subtitleStyle(): string {
    return this.width ? `font-size: calc(var(--w) * 0.03);` : 'font-size: 1.25rem;';
  }

  ngOnInit(): void {

    if (this.labelAlwaysVisible) {
      Chart.register(ChartDataLabels);
      Chart.overrides.doughnut.plugins.datalabels = {
        color: '#FFF',
        formatter: this.dataLabelCallback
      };
      // Chart.defaults.set('plugins.datalabels', );
    }

    this.chartOptions = {
      plugins: {
          tooltip: {
            callbacks: {
                label: this.labelCallback
            }
        }
      }
    };
  }

}
