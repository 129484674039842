<!-- Titulo -->
<p class="is-size-3 pb-4 has-text-weight-bold has-text-primary has-text-centered">Leia e confirme</p>
<p class="is-size-3 pb-4 has-text-weight-bold has-text-primary has-text-centered">Termos de uso</p>

<!-- Corpo -->

<p class="is-size-5 pb-4 is-scrollable has-text-justified">
  Mauris lacinia lorem sed urna hendrerit, eget ornare enim faucibus. Aenean feugiat fermentum eros ac rhoncus. Proin in neque feugiat, lacinia arcu pretium, lacinia justo. Etiam vitae justo ac urna suscipit pellentesque. Curabitur tristique posuere odio id consectetur. Vivamus a nibh vel ipsum consectetur eleifend. Sed tempor sagittis tortor eu aliquam. Integer purus ante, fermentum aliquet suscipit vitae, aliquet in risus. Nulla ultricies id lectus ut tristique. Nunc feugiat lectus sem. Nunc egestas faucibus orci, a dapibus elit posuere ut. Maecenas tristique tellus in nunc eleifend, sed eleifend nulla fermentum. Ut non malesuada est. Duis elementum ex vel tellus condimentum semper.
  Donec eu dignissim justo, ac placerat urna. Integer sollicitudin sed massa sed ultrices. Aliquam erat volutpat. Nulla pretium erat sit amet ipsum accumsan, et porta dui iaculis. Nullam euismod molestie enim. Donec nec dignissim elit, posuere tempus eros. Phasellus at nunc tortor. Sed orci turpis, malesuada et consequat nec, imperdiet at sem. Quisque at blandit massa. Nam at auctor sapien. Curabitur condimentum, nisl vel commodo vehicula, mauris felis blandit nisi, quis hendrerit odio metus a leo. Donec quis hendrerit ex. Pellentesque et purus commodo tortor varius sodales eget id leo. Nullam feugiat eget velit vel dictum. Suspendisse potenti.
  Donec facilisis dignissim dapibus. Aliquam quis congue est. Donec odio nulla, malesuada at felis et, varius semper sem. Integer malesuada mi sit amet libero tincidunt euismod. Quisque non rhoncus nunc, at finibus urna. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Ut malesuada nec mauris sit amet luctus. Nullam arcu odio, porttitor id facilisis sit amet, tincidunt non justo. Etiam quis nisi consectetur, aliquet sapien at, dapibus arcu. Ut rutrum metus ac nisi placerat, in gravida dolor ultrices. Integer vulputate neque non nisi luctus, id fringilla enim feugiat. Donec pharetra, ante ut dictum accumsan.

  <span class="is-flex is-align-items-center is-justify-content-center">
    <app-button
      title="li e concordo com os termos"
      class="ml-5"
      [isDefault]="true"
      (handleClickEmitter)="confirm.emit()"
    ></app-button>
  </span>
</p>
