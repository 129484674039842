import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { removeDuplicates } from 'src/app/shared/utils';

@Component({
  selector: 'app-checker-auction-row',
  templateUrl: './checker-auction-row.component.html',
  styleUrls: ['./checker-auction-row.component.scss']
})
export class CheckerAuctionRowComponent implements OnInit, OnDestroy {

  @Input() tab: string;
  @Input() auction: any;
  @Output() handleCheck = new EventEmitter<any>();
  @Output() handleOpenDetails = new EventEmitter<string>();
  @Output() handleCloseDetails = new EventEmitter<void>();
  @Output() handleEdit = new EventEmitter<any>();

  @Input() isMoreDetailsOpened: boolean;
  @Input() moreDetailsToggle = false;

  lot = {
    _id: null,
    identifier: null,
    ots: null,
    origin: null,
    destination: null,
    volume: null,
    type: null,
    initialValue: null,
    lastBid: null,
    totalBids: null,
    delta: null,
    startDate: null,
    endDate: null,
    shippingCompany: null,
    minBidValue: null,
    lastExportDate: null,
    integratedByPromisor: null,
    comboType: null,
    comboLots: null,
    status: null
  };

  pendingTime = null;
  pendingInterval = null;
  orderType = null;
  isEnding = false;
  isShowLoads = false;
  selectedLot = null;

  constructor() { }

  ngOnInit(): void {
    this.fillLotData();
    this.getOrderType();
  }

  fillLotData() {
    this.lot._id = this.auction && this.auction._id ? this.auction._id : null;
    this.lot.identifier = this.auction && this.auction.auction ? this.auction.auction.number : null;
    this.lot.ots = this.getOts();
    this.lot.origin = this.auction && this.auction.origin;
    this.lot.destination = this.auction && this.auction.destination;
    this.lot.volume = this.auction && this.auction.finalWeight;
    this.lot.type = this.auction && this.auction.auction ? this.auction.auction.type : null;
    this.lot.startDate = this.auction && this.auction.auction ? new Date(this.auction.auction.plannedStart) : null;
    this.lot.endDate = this.auction && this.auction.auction ? new Date(this.auction.auction.plannedEnd) : null;
    this.lot.totalBids = this.auction.totalBids;
    this.lot.initialValue = this.auction.pricePerTon;
    this.lot.shippingCompany = this.auction.organization && this.auction.organization.name ? this.auction.organization.name : '-';
    this.lot.delta = this.auction.delta;
    this.lot.lastBid = this.auction.minBidValue;
    this.lot.minBidValue = this.auction.minBidValue;
    this.lot.lastExportDate = this.auction.lastExportDate ? new Date(this.auction.lastExportDate) : null;
    this.lot.comboType = this.auction.comboType ? this.auction.comboType : null;
    this.lot.comboLots = this.auction?.comboLots ? this.auction?.comboLots : null;
    if (this.lot.lastExportDate) {
      this.lot.lastExportDate.setUTCHours(new Date(this.lot.lastExportDate).getUTCHours() + 3);
      this.lot.lastExportDate = `Download da carga realizado em ${this.lot.lastExportDate.toLocaleDateString()} - ${this.lot.lastExportDate.toLocaleTimeString()}`;
    }
    if (this.lot.endDate) {
      this.getPendingTime();
    }
    this.lot.integratedByPromisor = this.auction.deal && this.auction.deal.integratedByPromisor ? this.auction.deal.integratedByPromisor : false;
    this.lot.status = this.getLotStatus(this.auction.deal);
  }

  getPendingTime() {
    const startDate = new Date();
    startDate.setUTCHours(startDate.getUTCHours() - 3);
    const endDate = new Date(this.auction.auction.plannedEnd);
    if (endDate >= startDate) {
      const min = Math.floor((endDate.getTime() - startDate.getTime()) / (60 * 1000) % 60);
      const hour = Math.floor((endDate.getTime() - startDate.getTime()) / (60 * 60 * 1000) % 24);
      const day = Math.floor((endDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000));
      if (this.checkIfIsEnding(day, hour, min)) {
        this.isEnding = true;
        this.pendingTime = `${min}min`;
      } else {
        this.pendingTime = `${day}d ${hour}hr ${min}min`;
      }
    } else {
      this.pendingTime = `--:--`;
    }
    this.pendingInterval = setInterval(() => {
      const start = new Date();
      start.setUTCHours(start.getUTCHours() - 3);
      const end = new Date(this.auction.auction.plannedEnd);
      if (end >= start) {
        const min = Math.floor((end.getTime() - start.getTime()) / (60 * 1000) % 60);
        const hour = Math.floor((end.getTime() - start.getTime()) / (60 * 60 * 1000) % 24);
        const day = Math.floor((end.getTime() - start.getTime()) / (24 * 60 * 60 * 1000));
        if (this.checkIfIsEnding(day, hour, min)) {
          this.isEnding = true;
          this.pendingTime = `${min}min`;
        } else {
          this.pendingTime = `${day}d ${hour}hr ${min}min`;
        }
      } else {
        this.pendingTime = `--:--`;
      }
    }, 1000 * 60);
  }

  checkIfIsEnding(days, hours, minutes) {
    return (days === 0) && (hours === 0) && (minutes <= 30);
  }

  getOts() {
    let ots = [];
    if (!this.auction || !this.auction.loads || this.auction.loads.length === 0) {
      return ots;
    }
    this.auction.loads.map(l => {
      ots = ots.concat(l.ots);
    });
    removeDuplicates(ots);
    return ots;
  }

  check(event) {
    this.handleCheck.emit({
      _id: this.auction._id,
      checked: event.target.checked
    });
  }

  showDetails(combo?) {
    this.selectedLot = combo ? combo._id : null;
    this.handleOpenDetails.emit(combo ? combo : this.auction);
  }

  hideDetails() {
    this.isMoreDetailsOpened = false;
    this.handleCloseDetails.emit();
    this.selectedLot = null;
  }

  ngOnDestroy() {
    if (this.pendingInterval) {
      clearInterval(this.pendingInterval);
    }
  }

  getOrderType() {
    if (this.auction && this.auction.loads && this.auction.loads.length > 0 && this.auction.loads[0].orders) {
      const orderTypes = this.auction.loads[0].orders.map(o => o.type);
      if (orderTypes.filter(t => t === 'transferenciaDeEstoque').length > 0) {
        this.orderType = 'Transferência MI';
      } else {
        this.orderType = 'Venda MI';
      }
    }
  }

  editAuction() {
    this.handleEdit.emit(this.auction);
  }

  showLoads() {
    this.isShowLoads = !this.isShowLoads;
  }

  getRowHeigth(ots) {
    return `expanded-${ots <= 4 ? ots : 4}`;
  }

  getLotStatus(deal) {
    if (!deal || !deal.integration) { return; }
    switch (deal.integration.status) {
      case 'confirmed':
        return 'Confirmado';
      case 'error':
        return 'Erro';
      case 'deleted':
        return 'Deletado';
      case 'RFC3':
        return 'Integrando';
      case 'RFC5':
        return 'Integrando';
      default:
        break;
    }
    if (deal.integration.integratedByPromisor === true) {
      return 'Integrando';
    } else {
      return 'Não confirmado';
    }
  }
}
