import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalNotificationService } from 'src/app/shared/services/global-notification.service';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { APIData } from 'src/app/interfaces/ApiResponse';

@Injectable({
  providedIn: 'root'
})
export class ShipperTrackingService {

  constructor(
    private http: HttpClient,
    private notificationService: GlobalNotificationService
  ) { }

  async notifyTransporter(data) {
    try {
      const response = await this.http.post(`${environment.api.meta.url}${environment.api.transportationApi}tracking/shipper/notify`, data).toPromise();
      return response;
    } catch (error) {
      console.error(error);
      this.notificationService.notification$.next({
        title: 'Falha ao notificar transportador(es)',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
      return null;
    }
  }

  async uploadFile(data, type) {
    try {
      const response = await this.http.post(`${environment.api.meta.url}${environment.api.transportationApi}importTrackingOccurences?action=${type}`, data).toPromise();
      if (response && response['data']) {
        return response['data'];
      }
      return [];
    } catch (error) {
      console.error(error);
      this.notificationService.notification$.next({
        title: 'Falha ao importar planilha',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
      return null;
    }
  }

  async exportFiles(transportationIds) {
    try {
      const response = await this.http.post(`${environment.api.meta.url}${environment.api.transportationApi}exportTrackingOccurences`, { ids: transportationIds }, { responseType: 'arraybuffer' }).toPromise();
      return response;
    } catch (error) {
      console.error(error);
      this.notificationService.notification$.next({
        title: 'Falha ao exportar dados selecionados',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
      return null;
    }
  }

  async list(query = '', page = 0, limit = 50) {
    try {
      const response = await this.http.get(`${environment.api.meta.url}${environment.api.transportationApi}tracking/shipper/${page}/${limit}${query ? '?' + query : ''}`).toPromise();
      if (response && response['data']) {
        return response['data'];
      }
      return [];
    } catch (error) {
      console.error(error);
      this.notificationService.notification$.next({
        title: 'Falha ao listar dados disponíveis no tracking',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
      return null;
    }
  }

  async details(transportationId) {
    try {
      const response = await this.http.get(`${environment.api.meta.url}${environment.api.transportationApi}tracking/shipper/details/${transportationId}`).toPromise();
      if (response && response['data']) {
        return response['data'];
      }
      return [];
    } catch (error) {
      console.error(error);
      this.notificationService.notification$.next({
        title: 'Falha ao listar detalhes do caminhão selecionado',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
      return null;
    }
  }

  async exportAll(status) {
    try {
      const response = await this.http.post(`${environment.api.meta.url}${environment.api.transportationApi}exportTrackingOccurences`, { statusTypeDeliveryShipping: status }, { responseType: 'arraybuffer' }).toPromise();
      return response;
    } catch (error) {
      console.error(error);
      this.notificationService.notification$.next({
        title: 'Falha ao exportar dados selecionados',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
      return null;
    }
  }

  async listTransporters( query) {
    try {
      const response = await this.http.get(`${environment.api.meta.url}${environment.api.transportationApi}tracking/shipper/listTransporters/?${query}`).toPromise();
      if (response && response['data']) {
        return response['data'];
      }
      return [];
    } catch (error) {
      console.error(error);
      this.notificationService.notification$.next({
        title: 'Falha ao listar transportadores',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
      return null;
    }
  }

  async listImportations() {
    try {
      const response = await this.http.get(`${environment.api.meta.url}${environment.api.transportationApi}tracking/shipper/importations/0/5`).toPromise();
      if (response && response['data']) {
        return response['data'];
      }
      return [];
    } catch (error) {
      console.error(error);
      this.notificationService.notification$.next({
        title: 'Falha ao listar importações',
        type: 'error',
        message: 'Recarregue a página. ' + error.error.message
      });
      return null;
    }
  }

  getTabCountByName(nameTab, query = '') {
    return this.http.get(`${environment.api.meta.url}${environment.api.transportationApi}tracking/shipper/tab/count/${nameTab}${query ? '?' + query : ''}`);
  }

  importTrackingOccurrencesOnline() {
    return this.http.post(`${environment.api.meta.url}${environment.api.transportationApi}importTrackingOccurencesFromSFTP`, { action: 'import', type: 'online' });
  }

  exportTrackingOccurrencesOnline() {
    return this.http.post(`${environment.api.meta.url}${environment.api.transportationApi}exportTrackingOccurencesFromSFTP`, { action: 'export', type: 'online' });
  }

  getTransportationImportationDetails(offset = 0, limit = 1) {
    return this.http.get<APIData<any>>(`${environment.api.meta.url}${environment.api.transportationApi}getTransportationImportationDetails/${offset}/${limit}`, {}).pipe(
      map(response => {
        return response.data;
      })
    );
  }

}
