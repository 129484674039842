import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';
import { PluginsService } from '../../../services/plugins.service';

@Component({
  selector: 'app-active-carrier-users',
  templateUrl: './active-carrier-users.component.html',
  styleUrls: ['./active-carrier-users.component.scss']
})
export class ActiveCarrierUsersComponent implements OnInit {

  @Output() confirm = new EventEmitter();

  users: Array<any> = [];
  deletedUsers = [];
  displayAllUsers = true;

  constructor(private pluginsService: PluginsService, private userService: UserService, private router: Router) { }

  ngOnInit(): void {
    const user = this.userService.getLoggedUser();

    this.pluginsService.getUsersOfCarrier(user.organization).subscribe((users) => this.users = users);
  }

  toogleDisplayAllUsers() {
    this.displayAllUsers = !this.displayAllUsers;
  }

  process() {
    this.deletedUsers = this.users.filter(user => !user.isChecked);
    this.toogleDisplayAllUsers();
  }

  onSubmit() {
    const payload = this.users.filter(u => u.isChecked);
    const user = this.userService.getLoggedUser();

    this.pluginsService.saveUsersOfCarrier(user.organization, payload).subscribe(() => this.router.navigate(['/perfil/transportadora']));
  }

}
