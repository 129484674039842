import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MAIN_CAROUSEL } from 'src/app/data/main-carousel';
import { UserService } from 'src/app/services/user/user.service';
import { CommonForm } from 'src/app/shared/common-form';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent extends CommonForm implements OnInit {

  emailSent = false;
  hasError = false;
  emailToDisplay = null;
  errorToDisplay = null;

  form = new UntypedFormGroup({
    email: new UntypedFormControl(history.state.data?.email || '', [Validators.required, Validators.email])
  });

  pathImages = MAIN_CAROUSEL;

  constructor(
    private userService: UserService,
    private route: Router) {
      super();
    }

  get email() {
    return this.form.get('email');
  }

  ngOnInit() {}

  async sendEmail() {
    const result = await this.userService.sendPasswordRecoveryEmail(this.email.value);

    if (result.data) {
      delete this.hasError;
      delete this.errorToDisplay;
      this.emailSent = true;
      this.emailToDisplay = result.data.email;
    } else {
      this.displayError(result);
    }
  }

  displayError(error: string = 'Ocorreu um erro') {
    this.hasError = true;
    this.errorToDisplay = error;
  }

  backToLogin() {
    this.route.navigate(['/']);
  }

}
