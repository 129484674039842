<div class="modal is-active">
  <div class="modal-background"></div>
  <div darkMode class="modal-content card is-radius-large is-shadowed-small" [ngClass]="{
    'modal-small': isSmall,
    'modal-medium': isMedium,
    'modal-large': size === 'large',
    'overflow-none': noScroll
  }">
    <header [ngClass]="{'card-header': !noHeader}">
      <p *ngIf="!noHeader" class="card-header-title is-size-4 has-text-weight-bold">
        {{ title }}
      </p>
      <button *ngIf="!noClose" class="delete is-medium is-pulled-right is-margined-small" aria-label="Fechar"
        (click)="modalDismiss()"></button>
    </header>
    <main class="card-content default-margin" [ngClass]="{'no-padding-content': noPadding}">
      <ng-content></ng-content>
    </main>
  </div>
</div>
