import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FeatureToggleService {

  featuresConfig = null;

  constructor(
    private http: HttpClient,
  ) { }

  public async getFeaturesConfig() {
    return this.featuresConfig ? this.featuresConfig : await this.saveFeaturesConfig();
  }

  public async saveFeaturesConfig() {
    const result = await this.http.get('./config/feature.config.json').toPromise();
    this.featuresConfig = result;
    return this.featuresConfig;
  }
}
