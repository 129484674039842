import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-auction-shipping-rules',
  templateUrl: './auction-shipping-rules.component.html',
  styleUrls: ['./auction-shipping-rules.component.scss']
})
export class AuctionShippingRulesComponent {
  @Input() canViewShippingRules = false;
  @Output() handleClick = new EventEmitter();

  constructor() { }
}
