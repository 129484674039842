/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @angular-eslint/no-output-native */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {

  @Input() label: string;
  @Input() modelValue?: string;
  @Input() name: string;
  @Input() appendTo: string;
  @Input() items: Array<any>;
  @Input() bindValue: string;
  @Input() placeholder: string;
  @Input() validationMessage: string;
  @Input() helpMessage: string;
  @Input() isDisabled: boolean;
  @Input() isMultiple = false;
  @Input() maxSelectedItems = null;
  @Input() closeOnSelect = true;
  @Input() validationError: boolean;
  @Input() control: AbstractControl = new UntypedFormControl();
  @Input() isDirty: boolean;
  @Input() clearable = true;
  @Input() searchable = true;
  @Output() handleDelete = new EventEmitter<any>();
  @Output() valueChange = new EventEmitter();
  @Output() clearSelectedChange = new EventEmitter();
  @Output() change = new EventEmitter();
  @Output() handleOpen = new EventEmitter();

  _value = null;

  @Input()
  set clearSelected(val) {
    const value = this.isMultiple ? [] : null;

    this.control.setValue(value);
    this.valueChange.emit(value);
    this.change.emit(value);

    setTimeout(() => this.clearSelectedChange.emit(false));
  }

  @Input()
  get value() {
    return this._value;
  }
  set value(val) {
    this._value = val;
    this.valueChange.emit(this._value);
  }

  constructor() { }

  changeInternal(value) {
    if (!this.isMultiple) {
      this.valueChange.emit(value ?? null);
      this.change.emit(value?.[this.bindValue] ?? value);
    } else {
      this.change.emit(value);
    }
  }

  ngOnInit(): void {
    if (this.control.value) {
      this.value = this.control.value;
    }
  }

  delete(item) {
    this.handleDelete.emit(item);
  }

}
