<div class="notification-global-container">
    <template #messagecontainer>
    </template>
</div>
<div class="push-notification-container">
    <template #pushnotificationcontainer>
    </template>
</div>
<app-loader-spinner></app-loader-spinner>

<ng-container *ngIf="canRenderMenu">
  <app-menu *ngIf="canShowMenu"></app-menu>
</ng-container>
<section #main
  [ngClass]="{'menu-loadshark': canShowMenu }">
  <app-global-alert #globalAlert></app-global-alert>
  <router-outlet></router-outlet>
</section>
<ng-container *ngIf="canRenderMenu">
  <app-tab-menu *ngIf="canShowMenu"></app-tab-menu>
</ng-container>
<app-confirm-terms-modal></app-confirm-terms-modal>
<app-new-alert-notification></app-new-alert-notification>
<app-websocket-connection-status></app-websocket-connection-status>
<app-connection-status></app-connection-status>
<app-new-version-checker></app-new-version-checker>
