import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { IConfig, NgxMaskPipe, NgxMaskModule } from 'ngx-mask';
import { SideImageComponent } from 'src/app/shared/components/side-image/side-image.component';
import { ButtonComponent } from './components/button/button.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { CleanDatetimepickerComponent } from './components/clean-datetimepicker/clean-datetimepicker.component';
import { ComponentContainerComponent } from './components/component-container/component-container.component';
import { DatetimepickerComponent } from './components/datetimepicker/datetimepicker.component';
import { DoubleButtonComponent } from './components/double-button/double-button.component';
import { DropdownMenuLinkComponent } from './components/dropdown-menu-link/dropdown-menu-link.component';
import { DropdownMenuComponent } from './components/dropdown-menu/dropdown-menu.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { ExpiredTokenModalComponent } from './components/expired-token-modal/expired-token-modal.component';
import { FeatureToggleDirective } from './components/feature-toggle/feature-toggle.directive';
import { FeatureToggleService } from './components/feature-toggle/feature-toggle.service';
import { IncrementNumbersComponent } from './components/increment-numbers/increment-numbers.component';
import { InputComponent } from './components/input/input.component';
import { LabeledInputComponent } from './components/labeled-input/labeled-input.component';
import { LoadMoreComponent } from './components/load-more/load-more.component';
import { ModalComponent } from './components/modal/modal.component';
import { MultipleSelectComponent } from './components/multiple-select/multiple-select.component';
import { OrderButtonComponent } from './components/order-button/order-button.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PopoverFilterComponent } from './components/popover-filter/popover-filter.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { RangeDatepickerComponent } from './components/range-datepicker/range-datepicker.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { SelectComponent } from './components/select/select.component';
import { SuggestPasswordModalComponent } from './components/suggest-password-modal/suggest-password-modal.component';
import { SwitchComponent } from './components/switch/switch.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TimepickerComponent } from './components/timepicker/timepicker.component';
import { TypographyComponent } from './components/typography/typography.component';
import { UploadButtonComponent } from './components/upload-button/upload-button.component';
import { ImportedDataModalComponent } from './components/upload-file/imported-data-modal/imported-data-modal.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { WelcomeModalComponent } from './components/welcome-modal/welcome-modal.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { GenerateIdentifiersDirective } from './directives/generate-identifiers.directive';
import { GtagDirective } from './directives/gtag.directive';
import { UnloadStatusPipe } from './pipes/unload-status.pipe';
import { PopupComponent } from './components/popup/popup.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CardComponent } from './components/card/card.component';
import { RadioComponent } from './components/radio/radio.component';
import { RadioOptionsInputComponent } from './components/radio-options-input/radio-options-input.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { GlobalAlertComponent } from './components/global-alert/global-alert.component';
import { SortableComponent } from './components/sortable/sortable.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { ScrollManagerDirective } from './directives/scroll-manager.directive';
import { ScrollSectionDirective } from './directives/scroll-section.directive';
import { ScrollAnchorDirective } from './directives/scroll-anchor.directive';
import { ScoreComponent } from './components/score/score.component';
import { DataItemComponent } from './components/data-item/data-item.component';
import { DataWrapperComponent } from './components/data-wrapper/data-wrapper.component';
import { OriginDestinationRouteComponent } from './components/origin-destination-route/origin-destination-route.component';
import { OtMapPointComponent } from './components/ot-map-point/ot-map-point.component';
import { PipesModule } from './pipes/pipes.module';
import { DetailsDataBlockComponent } from './components/details-data-block/details-data-block.component';
import { OtDetailsRouteComponent } from './components/ot-details-route/ot-details-route.component';
import { ProfileWithScoreComponent } from './components/profile-with-score/profile-with-score.component';
import { MapComponent } from './components/map/map.component';
import { DoughnutChartComponent } from './components/doughnut-chart/doughnut-chart.component';
import { NgChartsModule } from 'ng2-charts';
import { BannerRecommendationComponent } from './components/banner-recommendation/banner-recommendation.component';
import { ComponentRecommendationComponent } from './components/component-recommendation/component-recommendation.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { PrivacyPolicyContentComponent } from './components/privacy-policy-content/privacy-policy-content.component';
import { SliderComponent } from './components/slider/slider.component';
import { TablesComponent } from './components/tables/tables.component';
import { ContextMenuComponent } from './components/context-menu/context-menu.component';

import { DesignSystemModule } from 'design-system';
import { HeadingComponent } from './heading/heading.component';
import { TextComponent } from './text/text.component';
import { TextareaComponent } from './components/textarea/textarea/textarea.component';
import { HasRoleDirective } from './directives/has-role.directive';
import { CardCustomizerComponent } from './components/card-customizer/card-customizer.component';
import { CounterDirective } from './directives/counter.directive';
import { GetValueFromFieldPipe } from './components/tables/pipes/get-value-from-field.pipe';
import { LabeledDataComponent } from './components/labeled-data/labeled-data.component';
import { DataRowFlexComponent } from './components/data-row-flex/data-row-flex.component';
import { TimelineModule } from './components/timeline/timeline.module';
import { PopoverComponent } from './components/popover/popover.component';
import { TablesNoHeadComponent } from './components/tables-no-head/tables-no-head.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TableComponent } from './components/table/table.component';
import { HeroComponent } from './components/hero/hero.component';
import { UploadComponent } from './components/upload/upload.component';
import { DarkModeDirective } from './directives/dark-mode.directive';
import { PublicLogoComponent } from './components/public-logo/public-logo.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

const components = [
  ModalComponent,
  MultipleSelectComponent,
  PaginationComponent,
  WelcomeModalComponent,
  FeatureToggleDirective,
  ComponentContainerComponent,
  ExpiredTokenModalComponent,
  RangeDatepickerComponent,
  SwitchComponent,
  OrderButtonComponent,
  DropdownComponent,
  LoadMoreComponent,
  DatetimepickerComponent,
  DropdownMenuComponent,
  ButtonComponent,
  InputComponent,
  SuggestPasswordModalComponent,
  CarouselComponent,
  IncrementNumbersComponent,
  ProgressBarComponent,
  UploadFileComponent,
  DropdownMenuLinkComponent,
  LabeledInputComponent,
  ImportedDataModalComponent,
  CleanDatetimepickerComponent,
  TimepickerComponent,
  ClickOutsideDirective,
  DoubleButtonComponent,
  SelectComponent,
  UploadButtonComponent,
  GtagDirective,
  GenerateIdentifiersDirective,
  UnloadStatusPipe,
  TabsComponent,
  SideImageComponent,
  PopoverFilterComponent,
  SearchInputComponent,
  PopupComponent,
  TypographyComponent,
  CheckboxComponent,
  CardComponent,
  RadioComponent,
  RadioOptionsInputComponent,
  AccordionComponent,
  RadioComponent,
  GlobalAlertComponent,
  SortableComponent,
  TooltipComponent,
  ScrollManagerDirective,
  ScrollSectionDirective,
  ScrollAnchorDirective,
  ScoreComponent,
  DataItemComponent,
  DataWrapperComponent,
  OriginDestinationRouteComponent,
  OtMapPointComponent,
  DetailsDataBlockComponent,
  OtDetailsRouteComponent,
  ProfileWithScoreComponent,
  MapComponent,
  DoughnutChartComponent,
  BannerRecommendationComponent,
  ComponentRecommendationComponent,
  BarChartComponent,
  PrivacyPolicyContentComponent,
  SliderComponent,
  TablesComponent,
  ContextMenuComponent,
  HeadingComponent,
  TextComponent,
  TextareaComponent,
  HasRoleDirective,
  CardCustomizerComponent,
  CounterDirective,
  GetValueFromFieldPipe,
  LabeledDataComponent,
  DataRowFlexComponent,
  PopoverComponent,
  TablesNoHeadComponent,
  TableComponent,
  HeroComponent,
  UploadComponent,
  DarkModeDirective,
  PublicLogoComponent
];

const modules = [
  TimelineModule,
];

@NgModule({
  declarations: [...components],
  exports: [...components, ...modules],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxMaskModule.forRoot(),
    LayoutModule,
    PipesModule,
    NgChartsModule,
    PipesModule,
    DesignSystemModule,
    TimelineModule,
    NgxSkeletonLoaderModule
  ],
  providers: [FeatureToggleService, NgxMaskPipe],
})
export class SharedModule { }
