/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { Component, HostListener, OnDestroy, OnInit, ViewChild, ViewContainerRef, ComponentRef, Renderer2, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgSelectConfig } from '@ng-select/ng-select';
import { environment } from 'src/environments/environment';
import { AuthService } from './core/auth/auth.service';
import { resetState as resetApprovalProcessState } from './features/approval-process/both/approval-process-details/data/State';
import { AuctionService } from './features/auction/services/auction.service';
import { TrackingService } from './features/tracking/tracking.service';
import { MenuService } from './menu/menu.service';
import { Profiles } from './models/profiles';
import { RegisterOldCarriersModalComponent } from './plugins/register-old-carriers-modal/register-old-carriers-modal.component';
import { AlertService } from './services/alert/alert.service';
import { OrderService } from './services/order/order.service';
import { UserService } from './services/user/user.service';
import { FeatureToggleService } from './shared/components/feature-toggle/feature-toggle.service';
import { GlobalAlertComponent } from './shared/components/global-alert/global-alert.component';
import { GlobalNotificationComponent } from './shared/components/global-notification/global-notification.component';
import { PushNotificationComponent } from './shared/components/push-notification/push-notification.component';
import { ApplicationMonitoringService } from './shared/services/application-monitoring.service';
import { GlobalNotificationService } from './shared/services/global-notification.service';
import { MaintenanceService } from './shared/services/maintenance.service';
import { PwaService } from './shared/services/pwa.service';
import { WebsocketService } from './shared/services/websocket.service';
import { ProfileService } from './shared/services/profile.service';
import { DarkModeService } from '@services/dark-mode.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {

  @ViewChild('messagecontainer', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;
  @ViewChild('pushnotificationcontainer', { static: true, read: ViewContainerRef }) pushNotificationentry: ViewContainerRef;
  @ViewChild('globalAlert') globalAlertComponent: GlobalAlertComponent;
  @ViewChild('registerOldCarriersModal') registerOldCarriersModal: RegisterOldCarriersModalComponent;
  @ViewChild('main', { static: true, read: ViewContainerRef }) main: ViewContainerRef;

  hasNotificationFavicon = false;

  pushNotificationInterval = null;

  route: string;

  showNotification = false;
  hasNotificationVisible = false;
  notificationGroupedCount = 0;
  notificationRef: ComponentRef<GlobalNotificationComponent>;

  hasPushNotificationVisible = false;
  pushNotificationGroupedCount = 0;
  pushNotificationRef: ComponentRef<PushNotificationComponent>;

  notificationsComponentsRef = [];
  pushNotificationsComponentsRef = [];

  urgentNotificationsInterval = null;
  auctionInterval = null;
  trackingInterval = null;

  _canShowMenu = false;
  publicRoutes = ['/login', '/forgot-password', '/cadastro/transportadora', '/reset-password'];

  favIcon: HTMLLinkElement = this.document.querySelector('#favicon');

  constructor(
    public router: Router,
    private notificationService: GlobalNotificationService,
    private userService: UserService,
    private featureToggleService: FeatureToggleService,
    private orderService: OrderService,
    private authService: AuthService,
    private websocketService: WebsocketService,
    private auctionService: AuctionService,
    private trackingService: TrackingService,
    private ngSelectConfig: NgSelectConfig,
    private apmService: ApplicationMonitoringService,
    private pwaService: PwaService,
    private alertService: AlertService,
    private maintenanceService: MaintenanceService,
    private menuService: MenuService,
    private profileService: ProfileService,
    private darkMode: DarkModeService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {

    this.ngSelectConfig.notFoundText = 'Nenhum registro encontrado';
    this.ngSelectConfig.bindLabel = 'label';

    const publicRoutes = this.router.config.filter(r => !r.data?.allowedProfiles?.length).map(r => `/${r.path}`).filter(r => r !== '/');
    this.publicRoutes = publicRoutes;

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (val.url === '/login' || val.url.includes('register')) {
          this.notificationsComponentsRef.forEach(element => {
            element.instance.showNotification = false;
          });
        }
        this._canShowMenu = !this.publicRoutes.some(publicUrl => {
          const url = publicUrl.split(':')[0];
          return !!val.url.includes(url);
        });
        // setTimeout(() => this.itsOldCarrier());
      }
    });

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.route = router.url;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.route = event.urlAfterRedirects;
        this.router.navigated = false;

        this.sharedFunctions();
        (window as any).gtag('config', environment.googleAnalytics, {
          page_path: event.urlAfterRedirects
        });
      }
    });

    this.notificationService.notification$.subscribe((data) => {
      this.showNotification = true;
      if (this.authService.isTokenExpired()) { return; }
      this.createNotificationComponent(data);
    });

    this.notificationService.pushNotification$.subscribe((data) => {
      if (this.canCreatePushNotification(data)) {
        this.createPushNotificationComponent(data);
        this.changeFavicon();
      }
    });

    this.pushNotificationInterval = setInterval(async () => {
      await this.notificationService.processPushNotificationQueue();
    }, 8000);

    this.auctionInterval = setInterval(async () => {
      this.auctionService.proccessMessages();
    }, 5000);

    this.trackingInterval = setInterval(async () => {
      this.trackingService.processMessages();
    }, 5000);
  }

  get canShowMenu() {
    return this._canShowMenu;
  }

  get canRenderMenu() {
    return !!localStorage.getItem('profiles');
  }

  @HostListener('window:visibilitychange', ['$event'])
  onVisibilityChange() {
    if (document.hidden) {
      this.hasNotificationFavicon = true;
    } else {
      this.hasNotificationFavicon = false;
      this.changeFavicon();
    }
  }

  ngOnDestroy() {
    this.websocketService.disconnect();
    if (this.pushNotificationInterval) {
      clearInterval(this.pushNotificationInterval);
    }
    if (this.urgentNotificationsInterval) {
      clearInterval(this.urgentNotificationsInterval);
    }
    if (this.auctionInterval) {
      clearInterval(this.auctionInterval);
    }
    if (this.trackingInterval) {
      clearInterval(this.trackingInterval);
    }

    resetApprovalProcessState();
  }

  ngOnInit() {
    this.apmService.init();
    this.authService.isLoggedUser.subscribe(async logged => {
      if (logged) {

        this.maintenanceService.isUnderMaintenance().subscribe({
          next: ({ activated }) => {
            if (activated) {
              console.log('O sistema se encontra em manutenção programada...');
              this.authService.logout();
            }
          }
        });

        // Show alerts if has any configuration enabled
        this.alertService.getAlertCheck().subscribe({
          next: data => {
            if (data && data.length) {
              const [content] = data;
              this.alertService.toggleAlert(content);
            }
          }
        });

        // Show AskNicely survey
        this.userService.showSurvey();

        // Setting up APM user context
        const user = this.authService.getLoggedUser();
        this.apmService.setContext({ id: user._id, username: user.fullName, email: user.email });

        // Connecting to WS
        this.createWssConnection();

        // Getting some important data for logged user
        await this.notificationService.getNotificationSettings();
        await this.orderService.listUserOts();
        this.userService.getNotifications();
      }
    });
    this.darkMode.toggled$.subscribe({
      next: isDarkMode => {
        if (isDarkMode) {
          this.renderer.addClass(this.document.body, 'dark');
        } else {
          this.renderer.removeClass(this.document.body, 'dark');
        }
      }
    });
    this.darkMode.toggled$.subscribe({
      next: isDarkMode => {
        if (isDarkMode) {
          this.renderer.addClass(this.document.body, 'dark');
        } else {
          this.renderer.removeClass(this.document.body, 'dark');
        }
      }
    });
    this.urgentNotificationsInterval = setInterval(() => this.processUrgentNotification(), 1000 * 60 * environment.urgentNotificationInterval);
  }

  itsOldCarrier() {
    const userProfiles = this.userService.getUserProfiles() ?? [];
    const isCarrier = userProfiles.includes(Profiles.Transportadora);
    const isOld = !localStorage.getItem('transporter');

    if (isCarrier && isOld) {
        this.registerOldCarriersModal.displayModal = true;
    } else {
      this.registerOldCarriersModal.displayModal = false;
    }
  }

  processUrgentNotification() {
    const countNotifications = this.notificationService.countNotReadUrgentNotifications();
    if (countNotifications > 0) {
      const notification = {
        title: 'NOTIFICAÇÕES',
        subtitle: '',
        message: `Você tem ${countNotifications} notificações urgentes`,
        type: 'error',
        templateId: '',
        notVisibleRoutes: ['programacao'],
        showPushNotification: true,
        readAt: new Date()
      };
      this.notificationService.pushNotification(notification);
    }
  }

  canCreatePushNotification(notification) {
    let userShowNotificationSetting = true;
    const userSettingsCache = localStorage.getItem('userNotificationsSettings');
    const userSettings = userSettingsCache ? JSON.parse(userSettingsCache) : null;
    if (userSettings) {
      const notificationSetting = userSettings.find(s => s.templateId === environment.urgentNotificationTemplatedId);
      userShowNotificationSetting = notificationSetting && notificationSetting.showPushNotification !== null ? notificationSetting.showPushNotification : true;
    }
    const route = this.router.url.substr(1, this.router.url.length);
    const isRouteVisible = !notification.notVisibleRoutes || notification.notVisibleRoutes.length === 0 ||
      (notification.notVisibleRoutes && notification.notVisibleRoutes.length > 0 && notification.notVisibleRoutes.filter(r => r === route).length === 0);
    return notification && notification.showPushNotification && isRouteVisible && userShowNotificationSetting;
  }

  createWssConnection() {
    this.websocketService.initSocket();
    const user = this.userService.getLoggedUser();
    if (user) {
      this.websocketService.setUserConnection(user._id);
    }
  }

  async saveFeaturesConfiguration() {
    await this.featureToggleService.saveFeaturesConfig();
  }

  createNotificationComponent(notification) {
    if (!this.hasNotificationVisible) {
      this.notificationRef = this.entry.createComponent(GlobalNotificationComponent);
      this.notificationRef.instance.data = {
        title: notification.title,
        type: notification.type,
        message: notification.message
      };
      this.notificationsComponentsRef.push(this.notificationRef);
      this.hasNotificationVisible = true;
      setTimeout(() => {
        this.notificationRef.destroy();
        this.hasNotificationVisible = false;
        this.notificationGroupedCount = 0;
      }, 5000);
    } else {
      if (notification.title === this.notificationRef.instance?.data?.title) {
        this.notificationGroupedCount++;
        this.notificationRef.instance.data.count = this.notificationGroupedCount;
      }
    }
  }

  createPushNotificationComponent(notification) {
    if (!this.hasPushNotificationVisible) {
    this.pushNotificationRef = this.pushNotificationentry.createComponent(PushNotificationComponent);
    notification['count'] = this.pushNotificationGroupedCount;
    this.pushNotificationRef.instance.notification = notification;
    this.notificationsComponentsRef.push(this.pushNotificationRef);
    this.hasPushNotificationVisible = true;
    setTimeout(() => {
      this.pushNotificationRef.destroy();
      this.hasPushNotificationVisible = false;
      this.pushNotificationGroupedCount = 0;
        }, 8000);
    } else{
      if (notification.title === this.pushNotificationRef.instance?.notification?.title) {
        this.pushNotificationGroupedCount++;
        this.pushNotificationRef.instance.notification.count = this.pushNotificationGroupedCount;
      }
    }

  }

  changeFavicon() {
    const defaultIco = 'favicon.ico';
    const notificationIco = './assets/notification/favicon.ico';
    this.favIcon.href = this.hasNotificationFavicon ? notificationIco : defaultIco;
  }

  public isAuthView() {
    const blacklist = ['/', '/login', '/dashboard'];
    return -1 !== blacklist.indexOf(this.route);
  }

  private sharedFunctions() {
    const w = (window as any);

    if (!w.gtmScript) {
      w.dataLayer = w.dataLayer || [];

      w.gtmScript = document.createElement('script');
      w.gtmScript.type = 'text/javascript';
      w.gtmScript.async = 'true';
      w.gtmScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.googleAnalytics;
      const user = this.userService.getLoggedUser();
      w.gtmScript.onload = () => {
        w.gtag('js', new Date());
        try {
          w.gtag('config', environment.googleAnalytics, {
            user_id: user?.email
          });

          w.gtag('set', 'user_data', {
            email: user?.email,
            first_name: user?.name,
            last_name: user?.lastName
          });
        } catch (err) {
          console.log(err);
        }
      };

      w.gtag = () => {
        w.dataLayer.push(arguments);
      };

      w.gtag('consent', 'default', {
        ad_storage: 'denied'
      });

      document.getElementsByTagName('head')[0].appendChild(w.gtmScript);
    }

    function togglePasswordType(element: any) {
      const target = element.dataset.target;
      const elem = document.querySelector(target);

      elem.type = element.checked ? 'text' : 'password';
      elem.focus();
    }

    function toggleDropdown(element: any) {
      const isExpanded = element.getAttribute('aria-expanded') === 'true';

      element.setAttribute('aria-expanded', !isExpanded);
      if (element.parentNode.classList.contains('dropdown-trigger')) {
        element.parentNode.parentNode.classList.toggle('is-active');
      } else {
        element.parentNode.classList.toggle('is-active');
      }
      return void (0);
    }

    function togglePanel(element: any) {
      const isExpanded = element.getAttribute('aria-expanded') === 'true';
      const direction = element.dataset.direction;
      const controls = element.getAttribute('aria-controls');
      const target = document.getElementById(controls);

      const targetHeight = target.offsetHeight;
      const targetWidth = target.offsetWidth;

      element.setAttribute('aria-expanded', !isExpanded);
      if (direction === 'horizontal') {
        target.style.marginLeft = (isExpanded ? ('-' + targetWidth) : 0) + 'px';
      } else if (direction === 'vertical') {
        target.style.marginBottom = (isExpanded ? ('-' + targetHeight) : 0) + 'px';
      }

      return void (0);
    }

    function toggleDashboardPanel(element: any) {
      const isExpanded = element.getAttribute('aria-expanded') === 'true';
      const controls = element.getAttribute('aria-controls');
      const target = document.getElementById(controls);

      element.setAttribute('aria-expanded', !isExpanded);
      target.style.width = (isExpanded ? '40vw' : '');

      return void (0);
    }

    function toggleCollapse(element: any) {

      const isExpanded = element.getAttribute('aria-expanded') === 'true';
      const controls = element.getAttribute('aria-controls');
      const target = document.getElementById(controls);

      element.setAttribute('aria-expanded', !isExpanded);

      if (target) {
        target.classList.toggle('is-collapsed');
      }

      return void (0);
    }

    function toggleNavbarMenu(element: any) {
      const isExpanded = element.getAttribute('aria-expanded') === 'true';
      const controls = element.getAttribute('aria-controls');
      const target = document.getElementById(controls);

      element.setAttribute('aria-expanded', !isExpanded);

      if (isExpanded) {
        element.classList.remove('is-active');
        target.classList.remove('is-active');
      } else {
        element.classList.add('is-active');
        target.classList.add('is-active');
      }
    }

    document.onclick = (event: any) => {
      const target = event.target;

      event.stopPropagation();

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      closeDropdown(event);

      if (target.dataset && target.dataset.toggle === 'password') {
        togglePasswordType(target);
      }
      if (target.dataset && target.dataset.toggle === 'panel') {
        togglePanel(target);
      }
      if (target.dataset && target.dataset.toggle === 'dashboard-panel') {
        toggleDashboardPanel(target);
      }
      if (target.dataset && target.dataset.toggle === 'collapse') {
        toggleCollapse(target);
      }
      if (target.dataset && target.dataset.toggle === 'navbar-menu') {
        toggleNavbarMenu(target);
      }

      if (target && target.getAttribute('aria-haspopup') === 'true') {
        toggleDropdown(target);
      }
    };

    function closeDropdown(event: any) {
      const dropdownTogglers = document.querySelectorAll('[aria-haspopup="true"]');
      const path = event.path || event.composedPath();
      let togglerIndex = 0;

      for (const pathIndex of path) {
        const hasPopup = pathIndex.getAttribute('aria-haspopup') === 'true';
        const isExpanded = event.target.getAttribute('aria-expanded') === 'true';
        const isForm = pathIndex.tagName === 'FORM';

        if (hasPopup && isExpanded || isForm) {
          return void (0);
        } else if (pathIndex.tagName === 'BODY') {
          break;
        }
      }

      for (; togglerIndex < dropdownTogglers.length; togglerIndex++) {
        const elem = dropdownTogglers[togglerIndex];
        const isExpanded = (elem.getAttribute('aria-expanded') === 'true');

        if (isExpanded) {
          elem.setAttribute('aria-expanded', 'false');
          elem.parentNode['classList'].remove('is-active');
          elem.parentNode.parentNode['classList'].remove('is-active');
        }
      }
    }
  }

}
