/* eslint-disable @typescript-eslint/member-ordering */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { InputOld, OldType } from './input-old.props';
import { InputFieldValidation, InputSize, InputType, InputVariant } from 'design-system/lib/components/forms/input-field/input-field.types';
import { translateCommonErrors } from '../../form-errors';
import { NgxMaskPipe } from 'ngx-mask';
import { DarkModeService } from '@services/dark-mode.service';

export enum InputMaskPatterns {
  cpf = '000.000.000-00',
  cnpj = '00.000.000/0000-00',
  cpfcnpj = '000.000.000-00||00.000.000/0000-00',
  email = 'email@domain.com',
  cep = '00000-000',
  plate = 'AAA-0A00||AAA-0000',
  textdate = '00/00/0000',
  time = 'Hh:m0',
  phone = '(00) 00000-0000||(00) 0000-0000',
  currency = 'separator.2'
};

enum RawType {
  text = 'text',
  password = 'password',
  number = 'number',
  email = 'email',
  url = 'url',
  search = 'search',
  tel = 'tel',
  date = 'date',
  time = 'time'
}

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent extends InputOld implements OnInit {

  @Input() label: string;
  @Input() placeholder: string;
  @Input() trailingIcon: string;
  @Input() leadingIcon: string;
  @Input() canClickTrailingIcon: boolean;
  @Input() canClickLeadingIcon: boolean;
  @Input() variant: InputVariant;
  @Input() control: AbstractControl;
  @Input() minLength: number | string | null;
  @Input() maxLength: number | string | null;
  @Input() dropSpecialCharacters: boolean;
  @Input() prefix = '';
  @Input() thousandSeparator = '.';
  @Input() separatorLimit: string;
  @Input() decimalMarker: '.' | ',';
  @Input() pattern: string;
  @Input() size: InputSize = 'medium';
  @Output() trailingIconClick = new EventEmitter<MouseEvent>();
  @Output() leadingIconClick = new EventEmitter<MouseEvent>();

  _value = null;
  _id = null;
  _disabled = false;
  _validation = undefined;
  _mask = undefined;
  _helperText = '';
  _rawType: InputType = 'text';
  _type: InputType | OldType = 'text';
  _isDark = false;

  constructor(private maskPipe: NgxMaskPipe, private darkMode: DarkModeService) {
    super();
    this.darkMode.toggled$.subscribe({
      next: isDarkMode => this._isDark = isDarkMode
    });
  }

  get isDark(): boolean {
    return this._isDark;
  }

  get rawType(): InputType {
    return this._rawType;
  }

  get validWithMask(): boolean {
    return this._rawType === 'text';
  }

  get maskPattern() {
    if (this.mask) {
      return this.mask;
    }
    if (InputMaskPatterns[this.type]) {
      return InputMaskPatterns[this.type];
    }
  }

  @Input()
  get type(): InputType | OldType {
    return this._type;
  }
  set type(val: InputType | OldType) {
    this._type = val;
    if (RawType[val]) {
      this._rawType = val as InputType;
    }
    if (InputMaskPatterns[this.type]) {
      this.mask = InputMaskPatterns[this.type];
    }
  }

  @Input()
  get mask(): string {
    return this._mask;
  }
  set mask(val: string) {
    this._mask = val;
  }

  @Input()
  get disabled(): boolean {
    if (this.isDisabled !== undefined) {
      return this.isDisabled;
    }
    return this._disabled;
  }
  set disabled(val: boolean) {
    this._disabled = val;
  }

  @Input()
  get validation(): InputFieldValidation {
    if (this.validationError !== undefined && typeof this.validationError !== 'function') {
      const hasError = this.validationError;
      return !hasError; // false -> has error / true -> no error
    }
    if (this.validationError !== undefined && typeof this.validationError === 'function') {
      return this.validationError;
    }
    return this._validation;
  }
  set validation(val: InputFieldValidation) {
    this._validation = val;
  }

  @Input()
  get helperText(): string {
    if (this.validation === undefined && this.validationError === undefined) {
      return this._helperText;
    }

    if (!this._helperText && this.control?.errors && this.control?.dirty) {
      if (this.validationMessage) {
        return this.validationMessage;
      }
      return translateCommonErrors(this.control?.errors);
    }

    return this._helperText || this.validationMessage;
  }
  set helperText(val: string) {
    this._helperText = val;
  }

  @Input()
  get value(): string {
    return this._value;
  }
  set value(val: string) {
    if (val !== this.value) {
      let value = typeof val === 'string' ? val : String(val);
      if (this.mask) {
        if (this.control) {
          value = this.maskPipe.transform(value, this.mask);
          this.control.setValue(value);
          this.control.updateValueAndValidity();
        }
      }
      this._value = value;
      this.valueChange.emit(this.value);
    }
  }

  @Input()
  get id(): string {
    return this._id;
  }
  set id(val: string) {
    this._id = val;
  }

  ngOnInit(): void {
    if (this.control?.value) {
      this.value = this.control.value;
    }
  }

}
