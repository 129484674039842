import { Pipe, PipeTransform } from '@angular/core';
import { deliveryStatus } from '../../enums/deliveryStatus';

@Pipe({
  name: 'deliveryStatus'
})
export class DeliveryStatusPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (!value) { return ''; }
    const response = deliveryStatus[value];
    if (!response) { return value; }
    return response;
  }

}
