import { Component, Input } from '@angular/core';
import { Globals } from 'src/app/core/globals';

@Component({
  selector: 'app-client-restrictions',
  templateUrl: './client-restrictions.component.html',
  styleUrls: ['./client-restrictions.component.scss']
})
export class ClientRestrictionsComponent {

  @Input() clientRestriction: any;

  constructor(
    public globals: Globals
  ) { }

  public translateAllowedVehicleTypes(allowedVehicleTypes: string[]) {
    const values = [];
    for (const vehicle of allowedVehicleTypes) {
      let value: string;
      switch (vehicle) {
        // case 'Truck':
        //   value = 'Truck';
        //   break;
        case 'CurtainDideTruck':
          value = 'Truck sider';
          break;
        case 'CurtainSideTractorUnit':
          value = 'Carreta sider';
          break;
        case 'SideWallOpenTruck':
          value = 'Truck grade baixa';
          break;
        case 'GrainTruck':
          value = 'Truck graneleiro';
          break;
        // case 'TractorUnit':
        //   value = 'Carreta';
        //   break;
        case 'SideWallOpenTractorUnit':
          value = 'Carreta grade baixa';
          break;
        case 'GrainTractorUnit':
          value = 'Carreta graneleira';
          break;
        case 'SiloTractorUnit':
          value = 'Carreta silo';
          break;
        case 'SiloTractorUnitWithCompressor':
          value = 'Carreta silo com compressor';
          break;
        case '40ADeliveryInContainer':
          value = 'Container 40A';
          break;
        default:
          value = vehicle;
          break;
      }
      values.push(value);
    }
    return values;
  }

  public translateAllowedCargoTypes(allowedCargoTypes: string[]) {
    const values = [];
    for (const cargo of allowedCargoTypes) {
      let value: string;
      switch (cargo) {
        case 'BigBag':
          value = 'BigBag';
          break;
        case 'Bulk':
          value = 'Granel';
          break;
        case 'Pallet':
          value = 'Pallet';
          break;
        case 'LooseBag':
          value = 'Saco Solto';
          break;
        default:
          value = '';
          break;
      }
      values.push(value);
    }
    return values;
  }
}
