import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { UserService } from '../../../services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class SuggestPasswordGuardService implements CanActivate {

  constructor(
    private router: Router,
    private userService: UserService,
  ) {
  }

  public isAuthenticated(): boolean {
    return !!this.userService.getLoggedUser();
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!this.isAuthenticated()) {
      this.router.navigate(['login']);
      return false;
    } else if (!this.userCanAccessRoute(route)) {
      this.router.navigate(['404']);
      return false;
    }
    return true;
  }

  userCanAccessRoute(route: ActivatedRouteSnapshot) {
    const userProfiles = this.userService.getUserProfiles();
    let userCanAccess = false;
    if (userProfiles && route.data && route.data.allowedProfiles && route.data.allowedProfiles.length > 0) {
      if (route.data.allowedProfiles.includes('*')) { return true; };
      route.data.allowedProfiles.forEach(profile => {
        if (userProfiles.filter(p => p === profile).length > 0) {
          userCanAccess = true;
        }
      });
    } else if (route.data.allowedProfiles.length === 0) {
      userCanAccess = true;
    }
    return userCanAccess;
  }
}
