import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import { PluginsService } from '../../../services/plugins.service';

@Component({
  selector: 'app-confirm-data-carrier',
  templateUrl: './confirm-data-carrier.component.html',
  styleUrls: ['./confirm-data-carrier.component.scss']
})
export class ConfirmDataCarrierComponent implements OnInit {

  @Output() confirm = new EventEmitter();

  branchesList = [];
  cnpj: string;
  nameCompany: string;
  phone: string;
  acceptTerms = false;
  showTerms = false;

  constructor(private pluginsService: PluginsService, private userService: UserService) { }

  ngOnInit(): void {
    const user = this.userService.getLoggedUser();
    this.nameCompany = user.shippingCompany.name;
    // this.pluginsService.getCarrierByCompanyId(user.organization).subscribe((response) => console.log('foi'));
  }

  toogleShowTerms() {
    this.showTerms = !this.showTerms;
  }

  toogleConfirmTerms() {
    this.toogleShowTerms();
    this.acceptTerms = !this.acceptTerms;
  }

  onSubmit() {
    const user = this.userService.getLoggedUser();
    const payload = {
      cnpj: this.cnpj,
      nameCompany: this.nameCompany,
      phone: this.phone
    };

    this.pluginsService.saveMatrixData(user.organization, payload).subscribe((response) => this.confirm.emit());
  }
}
