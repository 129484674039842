export let State = {
  _initial: true,
  _isShipper: undefined,
  _isAdmin: undefined,
  _approvalProcessId: undefined,
  _checkUserAnswer: undefined,
};

const initialState = Object.freeze(Object.assign({}, State));

export const resetState = () => State = initialState;

