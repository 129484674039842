import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

interface PushNotification {
  title?: string;
  type: 'error' | 'warning' | 'success';
  message?: string;
}
@Injectable({
  providedIn: 'root'
})
export class GlobalNotificationService {

  isProcessing = false;
  stack = [];

  public notification$: Subject<PushNotification> = new Subject<PushNotification>();
  public pushNotification$: Subject<any> = new Subject<any>();

  public notificationQueueChange$: Subject<any> = new Subject<any>();

  // PushNotification Architecture
  queueNotifications = [];
  notifications = [];
  countNotifications = 0;
  totalNotifications$ = new BehaviorSubject(0);
  totalNotReadNotifications$ = new BehaviorSubject(0);

  constructor(
    private http: HttpClient
  ) { }

  // Push Notifications Architecture
  pushNotification(notification) {
    this.queueNotifications.push(notification);
  }

  popNotification() {
    if (this.queueNotifications && this.queueNotifications.length > 0) {
      const item = this.queueNotifications.splice(0, 1);
      this.notifications.unshift(item[0]);
      this.countNotifications++;
      return item[0];
    }
    return null;
  }

  setAllNotifications(payload) {
    this.notifications = payload.notifications;
    this.countNotifications = payload.count;
    this.emitChangeOnNotifications();
  }

  concatNotifications(notifications) {
    this.notifications = this.notifications.concat(notifications);
  }

  async listUserNotifications(page = 0, limit = 10) {
    try {
      const notifications = await this.http.get(`${environment.api.meta.url}${environment.api.ses}notification/listnotifications/${page}/${limit}`).toPromise();
      return notifications['data'];
    } catch (e) {
      console.error(e);
    }
  }

  async getAllNotificationsTemplates() {
    try {
      const result = await this.http.get(`${environment.api.meta.url}${environment.api.ses}notification/notificationtemplates`).toPromise();
      return result && result['data'] ? result['data'] : [];
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async getNotificationSettings() {
    try {
      const result = await this.http.get(`${environment.api.meta.url}${environment.api.ses}notification/listAllUserNotificationsSettings`).toPromise();
      if (result && result['data']) {
        localStorage.setItem('userNotificationsSettings', JSON.stringify(result['data']));
      }
      return result && result['data'] ? result['data'] : [];
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async getNotifications(offset = 0, limit = 10, type = 'all', module = '', search = '') {
    try {
      const result = await this.http.get(`${environment.api.meta.url}${environment.api.ses}notification/getNotifications/${offset}/${limit}?module=${module}&type=${type}&search=${search}`).toPromise();
      return result && result['data'] ? result['data'] : [];
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async deleteNotifications(ids) {
    try {
      const notifications = await this.http.patch(environment.api.meta.url + environment.api.ses + 'notification/deleteNotifications', {ids}).toPromise();
      return notifications;
    } catch (e) {
      console.error(e);
    }
  }

  async deleteAllNotificationsByModule(module) {
    try {
      const notifications = await this.http.patch(environment.api.meta.url + environment.api.ses + `notification/deleteAllNotificationsByModule/${module}`, {}).toPromise();
      return notifications;
    } catch (e) {
      console.error(e);
    }
  }

  async markNotificationAsRead(id) {
    const notification = this.notifications.find(n => n._id === id);
    if (notification && !notification.readAt) {
      notification.readAt = new Date();
      await this.updNotificationReadAt([id]);
      this.emitChangeOnNotifications();
    }
  }

  async markNotificationsAsRead(notifications) {
    if (notifications && notifications.length > 0) {
      await this.updNotificationReadAt(notifications.filter(n => n._id).map(n => n._id));
      this.emitChangeOnNotifications();
    }
  }

  async updNotificationReadAt(ids) {
    try {
      await this.http.put(environment.api.meta.url + environment.api.ses + 'notification/markNotificationsAsRead', { ids }).toPromise();
    } catch (e) {
      return null;
    }
  }

  getCountNotifications() {
    return this.countNotifications;
  }

  processPushNotificationQueue() {
    if (this.queueNotifications.length > 0) {
      const notification = this.popNotification();
      this.pushNotification$.next(notification);
      this.emitChangeOnNotifications();
      this.getNotificationsAndSetTotals();
    }
  }

  emitChangeOnNotifications() {
    this.notificationQueueChange$.next({
      notifications: this.notifications,
      count: this.countNotifications,
      queue: this.queueNotifications
    });
  }

  queuePushNotification(notification) {
    this.pushNotification(notification);
  }

  listNotifications() {
    return this.notifications;
  }

  async setNotificationSettings(templateId, body) {
    try {
      const result = await this.http.put(`${environment.api.meta.url}${environment.api.ses}notification/setNotificationSetting/${templateId}`, body).toPromise();
      return result && result['data'] ? result['data'] : [];
    } catch (e) {
      console.error(e);
      this.notification$.next({
        title: 'Falha ao atualizar configuração',
        type: 'error',
        message: e.message
      });
      return [];
    }
  }

  countNotReadUrgentNotifications() {
    return this.notifications.filter(n => !n.readAt && n.type === 'error').length;
  }

  async getAllGroups() {
    try {
      const result = await this.http.get(`${environment.api.meta.url}${environment.api.ses}notification/getAllGroups`).toPromise();
      return result && result['data'] ? result['data'] : [];
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  setTotalNotifications(total) {
    this.totalNotifications$.next(total);
  }

  getTotalNotifications() {
    return this.totalNotifications$.asObservable();
  }

  setTotalNotReadNotifications(total) {
    this.totalNotReadNotifications$.next(total);
  }

  getTotalNotReadNotifications() {
    return this.totalNotReadNotifications$.asObservable();
  }

  async getNotificationsAndSetTotals() {
    const result = await this.getNotifications(0, 50, 'all');
      if (result) {
        this.setAllNotifications({
          notifications: result.notifications,
          count: result.total
        });
        this.setTotalNotifications(result.total);
        this.setTotalNotReadNotifications(result.totalNotRead);
      }
  }

  sendNotification(notification: PushNotification) {
    this.notification$.next(notification);
  }

  sendPushNotification(notification: PushNotification) {
    this.pushNotification$.next(notification);
  }
}
