<div class="dropdown-loadshark dropdown" [ngClass]="{'is-active': isExpanded, 'is-right': aligned === 'right'}">
    <div class="dropdown-trigger dropdown-loadshark_trigger">
      <div class="has-tooltip-arrow" [attr.data-tooltip]="'Inverter ordenação'" (click)="handleReverseOrder()" *ngIf="canReverseOrder" >
        <span *ngIf="selectedItem"  class="icon invert-order" [ngClass]="{'rotate': isReverseOrder}">
          <i class="material-icons is-size-5">arrow_upward</i>
        </span>
        <span *ngIf="!selectedItem"  class="icon invert-order disabled">
          <i class="material-icons is-size-5">swap_vert</i>
        </span>
      </div>
      <button class="button has-text-black">
        <span class="icon mt-2p" *ngIf="!canClearOrder" (click)="handleExpand()">
          <i class="material-icons is-size-5">layers</i>
        </span>
        <span class="icon mt-2p" *ngIf="!selectedItem && canClearOrder" (click)="handleExpand()">
          <i class="material-icons is-size-5">layers</i>
        </span>
        <span class="icon has-tooltip-arrow mt-2p" [attr.data-tooltip]="'Remover ordenação'" *ngIf="selectedItem && canClearOrder" (click)="handleClearOrder()">
          <i class="material-icons is-size-5">close</i>
        </span>
        <div (click)="handleExpand()">
          <span class="has-tooltip-arrow has-tooltip-top" [attr.data-tooltip]="tooltipText">{{ selectedItem || 'Ordenar' }}</span>
          <span class="icon is-small is-relative arrow" [ngClass]="{'rotate': isExpanded}">
            <i class="material-icons regular-text has-text-black" aria-hidden="true">
              expand_more
            </i>
          </span>
        </div>
      </button>
    </div>
    <div class="dropdown-loadshark_menu" [ngClass]="{'fade-in': isExpanded, 'fade-out': !isExpanded}">
      <div class="dropdown-loadshark_content dropdown-content">
        <div *ngFor="let item of items">
          <span class="dropdown-item is-clickable" (click)="handleOrder(item)">
            {{ item.label }}
          </span>
        </div>
      </div>
    </div>
  </div>
  
  