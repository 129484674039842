import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'canViewAuction'
})
export class CanViewAuctionPipe implements PipeTransform {

  transform(value: any, id: any): boolean {
    if (!value || !id) { return false; }
    const canViewThisAuction = value.participants?.invited?.includes(id) && !value.participants?.denied?.includes(id) && !value.participants?.joined?.includes(id);
    return canViewThisAuction;
  }

}
