import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent {

  @Input() value = '';

  @Output() search = new EventEmitter();
  @Output() valueChange = new EventEmitter();

  constructor() { }

  updateValues(value) {
    this.valueChange.emit(value);
    this.search.emit(value);
  }

  clearSearch() {
    this.value = '';
    this.valueChange.emit('');
    this.search.emit('');
  }

}
