import { Pipe, PipeTransform } from '@angular/core';

export const isCombo = (type: string) => type === 'combo';

export const isNoteChange = (type: string) => type === 'trocaDeNota';

export const isTranshipment = (type: string) => type === 'transbordo';

@Pipe({
  name: 'otCheckers'
})
export class OtCheckersPipe implements PipeTransform {
  transform(value: string, params: string[], condition: 'or' | 'and' = 'and'): boolean {
    const conditionalArrayMethods = {
      and: 'every',
      or: 'some'
    };

    const arraRes = params.map(i => eval(i)(value));

    return arraRes[conditionalArrayMethods[condition]](i => i);
  }
}
