<article *ngIf="isNewVersionAvailable" class="new-version-checker message is-info">
  <div class="message-body">
    <b>
      Nova versão disponível
    </b>
    <br>
    <app-button 
    title="Clique para atualizar"
    icon="refresh"
    variant="link"
    size="small"
    (handleClickEmitter)="update()"
    >
    </app-button>
  </div>
</article>