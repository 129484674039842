import { Component, Output, EventEmitter, Input, ViewChild, ElementRef, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-range-datepicker',
  templateUrl: './range-datepicker.component.html',
  styleUrls: ['./range-datepicker.component.scss']
})
export class RangeDatepickerComponent implements OnInit {

  @ViewChild('listPeriods', { static: true }) listPeriods: ElementRef;
  @ViewChild('startDateInput', { static: true }) startDateInput: ElementRef;
  @ViewChild('endDateInput', { static: true }) endDateInput: ElementRef;
  @Output() handleDateChange = new EventEmitter<any>();

  @Input() startDate;
  @Input() endDate;


  public minimumDate = moment('01-01-2000').format('YYYY-MM-DD');
  public today = moment().format('YYYY-MM-DD');

  private _config = {
    start: null,
    end: null
  };

  @Input() public set config(v) {
    this._config = v;
  }
  public get config() {
    return this._config;
  }

  isCustom = false;
  isExpanded = false;

  periods = [
    { label: 'Hoje', value: '', checked: false },
    { label: '1 mês', value: 'month', checked: true },
    { label: '1 semana', value: 'week', checked: false },
    { label: 'Personalizado', value: 'custom', checked: false },
  ];

  periodSelected = this.periods.find(p => p.checked);

  constructor() {
  }

  ngOnInit(): void {
    this.setupDates();
    this.filter();
  }

  setupDates() {
    // Configura se nenhum tiver definido
    if (!this.config.start && !this.config.end) {
      this.startDate = moment().subtract(1, 'month').format('YYYY-MM-DD');
      this.endDate = moment().format('YYYY-MM-DD');
      return;
    }

    this.isCustom = true;
    this.validateEndDate();

    // Não configura se tiver definido os dois
    if (this.config.start && this.config.end) { return; }

    if (this.config.start) { this.endDate = this.config.start; }
    if (this.config.end) { this.startDate = this.config.end; }

  }

  changeDateBySelect(value) {

    this.changePeriodSelected(value);

    if (value === 'custom') { return; }

    if (value) {
      this.startDate = moment(this.endDate).subtract(1, value).format('YYYY-MM-DD');
      this.endDate = moment(this.endDate).format('YYYY-MM-DD');
    } else {
      this.startDate = moment().subtract(1, value).format('YYYY-MM-DD');
      this.endDate = moment().format('YYYY-MM-DD');
    }
  }

  changeDateByInput(value, input) {
    if (!value) { return; }

    const when = input === 'startDate' ? 'isAfter' : 'isBefore';
    const target = input === 'startDate' ? this.endDate : this.startDate;

    if (this.isValidDateToFilter(value, when, target)) {
      // Define como customizado
      this.changePeriodSelected('custom');
      this[input] = moment(value).format('YYYY-MM-DD');
    } else {
      this[input + 'Input'].nativeElement.value = this[input];
    }
  }

  isValidDateToFilter = (value, when, target) =>
    when === 'isAfter' ? !moment(value).isAfter(target) : !moment(value).isBefore(target)

  isValidDate = (value) => moment(value).isSameOrBefore(this.today);

  filter = () => this.handleDateChange.emit({ startDate: this.startDate, endDate: this.endDate});

  validateEndDate() {
    if (this.config.end) {
      const index = this.periods.findIndex(p => p.label === 'Hoje');
      this.periods[index].checked = true;
    }
  }

  resetDate = () => {
    this.startDateInput.nativeElement.value = this.today;
    this.endDateInput.nativeElement.value = this.today;
  }

  changePeriodSelected = (value) => {
    const periodSelected = this.periods.find(p => p.value === value);
    const periods =
      this.periods
        .map(p => ({...p, checked: false}))
        .map(i => i.value !== value ? i : ({ ...i, checked: true }));

    this.periodSelected = periodSelected;
    this.periods = periods;
  }

}
