import { Component, OnInit } from '@angular/core';
import { Validators, AbstractControl, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MAIN_CAROUSEL } from 'src/app/data/main-carousel';
import { UserService } from 'src/app/services/user/user.service';
import { CommonForm } from 'src/app/shared/common-form';
import { addError } from 'src/app/shared/form-errors';
import * as zxcvbn from 'zxcvbn';
import { AuthService } from '../../../core/auth/auth.service';

const checkIfPasswordsMatch = (c: AbstractControl) => {
  if (!c.get('newPassword').value || !c.get('confirmPassword').value) { return null; }
  return c.get('newPassword').value !== c.get('confirmPassword').value ? { checkIfPasswordsMatch: true } : null;
};

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends CommonForm implements OnInit {

  resetedPassword = false;
  hasError = false;
  hasTokenError = false;
  passwordVisibility = true;
  submited = false;
  validatingToken = true;

  emailToDisplay = null;
  errorToDisplay = null;

  token: string;
  user: string;

  passwordEstimation: number;
  validation = {
    hasNumber: false,
    hasUppercase: false,
    hasLowercase: false,
    hasEightDigits: false
  };

  form: UntypedFormGroup;

  pathImages = MAIN_CAROUSEL;

  newPassword = new UntypedFormControl('', [Validators.required]);
  confirmPassword = new UntypedFormControl('', [Validators.required,
    Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`|{}:;/!.?\"()\[\]-]{8,50}$/)
  ]);

  constructor(
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    ) {
      super();
  }

  get controls() {
    return this.form.controls;
  }

  ngOnInit() {
    this.checkToken();
    this.form = new UntypedFormGroup({
      newPassword: this.newPassword,
      confirmPassword: this.confirmPassword
    }, {
      validators: [checkIfPasswordsMatch]
    });
    addError({ match: 'As senhas digitadas devem coincidir' });

    this.form.get('newPassword').valueChanges.subscribe(value => {
      const equal = value === this.form.get('confirmPassword').value;
      if (!equal) {
        this.form.controls.confirmPassword.setErrors({ match: true });
      } else {
        this.form.controls.confirmPassword.setErrors(null);
      }
    });
    this.form.get('confirmPassword').valueChanges.subscribe(value => {
      const equal = value === this.form.get('newPassword').value;
      if (!equal) {
        this.form.controls.confirmPassword.setErrors({ match: true });
      } else {
        this.form.controls.confirmPassword.setErrors(null);
      }
    });
  }

  async checkToken() {
    this.activatedRoute.queryParams.subscribe({
      next: async value => {
        if (!value.token) { return; }
        this.token = value.token;
        const response = await this.userService.checkToken(this.token);
        if (response.data) {
          setTimeout(() => {
            this.validatingToken = false;
            this.hasTokenError = false;
            this.user = response.data.name;
          }, 2000);
        } else {
          setTimeout(() => {
            this.validatingToken = false;
            this.hasTokenError = true;
            this.displayError(response);
          }, 2000);

        }
      }
    });
  }

  getStrength() {
    this.passwordEstimation = zxcvbn(this.form.controls.newPassword.value).score;
    this.validation.hasNumber = /\d/.test(this.form.controls.newPassword.value);
    this.validation.hasUppercase = /[A-Z]/.test(this.form.controls.newPassword.value);
    this.validation.hasLowercase = /[a-z]/.test(this.form.controls.newPassword.value);
    this.validation.hasEightDigits = this.form.controls.newPassword.value.length >= 8;
  }

  async resetPassword() {
    const { newPassword, confirmPassword } = this.form.value;

    if (newPassword !== confirmPassword) {
      this.displayError('As senhas não coincidem');
    } else if (this.form.controls.confirmPassword.hasError('pattern')) {
      this.displayError('A senha precisa ter pelo menos 8 caracteres, sendo pelo menos um maiúsculo, um minúsculo e um número');
    } else {

      const userData = {
        newPassword,
        token: this.token
      };

      const response = await this.userService.newPassword(userData);

      if (response.data) {
        this.hasError = false;
        this.resetedPassword = true;
        setTimeout(() => {
          this.goToLogin();
        }, 5000);
      } else {
        this.displayError(response);
      }
    }

  }

  displayError(error: string = 'Ocorreu um erro') {
    this.hasError = true;
    this.errorToDisplay = error;
  }

  goToLogin() {
    this.router.navigate(['/login']);
  }

}
