import { Component, Input, Output, OnChanges, EventEmitter, SimpleChanges } from '@angular/core';

type DefaultGridDataValue = {
  value: string | number | boolean | object;
  format?: any;
  formatOptions?: any;
  action?: (params) => void;
};

export type GridDataValue = {
  [key: string]: string | number | boolean | DefaultGridDataValue;
  checked?: boolean;
  disabled?: boolean;
};

export type SortColData = 'both' | 'asc' | 'desc';

export type ColDataValue = {
  field: string;
  header?: string;
  sort?: {
    options: SortColData;
    active?: Omit<SortColData, 'both'>;
  };
};

@Component({
  selector: 'app-tables-no-head',
  templateUrl: './tables-no-head.component.html',
  styleUrls: ['./tables-no-head.component.scss']
})
export class TablesNoHeadComponent implements OnChanges {

  @Input() gridData: GridDataValue[];
  @Input() colData: ColDataValue[];
  @Input() title: any;
  @Input() deleteAllButton = true;
  @Input() showOptions = true;
  @Input() showEditOption = true;
  @Input() showSelectBox = true;
  @Input() showDeleteOption = true;
  @Input() noPadding = false;
  @Output() editEmitter = new EventEmitter();
  @Output() deleteEmitter = new EventEmitter();
  @Output() deleteAllEmitter = new EventEmitter();
  @Output() sortEmitter = new EventEmitter();
  @Output() selectEmitter = new EventEmitter();
  @Output() selectGroupEmitter = new EventEmitter();

  constructor() {}

  get isAllChecked(): boolean {
    return this.gridData?.every(d => d.checked);
  }

  get isSomeChecked(): boolean {
    return this.gridData?.some(d => d.checked);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.gridData?.currentValue) {
      this.gridData?.forEach(d => d.checked = false);
    }
  }

  edit(row) {
    this.editEmitter.emit(row);
  }

  delete(row) {
    this.deleteEmitter.emit(row);
  }

  deleteAll() {
    this.deleteAllEmitter.emit(this.gridData?.filter(d => d.checked));
  }

  select(event, idx: number, groupIdx?: number) {
    if (this.gridData && this.gridData[idx]) {
      this.gridData[idx]['checked'] = event.target.checked;
      this.selectEmitter.emit(this.gridData?.filter(d => d.checked));
    }
  }

  selectAll() {
    const checked = this.isAllChecked;
    this.gridData?.forEach(d => d.checked = !checked);
  }

  handleAction(callback, value) {
    if (callback) {
      callback(value);
    }
  }

  handleSort(key: ColDataValue) {
    const sortOptions = key.sort?.options;

    if (!sortOptions) {
      return;
    }

    const isActive = key.sort?.active;
    if (!isActive) {
      this.handleInitialSort(key, sortOptions);
    } else {
      this.handleNextSort(key, sortOptions);
    }
    this.sortEmitter.emit({ field: key.field, sort: key.sort.active });
  }

  handleInitialSort(key, sortOptions) {
    if (sortOptions === 'asc') {
      key.sort.active = 'asc';
    } else {
      key.sort.active = 'desc';
    }
  }

  handleNextSort(key, sortOptions) {
    const currentSortValue = key.sort.active;

    if (sortOptions === 'both') {
      key.sort.active = currentSortValue === 'asc' ? 'desc' : 'asc';
    }
  }

}
