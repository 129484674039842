/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Input, OnInit } from '@angular/core';
import { ButtonColors, ButtonIconShapes, ButtonSizes, ButtonVariants } from 'design-system/lib/components/forms/button/button.component.types';
import { ButtonOld } from './button-old.props';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent extends ButtonOld implements OnInit {

  _disabled;
  _color: ButtonColors = 'primary';
  _size: ButtonSizes = 'medium';
  _variant: ButtonVariants = 'filled';

  @Input() icon: string;
  @Input() iconShape: ButtonIconShapes = 'circle';
  @Input() onlyIcon = false;
  @Input() loading = false;

  constructor() {
    super();
  }

  @Input()
  get color(): ButtonColors {
    if (this.isDefault) {
      return 'primary';
    }
    if (this.isDanger) {
      return 'danger';
    }
    if (this.isWarning) {
      return 'warning';
    }
    if (this.isSuccess) {
      return 'success';
    }

    return this._color;
  }
  set color(val: ButtonColors) {
    this._color = val;
  }

  @Input()
  get size(): ButtonSizes {
    if (this.isSmall) {
      return 'small';
    };
    if (this.isLarge) {
      return 'large';
    };
    return this._size;
  }
  set size(val: ButtonSizes) {
    this._size = val;
  }

  @Input()
  get disabled(): boolean {
    return this._disabled ?? this.isDisabled;
  }
  set disabled(val: boolean) {
    this._disabled = val;
  }

  @Input()
  get variant(): ButtonVariants {
    if (this.isOutlined || this.isTransparent || this.isSecondary) {
      return 'outlined';
    }
    if (this.noBackground) {
      return 'link';
    }
    return this._variant;
  }
  set variant(val: ButtonVariants) {
    this._variant = val;
  }

  get id(): string {
    if (this.title) {
      return `button${this.title?.split(' ')?.join('')}`;
    }
    return '';
  }

  ngOnInit(): void {
  }

  handleClick() {
    if (!this.disabled) {
      this.handleClickEmitter.emit();
    }
  }

}
