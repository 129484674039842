import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GlobalNotificationService } from '../../shared/services/global-notification.service';
import { LoaderService } from '../../shared/services/loader.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { calculateDeliveryStatus } from 'src/app/shared/utils';
import { Google } from 'src/app/shared/services/google.service';

@Injectable({
  providedIn: 'root'
})
export class TruckService {

  otNumberEmitter$ = new BehaviorSubject<any>(null);
  distanceMatrix;
  geocode;

  constructor(
    private http: HttpClient,
    private globalNotification: GlobalNotificationService,
    private loaderService: LoaderService,
    private route: Router,
    private googleService: Google
  ) {
    this.distanceMatrix = new this.googleService.google.maps.DistanceMatrixService();
    this.geocode = new this.googleService.google.maps.Geocoder();
  }

  public get getOTNumberChanges() {
    return this.otNumberEmitter$;
  }

  async getTruck(truckId) {
    try {
      const response = await this.http.get(environment.api.meta.url + environment.api.tractorUnit + '/' + truckId)
        .toPromise();
      return response && response['data'] && response['data']['tractorUnit'] ?
        response['data']['tractorUnit'] : null;
    } catch (e) {
      console.log(e);
      this.loaderService.hide();
      this.globalNotification.notification$.next({
        title: 'Falha ao carregar informações do caminhão',
        type: 'error',
        message: 'Recarregue a Página. ' + e.message
      });
      return null;
    }
  }

  async getActiveTrucks(page, limit) {
    try {
      const response = await this.http
        .get(environment.api.meta.url + environment.api.tractorUnit + '/' + page + '/' + limit + '?fields=_id,semiTrailers,semiTruck,lastLocation,isActive')
        .toPromise();
      return response && response['data'] ? response : null;
    } catch (e) {
      console.log(e);
      this.loaderService.hide();
      this.globalNotification.notification$.next({
        title: 'Falha ao carregar veículos',
        type: 'error',
        message: 'Recarregue a Página. ' + e.message
      });
      return null;
    }
  }

  async deleteTruck(truckId) {
    try {
      const response = await this.http
        .patch(environment.api.meta.url + environment.api.modal + 'tractorUnit/' + truckId, { isActive: false })
        .toPromise();
      return response;
    } catch (e) {
      console.log(e);
      this.loaderService.hide();
      this.globalNotification.notification$.next({
        title: 'Falha ao deletar veículo',
        type: 'error',
        message: 'Falha ao deletar veículo. ' + e.message
      });
      return null;
    }
  }

  async addTruck(truckId) {
    try {
      const response = await this.http
        .patch(environment.api.meta.url + environment.api.modal + 'tractorUnit/' + truckId, { isActive: true })
        .toPromise();
      this.globalNotification.notification$.next({
        title: 'Veículo adicionado',
        type: 'success',
        message: 'Veículo adicionado com sucesso.'
      });
      return response;
    } catch (e) {
      console.log(e);
      this.loaderService.hide();
      this.globalNotification.notification$.next({
        title: 'Falha ao adicionar veículo',
        type: 'error',
        message: 'Falha ao adicionar veículo. ' + e.message
      });
      return null;
    }
  }

  async getTruckByLicensePlate(modalType, licensePlate, regex?) {
    try {
      const response = await this.http
        .get(environment.api.meta.url + environment.api.tractorUnitMain + '/search/' + modalType + '/' + licensePlate)
        .toPromise();
      return response && response['data'] && response['data']['tractorUnit'] ?
        response['data']['tractorUnit'] : [];
    } catch (e) {
      this.loaderService.hide();
      return [];
    }
  }

  async getLastAndNextOT(trackerOrder) {
    try {
      const response = await this.http
        .get(environment.api.meta.url + environment.api.modal + `main/semiTruck?otNumber=${trackerOrder}`)
        .toPromise();
      return response && response['data'] && response['data']['results'] && response['data']['results']['info'] ? response['data']['results']['info'] : [];
    } catch (e) {
      console.log(e);
      this.loaderService.hide();
      this.globalNotification.notification$.next({
        title: 'Falha nos detalhes do pedido',
        type: 'error',
        message: 'Falha ao recuperar detalhes do pedido por OT. ' + e.message
      });
      return null;
    }
  }

  async getTrucksFilteredByParams(queryParams = '', page = 0) {
    try {
      const response = await this.http
        .get(`${environment.api.meta.url}${environment.api.truckMain}tractorUnit/filter/${page}/50?${queryParams}`)
        .toPromise();
      return response && response['data'] && response['data']['groups'] ?
        response['data'] : [];
    } catch (e) {
      console.log(e);
      this.loaderService.hide();
      this.globalNotification.notification$.next({
        title: 'Falha ao filtrar caminhões',
        type: 'error',
        message: 'Falha ao filtrar caminhões. ' + e.message
      });
      return null;
    }
  }

  async getTruckByPlateOT(plateOT) {
    try {
      const response = await this.http
        .get(`${environment.api.meta.url}${environment.api.tractorUnitMain}/filterLicensePlateOT/${plateOT}`)
        .toPromise();
      return response && response['data'] && response['data']['groups'] ?
        response['data'] : [];
    } catch (e) {
      console.log(e);
      this.loaderService.hide();
      this.globalNotification.notification$.next({
        title: 'Falha ao buscar caminhão pela placa/OT',
        type: 'error',
        message: e.error?.message || ('Falha ao buscar caminhão pela placa/OT. ' + e.message)
      });
      return null;
    }
  }

  async getMonitoringDataByOT(otNumber) {
    try {
      const response = await this.http
        .get(`${environment.api.meta.url}${environment.api.tractorUnit}/searchTractorUnit/${otNumber}`)
        .toPromise();
      return response && response['data'] && response['data']['tractorUnit'] && response['data']['tractorUnit'].length > 0 ?
        response['data']['tractorUnit'][0] : null;
    } catch (e) {
      console.log(e);
      this.loaderService.hide();
      this.globalNotification.notification$.next({
        title: 'Falha ao buscar os dados de monitoramento da OT',
        type: 'error',
        message: 'Falha ao buscar os dados de monitoramento da OT. ' + e.message
      });
      return null;
    }
  }

  plotTruckBySearchOT = (otNumber) => {
    this.route.navigate(['/dashboard']);
    this.getOTNumberChanges.next(otNumber);
  }

  public findByType(addressComponents, type) {
    return addressComponents.filter(item => item.types.indexOf(type) !== -1);
  }

  async getLastLocation(modal) {
    const lastLocation = await this.setLastLocation(modal);
    if (lastLocation) { modal.tractorUnit.lastLocation = lastLocation; }
    return modal;
  }

  async getAllLastLocation(data: any[]) {
    await new Promise(async (resolve) => {
      for (const truck of data) {
        const lastLocation = await this.setLastLocation(truck);
        if (lastLocation) { truck.tractorUnit.lastLocation = lastLocation; }
      }
      resolve(null);
    });
    return data;
  }

  async setLastLocation(element) {
    try {
      const dataLocation = element?.tractorUnit?.trackerData?.coordinates || element?.tractorUnit?.lastLocation?.location?.coordinates;

      if (!dataLocation) { return; }

      let lastLocation = element.tractorUnit.lastLocation;

      if (typeof lastLocation === 'string' || !lastLocation) { lastLocation = { address: null }; }

      if (!lastLocation.address) {

        await new Promise((resolve) => {
          this.geocode.geocode({
            location: new this.googleService.google.maps.LatLng({ lat: dataLocation[1], lng: dataLocation[0] })
          }, (response, status) => {
            if (status === this.googleService.google.maps.GeocoderStatus.OK) {
              try {
                const lastLocationLocality = this.findByType(response[0].address_components, 'administrative_area_level_2')[0];
                const lastLocationRegion = this.findByType(response[0].address_components, 'administrative_area_level_1')[0];
                lastLocation.address = response[0].formatted_address.toUpperCase();
                lastLocation.locality = lastLocationLocality.short_name.toUpperCase();
                lastLocation.region = lastLocationRegion.short_name.toUpperCase();
              } catch (error) {
                lastLocation.address = '-';
                lastLocation.locality = '-';
                lastLocation.region = '-';
              } finally {
                resolve(null);
              }
            }
          });
        });
      }
      return lastLocation;
    } catch (error) {
      console.error(error);
      return;
    }
  }

  async listActiveTrucks() {
    try {
      return [{ name: 'teste ' }];
    } catch (e) {
      this.globalNotification.notification$.next({
        title: 'Falha ao buscar caminhões',
        type: 'error',
        message: e.message
      });
    }
  }

  async listTrucks(semiTrailerLicensePlate, semiTruckLicensePlate) {
    try {
      return [{ name: 'teste ' }];
    } catch (e) {
      this.globalNotification.notification$.next({
        title: 'Falha ao buscar caminhões',
        type: 'error',
        message: e.message
      });
    }
  }

  async listSlots(listActive = true) {
    try {
      let endpoint = 'slot/0/1000';
      endpoint += listActive ? '?and[isActive]=true' : '?and[isActive]=false';
      const response = await this.http
        .get(`${environment.api.meta.url}${environment.api.truckMain}${endpoint}`)
        .toPromise();
      return response && response['data'] && response['data']['slot'] ? response['data']['slot'] : [];
    } catch (e) {
      this.globalNotification.notification$.next({
        title: 'Falha ao criar slot',
        type: 'error',
        message: e.message
      });
      return [];
    }
  }

  async countActiveSlots() {
    try {
      const response = await this.http
        .get(`${environment.api.meta.url}${environment.api.truckMain}slot/0/1?and[isActive]=true`)
        .toPromise();
      return response && response['data'] ? response['data']['total'] : [];
    } catch (e) {
      this.globalNotification.notification$.next({
        title: 'Falha ao criar slot',
        type: 'error',
        message: e.message
      });
      return [];
    }
  }

  async countInactiveSlots() {
    try {
      const response = await this.http
        .get(`${environment.api.meta.url}${environment.api.truckMain}slot/0/1?and[isActive]=false`)
        .toPromise();
      return response && response['data'] ? response['data']['total'] : [];
    } catch (e) {
      this.globalNotification.notification$.next({
        title: 'Falha ao criar slot',
        type: 'error',
        message: e.message
      });
      return [];
    }
  }

  async saveSlot(slot) {
    try {
      const response = await this.http
        .post(`${environment.api.meta.url}${environment.api.truckMain}slot`, slot)
        .toPromise();
      return response;
    } catch (e) {
      this.globalNotification.notification$.next({
        title: 'Falha ao criar SLOT',
        type: 'error',
        message: 'Caminhão já cadastrado em um SLOT'
      });
    }
  }

  async updateSlot(slot) {
    try {
      const response = await this.http
        .patch(`${environment.api.meta.url}${environment.api.truckMain}slot/${slot._id}`, slot)
        .toPromise();
      return response;
    } catch (e) {
      this.globalNotification.notification$.next({
        title: 'Falha ao atualizar slot',
        type: 'error',
        message: e.message
      });
    }
  }

  async getGroupedSlots(sort = 'region', query) {
    try {
      const response = await this.http
        .get(`${environment.api.meta.url}${environment.api.truckMain}slot/info?sort=${sort}${query}`)
        .toPromise();
      return response && response['data'] && response['data']['groups'] ? response['data']['groups'] : [];
    } catch (e) {
      this.globalNotification.notification$.next({
        title: 'Falha ao buscar slots',
        type: 'error',
        message: e.message
      });
    }
  }

  async updateSlotActiveStatus(slotId, status, slot) {
    try {
      const response = await this.http
        .patch(`${environment.api.meta.url}${environment.api.truckMain}slot/${slotId}/${status}`, slot)
        .toPromise();
      return response;
    } catch (e) {
      this.globalNotification.notification$.next({
        title: 'Falha ao ativar slot',
        type: 'error',
        message: e.message
      });
    }
  }

  async saveShippingCompany(shippingCompany) {
    try {
      const response = await this.http
        .post(`${environment.api.meta.url}/order/v1/shippingCompany`, shippingCompany)
        .toPromise();
      return response && response['data'] && response['data']['shippingCompany']
        ? response['data']['shippingCompany']
        : {};
    } catch (e) {
      this.globalNotification.notification$.next({
        title: 'Falha ao criar transportadora',
        type: 'error',
        message: e.message
      });
    }
  }

  async getUserRoutesFollowed() {
    try {
      const response = await this.http
        .get(`${environment.api.meta.url}${environment.api.truckMain}userFollowedRoutes`)
        .toPromise();
      return response && response['data'] ? response['data'] : [];
    } catch (e) {
      this.globalNotification.notification$.next({
        title: 'Falha ao criar transportadora',
        type: 'error',
        message: e.message
      });
    }
  }

  async saveUserFollowedRoute(origin, destination) {
    try {
      const response = await this.http
        .post(`${environment.api.meta.url}${environment.api.truckMain}userFollowedRoutes`, {
          origin,
          destination
        })
        .toPromise();
      this.globalNotification.notification$.next({
        title: 'Ativado!',
        type: 'success',
        message: `Vamos avisar aqui quando houver atualizações para a rota ${origin}/${destination}`
      });
      return response && response['data'] ? response['data'] : [];
    } catch (e) {
      this.globalNotification.notification$.next({
        title: 'Falha ao criar transportadora',
        type: 'error',
        message: e.message
      });
    }
  }

  async unfollowRoute(origin, destination) {
    try {
      const response = await this.http
        .patch(`${environment.api.meta.url}${environment.api.truckMain}userFollowedRoutes/delete`, {
          origin,
          destination
        })
        .toPromise();
      this.globalNotification.notification$.next({
        title: 'Desativado!',
        type: 'success',
        message: `Não vamos mais te avisar quando houver atualizações para a rota ${origin}/${destination}`
      });
      return response && response['data'] ? response['data'] : [];
    } catch (e) {
      this.globalNotification.notification$.next({
        title: 'Falha ao criar transportadora',
        type: 'error',
        message: e.message
      });
    }
  }

  async getInactiveTrucks() {
    try {
      const response = await this.http
        .get(`${environment.api.meta.url}${environment.api.tractorUnit}/countInactiveSlots`)
        .toPromise();
      return response && response['data'] ? response['data'].total : [];
    } catch (e) {
      console.log(e);
      this.globalNotification.notification$.next({
        title: 'Falha ao carregar informações dos slots inativos',
        type: 'error',
        message: 'Recarregue a Página. ' + e.message
      });
      return null;
    }
  }

  filterByDeliveryStatus = (data, deliveryStatus) => data.filter(({ tractorUnit }) => {
    const { plannedEnd, startTrip, endTrip, trackerData: { eta } } = tractorUnit;
    return calculateDeliveryStatus(plannedEnd, eta, endTrip, startTrip) === deliveryStatus;
  })

  async getPlannedEnd(truck) {
    const origin = truck.tractorUnit.trackerData.origin.address;
    const destination = truck.tractorUnit.trackerData.destination.address;
    const now = new Date().getTime();

    return new Promise((resolve, reject) => {
      try {
        this.distanceMatrix.getDistanceMatrix({
          origins: [origin],
          destinations: [destination],
          travelMode: this.googleService.google.maps.TravelMode.DRIVING,
          transitOptions: {
            departureTime: new Date()
          }
        }, (response, status) => {
          if (status === 'OK') {
            const durationValue = response['rows'][0].elements[0].duration.value;
            truck.tractorUnit.plannedEnd = new Date(now + (durationValue * 1000)).toISOString();
            resolve(null);
          }
        });
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  async getLastLocationOfTransportation(transportation) {
    const { modal: { trackerData: { coordinates } } } = transportation;
    const lastLocation = { address: '', locality: '', region: '' };

    if (!coordinates[0] || !coordinates[1]) { return; }
    await new Promise((res, rej) => {
      this.geocode.geocode({
        location: new this.googleService.google.maps.LatLng({ lat: coordinates[1], lng: coordinates[0] })
      }, (response, status) => {
        if (status === this.googleService.google.maps.GeocoderStatus.OK) {
          try {
            const lastLocationLocality = this.findByType(response[0].address_components, 'administrative_area_level_2')[0];
            const lastLocationRegion = this.findByType(response[0].address_components, 'administrative_area_level_1')[0];
            lastLocation.address = response[0].formatted_address.toUpperCase();
            lastLocation.locality = lastLocationLocality.short_name.toUpperCase();
            lastLocation.region = lastLocationRegion.short_name.toUpperCase();
            res(null);
          } catch (error) {
            lastLocation.address = '-';
            lastLocation.locality = '-';
            lastLocation.region = '-';
            rej(null);
          }
        }
      });
    });

    return lastLocation;
  }

}
