<app-button
  [isDefault]="true"
  [isPrimaryDark]="hasSomeActiveFilter"
  [isTransparent]="!hasSomeActiveFilter"
  (click)="openOrCloseFilter()"
  [title]="text"
  icon="filter_list">
</app-button>

<ng-content *ngIf="isFilterOpened"></ng-content>
