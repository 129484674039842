<div class="auction-category">
  <label *ngIf="checkboxCondition" class="checkbox checkbox-toggle">
    <input
      type="checkbox"
      name="loadCheckbox"
      class="checkbox-input-load"
      [checked]="checked"
      (change)="onCheck($event)"
    />
    <span class="label-toggle-content"> &nbsp; </span>
  </label>

  <div class="auction-category__content">
    <img
      *ngIf="hasLogo"
      src="assets/stellantis-logo.jpg"
      alt="Logo da empresa"
    />

    <div>
      <h6>Categoria</h6>
      <span [ngClass]="{ isCombo: comboType === 'combo' }">
        {{
          comboType === "roadTrain"
            ? "Rodotrem"
            : comboType === "combo"
            ? "Combo"
            : "Simples"
        }}
        <i
          class="material-icons is-medium ml-3 has-tooltip-arrow"
          [attr.data-tooltip]="
            comboType && comboType === 'combo'
              ? 'Leilão composto por mais de uma carga'
              : comboType === 'roadTrain'
              ? 'Leilão composto por duas ou mais cargas com a mesma origem e destino.'
              : 'Leilão composto somente por uma carga.'
          "
        >
          error
        </i>
      </span>
    </div>
  </div>
</div>
