<div class="wrapper">

  <label class="input-label">
    {{ label ? label : ' ' }}
  </label>

  <textarea
  [id]="name"
  [formControl]="control"
  [placeholder]="placeholder"
  [value]="value"
  [rows]="rows"
  ></textarea>
</div>
