import { UntypedFormControl } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-radio-options-input',
  templateUrl: './radio-options-input.component.html',
  styleUrls: ['./radio-options-input.component.scss']
})
export class RadioOptionsInputComponent implements OnInit, OnChanges {

  @Input() data: any[];
  @Input() name: string;
  @Input() control: UntypedFormControl;
  @Input() validationError: boolean;
  @Input() validationMessage: string;
  @Output() changeSelect = new EventEmitter();

  filteredOptions = [];

  constructor() { }

  ngOnInit(): void {
    this.filteredOptions = this.setInitialOptions(this.data);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['data']) {
      this.filteredOptions = this.setInitialOptions(this.data);
    }
  }

  setInitialOptions(data: any[]) {
    if(!data) {
      return;
    }

    return data;
  }

  filterOptions(event): void {
    if (event && event.target?.value) {
      const inputValue = event.target?.value;

      this.filteredOptions = this.data.filter(
        val => val.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    } else {
      this.filteredOptions = this.data;
    }
  }
}
