<div class="range-inputs">
  <!-- DROPDOWN -->
  <div class="dropdown" [ngClass]="{'is-active': isExpanded}">
    <div class="dropdown-trigger">
      <div class="is-flex is-align-items-center" aria-haspopup="true" aria-controls="dropdown-menu" (click)="isExpanded = !isExpanded">
        <span class="regular-text">
          {{periodSelected.label}}
        </span>
        <span class="icon is-small ml-4">
          <ng-container *ngIf="!isExpanded; else elseExpanded">
            <i class="material-icons regular-text" aria-hidden="true">
              expand_more
            </i>
          </ng-container>
          <ng-template #elseExpanded>
            <i class="material-icons regular-text" aria-hidden="true">
              expand_less
            </i>
          </ng-template>
        </span>
      </div>
    </div>
    <div class="dropdown-menu" id="dropdown-menu" role="menu">
      <div class="dropdown-content">
        <ng-container *ngFor="let period of periods">
          <span class="dropdown-item is-clickable regular-text"
            (click)="changeDateBySelect(period.value); isExpanded = !isExpanded">
            {{period.label}}
          </span>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- CALENDAR -->
  <input darkMode #startDateInput class="first-input" type="date" (change)="changeDateByInput($event.target.value, 'startDate')"
    [value]="startDate" [min]="minimumDate" [max]="endDate" required onkeydown="return false" />
  <input darkMode #endDateInput class="last-input" type="date" (change)="changeDateByInput($event.target.value, 'endDate')"
    [value]="endDate" [min]="startDate" required onkeydown="return false" />
  <span class="is-flex is-align-itens-center" (click)="filter()">
    <i class="material-icons is-size-5">search</i>
  </span>
</div>
