import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NotificationsCollapsibleComponent } from 'src/app/core/header/notifications-list/collapsible/notifications-collapsible.component';
import { AlertNotificationModule } from 'src/app/plugins/alert-notification/alert-notification.module';
import { RegistrationCompleteComponent } from 'src/app/plugins/registration-complete/registration-complete.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { HeaderComponent } from './header.component';
import { MenuSearchOtComponent } from './menu-search-ot/menu-search-ot.component';
import { NotificationsListComponent } from './notifications-list/notifications-list.component';

@NgModule({
  declarations: [
    HeaderComponent,
    MenuSearchOtComponent,
    NotificationsListComponent,
    NotificationsCollapsibleComponent,
    RegistrationCompleteComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FormsModule,
    AlertNotificationModule
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule { }
