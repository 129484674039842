import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayProp'
})
export class DisplayPropPipe implements PipeTransform {

  transform(value, prop, defaultValue): unknown {
    if (value && value.length > 0) {
      return value.map(i => i[prop]);
    }
    return defaultValue;
  }

}
