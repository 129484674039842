import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { ButtonSizes } from 'design-system/lib/components/forms/button/button.component.types';

@Component({
  selector: 'app-upload-button',
  templateUrl: './upload-button.component.html',
  styleUrls: ['./upload-button.component.scss']
})
export class UploadButtonComponent implements OnInit {

  @Input() isDisabled: boolean;
  @Input() isSideText = false;
  @Input() id: string;
  @Input() title: string;
  @Input() icon = 'upload';
  @Input() size: ButtonSizes = 'medium';
  @Input() imageIcon: string;
  @Input() isOutlined: boolean;
  @Input() isLink: boolean;
  @Input() hasPopover = true;
  @Input() acceptedFiles: string;
  @Input() acceptedFilesTracking: string;
  @Input() showUploadedFiles = true;
  @Input() isLoading = false;
  @Input() validation: (files: FileList, args?: any[]) => boolean;
  @Input() errorMessage: string;
  @Input() control: AbstractControl = new UntypedFormControl();
  @Input() onlyIcon = false;
  @Input() iconShape = 'square';
  @Output() uploadFileEmitter = new EventEmitter();

  inputId: string;

  fileName = '';
  fileIsValid = false;
  isImportedDataModalOpened = false;
  formData = new FormData();
  error = false;
  _disabled;

  constructor() {
    // Generates random id to input
    this.inputId = Math.random().toString(36).substring(3);
  }

  @Input()
  get disabled(): boolean {
    return this._disabled ?? this.isDisabled;
  }
  set disabled(val: boolean) {
    this._disabled = val;
  }

  ngOnInit(): void {
  }

  validator(files: FileList, ...args) {
    return this.validation(files, args);
  }

  updateFilename(event?: any) {
    this.error = false;
    const target = event && event.target as HTMLInputElement;

    const isInvalid = !target || !target['files'] || target['files'] && target.files.length === 0;

    let isInvalidFile: boolean;

    if (this.acceptedFilesTracking?.length) {
      isInvalidFile = !this.acceptedFilesTracking?.includes(target['files'][0].type);
    }

    if (isInvalid || this.validation && !this.validation(target['files']) || isInvalidFile) {
      this.fileIsValid = false;
      this.fileName = '';
      this.error = true;
      target.value = '';
      if (isInvalidFile) {
        this.errorMessage = 'Extensão de arquivo inválida. Por favor envie arquivos tipo pdf ou imagens';
      }
      return;
    }

    const file = target.files[0];
    this.fileIsValid = true;
    this.fileName = target['files'][0].name;
    this.control.setValue(file);
    this.uploadFile(file);
  }

  uploadFile(file) {
    this.formData.append('file', file);
    this.uploadFileEmitter.emit(this.formData);
  }

  removeFile() {
    if (this.control) {
      this.control.patchValue(null);
    }
    this.formData.delete('file');
    this.fileName = null;
    this.uploadFileEmitter.emit(null);
  }

  removeError() {
    this.error = false;
  }

}
