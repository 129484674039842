<div darkMode class="card wrapper">
  <div *ngIf="title" class="card-title">
    {{ title }}
  </div>
  <div class="card-content is-size-6" [ngClass]="{'p-0': noPadding}">
    <div class="table-actions is-striped" *ngIf="showOptions">
      <div class="is-flex is-align-items-center">
        <app-button
          title="Selecionar tudo"
          icon="check_box"
          variant="link"
          size="small"
          (handleClickEmitter)="selectAll()"
        ></app-button>
      </div>
      <div class="is-flex is-align-items-center ml-2" *ngIf="deleteAllButton">
        <app-button
          title="Excluir selecionado(s)"
          icon="delete"
          variant="link"
          size="small"
          [disabled]="!isSomeChecked"
          (handleClickEmitter)="deleteAll()"
        ></app-button>
      </div>
    </div>

    <table darkMode class="table is-fullwidth">
      <thead>
        <tr class="col-data">
          <!-- Expand e check all button (th for correct spacing) -->
          <ng-container *ngIf="gridDataGrouped">
            <th colspan="2"></th>
          </ng-container>

          <th *ngIf="showSelectBox" colspan="1">
            <input type="checkbox" disabled [checked]="isAllChecked" />
          </th>
          <th *ngFor="let key of colData" colspan="1">
            <div
            class="is-flex is-align-items-center"
            [ngClass]="{'is-clickable': key.sort}"
            (click)="handleSort(key)"
            >
              {{ key.header }}
              <i *ngIf="key.sort"
              class="sort-icon material-icons is-size-5 ml-2"
              [ngClass]="{'active': key.sort.active}"
              >
                {{ key.sort.active === 'desc' ? 'arrow_downward' : 'arrow_upward' }}
              </i>
            </div>
          </th>
          <th
            *ngIf="showEditOption || showDeleteOption"
            class="is-flex is-justify-content-center"
          >
            Ações
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="gridData; else grouped">
          <tr *ngFor="let record of gridData; let i = index" class="grid-data">
            <td *ngIf="showSelectBox" (click)="select($event, i)">
              <input type="checkbox" [checked]="record.checked" [disabled]="record.disabled" />
            </td>
            <td
              [ngClass]="{ warning: record.disabled }"
              *ngFor="let key of colData"
            >
              <ng-container
                  *ngIf="record | getValueFromField : key.field as result"
                >
                  <span
                    [ngClass]="{
                      'has-text-primary has-text-weight-medium is-underlined is-clickable': result.action
                    }"
                    (click)="handleAction(result.action, record)"
                  >
                    {{ result.value }}
                  </span>
                </ng-container>
            </td>
            <td>
              <div class="is-flex is-justify-content-center">
                <app-button
                  *ngIf="showEditOption"
                  icon="edit"
                  size="small"
                  variant="link"
                  color="warning"
                  (handleClickEmitter)="edit(record)"
                >
                </app-button>
                <app-button
                  *ngIf="showDeleteOption"
                  size="small"
                  variant="link"
                  color="danger"
                  icon="delete"
                  (handleClickEmitter)="delete(record)"
                >
                </app-button>
              </div>
            </td>
          </tr>
        </ng-container>
        <ng-template #grouped>
          <ng-container
          *ngFor="let dataGrouped of gridDataGrouped; let groupIdx = index"
          >
            <!-- Head -->
            <tr
            class="grid-data"
            [ngClass]="{ 'has-text-weight-bold has-text-black': dataGrouped.expanded }"
            [ngStyle]="{ 'background-color': dataGrouped.expanded ? '#A8F0EC' : (!darkModeIsEnabled ? '#fff' : darkBackgroundColor)}"
            >
              <!-- Expand/Reduce  -->
              <td>
                <span
                  role="button"
                  class="has-text-primary is-clickable"
                  (click)="expandGroup(groupIdx)"
                >
                  <i class="material-icons">
                    {{ !dataGrouped.expanded ? "expand_more" : "expand_less" }}
                  </i>
                </span>
              </td>
              <!-- Check all from group -->
              <td (click)="selectGroup($event, groupIdx)">
                <input type="checkbox" [checked]="dataGrouped.checked" />
              </td>
              <!-- Fields -->
              <td *ngFor="let key of colData">
                <ng-container
                  *ngIf="dataGrouped.head | getValueFromField : key.field as head">

                  <app-button
                    *ngIf="head.action"
                    title="Ver Perfil"
                    size="small"
                    variant="filled"
                    (handleClickEmitter)="handleAction(head.action, dataGrouped.head)">

                  </app-button>
                  <span *ngIf="!head.action">
                    {{ head.value }}
                  </span>
                </ng-container>
              </td>
            </tr>
            <!-- Grouped data -->
            <tr
              class="grid-data inner"
              [hidden]="!dataGrouped.expanded"
              *ngFor="let record of dataGrouped.group; let i = index"
            >
              <td colspan="1"></td>
              <td (click)="select($event, i, groupIdx )">
                <input type="checkbox" [checked]="record.checked" [disabled]="record.disabled" />
              </td>
              <td *ngFor="let key of colData">
                <ng-container
                  *ngIf="record | getValueFromField : key.field as result"
                >
                  <span
                    [ngClass]="{
                      'has-text-primary has-text-weight-medium is-underlined is-clickable': result.action
                    }"
                    (click)="handleAction(result.action, record)"
                  >
                    {{ result.value }}
                  </span>
                </ng-container>
              </td>
            </tr>
          </ng-container>
        </ng-template>
      </tbody>
    </table>
  </div>
</div>
