import { Component, Input, OnInit } from '@angular/core';

type Score = 'Ótimo' | 'Regular' | 'Insuficiente';

type Scores = {
  [score in Score]: {
    min: number;
    max: number;
  };
};

@Component({
  selector: 'app-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.scss']
})
export class ScoreComponent implements OnInit {

  @Input() percent;
  @Input() isEmpty;
  @Input() emptyText = 'Não finalizado';

  scores: Scores = {
    Ótimo: {
      min: 80,
      max: 100
    },
    Regular: {
      min: 50,
      max: 79
    },
    Insuficiente: {
      max: 49,
      min: 0
    }
  };

  constructor() { }

  get translatedScore(): string {
    let result;
    Object.entries(this.scores).forEach(([score, range]) => {
      if (this.percent >= range.min && this.percent <= range.max) {
        result = score;
      }
    });
    return result;
  }


  ngOnInit(): void {
  }

}
