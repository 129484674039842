import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private userService: UserService) { }

  saveProfilesInLocalStorage() {
    return new Observable(subscribe => {
      this.userService.listProfiles(0, 1000, '').subscribe({
        next: result => {
          if (result) {
            const profilesInfo = result.map(p => ({
                id: p._id,
                name: p.name,
                isAdmin: p.isAdmin,
                defaultRoute: p.defaultRoute
            }));
            window.localStorage.setItem('profiles', JSON.stringify(profilesInfo));
            subscribe.next(true);
          }
        },
        error: e => {
          console.error(e);
          subscribe.next(false);
        }
      });
    });
  }
}
