import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auction-divider',
  templateUrl: './auction-divider.component.html',
  styleUrls: ['./auction-divider.component.scss']
})
export class AuctionDividerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
