import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TimelineItem } from '../../models/timeline';
import { DarkModeService } from '@services/dark-mode.service';

@Component({
  selector: 'app-timeline-content',
  templateUrl: './timeline-content.component.html',
  styleUrls: ['./timeline-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineContentComponent implements OnInit {

  @Input() data: TimelineItem;
  @Input() subItem: boolean;

  constructor(private darkMode: DarkModeService) { }

  get iconClass(): string {
    const isDark = this.darkMode.isDark;
    const style = this.data.icon.type && this.data.icon.type === 'solid'
      ? 'material-icons'
      : 'material-icons-outlined';
    return `${isDark ? 'dark' : ''} ${style} ${this.data?.icon?.color}`;
  }

  get iconName(): string {
    return this.data?.icon?.name.trim();
  }

  ngOnInit(): void {
  }

}
