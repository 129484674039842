<div class="page">
    <div class="modal is-active">
        <div class="modal-background"></div>
        <div class="modal-content modal-width card is-radius-large is-shadowed-small">
            <div class="card-content has-text-centered">
                <div class="full-width modal-row">
                    <h3 class="modal-title">
                        <span *ngIf="!emailSent && passwordCount === 1">Sugerimos que você mude sua senha</span>
                        <span *ngIf="emailSent">Senha redefinida com sucesso</span>
                        <span *ngIf="!emailSent && passwordCount !== 1">Você precisa alterar sua senha</span>
                    </h3>
                </div>
                <div class="full-width modal-row">
                    <div class="columns welcome-text">
                        <div class="column is-12">
                            <h3 *ngIf="!emailSent" class="subtitle">
                                Implementamos um novo padrão de senhas mais forte e mais seguro.
                            </h3>
                            <p *ngIf="!emailSent && !emailError && passwordCount === 1" class="description">
                                Por favor, considere redefinir sua senha
                            </p>
                            <p *ngIf="!emailSent && !emailError && passwordCount !== 1" class="description">
                                Clique no botão para redefinir sua senha
                            </p>
                            <p *ngIf="emailSent && !emailError" class="description">
                                Sua senha foi redefinida com sucesso
                            </p>
                            <p *ngIf="emailError" class="description">
                                Houve um erro ao redefinir sua senha
                            </p>
                        </div>
                    </div>
                </div>
                <div *ngIf="!emailSent && !emailError" class="is-flex is-justify-content-center mb-4">
                    <app-button *ngIf="passwordCount === 1" class="mr-5" (handleClickEmitter)="closeModal()" title="Ignorar" [isSecondary]="true"></app-button>
                    <app-button (handleClickEmitter)="resetPassword()" title="Redefinir"></app-button>
                </div>
                <div *ngIf="emailSent || emailError" class="is-flex is-justify-content-center mb-4">
                    <app-button *ngIf="passwordCount === 1" class="mr-5" (handleClickEmitter)="closeModal()" title="Navegar"></app-button>
                    <app-button *ngIf="passwordCount !== 1" class="mr-5" (handleClickEmitter)="closeAndLoggout()" title="Ok"></app-button>
                </div>
            </div>
        </div>
    </div>
</div>
