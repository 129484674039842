import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auction-header',
  templateUrl: './auction-header.component.html',
  styleUrls: ['./auction-header.component.scss']
})
export class AuctionHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
