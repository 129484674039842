<ng-container *ngIf="displayAllUsers">
  <!-- Titulo -->
  <p class="is-size-3 has-text-centered pb-4 has-text-weight-bold">
    Seleção de usuários ativos da transportadora
  </p>
  <p class="is-size-5 has-text-justified pb-4">
    Selecione os usuários que continuarão com acesso liberado após esse cadastro
  </p>
  <!-- Corpo -->

  <div class="is-scrollable full-width">
    <app-card *ngFor="let user of users">
      <div class="is-flex full-width is-align-items-center is-justify-content-space-between">
        <app-checkbox [(value)]="user.isChecked"></app-checkbox>
        <p class="is-flex is-flex-direction-column is-size-5 pb-4">
          <b class="has-text-grey">Nome</b>
          {{ user.name }}
        </p>
        <p class="is-flex is-flex-direction-column is-size-5 pb-4">
          <b class="has-text-grey">E-mail</b>
          {{ user.email }}
        </p>
      </div>
    </app-card>
  </div>

  <div class="is-flex is-align-items-center is-justify-content-center">
    <app-button
      title="Prosseguir"
      class="ml-5"
      [isDefault]="true"
      (handleClickEmitter)="process()"
    ></app-button>
  </div>
</ng-container>

<ng-container *ngIf="!displayAllUsers">
  <!-- Titulo -->
  <p class="is-size-3 has-text-centered pb-4 has-text-weight-bold">
    Atenção!
  </p>
  <p class="is-size-5 has-text-justified pb-4">
    Os usuários abaixo serão inativados e não terão mais acesso ao Loadshark
  </p>

  <!-- Corpo -->
  <div class="is-scrollable full-width">
    <app-card *ngFor="let user of deletedUsers">
      <div class="is-flex full-width is-align-items-center is-justify-content-space-between">
        <p class="is-flex is-flex-direction-column is-size-5 pb-4">
          <b class="has-text-grey">Nome</b>
          {{ user.name }}
        </p>
        <p class="is-flex is-flex-direction-column is-size-5 pb-4">
          <b class="has-text-grey">E-mail</b>
          {{ user.email }}
        </p>
      </div>
    </app-card>
  </div>

  <p class="is-size-3 has-text-centered pb-4 has-text-weight-bold">
    Deseja prosseguir?
  </p>

  <div class="is-flex is-align-items-center is-justify-content-center">
    <app-button
      title="Não"
      class="ml-5"
      [isDefault]="true"
      (handleClickEmitter)="toogleDisplayAllUsers()"
    ></app-button>

    <app-button
      title="Sim"
      class="ml-5"
      [isDefault]="true"
      (handleClickEmitter)="onSubmit()"
    ></app-button>
  </div>
</ng-container>

