import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

export enum StepModal {
  INFORMATION = 1,
  CONFIRM_DATA_CARRIER = 2,
  SHOW_TERMS = 3,
  ACTIVE_CARRIER_USERS = 4,
}

@Component({
  selector: 'app-register-old-carriers-modal',
  templateUrl: './register-old-carriers-modal.component.html',
  styleUrls: ['./register-old-carriers-modal.component.scss']
})
export class RegisterOldCarriersModalComponent implements OnInit{

  displayModal = false;
  StepModal= StepModal;
  step = StepModal.INFORMATION;

  constructor() { }

  ngOnInit(): void {
  }

  closeModal = () => this.displayModal = false;

  nextStep(step: StepModal) {
    this.step = step;
  }
}
