import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {

  @Input() label: string;
  @Input() isDisabled = false;
  @Input() control: AbstractControl = new UntypedFormControl();
  @Output() valueChange = new EventEmitter();
  @Output() change = new EventEmitter();
  @Input()
  get value() {
    return this._value;
  }
  set value(val) {
    this._value = val;
    this.valueChange.emit(this._value);
  }

  _value = null;

  constructor() { }

  ngOnInit(): void {
    if (this.control.value) {
      this.value = this.control.value;
    }
  }

  toogleChecked() {
    this.change.emit(this._value);
  }
}
