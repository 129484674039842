<div class="no-auctions">
  <div class="content">
    <h1>
      {{ title }}
    </h1>
    <p class="regular-text">
      {{ description }}
    </p>
  </div>
  <div class="actions">
    <ng-content></ng-content>
  </div>
</div>