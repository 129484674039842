import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Profiles } from 'src/app/models/profiles';
import { UserService } from 'src/app/services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class UnloadWindowsGuardService {

  constructor(
    private userService: UserService,
    private router: Router
  ) { }

  canActivate(): boolean {
    const profiles = this.userService.getUserProfiles();
    const isShipper = [Profiles.Administrador, Profiles.EmbarcadorAgendamento, Profiles.EmbarcadorAgendamentoLeitura];
    const isCarrier = [Profiles.Administrador, Profiles.TransportadoraAgendamento];
    const isSupplier = [Profiles.Administrador, Profiles.FornecedorAgendamento];

    if (profiles.some(p => isShipper.includes(p))) {
      this.router.navigate(['/janelas/embarcador']);
      return false;
    }

    if (profiles.some(p => isCarrier.includes(p))) {
      this.router.navigate(['/janelas/transportadora']);
      return false;
    }

    if (profiles.some(p => isSupplier.includes(p))) {
      this.router.navigate(['/janelas/fornecedor']);
      return false;
    }

    return false;
  }
}
