import { Injectable } from '@angular/core';

declare let ga: () => any; // Declare ga as a function

@Injectable({
  providedIn: 'root'
})
export class AnalyiticsService {

  constructor() { }

  // create our event emitter to send our data to Google Analytics
  public eventEmitter(eventCategory: string,
                      eventAction: string,
                      eventLabel: string = null,
                      eventValue: number = null) {
    (window as any).gtag('event', eventCategory , {
      event_category: eventCategory,
      event_action: eventAction,
      event_label: '',
    });
    // (window as any)('send', 'event', {
    //   eventCategory: eventCategory,
    //   eventLabel: eventLabel,
    //   eventAction: eventAction,
    //   eventValue: eventValue
    // });
  }
}
