import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { GlobalNotificationService } from '../../shared/services/global-notification.service';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(
    private http: HttpClient,
    private notificationService: GlobalNotificationService
  ) { }

  async listOrganizations(page, limit, queryFilter) {
    try {
      let url = environment.api.meta.url + environment.api.organization  + page + '/' + limit;
      url = queryFilter ? url + queryFilter : url;
      const organizations = await this.http.get(url).toPromise();
      return organizations;
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        title: 'Não foi possível carregar as organizações do sistema',
        type: 'error',
        message: e.message
      });
      return [];
    }
  }

  async postOrganizations(data) {
    try {
      const url = environment.api.meta.url + environment.api.organization;
      const organizations: any = await this.http.post(url, data).toPromise();
      return organizations && organizations['data'] ? organizations['data'] : {};
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        title: 'Erro ao salvar as organizações do sistema',
        type: 'error',
        message: 'Não é possivel salvar a organização. Tente novamente ou contate o suporte da LoadShark'
      });
      return [];
    }
  }

  async patchOrganizations(id, data) {
    try {
      const url = environment.api.meta.url + environment.api.organization + id;
      const organizations: any = await this.http.patch(url, data).toPromise();
      return organizations;
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        title: 'Erro ao atualizar as organizações do sistema',
        type: 'error',
        message: 'Não é possivel atualizar a organização. Tente novamente ou contate o suporte da LoadShark'
      });
      return [];
    }
  }

  async deleteOrganizations(id) {
    try {
      const url = environment.api.meta.url + environment.api.organization + '/' + id;
      const organizations: any = await this.http.delete(url).toPromise();
      return organizations;
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        title: 'Erro ao deletar as organizações do sistema',
        type: 'error',
        message: 'Não é possivel deletar a organização. Tente novamente ou contate o suporte da LoadShark'
      });
      return [];
    }
  }

  getCachedOrganizations() {
    return JSON.parse(localStorage.getItem('organizations'));
  }

  async postShippingCompany(data) {
    try {
      const url = environment.api.meta.url + environment.api.shippingCompany;
      const shippingCompany: any = await this.http.post(url, data).toPromise();
      return shippingCompany;
    } catch (e) {
      this.notificationService.notification$.next({
        title: 'Erro ao salvar a transportadora',
        type: 'error',
        message: e.error.message
      });
      return e.error.message;
    }
  }
  async patchShippingCompany(id, data) {
    try {
      const url = `${environment.api.meta.url}${environment.api.shippingCompany}/${id}`;
      const shippingCompany: any = await this.http.patch(url, data).toPromise();
      return shippingCompany;
    } catch (e) {
      this.notificationService.notification$.next({
        title: 'Erro ao atualizar a transportadora',
        type: 'error',
        message: 'Não é possivel atualizar a transportadora. Tente novamente ou contate o suporte da LoadShark'
      });
      return [];
    }
  }
  async removeShippingCompany(id) {
    try {
      const url = `${environment.api.meta.url}${environment.api.shippingCompany}/${id}`;
      const shippingCompany: any = await this.http.delete(url).toPromise();
      return shippingCompany;
    } catch (e) {
      this.notificationService.notification$.next({
        title: 'Erro ao excluir a transportadora',
        type: 'error',
        message: 'Não é possivel excluir a transportadora. Tente novamente ou contate o suporte da LoadShark'
      });
      return [];
    }
  }
  async getOrganization(id) {
    try {
      const url = `${environment.api.meta.url}${environment.api.organization}${id}`;
      const shippingCompany: any = await this.http.get(url).toPromise();
      return shippingCompany;
    } catch (e) {
      this.notificationService.notification$.next({
        title: 'Erro ao buscar a organização',
        type: 'error',
        message: 'Não é possivel buscar a organização. Tente novamente ou contate o suporte da LoadShark'
      });
      return [];
    }
  }

  async findOrganizations(query, page, limit) {
    try {
      const url = `${environment.api.meta.url}${environment.api.organization}/find/${page}/${limit}`;
      const data: any = await this.http.post(url, query).toPromise();
      return data.data;
    } catch (e) {
      this.notificationService.notification$.next({
        title: 'Erro ao buscar a organização',
        type: 'error',
        message: 'Não é possivel buscar a organização. Tente novamente ou contate o suporte da LoadShark'
      });
      return [];
    }
  }

  async getReasons() {
    try {
      const url = `${environment.api.meta.url}${environment.api.userMain}/tenancy/getOrganizationBlockReasons/5d9b8186a3ed43001d58026a`;
      return await this.http.get(url).toPromise();
    } catch (e) {
      this.notificationService.notification$.next({
        title: 'erro ao achar razões',
        type: 'error',
        message: 'Não é possivel buscar uma razão. Tente novamente ou contate o suporte da LoadShark'
      });
      return [];
    }
  }

  getOrganizations(page, limit, queryFilter) {
      let url = `${environment.api.meta.url}${environment.api.organization}${page}/${limit}`;
      url = queryFilter ? url + queryFilter : url;
      return this.http.get<any>(url).pipe(
        map(response => response.data)
      );
  }
}
