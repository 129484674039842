import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoaderService } from '../../../shared/services/loader.service';
import { GlobalNotificationService } from '../../../shared/services/global-notification.service';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { downloadFile, removeDuplicates } from 'src/app/shared/utils';
import moment from 'moment';
import { UserService } from '../../../services/user/user.service';
import { APIDataRegister } from 'src/app/interfaces/ApiResponse';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CheckAuctionService {

  // messages coming from socket to produce real time data
  messages = [];
  private readonly API = `${environment.api.meta.url}${environment.api.auctionLot}`;
  private readonly API_AUCTION_MAIN = `${environment.api.meta.url}${environment.api.auctionMain}`;

  public auctionEvents$ = new Subject<any>();

  constructor(
    private http: HttpClient,
    private loaderService: LoaderService,
    private notificationService: GlobalNotificationService,
    private userService: UserService
  ) { }

  calculateTotalValue(weight, value) {
    let newTotalValue = 0;

    if (weight >= 0 && weight <= 12.5) {
      newTotalValue = value * 12.5;
    } else if (weight > 12.5 && weight <= 15) {
      newTotalValue = value * weight;
    } else if (weight > 15 && weight <= 25) {
      newTotalValue = value * 25;
    } else if (weight > 25) {
      newTotalValue = value * weight;
    }

    return newTotalValue;
  }

  async getAuctionConference(offset, limit, query) {
    try {
      const response = await this.http.get(environment.api.meta.url + environment.api.auctionLot + `getAuctionConference/${offset}/${limit}/${query}`).toPromise();
      return response && response['data'] ? response['data'] : {};
    } catch (e) {
      this.notificationService.notification$.next({
        title: 'Falha ao carregar os dados dos leilões',
        type: 'error',
        message: 'Recarregue a página. ' + e.error.message
      });
    }
  }

  async downloadAuctions(auctions, access = 'embarcador') {
    try {
      const body = this.prepareDataToCSV(auctions, access);
      const data = await this.http.post(environment.api.meta.url + '/auction/v1/lot/downloadCsv/', body, { responseType: 'arraybuffer' }).toPromise();
      downloadFile(data, 'text/csv;charset=utf-8;', 'leilao.csv', true);
      this.notificationService.notification$.next({
        title: 'Download concluído',
        type: 'success',
        message: 'CSV gerado com sucesso!'
      });
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        title: 'Erro ao baixar cargas',
        type: 'error',
        message: e.message
      });
    }
  }

  prepareDataToCSV(auctions, access) {
    if (access === 'embarcador') {
      if (auctions && auctions.length > 0) {
        const translate = {
          identifier: 'Identificador',
          comboType: 'Categoria',
          ots: 'O.Ts',
          origin: 'Origem',
          destination: 'Destino',
          volume: 'Volume',
          type: 'Tipo',
          startDate: 'Dt Inicio',
          endDate: 'Dt Termino',
          totalBids: 'Lances',
          initialValue: 'Valor inicial',
          shippingCompany: 'Transportadora',
          delta: 'Delta',
          lastBid: 'Ultimo lance',
          minBidValue: 'Valor final',
          vehicle: 'Veículo',
          transporter: 'Cod. Transportador',
          shippingDate: 'Dt Expedicao'
        };
        const data = auctions.map(auction => this.transformDataEmbarcador(auction));
        if (data && data.length > 0) {
          return { translate, data };
        }
      }
      return [];
    }
    if (access === 'conferencia') {
      if (auctions && auctions.length > 0) {
        const translate = {
          identifier: 'Identificador',
          ots: 'O.Ts',
          origin: 'Origem',
          destination: 'Destino',
          volume: 'Volume',
          type: 'Tipo',
          startDate: 'Dt Inicio',
          endDate: 'Dt Termino',
          totalBids: 'Lances',
          initialValue: 'Valor inicial',
          shippingCompany: 'Transportadora',
          delta: 'Delta',
          lastBid: 'Ultimo lance',
          minBidValue: 'Valor final',
          vehicle: 'Veículo',
          transporter: 'Cod. Transportador',
          shippingDate: 'Dt Expedicao',
          loadDate: 'Dt Carregamento'
        };
        const data = auctions.map(auction => this.transformDataConferencia(auction));
        if (data && data.length > 0) {
          return { translate, data };
        }
      }
      return [];
    } else {
      if (auctions && auctions.length > 0) {
        const translate = {
          comboType: 'Categoria',
          ots: 'O.Ts',
          origin: 'Origem',
          destination: 'Destino',
          volume: 'Volume',
          type: 'Tipo',
          shippingDate: 'Dt Expedicao',
          totalBids: 'Lances',
          initialValue: 'Valor inicial',
          lastBid: 'Ultimo lance',
          finalValue: 'Valor final',
        };
        const data = auctions.map(auction => this.transformDataTransportadora(auction));
        if (data && data.length > 0) {
          return { translate, data };
        }
      }
      return [];
    }
  }

  transformDataConferencia(auction) {
    const lot = {};
    lot['identifier'] = auction && auction.identifier ? auction.identifier : null;
    lot['ots'] = auction && auction.ots ? auction.ots : null;
    lot['origin'] = auction && auction.origin ? auction.origin : null;
    lot['destination'] = auction && auction.destination ? auction.destination : null;
    lot['volume'] = auction && auction.volume ? auction.volume : null;
    lot['type'] = auction && auction.type === 'reverse' ? 'Reverso' : null;
    lot['startDate'] = auction && auction.startDate ? moment(new Date(auction.startDate)).add(3, 'h').format('DD/MM/YYYY - HH:mm') : null;
    lot['endDate'] = auction && auction.endDate ? moment(new Date(auction.endDate)).add(3, 'h').format('DD/MM/YYYY - HH:mm') : null;
    lot['totalBids'] = auction && auction.totalBids ? auction.totalBids : null;
    lot['initialValue'] = auction && auction.initialValue ? auction.initialValue : null;
    lot['shippingCompany'] = auction && auction.shippingCompany ? auction.shippingCompany : null;
    lot['delta'] = auction && auction.delta ? auction.delta : null;
    lot['lastBid'] = auction && auction.lastBid ? auction.lastBid : null;
    lot['minBidValue'] = auction && auction.minBidValue ? auction.minBidValue : null;
    lot['shippingDate'] = auction && auction.shippingDate ? moment(new Date(auction.shippingDate)).add(3, 'h').format('DD/MM/YYYY - HH:mm') : null;
    lot['loadDate'] = auction && auction.loadDate ? moment(new Date(auction.loadDate)).add(3, 'h').format('DD/MM/YYYY - HH:mm') : null;
    lot['vehicle'] = auction && auction.vehicle ? auction.vehicle : null;
    lot['transporter'] = auction && auction.transporter ? auction.transporter : null;
    return lot;
  }
  transformDataEmbarcador(auction) {
    const lot = {};
    lot['identifier'] = auction && auction.auction ? auction.auction.number : null;
    lot['comboType'] = auction.comboType === 'combo' ? 'Combo' : auction.comboType === 'roadTrain' ? 'Rodotrem' : 'Simples';
    lot['ots'] = this.getOts(auction);
    lot['origin'] = auction.origin;
    lot['destination'] = auction.destination;
    lot['volume'] = auction.finalWeight;
    lot['type'] = 'Reverso';
    lot['startDate'] = auction && auction.auction ? moment(new Date(auction.auction.plannedStart)).add(3, 'h').format('DD/MM/YYYY - HH:mm') : null;
    lot['endDate'] = auction && auction.auction ? moment(new Date(auction.auction.plannedEnd)).add(3, 'h').format('DD/MM/YYYY - HH:mm') : null;
    lot['totalBids'] = auction.totalBids;
    lot['initialValue'] = auction.pricePerTon;
    lot['shippingCompany'] = auction.organization && auction.organization.name ? auction.organization.name : '-';
    lot['delta'] = auction.delta;
    lot['lastBid'] = auction.minBidValue;
    lot['minBidValue'] = auction.minBidValue;
    lot['shippingDate'] = moment(auction.shippingDate).add(3, 'h').format('DD/MM/YYYY - HH:mm');
    lot['vehicle'] = '-';
    if (auction?.deal?.vehicle) {
      lot['vehicle'] = auction.deal.vehicle;
    }
    lot['transporter'] = '-';
    if (auction?.deal?.transporter) {
      lot['transporter'] = auction.deal.transporter;
    }
    return lot;
  }

  transformDataTransportadora(auction) {
    const lot = {};
    lot['comboType'] = auction.comboType === 'combo' ? 'Combo' : auction.comboType === 'roadTrain' ? 'Rodotrem' : 'Simples';
    lot['ots'] = auction.ots;
    lot['origin'] = auction.origin;
    lot['destination'] = auction.destination;
    lot['volume'] = auction.weight;
    lot['type'] = 'Reverso';
    lot['totalBids'] = auction.totalBids;
    lot['initialValue'] = auction.pricePerTon.toFixed(2);
    lot['shippingDate'] = moment(auction.shippingDate).add(3, 'h').format('DD/MM/YYYY - HH:mm');
    lot['finalValue'] = this.calculateTotalValue(auction.weight, auction.minBidValue).toFixed(2);
    return lot;
  }

  getOts(auction) {
    let ots = [];
    if (!auction || !auction.loads || auction.loads.length === 0) {
      return ots;
    }
    auction.loads.map(l => {
      ots = ots.concat(l.ots);
    });
    removeDuplicates(ots);
    return ots;
  }
}
