import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DarkModeService } from '@services/dark-mode.service';
import { BehaviorSubject, catchError, Observable, of, tap } from 'rxjs';
import { User } from 'src/app/models';
import { ConfirmTermsService } from 'src/app/plugins/confirm-terms-modal/services/confirm-terms.service';
import { UserService } from 'src/app/services/user/user.service';
import { ProfileService } from 'src/app/shared/services/profile.service';
import { WebsocketService } from 'src/app/shared/services/websocket.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _isLoggedUser$;

  private readonly url = `${environment.api.meta.url}${environment.api.auth}`;

  constructor(
    private http: HttpClient,
    private router: Router,
    private webSocketService: WebsocketService,
    private userService: UserService,
    private confirmTermsService: ConfirmTermsService,
    private profileService: ProfileService,
    private darkMode: DarkModeService
  ) {
    const isLogged = !!this.userService.getLoggedUser();
    this._isLoggedUser$ = new BehaviorSubject<boolean>(isLogged);
  }

  public get isLogged() {
    return this._isLoggedUser$.value;
  }

  public get isLoggedUser(): Observable<boolean> {
    return this._isLoggedUser$;
  }

  public setIsLoggedUser(value: boolean) {
    this._isLoggedUser$.next(value);
  }

  public getLoggedUser() {
    const cookies = document.cookie.split(';');
    let claim = cookies.find(c => c.includes('user-info'));
    if (claim) {
      claim = claim.split('user-info=')[1];
      if (claim) {
        return JSON.parse(decodeURIComponent(claim));
      }
    }
    return null;
  }

  login({email, password, tenancy, onlyCheck = false}) {
    return this.http.post<User>(`${this.url}email`, {
      email,
      password,
      tenancy,
      expires: 432000
    }).pipe(
      tap(_ => {
        if (!onlyCheck) {
          this.removeTokenAsExpired();
          this.setIsLoggedUser(true);
          this.profileService.saveProfilesInLocalStorage();
        }
      }),
      catchError(err => this.handleError(err, email))
    );
  }

  public logout({ withoutRedirect } = { withoutRedirect: false }, callback?) {
    this.http.get(`${this.url}logout`).subscribe({
      next: _ => {
        this.resetLoggedState();
        if (!withoutRedirect) {
          this.router.navigate(['login']);
        }
        if (callback) {
          callback();
        }
      },
      error: e => {
        console.error(e);
        document.cookie = 'Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        document.cookie = 'user-info=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        this.resetLoggedState();
        if (callback) {
          callback();
        }
      }
    });
  }

  public resetLoggedState() {
    window.localStorage.clear();
    this.webSocketService.disconnect();
    this.setIsLoggedUser(false);
    window.localStorage.setItem('theme', this.darkMode.colorMode);
  }

  public setTokenAsExpired(isExpired?) {
    if (isExpired) {
      window.localStorage.setItem('tokenExpired', isExpired);
    } else {
      window.localStorage.removeItem('tokenExpired');
    }
  }

  public removeTokenAsExpired() {
    window.localStorage.removeItem('tokenExpired');
  }

  public isTokenExpired() {
    return window.localStorage.getItem('tokenExpired');
  }

  public mountUserAfterAuthenticate() {
    this.startWebSocketConnection();
    this.profileService.saveProfilesInLocalStorage();
  }

  public startWebSocketConnection() {
    const user = this.getLoggedUser();
    this.webSocketService.initSocket();
    if (user) {
      this.webSocketService.setUserConnection(user._id);
    }
  }

  public verifyTransporterIsValid() {
    const user = this.getLoggedUser();

    if (user && user.transporter) {
      const hasntUserVerification = !user.userVerification;

      if (hasntUserVerification) {
        return this.confirmTermsService.toggleModal();
      }

      if (user.userVerification && (!user.userVerification?.accordingTo || !user.userVerification?.infoConfirmed || !user.userVerification?.readAndAccepted)) {
        return this.confirmTermsService.toggleModal();
      }
    }
  }

  private handleError(err: any, email?: string) {
    if (err.error?.errors && err.error?.errors.length) {
      const { code } = err.error.errors[0];
      if (code && code === 3079) {
        this.userService.sendEmailValidation(email);
      }
    }
    if (err.status === 500) {
      this.router.navigate(['/login']);
    } else {
      throw err;
    }
    return of(err);
  }

}
