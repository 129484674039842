import { Directive, EventEmitter, Input, Output } from '@angular/core';

@Directive()
export class InputOld {
  @Input() validationMessage: string;
  @Input() helpMessage: string;
  @Input() min: number;
  @Input() max: number;
  @Input() step: number;
  @Input() isDisabled: boolean;
  @Input() validationError: boolean;
  @Input() validationMask = true;
  @Output() handleKeyup = new EventEmitter();
  @Output() valueChange = new EventEmitter();
  @Output() handleBlur = new EventEmitter();
}

export type OldType =  'cpf' | 'cnpj' | 'cpfcnpj' | 'cep' | 'plate' | 'currency' | 'date' | 'time' | 'phone';
