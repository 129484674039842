import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoaderService } from '../../../shared/services/loader.service';
import { GlobalNotificationService } from '../../../shared/services/global-notification.service';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { downloadFile, removeDuplicates } from 'src/app/shared/utils';
import moment from 'moment';
import { UserService } from '../../../services/user/user.service';
import { APIDataRegister } from 'src/app/interfaces/ApiResponse';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EditProfileService {

  // messages coming from socket to produce real time data
  messages = [];
  private readonly API = `${environment.api.meta.url}${environment.api.auctionLot}`;
  private readonly API_AUCTION_MAIN = `${environment.api.meta.url}${environment.api.auctionMain}`;

  constructor(
    private http: HttpClient,
    private loaderService: LoaderService,
    private notificationService: GlobalNotificationService,
    private userService: UserService
  ) { }

  async getVehicleTypes(offset = 0, limit = 500) {
    try {
      const response = await this.http.get(environment.api.meta.url + `/user/v1/vehicle/${offset}/${limit}`).toPromise();
      return response && response['data'] ? response['data'] : [];
    } catch (error) {
      this.notificationService.notification$?.next({
        title: 'Falha ao carregar os tipos de veículo',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  async setConfiguration(configuration) {
    try {
      const response = await this.http.post(environment.api.meta.url + '/auction/v1/configuration/setConfiguration', configuration).toPromise();
      return response && response['data'] ? response['data'] : [];
    } catch (error) {
      this.notificationService.notification$?.next({
        title: 'Falha ao salvar a configuração de perfil',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  async getTransporterProfileData() {
    try {
      const response = await this.http.get(environment.api.meta.url + '/auction/v1/configuration/getConfiguration').toPromise();
      return response && response['data'] ? response['data'] : null;
    } catch (error) {
      console.error(error);
    }
  }
}
