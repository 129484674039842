import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-labeled-data',
  templateUrl: './labeled-data.component.html',
  styleUrls: ['./labeled-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabeledDataComponent implements OnInit {

  @Input() id: string;
  @Input() label: string;
  @Input() data: string | number;

  constructor() { }

  ngOnInit(): void {
  }

}
