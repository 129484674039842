<div *ngIf="isVisibleForm" id="notification" class="notification is-info">
    <div class="is-size-4 header">
      <span>
        Nos ajude a melhorar o Loadshark :)
      </span>
    </div>
    <button (click)="closeForm()" class="delete"></button>
    <br>
    <div class="body is-size-6">
        <p>
          Olá {{ user.name }}, tudo bem? Responda nossa pesquisa rápida e nos ajude a tornar o Loadshark cada vez melhor para você.
        </p>

        <br>
        <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdwoG5S0sZL8vG4kYo9VeyXWsjJYZVCW6fQIALazufoiVUdsA/viewform">
          <button class="button is-light" >
            <strong>
                Questionário
            </strong>
          </button>
        </a>
        
    </div>
</div>
