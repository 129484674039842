import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from '../services/user/user.service';

@Injectable({ providedIn: 'root' })
export class AppPreloadingStrategy implements PreloadingStrategy {

    constructor(private userService: UserService) {}

    preload(route: Route, load: () => Observable<any>): Observable<boolean | null> {
      const hasGoodNetwork = this.preloadByNetwork();
      if (hasGoodNetwork) {
        return this.preloadByProfile(route.data?.allowedProfiles) ? load() : of(null);
      }
      return of(null);
    }

    preloadByProfile(allowedProfiles: string[]) {
      const userProfiles = this.userService.getUserProfiles();
      if (allowedProfiles && userProfiles) {
        if (allowedProfiles.includes('*')) { return true; }
        return allowedProfiles.some(p => userProfiles.includes(p));
      }
      return false;
    }

    preloadByNetwork() {
      const connection = navigator['connection'];
      if (connection?.saveData) {
        return false;
      }
      const speed = connection?.effectiveType;
      const slowConnections = ['slow-2g', '2g'];
      if (slowConnections.includes(speed)) {
        return false;
      }
      return true;
    }

}
