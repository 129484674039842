<app-modal *ngIf="isOpen" [noHeader]="true" [noClose]="true">
    <div class="consent-term">
        <h1 class="large-text has-text-weight-bold">Termos e Condições Gerais do Contrato de Transporte Rodoviário de Cargas
            Paletizadas para o Mercado Interno.</h1>
        <div class="terms">
            Este Termos e Condições Gerais do Transporte Rodoviário de Cargas Paletizadas para o Mercado Interno
            (“T&C”), que as Partes reconhecem como válido, eficaz e vinculante, estabelece os termos e condições gerais
            para negociação de valores a serem pagos pela BRASKEM S.A. (“CONTRATANTE”) à transportadora contratada
            (“CONTRATADA”) pela realização dos serviços de transporte nas rotas constantes no Anexo II do Contrato de
            Transporte Rodoviário de Cargas Paletizadas firmado entre a CONTRATANTE e a CONTRATADA, denominadas, cada
            uma, “Parte” ou, em conjunto, “Partes”. Este T&C não substitui e/ou sobrepõe as condições estabelecidas
            entre as Partes no Contrato firmado entre as mesmas, em especial, as obrigações de contratação de seguro,
            exceto com relação a solicitação de realização de rotas, pela CONTRATANTE, e aos valores a serem pagos pela
            CONTRATANTE por rota do Anexo II a ser realizada pela CONTRATADA, mediante utilização do portal eletrônico
            denominado “LoadShark”, desenvolvido e disponibilizado pela CONTRATANTE.

            <p><strong>1. Consentimento.</strong> A aceitação deste T&C pela CONTRATADA se dará por meio do aceite a ser
                realizado no
                LoadShark.</p>

            <p><strong>2. Rotas e Pagamento.</strong> A CONTRATADA passará a ter acesso às rotas do Anexo II via
                LoadShark da CONTRATANTE,
                onde terá a possibilidade de, junto a outras transportadoras, selecionar dentre as rotas
                disponibilizadas
                aquelas que tiver interesse para prestar serviços. As rotas apresentarão um valor inicial, cabendo à
                CONTRATADA realizar a sua proposta de valor para aquela rota no LoadShark. A nova proposta de valor
                apresentada pela CONTRATADA será avaliada pela CONTRATANTE no prazo de até 20 (vinte) horas úteis,
                contadas
                a partir do encerramento do período para envio de propostas, momento em que será apurado o ganhador da
                rota,
                que será aquele que apresentar a proposta de menor valor para a rota em questão. Ficará prorrogado
                automaticamente, por mais 1 minuto, o período de envio de propostas caso, no minuto que anteceder ao fim
                do
                período de envio, seja enviada nova proposta de menor valor. Após o referido período, o resultado da
                apuração, que informará os não vencedores, ficará disponível no LoadShark.</p>

            <p><strong>3. Base de cálculo.</strong> Para cálculo do valor total da rota em questão, a CONTRATADA deverá
                considerar o peso
                líquido informado pela CONTRATANTE no LoadShark, respeitando a quantidade mínima de 25 (vinte e cinco)
                toneladas, quando tratar-se do tipo de veículo carreta, e 12,5 (doze vírgula cinco) toneladas, quando
                tratar-se de veículo do tipo Truck. Acima dos referidos volumes serão calculados pelo peso líquido
                carregado. Para oferecimento da proposta de valor da rota em questão a CONTRATADA deverá considerar a
                apresentação de valor com todos os impostos , exceto ISS/ICMS que deverá ser acrescido conforme
                legislação.
                Assim, como determinado contratualmente, nos preços estão incluídos todos os custos de direção,
                supervisão,
                mão-de-obra direta e indireta, adicionais (inclusive de periculosidade/insalubridade), insumos, encargos
                sociais, trabalhistas e previdenciários, tributos, ferramentas, utensílios, máquinas, combustíveis e
                lubrificantes, contribuições sociais, licenças e emolumentos fiscais entre outros, bem como a
                remuneração e
                o lucro da CONTRATADA.</p>

            <p><strong>4. Frete de Retorno.</strong> Caso seja necessário retornar com o Produto transportado para a
                origem por motivo
                alheio à conduta da CONTRATADA, a CONTRATANTE pagará à CONTRATADA o valor de frete determinado no Anexo
                II
                da rota em questão.</p>

            <p><strong>5. Independência das Disposições.</strong> Caso alguma disposição deste T&C venha a ser declarada
                ou considerada
                ilegal, inexequível ou nula, as demais disposições permanecerão válidas e obrigatórias.</p>

            <p><strong>6. Cessão.</strong> A CONTRATADA não poderá ceder, total ou parcialmente, a terceiros, os
                direitos e obrigações
                decorrentes desta relação jurídica de prestação de serviço.</p>

            <p><strong>7. Confidencialidade.</strong> As condições estabelecidas por meio deste T&C são sigilosas e não
                podem ser divulgadas
                a terceiros.</p>

            <p><strong>8. Terminação.</strong> Nos casos de descumprimento de qualquer das obrigações estipuladas neste
                T&C, a CONTRATANTE
                poderá rescindir a referida relação jurídica prestação de serviço de transporte, mediante bloqueio de
                acesso
                ao LoadShark.</p>

            <p><strong>9. Capacidade e exclusividade.</strong> Assim como estabelecido contratualmente, a CONTRATADA
                declara que é capaz de
                organizar a sua frota de modo a atender os fluxos e rotas apesentadas no “LoadShark”, inclusive no que
                tange
                à capacidade e adequação dos veículos, mediante a realização de manutenção preditiva e corretiva,
                obtenção
                de todas as licenças e autorizações, RNTRC, SASSMAQ, entre outras obrigações previstas no Contrato.
                Ainda,
                assim como o Contrato, este T&C é firmado sem exclusividade para ambas as Partes, podendo a CONTRATANTE
                optar pela contratação de outras empresas para realização dos serviços de transporte na rota em que a
                CONTRATADA tenha interesse de realizar/tenha apresentado proposta de valor. A apresentação, pela
                CONTRATADA,
                de proposta de valor pela rota não representa qualquer obrigação da CONTRATANTE em contrata-la para a
                prestação do serviço.</p>

            <p><strong>10. Ratificação.</strong> As Partes ratificam mutuamente todos os outros termos e condições do
                Contrato e seus
                aditivos, os quais permanecerão em pleno vigor e efeito e aplicar-se-ão igualmente a estes T&C,
                aplicáveis
                para as contratações feitas através do LoadShark.</p>

            <p><strong>11. Lei Aplicável e Foro.</strong> As Partes concordam e reconhecem expressamente que esta
                relação jurídica
                estabelecida por este T&C, será regida e interpretada de acordo com a legislação da República Federativa
                do
                Brasil. Fica eleito o Foro da Comarca da Capital do Estado de São Paulo para dirimir eventuais
                controvérsias
                oriundas desta relação jurídica de venda, com renúncia expressa de qualquer outro, por mais privilegiado
                que
                seja.</p>

        </div>
        <div class="is-flex is-justify-content-flex-end">
            <a class="button is-small is-white" [attr.href]="downloadLink" target="_blank"
                download="Termos e Condições Gerais de Transporte Rodoviário.pdf">
                <span class="icon is-small">
                    <i class="material-icons is-medium">save_alt</i>
                </span>
                <span>Fazer o download deste termo</span>
            </a>
        </div>
        <label class="checkbox large-text">
            <input type="checkbox" (change)="allowToContinue()">
            Estou ciente e concordo com o termo acima.
        </label>
        <button (click)="continue()" [disabled]="!canContinue" class="button is-primary is-rounded is-medium">
            Continuar
        </button>
    </div>
</app-modal>