import { Component, Input, OnInit } from '@angular/core';

type Level = 1 | 2 | 3 | 4 | 5;

type Variant = 'title' | 'subtitle'

@Component({
  selector: 'app-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss']
})
export class HeadingComponent implements OnInit {

  @Input()
  level: Level = 2;

  @Input()
  variant: Variant = 'title';

  constructor() { }

  ngOnInit(): void {
  }

}
