/* eslint-disable @typescript-eslint/member-ordering */
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';

export interface ContextMenuItem {
  label: string;
  onClick: () => void;
}

export interface ContextMenuConfig {
  label: string;
  items: ContextMenuItem[];
}

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent implements OnInit {

  @Input() ctxMenuConfig: ContextMenuConfig[];
  @Input() profiles: string[];

  toggleMenuVisible = false;

  _contextMenuX = 0;
  _contextMenuY = 0;

  constructor(private userService: UserService) { }

  set contextMenuY(val) {
    this._contextMenuY = val;
  }

  get contextMenuY() {
    return this._contextMenuY;
  }

  set contextMenuX(val) {
    this._contextMenuX = val;
  }

  get contextMenuX() {
    return this._contextMenuX;
  }

  get hasProfiles(): boolean {
    const profiles = this.userService.getUserProfiles();
    return this.profiles.some(p => profiles.includes(p));
  }

  @HostListener('document:contextmenu', ['$event'])
  rightClick(event) {
    if (this.hasProfiles) {
      event.preventDefault();
      const { x, y } = event;

      this.setContextMenuPosition(x, y);
      this.toggleContextMenu();
    }
  }

  ngOnInit(): void {
  }

  setContextMenuPosition(x, y) {
    this.contextMenuX = x;
    this.contextMenuY = y;
  }

  toggleContextMenu() {
    this.toggleMenuVisible = !this.toggleMenuVisible;
  }

  handleCtxMenuClick(handler) {
    if (handler) {
      handler();
    }
    this.toggleContextMenu();
  }

  handleClickOutside() {
    if (this.hasProfiles) {
      this.toggleMenuVisible = false;
    }
  }

}
