<div class="wrapper">
  <button *ngIf="currentItem >= offsetLimit" (click)="navigateLeft()" class="arrow-left" aria-label="Previous">
    <span darkMode class="material-icons is-size-1">
      chevron_left
    </span>
  </button>
  <button *ngIf="maxItems > 3" (click)="navigateRight()" class="arrow-right" aria-label="Next">
    <span darkMode class="material-icons is-size-1">
      chevron_right
    </span>
  </button>
  <div class="carousel-wrapper">
    <div class="carousel">
      <ng-content></ng-content>
    </div>
  </div>
</div>
