import { v4 as uuidV4 } from 'uuid';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent {
  @Input() label: string;
  @Input() name: string;
  @Input() value: string;
  @Input() control: UntypedFormControl;

  @Output() changeSelect = new EventEmitter();

  _id = uuidV4();

  constructor() { }
}
