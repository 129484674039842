import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-auction-skeleton',
  templateUrl: './auction-skeleton.component.html',
  styleUrls: ['./auction-skeleton.component.scss']
})
export class AuctionSkeletonComponent implements OnInit {

  @Input() numberOfCards = 3;
  @Input() numberOfLiCardLines = 3;

  Array = Array;

  constructor() { }

  ngOnInit() {
  }

}
