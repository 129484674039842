/*
  How to create a new profile:

  - Create a profile in our database or using profiles screen.
  - Add here the new profile to the Profiles enum using the following pattern:

    [Persona] - [modulo] [submodulo]      (Use capitalize pattern/only first letter capitalized).

    i.e: Transportadora - repasse L&G
*/

export enum Profiles {
  Administrador = 'Administrador',
  Embarcador = 'Embarcador',
  Transportadora = 'Transportadora',
  AdministradorTransportadora = 'Administrador transportadora',
  EmbarcadorAdmin = 'Embarcador - admin',
  EmbarcadorPlanejador = 'Embarcador - planejador',
  EmbarcadorRepasse = 'Embarcador - repasse',
  EmbarcadorMonitoramento = 'Embarcador - monitoramento',
  EmbarcadorGestaoDedicado = 'Embarcador - gestão dedicado',
  TransportadoraRepasseResinas = 'Transportadora - repasse (resinas)',
  TransportadoraAdminPerfil = 'Transportadora admin - perfil',
  TransportadoraOperador = 'Transportadora - operador',
  CustomerServicesMonitoramento = 'Customer services - monitoramento',
  EmbarcadorLeilao = 'Embarcador - leilão',
  TransportadoraLeilao = 'Transportadora - leilão',
  ConferenciaLeilao = 'Embarcador - conferência leilão',
  EmbarcadorTracking = 'Operador tracking',
  TransportadoraTracking = 'Transportador tracking',
  EmbarcadorEtanol = 'Embarcador - descarga etanol',
  TransportadoraEtanol = 'Transportadora - descarga etanol',
  OperadorEtanol = 'Operador - descarga etanol',
  GerenteOperadorEtanol = 'Gerente operador - descarga etanol',
  TransportadoraLeilaoRotas = 'Transportadora - leilão rotas',
  EmbarcadorLeilaoRotas = 'Embarcador - leilão rotas',
  EmbarcadorAgendamento = 'Embarcador - agendamento',
  EmbarcadorAgendamentoLeitura = 'Embarcador - agendamento (somente leitura)',
  TransportadoraAgendamento = 'Transportadora - agendamento',
  TransportadoraRepasseLG = 'Transportadora - repasse (l&g)',
  Fornecedor = 'Fornecedor',
  FornecedorAgendamento = 'Fornecedor - agendamento',
}

export type Profile = keyof typeof Profiles;
