import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuctionConsentService } from 'src/app/features/auction/auction-consent-term/auction-consent.service';

@Component({
  selector: 'app-auction-consent-term',
  templateUrl: './auction-consent-term.component.html',
  styleUrls: ['./auction-consent-term.component.scss']
})
export class AuctionConsentTermComponent implements OnInit {

  isOpen = true;
  canContinue = false;

  downloadLink = null;

  @Output() handleSave = new EventEmitter();

  constructor(private auctionConsentService: AuctionConsentService) { }

  ngOnInit(): void {
    this.getDownloadLink();
  }

  allowToContinue() {
    this.canContinue = !this.canContinue;
  }

  continue() {
    this.isOpen = true;
    this.handleSave.emit();
  }

  async getDownloadLink() {
    const response = await this.auctionConsentService.downloadAuctionTerm();
    if (response && response.link) {
      this.downloadLink = response.link;
    }
  }

}
