import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

  @Input() title: string;
  @Input() size: 'default' | 'large';
  @Input() isOpen: boolean;
  @Input() isMedium: boolean;
  @Input() isSmall: boolean;
  @Input() noPadding: boolean;
  @Input() noScroll = false;
  @Input() noHeader = false;
  @Input() noClose = false;
  @Output() isOpenChanged = new EventEmitter<boolean>();

  constructor() {
  }

  private updateIsOpen() {
    this.isOpen = !this.isOpen;
    this.isOpenChanged.emit(this.isOpen);
  }

  public modalDismiss() {
    this.updateIsOpen();
  }
}
