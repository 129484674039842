import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: [ './bar-chart.component.scss' ],
})

export class BarChartComponent implements OnInit {

  @Input() data: any[];
  @Input() options;
  @Input() width: number;
  @Input() height: number;
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  public barChartOptions: ChartConfiguration['options'];

  public barChartType: ChartType = 'bar';
  public barChartPlugins = [{
    beforeInit: (chart, chartOptions) => {
      chart.legend.afterFit = () => {
        if (chart.legend.margins) {
          chart.height += 10;
        }
      };
    }
  }];

  public barChartData: ChartData<'bar'>;

  ngOnInit(): void {
  }

}
