
import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[clickOutside]'
})
export class ClickOutsideDirective {

  @Output() clickOutside = new EventEmitter<MouseEvent>();
  @Input() ignoreClickInClass: string;

  constructor(private _elementRef: ElementRef) { }

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: (MouseEvent | any), targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }

    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    const ignoreClick = this.ignoreClickInClass ? !!event?.path?.find((item) => item?.className?.includes(this.ignoreClickInClass)) : false;

    if (!clickedInside && !ignoreClick) {
      this.clickOutside.emit(event);
    }
  }
}
