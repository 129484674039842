import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

type ColorMode = 'light' | 'dark';

const prefersColorScheme = window.matchMedia('(prefers-color-scheme: dark)');
const savedColorTheme = window.localStorage.getItem('theme') as ColorMode;

@Injectable({
  providedIn: 'root'
})
export class DarkModeService {

  _colorMode: ColorMode = savedColorTheme ? savedColorTheme : (prefersColorScheme.matches ? 'dark' : 'light');
  toggled$ = new BehaviorSubject<boolean>(this._colorMode === 'dark');

  constructor() { }

  get isDark() {
    return this._colorMode === 'dark';
  }

  get colorMode() {
    return this._colorMode;
  }
  set colorMode(value: ColorMode) {
    this._colorMode = value;
  }

  toggleDarkMode() {
    const isCurrentDark = this._colorMode === 'dark';
    this._colorMode = isCurrentDark ? 'light' : 'dark';
    this.toggled$.next(!isCurrentDark);
    window.localStorage.setItem('theme', this._colorMode);
  }

}
