import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UserService } from '../services/user/user.service';
import { ProfileService } from '../shared/services/profile.service';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuardService implements CanActivate {

  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private profileService: ProfileService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isAuthenticated = this.userService.getLoggedUser();
    const params = route.queryParams;

    if (isAuthenticated) {
      this.profileService.saveProfilesInLocalStorage().subscribe({
        next: _ => {
          const response = this.handleRedirect(isAuthenticated, route, state);
          return response;
        },
        error: e => {
          console.error(e);
          return false;
        }
      });
    } else {
      return this.handleRedirect(isAuthenticated, route, state);
    }
  }

  handleRedirect(isAuthenticated, route, state) {
    if (isAuthenticated) {
      if (this.handleSingleLogOut(route, state)) { return true; }
      this.userService.redirectUser(route, state);
    } else if (!isAuthenticated && !state.url.includes('/login')) {
      this.router.navigate(['/login'], { queryParams: route.queryParams });
    }
    return true;
  }

  handleSingleLogOut(route, state): boolean {
    if (!route.queryParams) { return false; }
    if (route.queryParams.action && route.queryParams.action === 'logout') {
      this.authService.logout();
      return true;
    }
    this.userService.redirectUser(route, state);
    return false;
  }
}
