<section class="hero">
  <div class="hero-body banner"
  [ngStyle]="{
    'background-image': background,
    'min-height.px': height
  }">
    <h1 [ngStyle]="{'color': textColor}" [innerHTML]="title">
    </h1>

    <h4 [ngStyle]="{'color': textColor}" [innerHTML]="subtitle">
    </h4>

    <app-button
    [icon]="buttonIcon"
    [title]="buttonTitle"
    [disabled]="buttonDisabled"
    (handleClickEmitter)="handleCTAEmitter.emit()"
    >
    </app-button>
  </div>
</section>
