import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitItems'
})
export class LimitItemsPipe implements PipeTransform {

  transform(value: unknown, initial: number, limit: number, ellipsis?: boolean): unknown {
    if (!value) { return null; }
    if (!Array.isArray(value)) { return value; }
    if (ellipsis && value.length > limit) {
      const items = value.slice(initial, limit);
      items.push('...');
      return items;
    }
    return value.slice(initial, limit);
  }

}
