import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { delay } from 'rxjs';
import { OnlineService } from 'src/app/services/online/online.service';

@Component({
  selector: 'app-connection-status',
  templateUrl: './connection-status.component.html',
  styleUrls: ['./connection-status.component.scss'],
  imports: [CommonModule],
  standalone: true
})
export class ConnectionStatusComponent implements OnInit {

  isOnline = true;
  isLogged = false;

  constructor(private onlineService: OnlineService, private route: Router) { }

  ngOnInit(): void {
    this.route.events.subscribe(event => {
      if(event instanceof NavigationStart){
        const blacklist = ['login', 'reset-password', 'forgot-password', 'cadastro'];
        this.isLogged = !blacklist.some(route => event.url.includes(route));
       }
     }
 );
    this.onlineService.isOnline$
      .pipe(delay(1500))
      .subscribe({
        next: value => this.isOnline = value
      });
  }

}
