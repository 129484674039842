<div class="wrapper" [attr.data-show-scroll]="canScroll">
  <div *ngIf="scrollLeft > 0" darkMode class="scroll-icon">
    <button (click)="navigateLeft()">
      <i class="material-icons">
        chevron_left
      </i>
    </button>
  </div>
  <div darkMode 
  *ngIf="tabs && tabs.length && show"
  #tabsEl 
  (mousemove)="handleDragging($event)"
  (mouseup)="stopDragging()"
  (mousedown)="isDragging = true"
  (touchmove)="handleTouchMove($event)"
  (touchstart)="isDragging = true"
  (touchend)="stopDragging()"
  class="tabs is-medium no-margin"
  [ngClass]="{'dark': isDark, 'large': isLarge, 'dragging': isDragging}">
    <ul [ngClass]="{'no-border': noBorder}">
      <ng-container *ngFor="let tab of tabs">      
        <li (click)="changeTab(tab)" [ngClass]="{'is-active has-text-weight-bold': tab.isActive}">
          <a darkMode [title]="tab.title">
            <ng-container *ngIf="!onlyIcon">
              {{ tab.title }}
            </ng-container>
            <ng-container *ngIf="tab.icon">
              <i class="material-icons ml-1">
                {{ tab.icon }}
              </i>
            </ng-container>
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
  <div *ngIf="scrollLeftWidth" darkMode class="scroll-icon">
    <button (click)="navigateRight()">
      <i class="material-icons">
        chevron_right
      </i>
    </button>
  </div>
</div>
