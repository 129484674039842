<div class="wrapper">
  <div class="slide-wrapper" [ngStyle]="{'height': size}">
    <div class="carousel">
      <ng-container *ngFor="let slide of slides; let i = index">
        <img [ngClass]="{'visible': i === currentSlide, 'hidden': i !== currentSlide}" [src]="slide.src" class="slide"
          [ngStyle]="{'object-fit': noCover ? 'contain' : 'cover'}" />
      </ng-container>

      <!-- controls -->
      <div *ngIf="!noControls" class="arrow-container">
        <button class="control prev" (click)="onPreviousClick()">
          <span class="arrow left"></span>
        </button>
        <button class="control next" (click)="onNextClick()">
          <span class="arrow right"></span>
        </button>
      </div>

      <div class="carousel-panel">
        <ng-container *ngIf="slides && slides[currentSlide] && slides[currentSlide].subtitle">
          <div class="has-text-centered">
            <span class="is-size-4 has-text-weight-semibold has-text-black">{{slides[currentSlide].subtitle}}</span>
          </div>
        </ng-container>

        <ng-container *ngIf="slides && slides[currentSlide] && slides[currentSlide].legend">
          <div class="legend has-text-centered">
            <span class="is-size-5">{{slides[currentSlide].legend}}</span>
          </div>
        </ng-container>

        <!-- slides circles -->
        <div class="slide-dots-container">
          <ng-container *ngFor="let slide of slides; let i=index">
            <div class="dot is-clickable" [ngClass]="{'active': currentSlide === i}" (click)="changeSlide(i)"></div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
