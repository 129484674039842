import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(
    private location: Location,
    private userService: UserService
  ) { }

  ngOnInit(): void {
  }

  goBack = () => {
    this.location.historyGo(-2);
  };

  goHome = () => {
    this.redirectUser();
  };

  redirectUser() {
    this.userService.handleDefaultRedirect();
  }

  isUserFirstAccess() {
    const user = this.userService.getLoggedUser();
    const userObj = user ? JSON.parse(user) : null;
    return userObj && userObj.countAccess === 1;
  }
}
