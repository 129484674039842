<aside darkMode class="ls-menu"
(clickOutside)="toggleItem(-1)"
[ngClass]="{'toggled': isOpen}">
  <!-- Cabeçalho do menu -->
  <header class="ls-menu__header" [ngClass]="{'is-align-items-center': !isOpen}">

    <div class="is-flex is-justify-content-space-between">
      <div darkMode class="logo" [ngClass]="{'toggled': isOpen }"></div>
      <app-button
      *ngIf="isOpen"
      [icon]="isDarkMode ? 'light_mode' : 'dark_mode'"
      iconShape="square"
      [onlyIcon]="true"
      (handleClickEmitter)="toggleDarkMode()"
      >
      </app-button>
    </div>

    <div *ngIf="isOpen" class="user-content">
      <h5>
        {{username}}
      </h5>

      <span>
        {{email}}
      </span>

      <p>
        {{company}}
      </p>
    </div>

    <button (click)="handleToggleMenu()" class="toggle-menu">
      <i class="material-icons-outlined">
        {{ isOpen ? 'close' : 'menu' }}
      </i>
      <span [ngClass]="{ 'ml-2': isOpen }">{{ isOpen ? 'Fechar' : '' }}</span>
    </button>

    <button class="notifications" (click)="toggleNotifications('all')">
      <div class="icon-plus-count">
        <i class="material-icons-outlined">
          notifications
        </i>
        <ng-container *ngIf="(totalNotReadNotifications$ | async) as totalNotReadNotifications">
          <div *ngIf="totalNotReadNotifications && hasNotReadNotifications" id="new-notification">
            {{totalNotReadNotifications < 10 ? totalNotReadNotifications : '9+'}}
          </div>
        </ng-container>
      </div>
      <span *ngIf="isOpen">
        Notificações
      </span>
    </button>
  </header>

  <!-- Conteúdo principal do menu -->
  <main class="ls-menu__items">

    <ul class="first-level">
      <!-- Item do menu -->
      <ng-container *ngFor="let item of items; let i=index">
        <li class="item"
        [title]="item.label"
        (mouseenter)="toggleItem(i)"
        (click)="!item?.items?.length && handleSelectItem(i)"
        [ngClass]="{'active': item.toggled, 'visited': activeItemIdx === i}">
          <!-- Título -->
          <div
          class="first-level__title"
          [ngClass]="{'is-justify-content-center': !isOpen}">
            <i class="material-icons-outlined">
              {{ item.icon }}
            </i>
            <span *ngIf="isOpen">
              {{ item.label }}
            </span>
          </div>
          <!-- Sub-items -->
          <ul
          *ngIf="item.items && item.items.length"
          class="second-level">
            <div *ngIf="!isOpen" class="sub-items-title">
              {{ item.label }}
            </div>
            <div class="sub-items-list">
              <ng-container *ngFor="let subItem of item.items; let j=index">
                <li
                [ngClass]="{'has-background-primary has-text-white': activeItemIdx === i && activeSubItemIdx(i, j)}"
                (click)="handleSelectItem(i, j)">
                  {{ subItem.label }}
                </li>
              </ng-container>
            </div>
          </ul>
        </li>
      </ng-container>
    </ul>
  </main>

  <!-- Rodapé do menu -->
  <footer class="ls-menu__footer" [ngClass]="{'is-align-items-center': !isOpen}">
    <button class="logout" (click)="logout()">
      <i class="material-icons-outlined">
        meeting_room
      </i>
      <span *ngIf="isOpen">
        Sair do Loadshark
      </span>
    </button>
  </footer>
</aside>

<app-notifications-list #notificationsList
*ngIf="isNotificationsOpened"
[menuToggled]="isOpen"
[notifications]="notifications"
[type]="notificationsType"
(handleShow)="toggleNotifications()">
</app-notifications-list>
