import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable, skip, tap } from 'rxjs';
import { Profiles } from 'src/app/models/profiles';
import { ContextMenuConfig } from 'src/app/shared/components/context-menu/context-menu.component';
import { ApplicationMonitoringService } from 'src/app/shared/services/application-monitoring.service';
import { WebsocketService } from 'src/app/shared/services/websocket.service';

@Component({
  selector: 'app-websocket-connection-status',
  templateUrl: './websocket-connection-status.component.html',
  styleUrls: ['./websocket-connection-status.component.scss']
})
export class WebsocketConnectionStatusComponent implements OnInit {

  routesToDisplay = ['leilao/transportadora/ot'];

  disconnectedMessage$ = new Observable<boolean>();
  connectedMessage$ = new Observable<boolean>();

  displayConnected = false;
  displayDisconnected = false;

  statusMinimized = false;

  firstReconnectionDate: Date;
  lastReconnectionDate: Date;
  reconnectedTimes = 0;
  msIntervalToCheck = 5 * 60000;
  threshold = 2;

  adminContextMenu: ContextMenuConfig[] = [
    {
      label: 'WebSocket',
      items: [
        {
          label: 'Conectar',
          onClick: () => this.connect()
        },
        {
          label: 'Desconectar',
          onClick: () => this.disconnect()
        },
        {
          label: 'Simular erro de conexão',
          onClick: () => this.forceError()
        },
      ]
    }
  ];

  adminContextMenuProfiles = [Profiles.Administrador];

  constructor(
    private router: Router,
    private apmService: ApplicationMonitoringService,
    private webSocketService: WebsocketService) {
  }

  get displayConnectionStatus(): boolean {
    return this.routesToDisplay.some(r => this.router.url.includes(r));
  }

  get displayConnectedMessage(): boolean {
    return this.displayConnected;
  }

  get displayDisconnectedMessage(): boolean {
    return this.displayDisconnected;
  }

  ngOnInit(): void {
    this.disconnectedMessage$ = this.webSocketService.connectionStatusChanges.pipe(
      skip(1),
      map(connectionStatus => connectionStatus !== 'connected' && connectionStatus !== 'reconnected'),
      tap(_ => {
        this.reconnectedTimes++;
        this.setDisconnectionsTimestamps();
        this.handleManyDisconnections();
        this.displayDisconnected = true;
      }),
    );
    this.connectedMessage$ = this.webSocketService.connectionStatusChanges.pipe(
      skip(1),
      map(connectionStatus => connectionStatus === 'reconnected'),
      tap(_ => this.displayConnected = true),
    );
  }

  setDisconnectionsTimestamps() {
    if (this.reconnectedTimes === 1) {
      this.firstReconnectionDate = new Date();
    }
    this.lastReconnectionDate = new Date();
  }

  handleManyDisconnections() {
    if (this.reconnectedTimes) {
      if (this.intervalBetweenLastAndNow()) {
        this.firstReconnectionDate = new Date();
        this.reconnectedTimes = 1;
      }
      if (this.intervalBetweenFirstAndNow() && this.hasExceedThreshold()) {
        this.firstReconnectionDate = new Date();
        this.reconnectedTimes = 1;
        const message = `Ocorrendo muitas reconexões em um curto período de tempo: ${this.reconnectedTimes}`;
        this.apmService.sendError(message);
        console.warn(message);
      }
    }
  }

  intervalBetweenLastAndNow() {
    const now = new Date().getTime();
    const lastReconnectionDate = this.lastReconnectionDate.getTime();
    return (now - lastReconnectionDate) > this.msIntervalToCheck;
  }

  intervalBetweenFirstAndNow() {
    const now = new Date().getTime();
    const firstReconnectionDate = this.firstReconnectionDate.getTime();
    return (now - firstReconnectionDate) > this.msIntervalToCheck;
  }

  hasExceedThreshold() {
    return this.reconnectedTimes > this.threshold;
  }

  closeConnect() {
    this.displayConnected = false;
  }

  closeDisconnect() {
    this.displayDisconnected = false;
  }

  minimize() {
    this.statusMinimized = !this.statusMinimized;
  }

  reloadPage() {
    location.reload();
  }

  forceError() {
    this.webSocketService.crashConnection();
  }

  connect() {
    this.webSocketService.reconnect();
  }

  disconnect() {
    this.webSocketService.disconnect();
  }

}
