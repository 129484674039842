<app-header title="Minha conta"></app-header>
<div class="columns">
    <div class="column">
        <div class="edit-profile">
            <div class="content">
                <div class="header">
                    <h2 class="large-text has-text-weight-bold">{{shippingCompanyName || '-'}}</h2>
                    <label for="cnpj">CNPJ</label>
                    <input mask="00.000.000/0000-00" type="text" class="input" [(ngModel)]="cnpj" maxlength="18">
                </div>
                <hr>
                <div class="branches">
                    <div class="branches-header">
                        <span class="large-text has-text-weight-bold">Minhas filiais</span>
                    </div>
                    <div class="branches-list">
                        <div class="columns">
                            <ng-container *ngIf="branches && branches.length > 0; else noData">
                                <div class="column is-4" *ngFor="let branch of branches">
                                    <p>{{branch?.name}}</p>
                                    <p>{{branch?.cnpj | cnpj}}</p>
                                    <p>Estado: {{branch?.originState}}</p>
                                    <p>Contatos:</p>
                                    <p *ngFor="let contact of branch.contactInfo">
                                        ({{contact?.areaCode}}) {{contact?.phone}} - {{contact?.contact}}
                                    </p>
                                </div>
                            </ng-container>
                            <ng-template #noData>
                                <div class="column">
                                    <span>Não existem filiais cadastradas</span>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="routes">
                    <div class="routes-header">
                        <span class="large-text has-text-weight-bold">Minhas rotas</span>
                        <button [ngClass]="{'is-primary': !isEditing, 'is-dark': isEditing }"
                        class="button is-rounded is-margined-medium-horizontal"
                        (click)="editRoute()">
                            {{ isEditing ? 'Finalizar edição' : 'Editar' }}
                        </button>
                    </div>
                    <span [hidden]="isEditing">
                        {{selectedRegions | displayProp:'label':'Nenhuma rota selecionada' }}
                    </span>
                    <div [hidden]="!isEditing" class="select-options-tags">
                        <ng-container *ngFor="let region of selectedRegions; let i = index">
                            <div class="tag is-clickable" *ngIf="i < 3">
                                {{ region.label }}&nbsp;&nbsp;
                                <span class="tag-close-btn" (click)="removeFilterOption(region.value)">
                                    <i class="material-icons is-small">close</i>
                                </span>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="selectedRegions.length > 3">
                            <div class="tag is-clickable">
                                <div class="more-details" [attr.data-tooltip]="showSelectedRegions()">
                                    +{{ selectedRegions.length - 3 }}...
                                </div>
                                <div class="more-tag-details" *ngIf="isOriginTagMoreDetailsOpened" (mouseleave)="closeTagMoreDetail('region')">
                                    <ul>
                                        <ng-container *ngFor="let region of selectedRegions; let i = index">
                                            <li *ngIf="i >= 3">
                                                <span style="max-width: 25px;">
                                                    {{ region.label }}
                                                </span>
                                                <span class="tag-close-btn"
                                                (click)="removeFilterOption(region.value)">
                                                    <i class="material-icons">close</i>
                                                </span>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div [hidden]="!isEditing" class="multiple-select">
                        <app-multiple-select #multipleSelectRegions *ngIf="regions && regions.length > 0" [options]="regions" (handleCheckedOptions)="saveSelectedRegions($event)"></app-multiple-select>
                    </div>
                </div>
                <hr>
                <div class="trucks">
                    <div class="trucks-header">
                        <span class="large-text has-text-weight-bold">Meus caminhões</span>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="subtitle">
                                <span class="regular-text has-text-weight-bold">
                                    Tamanho de equipamento
                                </span>
                            </div>
                            <div class="list-content">
                                <label class="checkbox" *ngFor="let vehicle of vehicleTypes; let i=index">
                                    <input type="checkbox" (change)="saveSelectedVehicles(i)" [checked]="vehicle.checked">
                                    {{vehicle.name}}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="actions">
                    <button [disabled]="!canSaveForm"
                    class="button is-primary is-rounded is-medium"
                    (click)="saveConfig()">
                        Salvar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
