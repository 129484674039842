<div
  class="route"
  [ngClass]="{ 'route-horizontal': isHorizontal }"
>
  <div class="route__tracking">
    <div class="route__tracking-origin"></div>
    <div class="route__tracking-line"></div>
    <div class="route__tracking-dest"></div>

    <div
      *ngIf="!wayPoint?.length && (type | otCheckers: ['isNoteChange'])"
      class="route__tracking-point"
    >
      <app-ot-map-point [type]="type"></app-ot-map-point>
    </div>

    <div *ngIf="wayPoint?.length" class="route__tracking-point">
      <!-- Listagem de pontos de troca -->
      <ng-container *ngFor="let item of wayPoint; let i = index">
        <app-ot-map-point
          *ngIf="item && i !== wayPoint?.length - 1"
          [data]="item"
          [type]="type"
        ></app-ot-map-point>
      </ng-container>
    </div>
  </div>

  <app-data-wrapper *ngIf="!isHorizontal" [isCol]="true">
    <app-data-item>
      <h6>Origem</h6>
      <b [title]="data?.origin">{{ data?.origin || "-" }}</b>
    </app-data-item>

    <app-data-item
      [ngClass]="{'opacity-40': (type | otCheckers: ['isNoteChange'])}"
    >
      <h6>Destino</h6>
      <b *ngIf="type !== 'transbordo'" [title]="data?.destination.split(')')[1] || data?.destination || '-'">{{ data?.destination.split(')')[1] || data?.destination || "-" }}</b>
      <b *ngIf="type === 'transbordo'" [title]="wayPoint[0]?.partnerCity + '/' + wayPoint[0]?.partnerUF || '-'">{{ wayPoint[0]?.partnerCity + '/' + wayPoint[0]?.partnerUF || "-" }}</b>
    </app-data-item>
  </app-data-wrapper>

  <app-data-wrapper
    *ngIf="isHorizontal"
    style="width: 100%"
    [isSpaceBetween]="true"
  >
    <app-data-item>
      <h6>Origem</h6>
      <b [title]="data?.origin">{{ data?.origin || "-" }}</b>
    </app-data-item>

    <app-data-item
      [ngClass]="{'opacity-40': (type | otCheckers: ['isNoteChange'])}"
    >
      <h6>Destino</h6>
      <b [title]="data?.destination.split(')')[1] || data?.destination || '-'">{{ data?.destination.split(')')[1] || data?.destination || "-" }}</b>
    </app-data-item>
  </app-data-wrapper>
</div>
