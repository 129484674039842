<div *ngIf="displayConnectionStatus">
  <div [hidden]="!displayDisconnectedMessage">
    <div darkMode *ngIf="(disconnectedMessage$ | async)" class="websocket-status notification">
      <header>
        <h3 class="is-flex">
          <span class="has-text-warning mr-2">
            <i class="material-icons">
              warning
            </i>
          </span>
          Erro de atualização do leilão
        </h3>

        <div class="actions">
          <button (click)="minimize()">
            <i class="material-icons">
              {{ statusMinimized ? 'add' : 'remove'}}
            </i>
          </button>
        </div>
      </header>

      <section [ngClass]="{'hidden': statusMinimized}">
        <p>
          Você não está recebendo atualizações em tempo real dos leilões que está participando.
          <br><br>
          <strong>Por favor, recarregue a página.</strong>
          <br><br>
          Caso o erro <strong>persista</strong>, contate o suporte
          pelo e-mail imediatamente!
          <br><br>
          E-mail: <a href="mailto:atendimento@loadshark.io">atendimento@loadshark.io</a>
        </p>
      </section>

      <footer class="is-flex" [ngClass]="{'hidden': statusMinimized}">
        <!-- <app-button class="mr-2" title="Fechar" [isOutlined]="true" (handleClickEmitter)="closeDisconnect()"></app-button> -->
        <app-button title="Atualizar e reconectar" (handleClickEmitter)="reloadPage()"></app-button>
      </footer>

    </div>
  </div>

  <div [hidden]="!displayConnectedMessage">
    <div darkMode *ngIf="(connectedMessage$ | async)" class="websocket-status notification">
      <header>
        <h3 class="is-flex">
          <span class="has-text-primary mr-2">
            <i class="material-icons">
              check_circle
            </i>
          </span>
          Reconectado ao leilão
        </h3>

        <div class="actions">
          <button (click)="minimize()">
            <i class="material-icons">
              {{ statusMinimized ? 'add' : 'remove'}}
            </i>
          </button>
          <button (click)="closeConnect()">
            <i class="material-icons">
              close
            </i>
          </button>
        </div>
      </header>

      <section [ngClass]="{'hidden': statusMinimized}">
        <p>
          <strong>Reconexão estabelecida</strong>, desculpe o transtorno.
          <br><br>
          Caso tenha algum problema no Loadshark nos contate no suporte pelo e-mail abaixo.
          <br><br>
          E-mail: <a href="mailto:atendimento@loadshark.io">atendimento@loadshark.io</a>
        </p>
      </section>

    </div>
  </div>

  <app-context-menu [ctxMenuConfig]="adminContextMenu" [profiles]="adminContextMenuProfiles"></app-context-menu>

</div>

