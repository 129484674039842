<div class="app-details-route">
  <div
    class="app-details-route__content"
    *ngFor="let item of data; let i = index"
  >
    <app-data-wrapper>
      <app-data-item *ngIf="data.length > 1">
        <h6>Carga</h6>
        <b>Nº{{ i + 1 }}</b>
      </app-data-item>

      <app-data-item>
        <h6>O.T.</h6>
        <b>{{ item?.ots?.[0] || "-" }}</b>
      </app-data-item>
    </app-data-wrapper>

    <div class="app-details-route__tracking">
      <div class="app-details-route__tracking-origin"></div>
      <div class="app-details-route__tracking-line"></div>
      <div class="app-details-route__tracking-dest"></div>

      <div class="app-details-route__tracking-point">
        <ng-container *ngIf="item?.wayPoint?.length">
          <ng-container *ngFor="let point of item?.wayPoint; let i = index">
            <div
              darkMode
              *ngIf="point?.distance"
              class="app-details-route__tracking-distance"
            >
              {{ (point?.distance | number: '1.0-0':'pt-br' || 0) }}km
            </div>

            <app-ot-map-point
              *ngIf="point && i !== item?.wayPoint?.length - 1"
              [data]="point"
              [type]="type"
            ></app-ot-map-point>
          </ng-container>
        </ng-container>

        <div darkMode
          *ngIf="!item?.wayPoint?.length || item?.wayPoint?.length < 2"
          class="app-details-route__tracking-distance"
        >
          {{ (item?.distance | number:'1.0-0':'pt-br') }}km
        </div>
      </div>
    </div>

    <app-data-wrapper [isSpaceBetween]="true" style="width: 100%">
      <app-data-item>
        <h6>Origem</h6>
        <b>{{ item?.origin || "-" }}</b>
      </app-data-item>

      <ng-container *ngIf="item?.wayPoint">
        <ng-container *ngFor="let point of item?.wayPoint; let i = index">
          <app-data-item
            *ngIf="point && i !== item?.wayPoint?.length - 1"
          >
            <h6>{{ type === 'trocaDeNota' ? 'Troca de nota' : 'Transbordo' }}</h6>
            <b>{{ point?.partnerCity + "/" + point?.partnerUF }}</b>
          </app-data-item>
        </ng-container>
      </ng-container>

      <app-data-item>
        <h6>Destino</h6>
        <b>{{ item?.destination || "-" }}</b>
      </app-data-item>
    </app-data-wrapper>
  </div>
</div>
