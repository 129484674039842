  <div  class='notification-container-duplicate opacity duplicate '  *ngIf="showNotification && data.title && data.message && data.type  && data.count >= 1">
    <article class="message"
        [ngClass]="{ 'is-danger': data.type === 'error', 'is-success': data.type === 'success', 'is-warning': data.type === 'warning', 'is-primary': data.type === 'default' }">
        <div class="message-header">
          <p>.</p>
        </div>
        <div class="message-body">
          <p>.</p>
        </div>
    </article>
  </div>
  <div [ngClass]="{'notification-container shadow' : data.count >= 1 , 'notification-container-duplicate' : true}" class='notification-container' *ngIf="showNotification && data.title && data.message && data.type">
    <article class="message"
        [ngClass]="{ 'is-danger': data.type === 'error', 'is-success': data.type === 'success', 'is-warning': data.type === 'warning', 'is-primary': data.type === 'default' }">
        <div class="message-header">
          <p>{{ data.title }}</p>
          <div><span id="count" *ngIf="data.count > 0"> {{ data.count ? data.count : '' }}</span><button class="delete" aria-label="delete" (click)="closeNotification()"></button></div>
        </div>
        <div class="message-body">
          {{ data.message }}
        </div>
    </article>
  </div>



