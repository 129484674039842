<div class="loadshark-select"
  [ngClass]="{
    'is-disabled': isDisabled,
    'focus': isMultiple ? control.value?.length : control.value,
    'is-dirty': isDirty
  }">

  <label class="input-label"
  [ngClass]="{'is-disabled': isDisabled, 'is-dirty': isDirty}">
    {{ label ? label : ' ' }}
  </label>

  <ng-select
    darkMode
    *ngIf="!modelValue"
    [appendTo]="appendTo"
    [id]="name"
    [items]="items"
    [multiple]="isMultiple"
    [attr.maxSelectedItems]="maxSelectedItems"
    [bindValue]="bindValue"
    [formControl]="control"
    [placeholder]="placeholder"
    [closeOnSelect]="closeOnSelect"
    [clearable]="clearable"
    [searchable]="searchable"
    (change)="changeInternal($event)"
    (remove)="delete($event)"
    (open)="handleOpen.emit($event)"
  >
  </ng-select>

  <ng-select
    darkMode
    *ngIf="modelValue"
    [appendTo]="appendTo"
    [id]="name"
    [items]="items"
    [multiple]="isMultiple"
    [attr.maxSelectedItems]="maxSelectedItems"
    [bindValue]="bindValue"
    [formControl]="control"
    [(ngModel)]="modelValue"
    [placeholder]="placeholder"
    [closeOnSelect]="closeOnSelect"
    [clearable]="clearable"
    [searchable]="searchable"
    (change)="changeInternal($event)"
    (remove)="delete($event)"
    (open)="handleOpen.emit($event)"
  >
  </ng-select>

  <p *ngIf="validationError" class="has-text-danger">
    {{ validationMessage ? validationMessage: 'Mensagem de validação não informada' }}
  </p>

  <p *ngIf="helpMessage">
    {{ helpMessage ? helpMessage : 'Mensagem de ajuda não informada' }}
  </p>
</div>
