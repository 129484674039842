<div class="wrapper">

  <div [ngClass]="{'side-file': isSideText}">
    <app-button
      [title]="title"
      [loading]="isLoading"
      [variant]="isOutlined ? 'outlined' : isLink ? 'link' : 'filled'"
      [icon]="icon"
      [size]="size"
      [onlyIcon]="onlyIcon"
      [iconShape]="iconShape"
      [disabled]="disabled"
      (handleClickEmitter)="inputFile.click()">
      </app-button>
    
      <div *ngIf="fileName && showUploadedFiles" class="is-flex is-justify-content-space-between my-4 is-align-items-center">
        <span [ngStyle]="{'width.ch': 20}" class="is-size-6 is-text-overflow">{{fileName}}</span>
        <i class="material-symbols-outlined is-clickable has-text-danger is-size-5 ml-2" (click)="removeFile()">delete</i>
      </div>
    
      <div *ngIf="error && errorMessage" class="is-flex is-justify-content-space-between m-4 has-text-centered">
        <span class="regular-text has-text-danger">{{errorMessage}}</span>
      </div>
    
      <input #inputFile [id]="id" class="file-input" type="file" (change)="updateFilename($event)" hidden>
  </div>
</div>
