<div [attr.style]="styles" class="container">
  <div style="display: block">
    <div class="text-inside">
      <span [attr.style]="subtitleStyle">
        {{ subTitle }}
      </span>
      <span [attr.style]="titleStyle" class="has-text-weight-bold">
        {{ title }}
      </span>
    </div>
    <canvas baseChart
      [data]="data"
      [type]="'doughnut'"
      [options]="chartOptions">
    </canvas>
  </div>
</div>
