import { Component, Input, OnInit, OnChanges, ViewChild, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { MultipleSelectComponent } from 'src/app/shared/components/multiple-select/multiple-select.component';

@Component({
  selector: 'app-filter-check-auctions',
  templateUrl: './filter-check-auctions.component.html',
  styleUrls: ['./filter-check-auctions.component.scss']
})
export class FilterCheckAuctionsComponent implements OnInit, OnChanges {

  @Input() auctions;

  @Output() handleFilters = new EventEmitter();

  statesOriginUF = [];
  statesDestinationUF = [];

  @ViewChild('multipleSelectOrigin')
  multipleSelectOrigin: MultipleSelectComponent;
  @ViewChild('multipleSelectDestination')
  multipleSelectDestination: MultipleSelectComponent;

  filtersSelecteds = false;
  originIsChecked = false;
  destinationIsChecked = false;
  isShowFilters = false;

  filters = {
    origin: [],
    destination: []
  };

  query = '?';

  constructor() { }

  ngOnInit(): void {
  }

  saveSelectedOptions(event, type) {
    this.filters[type] = event;
    this.filtersSelecteds = this.filters.origin.some(f => f.checked) || this.filters.destination.some(f => f.checked);
  }

  handleAllChecked = (event, scope) => this[`${scope}IsChecked`] = event;

  filter() {
    this.query = '?';
    const origins = this.filters.origin.filter(f => f.checked);
    const destinations = this.filters.destination.filter(f => f.checked);

    origins?.forEach(origin => this.query += `originUf=${origin.label}&`);
    destinations?.forEach(origin => this.query += `destinationUf=${origin.label}&`);

    this.handleFilters.emit(this.query);
  }

  showFilters() {
    this.isShowFilters = !this.isShowFilters;
  }

  getFilters() {
    if (this.auctions.length) {
      this.auctions.forEach(auction => {
        if (!this.statesOriginUF.includes(auction.origin.split('/')[1])) {
          this.statesOriginUF.push(auction.origin.split('/')[1]);
        }
        if (!this.statesDestinationUF.includes(auction.destination.split('/')[1])) {
          this.statesDestinationUF.push(auction.destination.split('/')[1]);
        }
      });
      this.mountFilters();
    }
  }

  mountFilters() {
    this.filters = {
      origin: this.statesOriginUF.map((s) => ({
        label: s,
        value: s,
        checked: false,
      })),
      destination: this.statesDestinationUF.map((s) => ({
        label: s,
        value: s,
        checked: false,
      })),
    };
    this.multipleSelectOrigin.updateOptions(this.filters.origin);
    this.multipleSelectDestination.updateOptions(this.filters.destination);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.auctions && !changes.auctions.previousValue?.length) {
      this.getFilters();
    }
  }

}
