import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientRestrictionsComponent } from './client-restrictions.component';



@NgModule({
  declarations: [
    ClientRestrictionsComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ClientRestrictionsComponent
  ]
})
export class ClientRestrictionsModule { }
