import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit  {

  @Input() placeholder: string;
  @Input() control: AbstractControl = new UntypedFormControl();
  @Input() name: string;
  @Input() value: string;
  @Input() rows: number;
  @Input() label: string;

  constructor() { }

  ngOnInit(): void {
  }

}
