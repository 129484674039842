<app-popup [noHeader]="true" [isVisible]="isVisible" (handleClose)="close()">
    <div class="wrapper">
        <div class="modal-title">Editar campos</div>
        <div class="field-container">
            <div class="fields">
                <div class="field-title">Campos Disponíveis</div>
                <ul *ngFor="let field of fields?.avaiable" class="field-list">
                    <li (click)="changeFieldStatus(field, 'avaiable')">
                        {{ field }}
                        <i class="material-icons" aria-hidden="true">add</i>
                    </li>
                </ul>
            </div>
            <div class="fields">
                <div class="field-title">Campos Utilizados</div>
                <ul *ngFor="let field of fields?.used" class="field-list">
                    <li (click)="changeFieldStatus(field, 'used')">
                        {{ field }}
                        <i class="material-icons" aria-hidden="true">remove</i>
                    </li>
                </ul>
            </div>
        </div>
        <footer>
            <app-button title="Cancelar" [isSecondary]="true"></app-button>
            <app-button title="Salvar"></app-button>
        </footer>
    </div>
</app-popup>
  