import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundValue'
})
export class RoundValuePipe implements PipeTransform {

  transform(value: any, digitsAfterComma = 2, ...args: any[]): string {
    if (value) {
      return value.toFixed(digitsAfterComma);
    }
    return null;
  }

}
