import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu.component';
import { NotificationsListComponent } from './components/notifications-list/notifications-list.component';
import { NotificationsCollapsibleComponent } from './components/notifications-list/collapsible/notifications-collapsible.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    MenuComponent,
    NotificationsListComponent,
    NotificationsCollapsibleComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule
  ],
  exports: [
    MenuComponent,
    NotificationsListComponent,
    NotificationsCollapsibleComponent
  ]
})
export class MenuModule { }
