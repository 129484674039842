import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GlobalNotificationService } from '../../shared/services/global-notification.service';
import { LoaderService } from '../../shared/services/loader.service';
import { Profiles } from './../../models/profiles';
import { APIData } from 'src/app/interfaces/ApiResponse';

declare const askNicelyConversation;

const PREFERENCES_API = `${environment.api.meta.url}${environment.api.preference}`;
const MAIL_API = `${environment.api.meta.url}${environment.api.users}sendUserConfirmationMail`;
@Injectable({
  providedIn: 'root'
})
export class UserService {

  profiles = Profiles;

  constructor(
    private http: HttpClient,
    private notificationService: GlobalNotificationService,
    private loaderService: LoaderService,
    private router: Router,
  ) { }

  public isUserShippingCompany() {
    try {
      const user = this.getLoggedUser();
      const profiles = JSON.parse(localStorage.getItem('profiles'));
      if (!profiles) {
        return true;
      }
      let isShippingCompany = false;
      user.profile.forEach(profile => {
        const profileInfo = profiles.find(p => p.id === profile);
        if (profileInfo.name.indexOf('Transportadora') !== -1) {
          isShippingCompany = true;
        }
      });
      return isShippingCompany;
    } catch {
      return true;
    }
  }

  public isUserAdmin() {
    try {
      const user = this.getLoggedUser();
      const profiles = JSON.parse(localStorage.getItem('profiles'));
      if (!profiles) {
        return false;
      }
      let isAdmin = false;
      user.profile.forEach(profile => {
        const profileInfo = profiles.find(p => p.id === profile);
        if (profileInfo.isAdmin) {
          isAdmin = true;
        }
      });
      return isAdmin;
    } catch {
      return false;
    }
  }

  public isUserEmbarcador() {
    try {
      const user = this.getLoggedUser();
      const profiles = JSON.parse(localStorage.getItem('profiles'));
      if (!profiles) {
        return false;
      }
      let isAdmin = false;
      user.profile.forEach(profile => {
        const profileInfo = profiles.find(p => p.id === profile);
        if (profileInfo.name.indexOf('Embarcador') !== -1 || profileInfo.name === Profiles.CustomerServicesMonitoramento) {
          isAdmin = true;
        }
      });
      return isAdmin;
    } catch {
      return false;
    }
  }

  public isUserShippingCompanyAdmin() {
    try {
      const user = this.getLoggedUser();
      const profiles = JSON.parse(localStorage.getItem('profiles'));
      if (!profiles) {
        return false;
      }
      let isAdmin = false;
      user.profile.forEach(profile => {
        const profileInfo = profiles.find(p => p.id === profile);
        if (profileInfo.name === Profiles.AdministradorTransportadora || profileInfo.name.includes('Transportadora - Admin')) {
          isAdmin = true;
        }
      });
      return isAdmin;
    } catch {
      return false;
    }
  }

  public isUserAuctionTransporter() {
    try {
      const user = this.getLoggedUser();
      const profiles = JSON.parse(localStorage.getItem('profiles'));
      if (!profiles) {
        return false;
      }
      let isUserAuctionTransporter = false;
      user.profile.forEach(profile => {
        const profileInfo = profiles.find(p => p.id === profile);
        if (profileInfo.name.includes(this.profiles.TransportadoraLeilao)) {
          isUserAuctionTransporter = true;
        }
      });
      return isUserAuctionTransporter;
    } catch {
      return false;
    }
  }

  public isUserAuctionChecker() {
    try {
      const user =this.getLoggedUser();
      const profiles = JSON.parse(localStorage.getItem('profiles'));
      if (!profiles) {
        return false;
      }
      let isUserAuctionChecker = false;
      user.profile.forEach(profile => {
        const profileInfo = profiles.find(p => p.id === profile);
        if (profileInfo.name.includes(this.profiles.ConferenciaLeilao) || profileInfo.name.includes(this.profiles.EmbarcadorLeilao)) {
          isUserAuctionChecker = true;
        }
      });
      return isUserAuctionChecker;
    } catch {
      return false;
    }
  }

  listProfiles(page, limit, queryFilter) {
    const url = `${environment.api.meta.url}${environment.api.profile}${page}/${limit}${queryFilter ? queryFilter : ''}`;
    return this.http.get(url).pipe(
      map((response: any) => response.data?.profile)
    );
  }

  getUserProfiles() {
    try {
      const userProfiles = [];
      const user = this.getLoggedUser();
      const profiles = JSON.parse(localStorage.getItem('profiles'));
      user.profile.forEach(profile => {
        const profileInfo = profiles.find(p => p.id === profile);
        if (profileInfo && profileInfo.name) {
          userProfiles.push(profileInfo.name);
        }
      });
      return userProfiles;
    } catch {
      return [];
    }
  }

  public getLoggedUser() {
    const cookies = document.cookie.split(';');
    let claim = cookies.find(c => c.includes('user-info'));
    if (claim) {
      claim = claim.split('user-info=')[1];
      if (claim) {
        return JSON.parse(decodeURIComponent(claim));
      }
    }
    return null;
  }

  public setLoggedUser(user) {
    document.cookie = user ? `user-info=${encodeURIComponent(JSON.stringify(user))}` : 'user-info=;expires=Thu, 01 Jan 1970 00:00:00 UTC;';
  }

  public getOrganization() {
    const claim = this.getLoggedUser();
    if (claim && claim.organization) {
      return claim.organization;
    }
    return null;
  }

  async listUsers(page, limit, queryFilter) {
    try {
      let url = environment.api.meta.url + environment.api.users + page + '/' + limit + '?';
      url = queryFilter ? url + queryFilter : url;
      const users = await this.http.get(url).toPromise();
      return users;
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        title: 'Não foi possível carregar os usuários',
        type: 'error',
        message: e.message
      });
      return [];
    }
  }

  async createUser(user) {
    try {
      const url = environment.api.meta.url + environment.api.user;
      const response = await this.http.post(url, user).toPromise();
      this.notificationService.notification$.next({
        title: 'Usuário criado',
        type: 'success',
        message: 'Usuário criado com sucesso'
      });
      return response;
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        title: 'Não foi possível criar o usuário',
        type: 'error',
        message: e.error.message
      });
      return e.error.message;
    }
  }

  async updateUser(id, user) {
    try {
      const url = environment.api.meta.url + environment.api.user + '/' + id;
      const response = await this.http.patch(url, user).toPromise();
      this.notificationService.notification$.next({
        title: 'Usuário atualizado',
        type: 'success',
        message: 'Usuário atualizado com sucesso'
      });
      return response;
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        title: 'Não foi possível atualizar o usuário',
        type: 'error',
        message: e.message
      });
      return [];
    }
  }

  async listUsersByOrganization() {
    try {
      const url = environment.api.meta.url + environment.api.users + 'getUserOrganization';
      const users = await this.http.get(url).toPromise();
      return users;
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        title: 'Não foi possível carregar os usuários da organização',
        type: 'error',
        message: e.message
      });
      return [];
    }
  }

  async sendPasswordRecoveryEmail(userEmail) {
    try {
      const url = environment.api.meta.url + environment.api.users + 'sendRecoveryPasswordEmail';
      const response = await this.http.post(url, { email: userEmail }).toPromise();
      return response;
    } catch (e) {
      console.error(e);
      return e.error.message;
    }
  }

  async sendPasswordRecovery(userEmail) {
    try {
      const url = environment.api.meta.url + environment.api.users + 'sendRecoveryPassword';
      const response = await this.http.post(url, { email: userEmail }).toPromise();
      return response;
    } catch (e) {
      console.error(e);
      return e.error.message;
    }
  }

  async changePassword(userData) {
    try {
      const url = environment.api.meta.url + environment.api.users + 'changePassword';
      const response = await this.http.post(url, { user: userData }).toPromise();
      this.notificationService.notification$.next({
        title: 'Senha alterada',
        type: 'success',
        message: 'Sua senha foi alterada com sucesso!'
      });
      return [response];
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        title: 'Erro ao alterar senha',
        type: 'error',
        message: e.error.message
      });
      return [];
    }
  }

  async sendNewEmailVerification(email) {
    const url = MAIL_API;
    const response = await this.http.post(url, email).subscribe({
      next: () => {
        this.notificationService.notification$.next({
          title: 'Re-envio de link de ativação',
          type: 'success',
          message: 'Foi enviado um novo email de ativação.'
        });
      },
      error: e => {
        this.notificationService.notification$.next({
          title: 'Re-envio de link de ativação',
          type: 'error',
          message: e.error.message
        });
      }
    });
      return response;
  }

  async newPassword(userData) {
    try {
      const url = environment.api.meta.url + environment.api.users + 'newPassword';
      const response = await this.http.post(url, userData).toPromise();
      return response;
    } catch (e) {
      console.error(e);
      return e.error.message;
    }
  }


  async checkToken(token) {
    try {
      const url = environment.api.meta.url + environment.api.users + 'check-token/' + token;
      const response = await this.http.get(url).toPromise();
      return response;
    } catch (e) {
      console.error(e);
      return e.error.message;
    }
  }

  async deleteShippingCompanyUser(userId) {
    try {
      const url = environment.api.meta.url + environment.api.users + 'deleteUserOrganization/' + userId;
      await this.http.delete(url).toPromise();
      return [];
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        title: 'Não foi possível deletar o usuário',
        type: 'error',
        message: e.message
      });
      return [];
    }
  }

  async createShippingCompanyUser(user) {
    try {
      const url = environment.api.meta.url + environment.api.users + 'createUserShippingCompany';
      const response = await this.http.post(url, user).toPromise();
      this.notificationService.notification$.next({
        title: 'Usuário criado',
        type: 'success',
        message: 'Usuário criado com sucesso'
      });
      return response;
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        title: 'Não foi possível criar o usuário',
        type: 'error',
        message: e.message
      });
      return [];
    }
  }

  async editShippingCompanyUser(id, data) {
    try {
      const url = environment.api.meta.url + environment.api.users + 'editUserOrganization/' + id;
      const response = await this.http.put(url, data).toPromise();
      this.notificationService.notification$.next({
        title: 'Usuário atualizado',
        type: 'success',
        message: 'Usuário atualizado com sucesso'
      });
      return response;
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        title: 'Erro ao atualizar o usuário',
        type: 'error',
        message: e.message
      });
      return [];
    }
  }

  getUserOrdersCount() {
    return localStorage.getItem('userOrdersCount');
  }

  async recoverPassword(email) {
    try {
      const url = environment.api.meta.url + environment.api.user + '/recoverPassword/' + email;
      const data = await this.http.get(url).toPromise();
      return data && data['data'] ? data['data'] : null;
    } catch (e) {
      console.error(e);
      return e.error.errors[0];
    }
  }

  userPasswordCount() {
    const user = this.getLoggedUser();
    const userObj = user ? user : null;
    return userObj.changePassword;
  }

  isUserFirstAccess() {
    const user = this.getLoggedUser();
    const userObj = user ? user : null;
    return userObj && userObj.countAccess <= 1;
  }

  getUserProfile() {
    const user = this.getLoggedUser();
    const profilesStorage = localStorage.getItem('profiles');
    const profiles = profilesStorage ? JSON.parse(profilesStorage) : null;
    const userProfileId = user.profile.length > 0 ? user.profile[0] : null;
    if (!user || !profiles || !userProfileId) { return null; }
    const profile = profiles.find(p => p.id === userProfileId);
    return profile;
  }

  async listAllShippingCompanies() {
    try {
      const url = environment.api.meta.url + environment.api.shippingCompany + '/0/1000';
      const result = await this.http.get(url).toPromise();
      return result && result['data'] && result['data']['shippingCompany'] ? result['data']['shippingCompany'] : [];
    } catch (e) {
      console.error(e.message);
      this.loaderService.hide();
      this.notificationService.notification$.next({
        title: 'Falha ao listar transportadoras.',
        type: 'error',
        message: e.errors ? e.errors.join(', ') : e.message
      });
      return null;
    }
  }

  async getAllUserPreferences() {
    try {
      const { id } = this.getLoggedUser();
      const url = environment.api.meta.url + environment.api.preference + id + `/all`;
      const data = await this.http.get(url).toPromise();

      if (Object.values(data['data'])) {
        window.localStorage.setItem(`UserPreferences-${id}`, JSON.stringify(data['data']));
        return data['data'];
      }
      return null;
    } catch (e) {
      console.error(e);
      return e.error.errors[0];
    }
  }

  async postUserPreferences(feature, preferences) {
    try {
      const url = environment.api.meta.url + environment.api.preference + `/${feature}`;
      const data = await this.http.post(url, preferences).toPromise();
      await this.getAllUserPreferences();
      return data && data['data'] ? data['data'] : null;
    } catch (e) {
      console.error(e);
      return e.error.errors[0];
    }
  }

  handleDefaultRedirect = () => {
    window.setTimeout(() => {
      this.router.navigate(['/']);
    }, 1000);
  };

  redirectUser(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const accessedUrl = state.url;
    const existingRoutes = this.router.config.map((r) => '/' + r.path);
    existingRoutes.push('/');

    if (existingRoutes.includes(accessedUrl)) {
      const profile = this.getUserProfile();

      if (!profile) {
        this.router.navigate(['/404']);
        throw new Error('Usuário não possui perfil');
      }

      if (profile.defaultRoute) {
        this.router.navigate([profile.defaultRoute]);
      } else {
        this.router.navigate(['/404']);
      }

      return;
    }
    this.router.navigate(['/404']);
  }

  async getOrganizationConfiguration() {
    try {
      const url = environment.api.meta.url + environment.api.organizationConfiguration + '0/1';
      const result = await this.http.get(url).toPromise();
      return result && result['data'] && result['data']['object'] ? result['data']['object'][0] : [];
    } catch (e) {
      console.error(e.message);
      this.loaderService.hide();
      this.notificationService.notification$.next({
        title: 'Falha ao listar configurações',
        type: 'error',
        message: e.errors ? e.errors.join(', ') : e.message
      });
      return null;
    }
  }

  async patchOrganizationConfiguration(settingId, settings) {
    try {
      const url = environment.api.meta.url + environment.api.organizationConfiguration + settingId;
      const result = await this.http.patch(url, settings).toPromise();
      if (result && result['data'] && result['data']['auction']) {
        this.notificationService.notification$.next({
          title: 'Alterações salvas',
          type: 'success',
          message: 'Suas configurações foram salvas com sucesso!'
        });
      }
      return result && result['data'] && result['data']['object'] ? result['data']['object'][0] : [];
    } catch (e) {
      console.error(e.message);
      this.loaderService.hide();
      this.notificationService.notification$.next({
        title: 'Falha ao salvar configurações.',
        type: 'error',
        message: e.errors ? e.errors.join(', ') : e.message
      });
      return null;
    }
  }

  checkUser(email) {
    return this.http.post(`${environment.api.meta.url}${environment.api.auth}check-user`, email).pipe(
      map((result: any) => result.data),
      catchError(e => of(e.error?.message))
    );
  }

  async getNotifications() {
    if (this.getLoggedUser()) {
      await this.notificationService.getNotificationsAndSetTotals();
    }
  }

  async sendRoutesPreferences(userId, preferences) {
    try {
      const result = await this.http.post(environment.api.meta.url + environment.api.userMain + `/preference/planning/route/${userId}`, preferences).toPromise();
      return result['data'];
    } catch (e) {
      this.loaderService.hide();
      this.notificationService.notification$.next({
        title: 'Falha ao salvar preferencias de rotas',
        type: 'error',
        message: e.error.message
      });
      return null;
    }
  }

  async getRoutesPreferences(userId) {
    try {
      const result = await this.http.get(environment.api.meta.url + environment.api.userMain + `/preference/planning/route/${userId}`).toPromise();
      return result && result['data'] && result['data'].userPreferences && result['data'].userPreferences.planning ? result['data'].userPreferences.planning : {};
    } catch (e) {
      this.loaderService.hide();
      this.notificationService.notification$.next({
        title: 'Falha ao carregar pedidos',
        type: 'error',
        message: e.message
      });
      return null;
    }
  }

  async getEmailCheckedTransportation(userId) {
    try {
      const url = environment.api.meta.url + environment.api.user + `/isActive?userId=${userId}`;
      const result = await this.http.get(url).toPromise();
      return result;
    } catch (e) {
      console.error(e.message);
      this.loaderService.hide();
      this.notificationService.notification$.next({
        title: 'Falha ao validar e-mail',
        type: 'error',
        message: e.errors ? e.errors.join(', ') : e.message
      });
      return null;
    }
  }

  async deletePreferenceRoute(preferenceId, routeId) {
    try {
      const result = await this.http.put(environment.api.meta.url + environment.api.userMain + `/preference/planning/route/${preferenceId}/${routeId}`, {}).toPromise();
      return result;
    } catch (e) {
      this.loaderService.hide();
      this.notificationService.notification$.next({
        title: 'Falha ao atualizar entregas',
        type: 'error',
        message: e.message
      });
      return null;
    }
  }

  validateUser(token) {
    return this.http.get<any>(`${environment.api.meta.url}${environment.api.user}/validateToken?token=${token}`).pipe(
      catchError(e => {
        console.error(e.message);
        this.loaderService.hide();
        return of(e.error);
      })
    );
  }

  async generateValidationToken(userId) {
    try {
      const url = environment.api.meta.url + environment.api.user + `/generateValidationToken?userId=${userId}`;
      const result = await this.http.get(url).toPromise();
      return result;
    } catch (e) {
      console.error(e.message);
      this.loaderService.hide();
      return e.error?.errors[0] || 'error';
    }
  }

  async sendEmailValidation(email) {
    try {
      const url = environment.api.meta.url + environment.api.user + '/sendUserConfirmationMail';
      const result = await this.http.post(url, { email }).toPromise();
      return result;
    } catch (e) {
      console.error(e.message);
      this.loaderService.hide();
      return e.error?.errors[0] || 'error';
    }
  }

  async showSurvey() {
    const response = await this.getOrganizationConfiguration();
    if (response) {
      const askNicelyConfig = response.askNicely;
      if (askNicelyConfig) {
        const user = await this.getLoggedUser();
        const name = user.fullName;
        const email = user.email;
        const email_hash = user.email_hash;
        const created = Math.floor(Date.now() / 1000);
        askNicelyConfig.config = {...askNicelyConfig.config, name, email, email_hash, created};
        if (typeof askNicelyConversation !== 'undefined') {
          askNicelyConversation(askNicelyConfig);
        }
      }
    }
  }

  getUserPreferences() {
    const { id } = this.getLoggedUser();
    return this.http.get(`${PREFERENCES_API}${id}/all`).pipe(
      map((response: any) => response.data)
    );
  }

  getSavedPreferences(moduleName: string) {
    return new Observable(observer => {
      this.getUserPreferences().subscribe({
        next: response => {
          if (response && response['userPreferences'] && response['userPreferences'][moduleName]) {
            return observer.next(response['userPreferences'][moduleName]);
          }
          return observer.next(null);
        },
        error: e => {
          console.error(e);
          return observer.error(e.error.errors[0]);
        },
      });
    });
  }

  savePreferences(moduleName, payload) {
    return this.http.post(`${PREFERENCES_API}save/${moduleName}`, payload).pipe(
      map((response: any) => response.data)
    );
  }

  deletePreferences(moduleName, payload) {
    return this.http.post(`${PREFERENCES_API}delete/${moduleName}`, payload).pipe(
      map((response: any) => response.data)
    );
  }

  getUserLastFilter(moduleName) {
    return this.http.get(`${PREFERENCES_API}getLastFilterByUser/${moduleName}`).pipe(
      map((response: any) => response.data)
    );
  }

  saveUserLastFilter(moduleName, payload) {
    return this.http.put(`${PREFERENCES_API}saveLastFilterByUser/${moduleName}`, payload).pipe(
      map((response: any) => response.data)
    );
  }

  hasProfiles(profiles: Profiles[], method: 'every' | 'some' | 'only' = 'some') {
    const userProfiles = this.getUserProfiles();

    if (method === 'some') {
      return profiles.some(p => userProfiles.includes(p));
    }

    if (method === 'every') {
      return profiles.every(p => userProfiles.includes(p));
    }

    if (method === 'only') {
      if (profiles.length > 1) {
        throw new Error('You should pass only one profile');
      }
      return !!(userProfiles.length === 1 && profiles.find(p => userProfiles.includes(p)));
    }
  }
}
