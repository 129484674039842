import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-card-customizer',
  templateUrl: './card-customizer.component.html',
  styleUrls: ['./card-customizer.component.scss']
})
export class CardCustomizerComponent implements OnInit {

  @Input() isVisible: boolean;
  @Input() fields;
  @Output() handleCloseModal = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  changeFieldStatus(field, status) {
    if (status === 'avaiable') {
      this.fields.avaiable = this.fields?.avaiable.filter(f => f !== field);
      this.fields.used.push(field);
      return;
    }
    this.fields.used = this.fields?.used.filter(f => f !== field);
      this.fields.avaiable.push(field);
  }

  close(): void {
    this.handleCloseModal.emit();
  }

}
