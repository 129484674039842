<input
darkMode
#datepicker
class="datetimepicker"
[ngClass]="{'outlined': isOutlined}"
[type]="type || 'datetime-local'"
[value]="value || initialDate"
[min]="min"
[max]="endingDate"
[formControl]="control"
[disabled]="disabled"
[readonly]="readonly"
(change)="getDate($event)"
pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}" required>
