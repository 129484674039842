<ng-container>
  <header id="menu"
  class="navbar is-fixed-top menu-container"
  [ngClass]="{'is-white': !isDark, 'is-dark': isDark }">
    <div class="navbar-brand">
      <h1 class="navbar-item is-paddingless-left">
        <span class="is-size-3 is-padded-small-left" (click)="verifyClicks()">
          {{ title }}
        </span>
      </h1>
      <a role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false" data-toggle="navbar-menu"
        aria-controls="navbar">
        <span class="material-icons is-not-clickable" aria-hidden="true">filter_list</span>
      </a>
    </div>
    <div darkMode class="navbar-menu" id="navbar">
      <div class="navbar-end">
        <ng-content></ng-content>
      </div>
    </div>
  </header>
</ng-container>
