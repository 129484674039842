<div darkMode
    *ngIf="showNotification"
    class="push-notification"
    [ngClass]="{'shadow' : notification.count > 0 , 'push-notification-success': notification.type === 'success', 'push-notification-error': notification.type === 'error', 'push-notification-warning': notification.type === 'warning' }">
    <div class="push-notification-content">
        <div class="columns">
            <div class="column is-11">
                <div class="columns is-multiline">
                    <div class="column is-12">
                        <span darkMode *ngIf="notification.subtitle" class="subtitle">{{ notification.subtitle }}</span>
                        <span darkMode *ngIf="notification.message" class="push-message">{{ notification.message }}</span>
                    </div>
                    <div class="column is-12" *ngIf="notification.title" style="padding-top: 0px;">
                        <div class="count">
                            <span darkMode class="title">{{ notification.title }}</span>
                            <span darkMode class="title" *ngIf="notification.count > 0">{{ notification.count }}</span>
                        </div>
                    </div>
                </div>

            </div>
            <div class="column is-1 route-btn" style="padding-top: 0px;">
                <div class="columns is-multiline" style="padding-top: 0px;">
                    <div class="column is-12" style="text-align: center; font-size: 14px; padding-top: 0px;">
                        <span class="material-icons is-clickable" style="font-size: 14px" (click)="closeNotification()">
                            close
                        </span>
                    </div>
                    <div class="column is-12" *ngIf="notification.route">
                        <span class="material-icons" (click)="goToRoute()">
                            keyboard_arrow_right
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div darkMode
    *ngIf="showNotification && notification.count > 0"
    class="push-notification-duplicate opacity opacity"
    [ngClass]="{ 'push-notification-success': notification.type === 'success', 'push-notification-error': notification.type === 'error', 'push-notification-warning': notification.type === 'warning' }">
    <div class="push-notification-content">
        <div class="columns">
            <div class="column is-11">
                <div class="columns is-multiline">
                    <div class="column is-12">
                        <span darkMode *ngIf="notification.subtitle" class="subtitle">{{ notification.subtitle }}</span>
                        <span darkMode *ngIf="notification.message" class="push-message">{{ notification.message }}</span>
                    </div>
                    <div class="column is-12" *ngIf="notification.title" style="padding-top: 0px;">
                        <div class="count">
                            <span darkMode class="title">{{ notification.title }}</span>
                            <span darkMode class="title">{{ notification.count }}</span>
                        </div>
                    </div>
                </div>

            </div>
            <div class="column is-1 route-btn" style="padding-top: 0px;">
                <div class="columns is-multiline" style="padding-top: 0px;">
                    <div class="column is-12" style="text-align: center; font-size: 14px; padding-top: 0px;">
                        <span class="material-icons is-clickable" style="font-size: 14px" (click)="closeNotification()">
                            close
                        </span>
                    </div>
                    <div class="column is-12" *ngIf="notification.route">
                        <span class="material-icons" (click)="goToRoute()">
                            keyboard_arrow_right
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
