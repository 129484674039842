import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { RegisterCarrierService } from 'src/app/features/register/register-carrier/services/register-carrier.service';
import { UserService } from 'src/app/services/user/user.service';
import { ConfirmTermsService } from './services/confirm-terms.service';

@Component({
  selector: 'app-confirm-terms-modal',
  templateUrl: './confirm-terms-modal.component.html',
  styleUrls: ['./confirm-terms-modal.component.scss']
})
export class ConfirmTermsModalComponent implements OnInit {

  @ViewChild('pdfview') pdfview: ElementRef;
  pdfContent: any;

  isVisible = false;

  accordingTo = true;
  infoConfirmed = true;
  readAndAccepted = true;
  termsIsVisible = false;

  idPrivatePolicy: string;
  idTermsOfUse: string;

  data = {
    title: '',
    content: '',
    url: ''
  };

  status: 'confirm' | 'emailSent' = 'confirm';

  constructor(
    private service: ConfirmTermsService,
    private userService: UserService,
    private authService: AuthService,
    private registerCarrierService: RegisterCarrierService) { }

  get user() {
    return this.userService.getLoggedUser();
  }

  ngOnInit(): void {
    this.listenToModalToggle();
  }

  renderTerms(): void {
    this.termsIsVisible = !this.termsIsVisible;
  }

  listenToModalToggle() {
    this.service.visible$.subscribe({
      next: value => {
        this.isVisible = value;
        if (value) {
          this.getData();
        }
      }
    });
  }

  confirmedTerms() {
    const data = {
      accordingTo: this.accordingTo,
      infoConfirmed: this.infoConfirmed,
      readAndAccepted: this.readAndAccepted,
      idPrivatePolicy: this.idPrivatePolicy
    };

    if (data && this.user) {
      this.service.confirmedTerms(this.user?._id, data).subscribe(_ => {
        this.status = 'emailSent';
      });
    }
  }

  close() {
    this.status = 'confirm';
    this.service.toggleModal();
    this.authService.logout();
  }

  getData() {
    this.registerCarrierService.downloadPrivacyPolicy().subscribe({
      next: res => {
        this.idPrivatePolicy = res._id;
        this.data.content = res.blob;
        this.data.url = res.url;
        this.data.title = 'Privacidade de dados';
        this.showData();
      }
    });
  }

  showData() {
    this.pdfContent =
      URL.createObjectURL(this.b64toBlob(this.data.content, 'application/pdf')) +
      '#toolbar=0&navpanes=0&scrollbar=0&view=FitH';

    this.pdfview.nativeElement.setAttribute('data', this.pdfContent);
  }

  b64toBlob(b64Data, contentType) {
    const byteCharacters = atob(b64Data);

    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  downloadFile() {
    window.location.href = this.data.url;
  }

}
