<div class="hero is-fullheight-with-navbar">
    <app-side-image [pathImages]="pathImages">
      <div class="hero-body columns login">
          <div class="is-hidden-touch"></div>
          <section class="column is-7-tablet login-body swipeInLeft">
              <main id="main">
                  <form [formGroup]="form" novalidate>
                      <fieldset>
                          <legend class="is-sr-only">Entrar na minha conta</legend>
                          <div class="field is-pulled-left">
                              <a [routerLink]="['/']">
                                <app-public-logo></app-public-logo>
                              </a>
                          </div>
                          <div class="is-clearfix"></div>

                          <div class="field is-pulled-left mb-6">
                            <p class="is-size-4 has-text-grey">
                                Negociação inteligente de fretes
                            </p>
                          </div>
                          <div class="is-clearfix"></div>

                          <article class="message is-danger" *ngIf="hasError">
                              <div class="message-body">
                                  <b>{{errorToDisplay}}</b>
                              </div>
                          </article>

                          <ng-container *ngIf="!emailSent; else displayEmail">
                              <div class="field is-pulled-left">
                                  <p class="is-size-4">Digite o e-mail associado à sua conta</p>
                              </div>
                              <div class="field">
                                <app-input
                                leadingIcon="email"
                                placeholder="Digite seu e-mail"
                                helperText="Será enviado um e-mail de recuperação para a conta acima."
                                [control]="email"
                                [validation]="commonControlValidation">
                                </app-input>
                              </div>
                              <div class="field is-pulled-left">
                                <app-button title="Voltar"
                                [isSecondary]="true"
                                (handleClickEmitter)="backToLogin()">
                                </app-button>
                              </div>
                              <div class="field is-pulled-right">
                                <app-button title="Enviar"
                                [isDisabled]="form.invalid"
                                (handleClickEmitter)="sendEmail()">
                                </app-button>
                              </div>
                          </ng-container>
                          <ng-template #displayEmail>
                              <div class="is-flex is-align-items-center is-pulled-left">
                                  <i class="material-icons has-text-primary is-medium mr-2">check_circle</i>&nbsp;
                                  <span class="regular-text">Enviamos um e-mail de acesso para {{emailToDisplay}}</span>
                              </div>
                          </ng-template>

                      </fieldset>
                  </form>
              </main>
          </section>
      </div>
    </app-side-image>
</div>
