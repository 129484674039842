import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoaderService } from '../../../shared/services/loader.service';
import { GlobalNotificationService } from '../../../shared/services/global-notification.service';
import { environment } from 'src/environments/environment';
import { of, Subject } from 'rxjs';
import { downloadFile, removeDuplicates } from 'src/app/shared/utils';
import moment from 'moment';
import { UserService } from '../../../services/user/user.service';
import { APIDataRegister } from 'src/app/interfaces/ApiResponse';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuctionService {

  public auctionEvents$ = new Subject<any>();

  // messages coming from socket to produce real time data
  messages = [];
  private readonly API = `${environment.api.meta.url}${environment.api.auctionLot}`;
  private readonly API_AUCTION_MAIN = `${environment.api.meta.url}${environment.api.auctionMain}`;

  constructor(
    private http: HttpClient,
    private loaderService: LoaderService,
    private notificationService: GlobalNotificationService,
    private userService: UserService
  ) { }


  calculateTotalValuePricePerTon(transportation) {
    const { finalWeight, pricePerTon } = transportation;

    let newTotalValue = 0;

    if (finalWeight >= 0 && finalWeight <= 12.5) {
      newTotalValue = pricePerTon * 12.5;
    } else if (finalWeight > 12.5 && finalWeight <= 15) {
      newTotalValue = pricePerTon * finalWeight;
    } else if (finalWeight > 15 && finalWeight <= 25) {
      newTotalValue = pricePerTon * 25;
    } else if (finalWeight > 25) {
      newTotalValue = pricePerTon * finalWeight;
    }

    return newTotalValue;
  }

  calculateTotalValueMinBidValue(transportation) {
    const { finalWeight, minBidValue } = transportation || {};

    let newTotalValue = 0;

    if (finalWeight >= 0 && finalWeight <= 12.5) {
      newTotalValue = minBidValue * 12.5;
    } else if (finalWeight > 12.5 && finalWeight <= 15) {
      newTotalValue = minBidValue * finalWeight;
    } else if (finalWeight > 15 && finalWeight <= 25) {
      newTotalValue = minBidValue * 25;
    } else if (finalWeight > 25) {
      newTotalValue = minBidValue * finalWeight;
    }

    return newTotalValue;
  }

  calculateTotalValue(weight, value) {
    let newTotalValue = 0;

    if (weight >= 0 && weight <= 12.5) {
      newTotalValue = value * 12.5;
    } else if (weight > 12.5 && weight <= 15) {
      newTotalValue = value * weight;
    } else if (weight > 15 && weight <= 25) {
      newTotalValue = value * 25;
    } else if (weight > 25) {
      newTotalValue = value * weight;
    }

    return newTotalValue;
  }

  getDashboard(begin: string, end: string) {
    const query = begin && end ? `?begin=${begin}&end=${end}` : '';
    return this.http.get<APIDataRegister<any>>(`${this.API_AUCTION_MAIN}dashboard${query}`).pipe(
      map((response) => response.data)
    );
  }

  downloadDashboard(begin: string, end: string) {
    const payload = begin && end ? { begin, end } : { };
    return this.http.post(`${this.API_AUCTION_MAIN}dashboard/report`,
      payload,
      { responseType: 'arraybuffer' }
    ).pipe(
      map((response) => {
        const byteArray = new Uint8Array(response);
        const a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', }));
        const prefix = begin && end ? `${begin}/${end}` : 'ate-hoje';
        a.download = `dashboard-leilão-ot/${prefix}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        return true;
      })
    );
  }

  async getAvailableOTs(offset = 0, limit = 50) {
    try {
      const response = await this.http.get(environment.api.meta.url + environment.api.auction + `getAvailable/${offset}/${limit}`).toPromise();
      return response && response['data'] ? response['data'] : [];
    } catch (error) {
      console.log(error);
      this.loaderService.hide();
      this.notificationService.notification$.next({
        title: 'Falha ao carregar O.Ts disponíveis',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  async getShippingDate(offset = 0, limit = 50) {
    try {
      const response = await this.http.get(environment.api.meta.url + environment.api.auction + `getShippingDate/${offset}/${limit}`).toPromise();
      return response && response['data'] ? response['data'] : [];
    } catch (error) {
      console.log(error);
      this.loaderService.hide();
      this.notificationService.notification$.next({
        title: 'Falha ao carregar datas disponíveis',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  createAuction(data) {
    return this.http.post<any>(`${environment.api.meta.url}${environment.api.auction}`, data).pipe(
      map(response => response.data),
      catchError(err => {
        console.log(err);
        this.loaderService.hide();
        this.notificationService.notification$.next({
          title: 'Falha ao criar leilão',
          type: 'error',
          message: err.error?.message || 'Ocorreu um erro desconhecido, por favor entre em contato com o suporte.'
        });
        return of(err);
      })
    );
  }

  async listAuctions(offset = 0, limit = 50, status = 'pending') {
    try {
      const response = await this.http.get(environment.api.meta.url + environment.api.auctionLot + `list/${offset}/${limit}?and[status]=${status}`).toPromise();
      return response && response['data'] ? response['data'] : [];
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao carregar os dados do leilão',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  async countAuctionsByStatus() {
    const response = await this.http.get(`${environment.api.meta.url}${environment.api.auctionLot}stats`).toPromise();
    return response && response['data'] && response['data']['stats'] ? response['data']['stats'] : [];
  }

  async countAuctionsByStatusShippingCompany() {
    const response = await this.http.get(`${environment.api.meta.url}${environment.api.auctionLot}shippingcompany/countStatus`).toPromise();
    return response && response['data'] ? response['data'] : [];
  }

  async finishAuction(auctionId, endingReason) {
    try {
      const response = await this.http.patch(environment.api.meta.url + environment.api.auctionLot + `end/${auctionId}`, { endingReason }).toPromise();
      this.notificationService.notification$.next({
        title: 'Finalizado com sucesso',
        type: 'success',
        message: 'O leilão foi finalizado antecipadamente com sucesso.'
      });
      return response && response['data'] ? response['data'] : [];
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao carregar os dados do leilão',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  async getDetailAuctionFinished(id) {
    try {
      const response = await this.http.get(environment.api.meta.url + environment.api.auctionLot + `getDetailAuctionFinished/${id}`).toPromise();
      return response && response['data'] ? response['data'][0] : {};
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao carregar os dados do leilão',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  getDetailsAuctionModifiedByOt(ot) {
    return this.http.get(`${this.API}getLotModified/${ot}`);
  }

  getDetailsLotModifiedById(id) {
    return this.http.get(`${this.API}getLotsModified/0/1?_id=${id}`);
  }

  async getAuctionEnded(offset, limit, queryFilter) {
    try {
      const response = await this.http.get(environment.api.meta.url + environment.api.auctionLot + `getAuctionEnded/${offset}/${limit}/${queryFilter}`).toPromise();
      return response && response['data'] ? response['data'] : {};
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao carregar os dados do leilão',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  getAuctionModified(offset, limit, queryFilter) {
    return this.http.get(`${this.API}getLotsModified/${offset}/${limit}/${queryFilter}`);
  }

  async getAuctionConference(offset, limit, query) {
    try {
      const response = await this.http.get(environment.api.meta.url + environment.api.auctionLot + `getAuctionConference/${offset}/${limit}/${query}`).toPromise();
      return response && response['data'] ? response['data'] : {};
    } catch (e) {
      this.notificationService.notification$.next({
        title: 'Falha ao carregar os dados dos leilões',
        type: 'error',
        message: 'Recarregue a página. ' + e.error.message
      });
    }
  }

  async getAuctionExecuting(offset, limit, queryFilter) {
    try {
      const response = await this.http.get(environment.api.meta.url + environment.api.auctionLot + `getAuctionExecuting/${offset}/${limit}/${queryFilter}`).toPromise();
      return response && response['data'] ? response['data'] : [];
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao carregar os dados do leilão',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
      return [];
    }
  }

  async getAuctionPending(offset, limit, queryFilter) {
    try {
      const response = await this.http.get(environment.api.meta.url + environment.api.auctionLot + `getAuctionPending/${offset}/${limit}/${queryFilter}`).toPromise();
      return response && response['data'] ? response['data'] : {};
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao carregar os dados do leilão',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  async getVehicleTypes(offset = 0, limit = 500) {
    try {
      const response = await this.http.get(environment.api.meta.url + `/user/v1/vehicle/${offset}/${limit}`).toPromise();
      return response && response['data'] ? response['data'] : [];
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao carregar os tipos de veículo',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  pushMessage(msg) {
    this.messages.push(msg);
  }

  proccessMessages() {
    if (this.messages.length > 0) {
      const item = this.messages.shift();
      this.auctionEvents$.next(item);
    }
  }

  async setConfiguration(configuration) {
    try {
      const response = await this.http.post(environment.api.meta.url + '/auction/v1/configuration/setConfiguration', configuration).toPromise();
      return response && response['data'] ? response['data'] : [];
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao salvar a configuração de perfil',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  async registerNewBid(bid) {
    try {
      const response = await this.http.post(`${this.API_AUCTION_MAIN}bid/new`, bid).toPromise();
      this.notificationService.notification$.next({
        title: 'Lance registrado',
        type: 'success',
        message: `Seu lance de R$${bid.value} foi registrado`
      });
      return response && response['data'] ? response['data'] : [];
    } catch (error) {
      if (error.error && error.error.errors) {
        this.notificationService.notification$.next({
          title: 'Lance não registrado',
          type: 'warning',
          message: error.error.errors.join(' ')
        });
      }
      return null;
    }
  }

  async getTransportersByOrg(organizationId) {
    try {
      const response = await this.http.get(environment.api.meta.url + `/auction/v1/deal/transportersByOrg/${organizationId}`).toPromise();
      return response && response['data'] ? response['data'] : {};
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao carregar os dados da transportadora',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  async setTransporter(organizationId, transporter, vehicle) {
    try {
      const response = await this.http.patch(environment.api.meta.url + `/auction/v1/deal/integrate/setTransporter/${organizationId}`, { transporter, vehicle }).toPromise();
      this.notificationService.notification$.next({
        title: 'Carga registrada',
        type: 'success',
        message: 'Sua carga foi registrada com sucesso!'
      });
      return response && response['data'] ? response['data'] : [];
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao salvar dados da transportadora vencedora',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  async listToIntegrate(dealId) {
    try {
      const response = await this.http.get(environment.api.meta.url + `/auction/v1/deal/listToIntegrate/${dealId}`).toPromise();
      return response && response['data'] ? response['data'] : {};
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao carregar a lista de integrações',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  listToIntegrateByOrg(organizationId, lotId = null) {
    const queryString = lotId ? `?lotId=${lotId}` : '';
    return this.http.get<APIDataRegister<any>>(environment.api.meta.url + `/auction/v1/deal/listToIntegrateByOrg/${organizationId}${queryString}`).pipe(
      map(response => response.data || { }),
      catchError((error): any => {
        this.notificationService.notification$.next({
          title: 'Falha ao carregar a lista de integrações',
          type: 'error',
          message: 'Recarregue a página. ' + error.message
        });
        return of(null);
      })
    );
  }

  async getDetailAuctionPending(lotId) {
    try {
      const response = await this.http.get(environment.api.meta.url + `/auction/v1/lot/getDetailAuctionPending/${lotId}`).toPromise();
      return response && response['data'] && response['data'].length > 0 ? response['data'][0] : {};
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao carregar detalhes da carga',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  async getDetailAuctionExecuting(lotId) {
    try {
      const response = await this.http.get(environment.api.meta.url + `/auction/v1/lot/getDetailAuctionExecuting/${lotId}`).toPromise();
      return response && response['data'] && response['data'].length > 0 ? response['data'][0] : {};
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao carregar detalhes da carga',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  checkBid(lotId) {
    return this.http.get<APIDataRegister<any>>(`${this.API_AUCTION_MAIN}bid/check/${lotId}`).pipe(
      map((response) => response?.data),
      catchError((error): any => {
        this.notificationService.notification$.next({
          title: 'Falha ao validar lance',
          type: 'error',
          message: 'Recarregue a página. ' + error.message
        });
        return of(null);
      })
    );
  }

  async downloadAuctions(auctions, access = 'embarcador') {
    try {
      const body = this.prepareDataToCSV(auctions, access);
      const data = await this.http.post(environment.api.meta.url + '/auction/v1/lot/downloadCsv/', body, { responseType: 'arraybuffer' }).toPromise();
      downloadFile(data, 'text/csv;charset=utf-8;', 'leilao.csv', true);
      this.notificationService.notification$.next({
        title: 'Download concluído',
        type: 'success',
        message: 'CSV gerado com sucesso!'
      });
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        title: 'Erro ao baixar cargas',
        type: 'error',
        message: e.message
      });
    }
  }

  prepareDataToCSV(auctions, access) {
    if (access === 'embarcador') {
      if (auctions && auctions.length > 0) {
        const translate = {
          identifier: 'Identificador',
          comboType: 'Categoria',
          ots: 'O.Ts',
          origin: 'Origem',
          destination: 'Destino',
          volume: 'Volume',
          type: 'Tipo',
          startDate: 'Dt Inicio',
          endDate: 'Dt Termino',
          totalBids: 'Lances',
          initialValue: 'Valor inicial',
          shippingCompany: 'Transportadora',
          delta: 'Delta',
          lastBid: 'Ultimo lance',
          minBidValue: 'Valor final',
          vehicle: 'Veículo',
          transporter: 'Cod. Transportador',
          shippingDate: 'Dt Expedicao'
        };
        const data = auctions.map(auction => this.transformDataEmbarcador(auction));
        if (data && data.length > 0) {
          return { translate, data };
        }
      }
      return [];
    }
    if (access === 'conferencia') {
      if (auctions && auctions.length > 0) {
        const translate = {
          identifier: 'Identificador',
          ots: 'O.Ts',
          origin: 'Origem',
          destination: 'Destino',
          volume: 'Volume',
          type: 'Tipo',
          startDate: 'Dt Inicio',
          endDate: 'Dt Termino',
          totalBids: 'Lances',
          initialValue: 'Valor inicial',
          shippingCompany: 'Transportadora',
          delta: 'Delta',
          lastBid: 'Ultimo lance',
          minBidValue: 'Valor final',
          vehicle: 'Veículo',
          transporter: 'Cod. Transportador',
          shippingDate: 'Dt Expedicao',
          loadDate: 'Dt Carregamento'
        };
        const data = auctions.map(auction => this.transformDataConferencia(auction));
        if (data && data.length > 0) {
          return { translate, data };
        }
      }
      return [];
    } else {
      if (auctions && auctions.length > 0) {
        const translate = {
          comboType: 'Categoria',
          ots: 'O.Ts',
          origin: 'Origem',
          destination: 'Destino',
          volume: 'Volume',
          type: 'Tipo',
          shippingDate: 'Dt Expedicao',
          totalBids: 'Lances',
          initialValue: 'Valor inicial',
          lastBid: 'Ultimo lance',
          finalValue: 'Valor final',
        };
        const data = auctions.map(auction => this.transformDataTransportadora(auction));
        if (data && data.length > 0) {
          return { translate, data };
        }
      }
      return [];
    }
  }

  transformDataConferencia(auction) {
    const lot = {};
    lot['identifier'] = auction && auction.identifier ? auction.identifier : null;
    lot['ots'] = auction && auction.ots ? auction.ots : null;
    lot['origin'] = auction && auction.origin ? auction.origin : null;
    lot['destination'] = auction && auction.destination ? auction.destination : null;
    lot['volume'] = auction && auction.volume ? auction.volume : null;
    lot['type'] = auction && auction.type === 'reverse' ? 'Reverso' : null;
    lot['startDate'] = auction && auction.startDate ? moment(new Date(auction.startDate)).add(3, 'h').format('DD/MM/YYYY - HH:mm') : null;
    lot['endDate'] = auction && auction.endDate ? moment(new Date(auction.endDate)).add(3, 'h').format('DD/MM/YYYY - HH:mm') : null;
    lot['totalBids'] = auction && auction.totalBids ? auction.totalBids : null;
    lot['initialValue'] = auction && auction.initialValue ? auction.initialValue : null;
    lot['shippingCompany'] = auction && auction.shippingCompany ? auction.shippingCompany : null;
    lot['delta'] = auction && auction.delta ? auction.delta : null;
    lot['lastBid'] = auction && auction.lastBid ? auction.lastBid : null;
    lot['minBidValue'] = auction && auction.minBidValue ? auction.minBidValue : null;
    lot['shippingDate'] = auction && auction.shippingDate ? moment(new Date(auction.shippingDate)).add(3, 'h').format('DD/MM/YYYY - HH:mm') : null;
    lot['loadDate'] = auction && auction.loadDate ? moment(new Date(auction.loadDate)).add(3, 'h').format('DD/MM/YYYY - HH:mm') : null;
    lot['vehicle'] = auction && auction.vehicle ? auction.vehicle : null;
    lot['transporter'] = auction && auction.transporter ? auction.transporter : null;
    return lot;
  }
  transformDataEmbarcador(auction) {
    const lot = {};
    lot['identifier'] = auction && auction.auction ? auction.auction.number : null;
    lot['comboType'] = auction.comboType === 'combo' ? 'Combo' : auction.comboType === 'roadTrain' ? 'Rodotrem' : 'Simples';
    lot['ots'] = this.getOts(auction);
    lot['origin'] = auction.origin;
    lot['destination'] = auction.destination;
    lot['volume'] = auction.finalWeight;
    lot['type'] = 'Reverso';
    lot['startDate'] = auction && auction.auction ? moment(new Date(auction.auction.plannedStart)).add(3, 'h').format('DD/MM/YYYY - HH:mm') : null;
    lot['endDate'] = auction && auction.auction ? moment(new Date(auction.auction.plannedEnd)).add(3, 'h').format('DD/MM/YYYY - HH:mm') : null;
    lot['totalBids'] = auction.totalBids;
    lot['initialValue'] = auction.pricePerTon;
    lot['shippingCompany'] = auction.organization && auction.organization.name ? auction.organization.name : '-';
    lot['delta'] = auction.delta;
    lot['lastBid'] = auction.minBidValue;
    lot['minBidValue'] = auction.minBidValue;
    lot['shippingDate'] = moment(auction.shippingDate).add(3, 'h').format('DD/MM/YYYY - HH:mm');
    lot['vehicle'] = '-';
    if (auction?.deal?.vehicle) {
      lot['vehicle'] = auction.deal.vehicle;
    }
    lot['transporter'] = '-';
    if (auction?.deal?.transporter) {
      lot['transporter'] = auction.deal.transporter;
    }
    return lot;
  }

  transformDataTransportadora(auction) {
    const lot = {};
    lot['comboType'] = auction.comboType === 'combo' ? 'Combo' : auction.comboType === 'roadTrain' ? 'Rodotrem' : 'Simples';
    lot['ots'] = auction.ots;
    lot['origin'] = auction.origin;
    lot['destination'] = auction.destination;
    lot['volume'] = auction.weight;
    lot['type'] = 'Reverso';
    lot['totalBids'] = auction.totalBids;
    lot['initialValue'] = auction.pricePerTon.toFixed(2);
    lot['shippingDate'] = moment(auction.shippingDate).add(3, 'h').format('DD/MM/YYYY - HH:mm');
    lot['finalValue'] = this.calculateTotalValue(auction.weight, auction.minBidValue).toFixed(2);
    return lot;
  }

  getOts(auction) {
    let ots = [];
    if (!auction || !auction.loads || auction.loads.length === 0) {
      return ots;
    }
    auction.loads.map(l => {
      ots = ots.concat(l.ots);
    });
    removeDuplicates(ots);
    return ots;
  }

  isFirstAccess() {
    const user = this.userService.getLoggedUser();
    return user.firstAccessAuction;
  }

  async acceptAuctionTerm() {
    try {
      const response = await this.http.patch(environment.api.meta.url + `/auction/v1/configuration/user/auctionTerm`, { auctionTermAccepted: true }).toPromise();
      return response && response['data'] ? response['data'] : {};
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao aceitar termo de consentimento do leilão',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  async checkAuctionTermStatus() {
    try {
      const { auctionTermAccepted } = this.userService.getLoggedUser();
      return auctionTermAccepted;
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  async downloadAuctionTerm() {
    try {
      const response = await this.http.get(environment.api.meta.url + environment.api.auctionMain + 'configuration/user/auctionTerm').toPromise();
      return response && response['data'] ? response['data'] : null;
    } catch (error) {
      console.error(error);
    }
  }

  async getTransporterProfileData() {
    try {
      const response = await this.http.get(environment.api.meta.url + '/auction/v1/configuration/getConfiguration').toPromise();
      return response && response['data'] ? response['data'] : null;
    } catch (error) {
      console.error(error);
    }
  }

  async getLots(auctionId) {
    try {
      const response = await this.http.get(environment.api.meta.url + '/auction/v1/auction/getLots/' + auctionId).toPromise();
      return response && response['data'] ? response['data'] : null;
    } catch (error) {
      console.error(error);
    }
  }

  async updateAuctionDate(auctionId, data) {
    try {
      const response = await this.http.patch(environment.api.meta.url + `/auction/v1/auction/${auctionId}/dates`, data).toPromise();
      this.notificationService.notification$.next({
        title: 'Horário do leilão atualizado',
        type: 'success',
        message: 'O horário do leilão foi atualizado com sucesso.'
      });
      return response && response['data'] ? response['data'] : {};
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao atualizar o horário do leilão',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  async updateAuctionPrice(lotId, data) {
    try {
      const response = await this.http.patch(environment.api.meta.url + `/auction/v1/lot/${lotId}/price`, data).toPromise();
      this.notificationService.notification$.next({
        title: 'Valor inicial do lote atualizado',
        type: 'success',
        message: 'O valor inicial do lote foi atualizado com sucesso.'
      });
      return response && response['data'] ? response['data'] : {};
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao atualizar o valor do lote desse leilão',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  async downloadSAPSheet(auctions) {
    try {
      const data = await this.http.post(`${this.API}downloadCsv/sap`, auctions, { responseType: 'arraybuffer' }).toPromise();
      downloadFile(data, 'text/csv;charset=utf-8;', 'leilao.csv', true);
      this.notificationService.notification$.next({
        title: 'Download concluído',
        type: 'success',
        message: 'CSV gerado com sucesso!'
      });
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        title: 'Erro ao baixar template de contratações',
        type: 'error',
        message: e.message
      });
    }
  }

  async getAuction(status, ot) {
    try {
      const response = await this.http.get(`${environment.api.meta.url}${environment.api.auctionLot}getAuction/${status}/${ot}`).toPromise();
      return response && response['data'] ? response['data'] : null;
    } catch (error) {
      console.error(error);
    }
  }

  async getCancelReasons() {
    try {
      const response = await this.http.get(`${environment.api.meta.url}${environment.api.auctionMain}configuration/cancelReasons`).toPromise();
      return response && response['data'] ? [response['data']] : null;
    } catch (error) {
      console.error(error);
    }
  }

  async getAuctionTemplates() {
    try {
      const response = await this.http.get(`${environment.api.meta.url}/auction/v1/template/0/50`).toPromise();
      return response && response['data'] ? response['data'] : null;
    } catch (error) {
      console.error(error);
    }
  }

  async createAuctionTemplate(template) {
    try {
      const response = await this.http.post(`${environment.api.meta.url}/auction/v1/template`, template).toPromise();
      return response && response['data'] ? response['data'] : null;
    } catch (error) {
      console.error(error);
    }
  }

  async updateAuctionTemplate(template) {
    try {
      const response = await this.http.patch(`${environment.api.meta.url}/auction/v1/template/${template._id}`, template).toPromise();
      return response && response['data'] ? response['data'] : null;
    } catch (error) {
      console.error(error);
    }
  }

  async deleteAuctionTemplate(templateId) {
    try {
      const response = await this.http.delete(`${environment.api.meta.url}/auction/v1/template/${templateId}`).toPromise();
      return response && response['data'] ? response['data'] : null;
    } catch (error) {
      console.error(error);
    }
  }

  async cancelAuction(auctionId, cancelReason) {
    try {
      const response = await this.http.patch(`${environment.api.meta.url}${environment.api.auctionMain}lot/cancel/${auctionId}`, cancelReason).toPromise();
      if (response && response['data']) {
        this.notificationService.notification$.next({
          title: 'Leilão cancelado',
          type: 'success',
          message: 'Leilão cancelado com sucesso!'
        });
      }
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async joinAuction(auctionId, bid) {
    try {
      const response = await this.http.post(`${environment.api.meta.url}${environment.api.auctionMain}routeAuction/transporter/join/${auctionId}`, bid).toPromise();
      return response;
    } catch (error) {
      console.error(error);
      if (error.error && error.error.errors) {
        this.notificationService.notification$.next({
          title: 'Não é possível participar do leilão',
          type: 'error',
          message: 'Não foi possível confirmar participação nesse leilão. Tente novamente'
        });
      }
      return null;
    }
  }

  async denyAuction(auctionId, reason) {
    try {
      const response = await this.http.post(`${environment.api.meta.url}${environment.api.auctionMain}routeAuction/transporter/deny/${auctionId}`, reason).toPromise();
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async registerNewRouteAuctionBid(bid) {
    try {
      const response = await this.http.post(environment.api.meta.url + '/auction/v1/bid/routeAuction/new', bid).toPromise();
      this.notificationService.notification$.next({
        title: 'Lance registrado',
        type: 'success',
        message: `Seu lance de R$${bid.value ? bid.value : bid.trip?.value} foi registrado`
      });
      return response && response['data'] ? response['data'] : [];
    } catch (error) {
      if (error.error && error.error.errors) {
        this.notificationService.notification$.next({
          title: 'Lance não registrado',
          type: 'warning',
          message: error.error.errors.join(' ')
        });
      }
      return null;
    }
  }

  async getAuctionRouteCities(city) {
    try {
      const response = await this.http.get(`${environment.api.meta.url}/auction/v1/city/search?text=${city}`).toPromise();
      return response && response['data'] ? response['data'] : null;
    } catch (error) {
      console.error(error);
    }
  }

  async uploadAuctionTerm(file, name) {
    try {
      const response = await this.http.post(`${environment.api.meta.url}/auction/v1/routeAuction/shipper/uploadAttachment?nameDocument=${name}`, file).toPromise();
      return response && response['data'] ? response['data'] : null;
    } catch (error) {
      return error;
    }
  }

  async getOrganizationsGroupList() {
    try {
      const response = await this.http.get(`${environment.api.meta.url}/auction/v1/organizationgroup/organizations/0/1000`).toPromise();
      return response && response['data'] ? response['data'] : null;
    } catch (error) {
      console.error(error);
    }
  }

  async getOrganizationsGroup(id) {
    try {
      const response = await this.http.get(`${environment.api.meta.url}/auction/v1/organizationgroup/${id}`).toPromise();
      return response && response['data'] ? response['data'] : null;
    } catch (error) {
      console.error(error);
    }
  }

  async createOrganizationGroup(payload) {
    try {
      const response = await this.http.post(`${environment.api.meta.url}/auction/v1/organizationgroup`, payload).toPromise();
      return response && response['data'] ? response['data'] : null;
    } catch (error) {
      console.error(error);
    }
  }

  async editOrganizationGroup(payload, id) {
    try {
      const response = await this.http.patch(`${environment.api.meta.url}/auction/v1/organizationgroup/${id}`, payload).toPromise();
      return response && response['data'] ? response['data'] : null;
    } catch (error) {
      console.error(error);
    }
  }

  async createAuctionRoute(payload) {
    try {
      const response = await this.http.post(`${environment.api.meta.url}/auction/v1/routeAuction/shipper`, payload).toPromise();
      return response && response['data'] ? response['data'] : null;
    } catch (error) {
      console.error(error);
      this.notificationService.notification$.next({
        title: 'Falha ao criar leilão',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  async carrierRouteExecutingOnes(offset = 0, limit = 500, queryFilter) {
    try {
      const response = await this.http.get(environment.api.meta.url + environment.api.auctionMain + `routeAuction/transporter/executing/${offset}/${limit}/${queryFilter}`).toPromise();
      return response && response['data'] ? response['data'] : [];
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao carregar os dados do leilão',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
      return [];
    }
  }

  async carrierRouteExecutingAuctionDetail(routeAuctionId) {
    try {
      const response = await this.http.get(environment.api.meta.url + environment.api.auctionMain + `routeAuction/transporter/executing/detail/${routeAuctionId}`).toPromise();
      return response && response['data'] ? response['data'] : {};
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao carregar os dados do leilão',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  async carrierRouteGetBidsUser(offset = 0, limit = 500, queryFilter) {
    try {
      const response = await this.http.get(environment.api.meta.url + environment.api.auctionMain + `routeAuction/transporter/bidsUser/${offset}/${limit}/${queryFilter}`).toPromise();
      return response && response['data'] ? response['data'] : [];
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao carregar os dados do leilão',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
      return [];
    }
  }

  async carrierRouteGetDetailBidsUser(routeAuctionId, organizationId) {
    try {
      const response = await this.http.get(environment.api.meta.url + `/auction/v1/routeAuction/transporter/bidsUser/detail/${routeAuctionId}`).toPromise();
      return response && response['data'] ? response['data'] : {};
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao carregar os detalhes dos lances',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  async carrierRouteGetFinishedOnes(offset = 0, limit = 500, queryFilter) {
    try {
      const response = await this.http.get(environment.api.meta.url + environment.api.auctionMain + `routeAuction/transporter/ended/${offset}/${limit}/${queryFilter}`).toPromise();
      return response && response['data'] ? response['data'] : [];
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao carregar os finalizados',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  async carrierRouteGetMyLoadsDetail(routeAuctionId, status) {
    try {
      const response = await this.http.get(environment.api.meta.url + `/auction/v1/routeAuction/transporter/${status}/details/${routeAuctionId}`).toPromise();
      return response && response['data'] && response['data']['routeAuction'] ? response['data']['routeAuction'] : {};
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao carregar detalhes da carga',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  async carrierRouteGetById(id) {
    try {
      const response = await this.http.get(environment.api.meta.url + `/auction/v1/routeAuction/${id}`).toPromise();
      return response && response['data'] ? response['data'] : {};
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao carregar detalhes da carga',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  async getNewRegisterBidData(id) {
    try {
      const response = await this.http.get(environment.api.meta.url + `/auction/v1/bid/routeAuction/${id}`).toPromise();
      return response && response['data'] ? response['data'] : {};
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao carregar detalhes do modal de lance',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  async getRouteAuctionPending(offset, limit, queryFilter) {
    try {
      const response = await this.http.get(`${environment.api.meta.url}/auction/v1/routeAuction/shipper/pending/${offset}/${limit}/${queryFilter}`).toPromise();
      return response && response['data'] ? response['data'] : {};
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao carregar os dados do leilão',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  async getRouteAuctionExecuting(offset, limit, queryFilter) {
    try {
      const response = await this.http.get(`${environment.api.meta.url}/auction/v1/routeAuction/shipper/executing/${offset}/${limit}/${queryFilter}`).toPromise();
      return response && response['data'] ? response['data'] : {};
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao carregar os dados do leilão',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  async getRouteAuctionConfirm(offset, limit, queryFilter) {
    try {
      const response = await this.http.get(`${environment.api.meta.url}/auction/v1/routeAuction/shipper/confirm/${offset}/${limit}/${queryFilter}`).toPromise();
      return response && response['data'] ? response['data'] : {};
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao carregar os dados do leilão',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  async getRouteAuctionEnded(offset, limit, queryFilter) {
    try {
      const response = await this.http.get(`${environment.api.meta.url}/auction/v1/routeAuction/shipper/ended/${offset}/${limit}/${queryFilter}`).toPromise();
      return response && response['data'] ? response['data'] : {};
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao carregar os dados do leilão',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  async getRouteAuctionPendingDetails(id) {
    try {
      const response = await this.http.get(`${environment.api.meta.url}/auction/v1/routeAuction/shipper/pending/details/${id}`).toPromise();
      return response && response['data'] ? response['data'] : {};
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao carregar os dados do leilão',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  async getRouteAuctionExecutingDetails(id) {
    try {
      const response = await this.http.get(`${environment.api.meta.url}/auction/v1/routeAuction/shipper/executing/details/${id}`).toPromise();
      return response && response['data'] ? response['data'] : {};
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao carregar os dados do leilão',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  async getRouteAuctionConfirmDetails(id) {
    try {
      const response = await this.http.get(`${environment.api.meta.url}/auction/v1/routeAuction/shipper/confirm/details/${id}`).toPromise();
      return response && response['data'] ? response['data'] : {};
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao carregar os dados do leilão',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  async getRouteAuctionEndedDetails(id) {
    try {
      const response = await this.http.get(`${environment.api.meta.url}/auction/v1/routeAuction/shipper/ended/details/${id}`).toPromise();
      return response && response['data'] ? response['data'] : {};
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao carregar os dados do leilão',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  async getCancelReasonsAuctionRoute() {
    try {
      const response = await this.http.get(`${environment.api.meta.url}/auction/v1/routeAuction/shipper/cancel/reasons`).toPromise();
      return response && response['data'] ? [response['data']] : null;
    } catch (error) {
      console.error(error);
    }
  }

  async cancelRouteAuction(auctionId, cancelReason) {
    try {
      const response = await this.http.post(`${environment.api.meta.url}/auction/v1/routeAuction/shipper/cancel/${auctionId}`, cancelReason).toPromise();
      if (response && response['data']) {
        this.notificationService.notification$.next({
          title: 'Leilão cancelado',
          type: 'success',
          message: 'Leilão cancelado com sucesso!'
        });
      }
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async finishRouteAuction(auctionId, endingReason) {
    try {
      const response = await this.http.post(`${environment.api.meta.url}/auction/v1/routeAuction/shipper/end/${auctionId}`, {endingReason}).toPromise();
      this.notificationService.notification$.next({
        title: 'Finalizado com sucesso',
        type: 'success',
        message: 'O leilão foi finalizado antecipadamente com sucesso.'
      });
      return response && response['data'] ? response['data'] : [];
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao finalizar o leilão',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  async confirmRouteAuction(payload) {
    try {
      const response = await this.http.post(`${environment.api.meta.url}/auction/v1/routeAuction/shipper/setWinners`, payload).toPromise();
      this.notificationService.notification$.next({
        title: 'Confirmado com sucesso',
        type: 'success',
        message: 'O leilão foi confirmado com sucesso.'
      });
      return response && response['data'] ? response['data'] : [];
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao confirmar o leilão',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  async downloadRouteAuctions(auctions, access = 'embarcador') {
    try {
      const body = this.prepareRouteDataToCSV(auctions, access);
      const data = await this.http.post(environment.api.meta.url + '/auction/v1/routeAuction/downloadCsv/', body, { responseType: 'arraybuffer' }).toPromise();
      downloadFile(data, 'text/csv;charset=utf-8;', 'leilao.csv');
      this.notificationService.notification$.next({
        title: 'Download concluído',
        type: 'success',
        message: 'CSV gerado com sucesso!'
      });
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        title: 'Erro ao baixar cargas',
        type: 'error',
        message: e.message
      });
    }
  }

  prepareRouteDataToCSV(auctions, access) {
    if (access === 'embarcador') {
      if (auctions && auctions.length > 0) {
        const translate = {
          identifier: 'Identificador',
          type: 'Tipo',
          origin: 'Origem',
          destination: 'Destino',
          finalWeight: 'Peso leiloado',
          biddedWeight: 'Peso já leiloado',
          startDate: 'Dt Inicio',
          endDate: 'Dt Termino',
          totalBids: 'Lances',
          initialValue: 'Valor inicial',
          minBidValue: 'Melhor lance',
        };
        const data = auctions.map(auction => this.transformDataEmbarcadorRoute(auction));
        if (data && data.length > 0) {
          return { translate, data };
        }
      }
      return [];
    }
    if (access === 'conferencia') {
      if (auctions && auctions.length > 0) {
        const translate = {
          identifier: 'Identificador',
          type: 'Tipo',
          origin: 'Origem',
          destination: 'Destino',
          finalWeight: 'Peso leiloado',
          biddedWeight: 'Peso já leiloado',
          startDate: 'Dt Inicio',
          endDate: 'Dt Termino',
          totalBids: 'Lances',
          initialValue: 'Valor inicial',
          minBidValue: 'Melhor lance',
        };
        const data = auctions.map(auction => this.transformDataEmbarcadorRoute(auction));
        if (data && data.length > 0) {
          return { translate, data };
        }
      }
      return [];
    } else {
      if (auctions && auctions.length > 0) {
        const translate = {
          identifier: 'Identificador',
          type: 'Tipo',
          origin: 'Origem',
          destination: 'Destino',
          finalWeight: 'Peso leiloado',
          biddedWeight: 'Peso já leiloado',
          startDate: 'Dt Inicio',
          endDate: 'Dt Termino',
          totalBids: 'Lances',
          initialValue: 'Valor inicial',
          minBidValue: 'Melhor lance',
        };
        const data = auctions.map(auction => this.transformDataEmbarcadorRoute(auction));
        if (data && data.length > 0) {
          return { translate, data };
        }
      }
      return [];
    }
  }

  transformDataEmbarcadorRoute(auction) {
    const lot = {};
    lot['identifier'] = auction?.number || null;
    lot['type'] = auction?.type === 'Reverse' ? 'Reverso' : 'Tomada de preço';
    lot['origin'] = auction?.origin;
    lot['destination'] = auction?.destination;
    lot['finalWeight'] = auction?.finalWeight;
    lot['biddedWeight'] = auction?.biddedWeight;
    lot['startDate'] = auction && auction.auction ? moment(new Date(auction.auction.plannedStart)).add(3, 'h').format('DD/MM/YYYY - HH:mm') : null;
    lot['endDate'] = auction && auction.auction ? moment(new Date(auction.auction.plannedEnd)).add(3, 'h').format('DD/MM/YYYY - HH:mm') : null;
    lot['totalBids'] = auction?.totalBids;
    lot['initialValue'] = auction?.pricePerTon;
    lot['minBidValue'] = auction?.minBidValue;
    return lot;
  }

  async deleteAuction(lotId) {
    const body = {};
    try {
      const response = await this.http.put(environment.api.meta.url + environment.api.auctionLot + `applyCancelation/${lotId}`, body).toPromise();
      return response && response['data'] ? response['data'] : {};
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao deletar leilão',
        type: 'error',
        message: 'Recarregue a página. ' + error.message
      });
    }
  }

  async editShippingDate(auctionData) {
    try {
      const response = await this.http.put(environment.api.meta.url + environment.api.auctionLot + `applyModification/${auctionData.lotId}`, auctionData).toPromise();
      return response && response['data'] ? response['data'] : {};
    } catch (error) {
      this.notificationService.notification$.next({
        title: 'Falha ao deletar leilão',
        type: 'error',
        message: 'Recarregue a página. ' + error.error.message
      });
    }
  }
}
