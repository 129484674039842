import { ErrorHandler, Injectable } from '@angular/core';
import { ApmErrorHandler } from '@elastic/apm-rum-angular';
import { ApplicationMonitoringService } from '@shared/services/application-monitoring.service';
import { PwaService } from '@shared/services/pwa.service';

@Injectable()
export class LoadChunkErrorHandler extends ApmErrorHandler implements ErrorHandler {

    constructor(apmService: ApplicationMonitoringService,
        private pwaService: PwaService) {
            const apm = apmService.getAPM();
            super(apm);
    }

    handleError(error: any): void {
        super.handleError(error);
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (chunkFailedMessage.test(error.message)) {
            console.error(error);
            this.pwaService.isNewVersionAvailable = true;
        }
    }
}
