<div
class="profile-with-chart"
[attr.style]="styles">
  <div *ngIf="background" [attr.style]="background" class="background-image"></div>
  <span *ngIf="!background"
    class="material-icons has-text-grey-light">
    insert_photo
  </span>
  <div *ngIf="isVerified" class="verified">
    <i class="material-icons">check</i>
  </div>
</div>
