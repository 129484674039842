import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import moment from 'moment';

import { inputTimeFormat } from 'src/app/shared/utils';

@Component({
  selector: 'app-clean-datetimepicker',
  templateUrl: './clean-datetimepicker.component.html',
  styleUrls: ['./clean-datetimepicker.component.scss']
})
export class CleanDatetimepickerComponent implements OnInit {

  defaultInitialDate = inputTimeFormat();
  defaultEndingDate = inputTimeFormat(moment().add(1, 'year'));

  @Input() disabled = false;
  @Input() value = this.defaultInitialDate;
  @Input() initialDate = this.defaultInitialDate;
  @Input() endingDate = this.defaultEndingDate;
  @Input() type = null;
  @Input() min;

  @Output() handleDateChange = new EventEmitter();

  @ViewChild('datepicker') datepicker: ElementRef;

  constructor() { }

  ngOnInit(): void {
  }

  getDate = event => {
    let date = event.target.value;
    if (event.target.validationMessage) {
      this.datepicker.nativeElement.value = this.value;
      date = this.datepicker.nativeElement.value;
    }
    this.value = date;
    this.handleDateChange.emit(this.value);
  }

}
