import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopoverComponent implements OnInit {

  @Input() isVisible = false;
  @Input() hasBackdrop = true;
  @Output() handleCloseEmitter = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  handleClose() {
    this.handleCloseEmitter.emit();
  }

}
