import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-auction-empty-state',
  templateUrl: './auction-empty-state.component.html',
  styleUrls: ['./auction-empty-state.component.scss']
})
export class AuctionEmptyStateComponent implements OnInit {

  @Input() title: string;
  @Input() description: string;

  constructor() { }

  ngOnInit() {
  }

}
