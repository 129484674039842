<div class="app-auction-details-bid-list">
  <p>LANCES <b>({{bids.length || 0}})</b></p>

  <ul *ngIf="bids.length; else empty" >
    <li *ngFor="let bid of bids">
      <b>{{bid.value | currency: 'R$'}}/T</b> | {{bid.createdAt | date: 'dd/MM/yyyy HH:mm'}}
      (
        <ng-container *ngIf="bid.isUserBid">Meu Lance</ng-container>
        <ng-container *ngIf="bid.partnerName">{{bid.partnerName}}</ng-container>
      )
    </li>
  </ul>

  <ng-template #empty>
    <p class="mt-3">Nenhum lance disponível</p>
  </ng-template>
</div>
