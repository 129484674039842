import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-suggest-password-modal',
  templateUrl: './suggest-password-modal.component.html',
  styleUrls: ['./suggest-password-modal.component.scss']
})
export class SuggestPasswordModalComponent implements OnInit {

  emailSent = false;
  emailError = false;
  emailToDisplay: string;
  passwordCount: number;

  constructor(private router: Router, private userService: UserService, private authService: AuthService) { }

  ngOnInit(): void {
    const user = this.userService.getLoggedUser();
    this.emailToDisplay = user?.email;
    this.passwordCount = user?.changePassword;
    if (this.passwordCount === 0) {
      this.router.navigate(['/']);
    }
  }

  closeModal(): void {
    this.router.navigate(['/']);
  }

  closeAndLoggout(): void {
    this.authService.logout();
  }

  async resetPassword(): Promise<void> {
    const response = await this.userService.sendPasswordRecovery(this.emailToDisplay);
    if (response && response.data) {
      const { token } = response.data;
      this.authService.logout({ withoutRedirect: true }, () => {
        this.router.navigate(['reset-password'], { queryParams: { token } });
      });

    }
  }

}
