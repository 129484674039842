<!-- <div class="app-tooltip" (mouseenter)="handleMouseEnter()" (mouseleave)="handleMouseLeave()">
  <div class="app-tooltip__message" [ngClass]="{ 'active': isHovering}">
    <div class="app-tooltip__message-content">
      {{ text }}
    </div>
  </div>

  <ng-content></ng-content>
</div> -->

<div class="is-flex is-align-items-center">
  <label class="mr-2">
    {{label}}
  </label>

  <div class="tooltip">
    <ng-content ></ng-content>
    <div class="tooltiptext">
      {{text}}
    </div>
  </div>
</div>
