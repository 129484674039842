import { Component, Input, OnInit } from '@angular/core';
import { TimelineSubItem } from '../../models/timeline';

@Component({
  selector: 'app-timeline-subitem',
  templateUrl: './timeline-subitem.component.html',
  styleUrls: ['./timeline-subitem.component.scss']
})
export class TimelineSubitemComponent implements OnInit {

  @Input() data: TimelineSubItem;
  @Input() subItem: boolean;

  constructor() { }

  get iconClass(): string {
    const style = this.data.icon.type && this.data.icon.type === 'solid'
      ? 'material-icons'
      : 'material-icons-outlined';
    return `${style} ${this.data?.icon?.color}`;
  }

  get iconName(): string {
    return this.data?.icon?.name.trim();
  }

  ngOnInit(): void {
  }

}
