<div darkMode class="card wrapper">
    <div *ngIf="title" class="card-title">
        {{ title }}
    </div>
    <div class="card-content is-size-6">
      <div class="table-actions is-striped" *ngIf="showOptions && hasItems">
        <div class="is-flex is-align-items-center">
          <app-button
          title="Selecionar tudo"
          icon="check_box"
          variant="link"
          size="small"
          (handleClickEmitter)="selectAll()"></app-button>
        </div>
        <div class="is-flex is-align-items-center ml-2" *ngIf="deleteAllButton">
          <app-button
          title="Excluir selecionado(s)"
          icon="delete"
          variant="link"
          size="small"
          [disabled]="!isSomeChecked"
          (handleClickEmitter)="deleteAll()">
          </app-button>
        </div>
      </div>

      <ng-container *ngIf="!isLoading; else skeletonLoader">
        <div *ngIf="hasItems; else noData" class="table-wrapper">
          <table darkMode class="table is-fullwidth">
            <thead>
              <tr class="col-data">
                <th *ngIf="showSelectBox" colspan="1">
                  <input type="checkbox" disabled [checked]="isAllChecked" />
                </th>
                <th *ngFor="let key of colData">
                  <div
                  class="is-flex is-align-items-center"
                  [ngClass]="{'is-clickable': key.sort}"
                  (click)="handleSort(key)"
                  >
                    {{ key.header }}
                    <i *ngIf="key.sort"
                    class="sort-icon material-icons is-size-5 ml-2"
                    [ngClass]="{'active': key.sort?.active}"
                    >
                      {{ key.sort.active === 'desc' ? 'arrow_upward' : 'arrow_downward' }}
                    </i>
                  </div>
                </th>
                <th *ngIf="(showEditOption || showDeleteOption) && showActions" class="is-flex is-justify-content-center">
                  Ações
                </th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let record of gridData; let i = index" class="grid-data">
                <td *ngIf="showSelectBox" (click)="select($event, i)"><input type="checkbox" [checked]="record.checked" /></td>
                <td [ngClass]="{'warning': record.isWarning}" *ngFor="let key of colData">
                  <span class="is-flex is-align-items-center">
                    <ng-container *ngIf="(getIconFromField(record, key)) as iconObj">
                      <i *ngIf="iconObj.icon" class="material-icons is-size-4 mr-2" [ngClass]="iconObj.iconColor">
                        {{iconObj.icon}}
                      </i>
                    </ng-container>
                    <ng-container *ngIf="!key.hasCustomAction; else customAction">
                      {{ getValueFromField(record, key) }}
                    </ng-container>
                    <ng-template #customAction>
                      <app-button
                      variant="link"
                      size="small"
                      [title]="key.hasCustomAction?.title"
                      (handleClickEmitter)="handleAction(record, key.hasCustomAction?.action)"
                      >
                      </app-button>
                    </ng-template>
                    <ng-container *ngIf="key.hasTag">
                      <span *ngIf="record.tag" class="tag is-light has-text-weight-bold">
                        {{record.tag}}
                      </span>
                    </ng-container>
                  </span>
                </td>
                <td *ngIf="showActions">
                  <div class="is-flex is-align-items-center is-justify-content-center">
                    <app-switch *ngIf="showToggleOption"
                    class="mr-6"
                    [toggleLabel]="record.toggleText"
                    [disabled]="record.toggleIsDisabled || record.isDisabled"
                    [isExtraSmall]="true"
                    [(isChecked)]="record.toggleIsChecked"
                    (handleCheck)="handleToggle(record, $event)"
                    >
                    </app-switch>
                    <app-button *ngIf="showEditOption"
                      icon="edit"
                      size="small"
                      variant="link"
                      color="warning"
                      [disabled]="record.isDisabled"
                      (handleClickEmitter)="edit(record)"
                    >
                    </app-button>
                    <app-button *ngIf="showDeleteOption"
                      size="small"
                      variant="link"
                      color="danger"
                      icon="delete"
                      [disabled]="record.isDisabled"
                      (handleClickEmitter)="delete(record)">
                    </app-button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-container>

      <ng-template #skeletonLoader>
        <div class="m-4">
          <ngx-skeleton-loader class="skeleton" count="1" appearance="line">
          </ngx-skeleton-loader>
        </div>
        <div class="mx-4">
          <ngx-skeleton-loader class="skeleton" count="7" appearance="line">
          </ngx-skeleton-loader>
        </div>
      </ng-template>

      <ng-template #noData>
        <div class="is-flex is-align-items-center is-justify-content-center full-width no-table-data">
          <h2 class="is-size-5">
            {{emptyStateMessage}}
          </h2>
        </div>
      </ng-template>

    </div>
</div>
