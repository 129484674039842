<div class="is-flex is-align-items-center" *ngIf="canShow">
  <app-switch class="has-tooltip-arrow has-tooltip-left" 
    [isChecked]="isActive" 
    [isExtraSmall]="true"
    [attr.data-tooltip]="
      isDisabled
        ? 'Você possui leilões ganhos/finalizados para esta mesma rota e data.'
        : null
    " [disabled]="isDisabled" (handleCheck)="toggleAutomaticBid($event)">
  </app-switch>
  <span class="ml-1" [ngClass]="{ 'has-text-grey': isDisabled }">Lance automático</span>
  <span *ngIf="canEdit" class="is-clickable" (click)="editAutomaticBidConfig()">
    <i class="material-icons is-size-4">edit</i>
  </span>
</div>