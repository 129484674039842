<div class="modal" [ngClass]="{'is-active': isVisible}">
    <div class="modal-background"></div>
    <div class="modal-card is-radius-large is-shadowed-small" [ngClass]="{
      'modal-xsmall': isXsmall,
      'modal-small': isSmall,
      'modal-medium': !isXsmall && !isSmall && !isLarge && isMedium,
      'modal-large': isLarge,
      'overflow-none': noScroll
    }">
        <header darkMode *ngIf="!noHeader" class="modal-card-head">
          <p class="modal-card-title">
              {{title}}
          </p>

          <!-- Close button with header -->
          <button *ngIf="!noClose"
          class="delete"
          aria-label="close"
          (click)="close()"
          >
          </button>
        </header>

        <div
        class="sub-header"
        *ngIf="hasTabs && !noHeader"
        >
          <ng-container *ngTemplateOutlet="tabs"></ng-container>
        </div>

        <section darkMode class="modal-card-body"
        [ngClass]="{
          'p-0': noPadding,
          'pt-0': !noHeader,
          'overflow-none': noScroll
        }">
          <!-- Close button without header -->
          <button *ngIf="!noClose && noHeader"
          class="delete is-pulled-right"
          aria-label="close"
          (click)="close()"
          >
          </button>

          <!-- Tabs without header -->
          <ng-container *ngIf="hasTabs && noHeader">
            <ng-container *ngTemplateOutlet="tabs"></ng-container>
          </ng-container>

          <!-- Content -->
          <ng-content></ng-content>
        </section>
      </div>
  </div>

  <ng-template #tabs>
    <ng-content select="app-tabs"></ng-content>
  </ng-template>
