import { Component, Input } from '@angular/core';

type WayPoint = {
  partnerCity: string;
  partnerCode: string;
  partnerName: string;
  partnerUF: string;
};

type RouteData = {
  destination?: string;
  origin?: string;
};

@Component({
  selector: 'app-origin-destination-route',
  templateUrl: './origin-destination-route.component.html',
  styleUrls: ['./origin-destination-route.component.scss'],
})
export class OriginDestinationRouteComponent {
  @Input() wayPoint: WayPoint[];
  @Input() data: RouteData;
  @Input() type: string;
  @Input() isHorizontal = false;

  constructor() {}
}
