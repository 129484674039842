<ng-container>
    <div darkMode class="columns pagination is-small is-centered" role="navigation" aria-label="pagination">
        <div class="column pagination-container">
            <button (click)="previousPages()" [disabled]="actualPage < 10">
                <<
            </button>
            <button (click)="previousPage()" [disabled]="actualPage < 2">
                <
            </button>

            <ng-container *ngIf="actualPage > 2">
              <button (click)="previousPages(actualPage)">
                1
              </button>

              <span *ngIf="actualPage > 3">...</span>
            </ng-container>

            <button (click)="previousPage()" *ngIf="actualPage > 1">
                {{ actualPage - 1 }}
            </button>
            <button class="is-current" *ngIf="actualPage === actualPage">
                {{ actualPage }}
            </button>
            <button (click)="nextPage()" *ngIf="countPages > 1 && actualPage < countPages">
                {{ actualPage + 1 }}
            </button>

            <ng-container *ngIf="actualPage < countPages - 1">
              <span *ngIf="actualPage < countPages - 2">...</span>

              <button (click)="nextPages(countPages - actualPage)">
                {{ countPages }}
              </button>
            </ng-container>

            <button (click)="nextPage()" [disabled]="actualPage === countPages">
                >
            </button>
            <button (click)="nextPages()" [disabled]="actualPage + 10 > countPages">
                >>
            </button>
        </div>
    </div>
</ng-container>
