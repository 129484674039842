/// <reference types="@types/googlemaps" />
import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root'})
export class Google {

  public google: typeof google = google;

  constructor() {
  }

}
