import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-wrapper',
  templateUrl: './data-wrapper.component.html',
  styleUrls: ['./data-wrapper.component.scss']
})
export class DataWrapperComponent {
  @Input() isCol = false;
  @Input() isSpaceBetween = false;

  constructor() { }
}
