import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { APIDataRegister } from 'src/app/interfaces/ApiResponse';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  readonly API = `${environment.api.meta.url}${environment.api.transportationApi}`;

  messages = [];

  trackingEvent$ = new Subject<any>();

  constructor(private http: HttpClient) { }

  pushMessage(message) {
    this.messages.push(message);
  }

  processMessages() {
    if (this.messages.length > 0) {
      const message = this.messages.shift();
      this.trackingEvent$.next(message);
    }
  }

  async listOccurrences(offset = 0, limit = 1000) {
    try {
      const response = await this.http.get(`${this.API}tracking/occurrence/${offset}/${limit}`).toPromise();
      if (response && response['data']) {
        return response['data'];
      }
      return [];
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async checkLastSeenDate(occurrenceId) {
    try {
      const response = await this.http.patch(`${this.API}tracking/shipper/occurrences/${occurrenceId}/seenDate`, {}).toPromise();
      if (response && response['data']) {
        return response['data'];
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
