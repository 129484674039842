import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'loadState'
})
export class LoadStatePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    switch (value) {
      case 'available':
        return 'Disponível';
      case 'moving':
        return 'Em trânsito';
      case 'full':
        return 'Com carga';
      case 'empty':
        return 'Vazio';
      case 'loading':
        return 'Carregando';
      case 'unloading':
        return 'Descarregando';
      case 'finished':
        return 'Finalizado';
      case 'scheduled':
        return 'Agendado';
      case 'loadWaiting':
        return 'Aguard. carregamento';
      case 'stopped':
        return 'Parado';
      case 'stopped_loading':
        return 'Parado (carregando)';
      case 'stopped_unloading':
        return 'Parado (descarregando)';
      case 'stopped_loadWaiting':
        return 'Parado (aguard. carreg.)';
      case 'stopped_ended':
        return 'Parado (carreg. finalizado)';
      case 'stopped_finished':
        return 'Parado (finalizado)';
      case 'stopped_scheduled':
        return 'Parado (agendado)';
      case 'stopped_full':
        return 'Parado (com carga)';
      case 'stopped_empty':
        return 'Parado (vazio)';
      case 'moving_empty':
        return 'Em trânsito (vazio)';
      case 'moving_full':
        return 'Em trânsito (com carga)';
      case 'moving_finished':
        return 'Em trânsito (finalizado)';
      case 'moving_scheduled':
        return 'Em trânsito (agendado)';
      case 'no signal':
        return 'Sem sinal';
      case 'ended':
        return 'Finalizado';
      default:
        return value;
    }
  }

}
