import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UserService } from '../services/user/user.service';
import { GlobalNotificationService } from '../shared/services/global-notification.service';
import { NotificationsListComponent } from './components/notifications-list/notifications-list.component';
import { DarkModeService } from '@services/dark-mode.service';
import { Menu, MenuItem, items } from './menu-items';
import { HttpClient } from '@angular/common/http';
import { downloadFile } from '@shared/utils';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @ViewChild('notificationsList') notificationsList: NotificationsListComponent;

  // Menu props
  isOpen = false;
  user = null;

  // Notifications
  isNotificationsOpened = false;
  notificationsType = 'all';

  items: Menu = items;

  userProfiles = [];

  notifications = [];
  hasNotReadNotifications = false;
  hasUrgentNotification = false;
  totalNotReadNotifications$ = null;
  totalNotifications$ = null;

  constructor(
    private authService: AuthService,
    private notificationService: GlobalNotificationService,
    private router: Router,
    private userService: UserService,
    private darkMode: DarkModeService,
    private httpClient: HttpClient) { }

  get isDarkMode() {
    return this.darkMode.isDark;
  }  

  get username(): string {
    return this.user?.fullName;
  }

  get email(): string {
    return this.user?.email;
  }

  get company(): string {
    return this.user?.shippingCompany?.name;
  }

  get activeItemIdx(): number {
    return this.items.findIndex(item => this.router.url.includes(item?.path) || item?.items?.some(subItem => this.router.url.includes(subItem.path)));
  }

  @HostListener('window:keydown', ['$event'])
  onKeyPress($event: KeyboardEvent) {
    if (($event.ctrlKey || $event.metaKey) && $event.key.toLowerCase() === 'b') {
      this.handleToggleMenu();
    }
    if (($event.shiftKey || $event.metaKey) && $event.key.toLowerCase() === 'n') {
      this.toggleNotifications('all');
    }
  }

  ngOnInit(): void {
    this.mount();
    this.items.find(i => i.label === 'Ajuda').items.find(i => i.label === 'Manual de abertura de chamado').onClick = () => {
      this.httpClient.get('assets/img/ticket-opening/Manual - Portal de Chamados LoadShark.pdf', { responseType: 'arraybuffer' }).subscribe({
        next: blob => {
          downloadFile(blob, 'application/pdf', 'Manual - Portal de Chamados LoadShark.pdf');
        }
      });      
    };

  }

  getUserProfiles() {
    this.userProfiles = this.userService.getUserProfiles();
  }

  getUserPagePermissions() {
    // O map abaixo se deve para não referenciar ao mesmo array do serviço
    this.items = this.items.filter(page => {

      if (!page.items) {
        const configRoute = this.router.config.find(r => page.path === r.path) ?? this.router.config.find(r => page.path?.split('/')[0] === r.path);

        if (configRoute?.data && 'visibleOnlyWithSSO' in configRoute?.data) {
          const user = this.userService.getLoggedUser();
          return user.authenticationType === 'SSO';
        }

        if (configRoute) {
          if (configRoute.data?.allowedProfiles.includes('*')) { return true; }
          const allowed = configRoute.data?.allowedProfiles.some(p => this.userProfiles.includes(p));
          return allowed;
        }
        return false;
      }

      // Filtra as paginas que o usuario tem acesso e ativa a url atual
      page.items = page.items.filter(subItem => {
        const configRoute = this.router.config.find(r => subItem.path === r.path) ?? this.router.config.find(r => subItem.path?.split('/')[0] === r.path);

        if (configRoute?.data && 'visibleOnlyWithSSO' in configRoute?.data) {
          const user = this.userService.getLoggedUser();
          return user.authenticationType === 'SSO';
        }

        if (configRoute) {
          if (configRoute.data?.allowedProfiles.includes('*')) { return subItem; }
          const allowed = configRoute.data?.allowedProfiles.some(p => this.userProfiles.includes(p));
          return allowed;
        }

        if (!subItem.path && subItem.onClick) {
          return true;
        }

        return false;
      });

      return page.items.length;

    });
  }

  getNotifications() {
    this.notifications = this.notificationService.listNotifications();
  }

  handleTotalNotifications() {
    this.totalNotifications$ = this.notificationService.getTotalNotifications();
    this.totalNotReadNotifications$ = this.notificationService.getTotalNotReadNotifications();
  }

  handleNotReadNotifications() {
    this.hasNotReadNotifications = this.notifications && this.notifications.filter(n => !n.readAt).length > 0;
    this.hasUrgentNotification = this.notifications && this.notifications.filter(n => !n.readAt && n.type === 'error').length > 0;
  }

  handleNotificationsUpdates() {
    this.notificationService.notificationQueueChange$.subscribe(data => {
      this.notifications = data.notifications;
      this.hasNotReadNotifications = this.notifications && this.notifications.filter(n => !n.readAt).length > 0;
      this.hasUrgentNotification = this.notifications && this.notifications.filter(n => !n.readAt && n.type === 'error').length > 0;
      if (this.hasNotReadNotifications) {
        this.updateNotificationsList();
      }
    });
  }

  handleToggleMenu() {
    this.toggleMenu();
    this.toggleItem(-1);
  }

  handleSelectItem(idx: number, childIdx?: number) {
    this.toggleItem(idx);

    if (childIdx === undefined) {
      this.handleRedirect(this.items[idx]);
      return;
    }

    this.handleRedirect(this.items[idx].items[childIdx]);
  }

  toggleMenu() {
    this.isOpen = !this.isOpen;
  }

  toggleItem(idx: number) {
    this.items.forEach((item, i) => item.toggled = idx === i);
  }

  handleRedirect(item: MenuItem) {
    if (item.path) {
      this.router.navigate([item.path]);
    }
    if (item.onClick) {
      item.onClick();
    }

    if (this.isOpen) {
      this.handleToggleMenu();
    } else {
      this.toggleItem(-1);
    }
  }

  logout() {
    this.authService.logout();
  }

  toggleNotifications(type) {
    this.isNotificationsOpened = !this.isNotificationsOpened;
    this.notificationsType = type;
  }

  activeSubItemIdx(idx: number, subIdx: number) {
    return this.router.url.includes(this.items[idx].items[subIdx].path);
  }

  updateNotificationsList() {
    if (this.isNotificationsOpened) {
      this.notificationsList.composeNotifications();
    }
  }

  update() {
    this.mount();
  }

  toggleDarkMode() {
    this.darkMode.toggleDarkMode();
  }

  private mount() {
    this.user = this.userService.getLoggedUser();
    this.getUserProfiles();
    this.getUserPagePermissions();
    this.getNotifications();
    this.handleTotalNotifications();
    this.handleNotReadNotifications();
    this.handleNotificationsUpdates();
  }



}
