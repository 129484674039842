<div class="notification-list-container">
  <div class="notification-list">
    <div class="columns is-multiline">
      <div class="column is-4">
        <div class="m-5">
          <app-button title="Voltar" variant="link" [isDefault]="true"
          [isSmall]="true" (handleClickEmitter)="onOpen()"></app-button>
        </div>
      </div>
      <div class="column is-12">
        <app-input
        placeholder="Buscar notificação"
        trailingIcon="search"
        [(value)]="searchedText"
        [canClickTrailingIcon]="true"
        (trailingIconClick)="getNotifications(0, 10, 'all', true)"
        (keyup.enter)="getNotifications(0, 10, 'all', true)">
        </app-input>
      </div>
      <div class="column is-12">
        <app-tabs [tabs]="notificationsTabs" (changeTabEmitter)="changeTab($event)"></app-tabs>
      </div>
      <div class="column is-12">
        <div *ngFor="let module of notificationsByModule">
          <app-notifications-collapsible [module]="module.module" [notifications]="module.notifications"
            [total]="module.total" [showNotifications]="module.collapsed" (paginationEmitter)="handlePagination($event)"
            (deleteEmitter)="deleteNotifications($event)" (redirectEmitter)="goToRoute($event)">
          </app-notifications-collapsible>
        </div>
      </div>
    </div>
  </div>
</div>
