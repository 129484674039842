<div class="field">
    <div class="is-flex is-align-items-center">
        <label class="label">{{label || ''}}</label>
        <input *ngIf="inputType === 'text'" class="input is-rounded" type="text" [(ngModel)]="inputModel"
            [ngClass]="{'is-danger': isInvalid}" [placeholder]="description || ''">
        <input *ngIf="inputType === 'number'" class="input is-rounded" type="number" [min]="minValue" [max]="maxValue"
            [(ngModel)]="inputModel" [ngClass]="{'is-danger': isInvalid}" [placeholder]="description || ''">
        <input *ngIf="inputType === 'currency'" class="input is-rounded" type="text" [(ngModel)]="inputModel"
            [ngClass]="{'is-danger': isInvalid}" prefix="R$" mask="separator.2" separatorLimit="10000000000"
            thousandSeparator="." decimalMarker=",">
    </div>
    <p *ngIf="isInvalid" class="help is-danger">{{validationMessage}}</p>
    <p *ngIf="!isInvalid" class="help">{{helpMessage}}</p>
</div>