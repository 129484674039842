<div
class="timeline-content"
[ngClass]="{'sub-item': subItem}"
>
  <ng-container *ngIf="data?.icon">
    <i class="is-size-4" [ngClass]="iconClass">{{ iconName }}</i>
  </ng-container>

  <div class="data-wrapper">
    <label
    *ngIf="data?.label"
    class="is-size-7 is-uppercase has-text-grey"
    >
      {{ data?.label | date:'dd/MM/YYYY'}}
    </label>

    <span class="is-size-6 has-text-weight-medium is-uppercase">
      {{ data?.content }}
    </span>

    <div class="is-flex">
      <span class="is-size-7 has-text-grey">
        ATRIBUIÇÃO:&nbsp;
      </span>
      <span class="is-size-7 has-text-weight-medium is-uppercase">
        {{ data?.responsible }}
      </span>
    </div>
  </div>
</div>
