<app-header [title]="'Página não encontrada'"></app-header>

<div class="page-404">
  <div class="page-404__wrapper">
    <div class="left-side">
      <div class="not-found">
        <h1 class="has-text-primary">
          404
        </h1>
        <h3 class="is-size-2 has-text-weight-bold">
          Oops! Via interditada
        </h3>
      </div>
      <p class="is-size-4">
        Alguma coisa deu errado com essa página.<br>
        Você pode voltar para a página inicial, ou <br>
        navegar na página que você visitou anteriormente.
      </p>
      <div class="buttons-wrapper is-flex mt-6">
        <div class="mr-1">
          <app-button variant="outlined" title="Voltar" icon="keyboard_arrow_left" (handleClickEmitter)="goBack()"></app-button>
        </div>
        <div class="ml-1">
          <app-button title="Home" icon="home" (handleClickEmitter)="goHome()"></app-button>
        </div>
      </div>
    </div>
    <div class="right-side">
      <img class="not-found-animation" src="./../../../assets/gif/404-isometric.gif" alt="Not found">
    </div>
  </div>
</div>
