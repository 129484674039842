import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-email-confirmation-sent',
  templateUrl: './email-confirmation-sent.component.html',
  styleUrls: ['./email-confirmation-sent.component.scss']
})
export class EmailConfirmationSentComponent implements OnInit {

  @Input() emailSent: string;
  @Output() handleFinish = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  finish() {
    this.handleFinish.emit();
  }

}
