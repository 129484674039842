import { Component, Input, OnInit } from '@angular/core';

export type TypographyType = 'headline' | 'subtitle' | 'paragraph' | 'caption' | 'label' | 'span';

export type TypographyVariant = 'first' | 'second' | 'third' | 'fourth' | 'fifth';

@Component({
  selector: 'app-typography',
  templateUrl: './typography.component.html',
  styleUrls: ['./typography.component.scss']
})
export class TypographyComponent implements OnInit {

  @Input() type: TypographyType = 'span';
  @Input() variant: TypographyVariant = 'first';

  constructor() { }

  ngOnInit(): void {
  }

}
