import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Profiles } from 'src/app/models/profiles';
import { UserService } from 'src/app/services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class TrackingGuardService implements CanActivate {

  constructor(
    private userService: UserService,
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const profiles = this.userService.getUserProfiles();
    const isShipper = [Profiles.Administrador, Profiles.EmbarcadorTracking];
    const isCarrier = [Profiles.Administrador, Profiles.TransportadoraTracking];

    if (profiles.some(p => isShipper.includes(p))) {
      this.router.navigate(['/tracking/embarcador']);
      return false;
    }

    if (profiles.some(p => isCarrier.includes(p))) {
      this.router.navigate(['/tracking/transportadora']);
      return false;
    }

    return false;
  }

}
