import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-double-button',
  templateUrl: './double-button.component.html',
  styleUrls: ['./double-button.component.scss']
})
export class DoubleButtonComponent implements OnInit {

  @Input() titles: [string];
  @Input() isDisabled: boolean;
  @Output() clickOne = new EventEmitter();
  @Output() clickSecundary = new EventEmitter();


  constructor() { }

  ngOnInit(): void {
  }

  handleClick(index) {
    if (!this.isDisabled) {
      if (index) {
        this.clickSecundary.emit();
      } else {
        this.clickOne.emit();
      }
    }
  }

}
