<div class="hero is-fullheight-with-navbar">
  <app-side-image [pathImages]="pathImages">
    <div class="hero-body columns login">
      <div class="is-hidden-touch"></div>
      <section class="column is-7-tablet login-body swipeInLeft">
        <main id="main">
          <form [formGroup]="form" novalidate>
            <fieldset>
              <legend class="is-sr-only">Entrar na minha conta</legend>
              <div class="field is-pulled-left">
                <a [routerLink]="['/']">
                  <app-public-logo></app-public-logo>
                </a>
              </div>
              <div class="is-clearfix"></div>

              <div class="field is-pulled-left mb-6">
                <p class="is-size-4 has-text-grey">
                  Negociação inteligente de fretes
                </p>
              </div>
              <div class="is-clearfix"></div>

              <article class="message is-danger" *ngIf="hasError && !validatingToken">
                <div class="message-body">
                  <b>{{errorToDisplay}}</b>
                </div>
              </article>

              <article class="message is-success" *ngIf="validatingToken && !hasError">
                <div class="message-body">
                  <b>Validando token...</b>
                </div>
              </article>

              <ng-container *ngIf="!resetedPassword && !hasTokenError && !validatingToken">
                <div class="field is-pulled-left">
                  <p class="is-size-4">Olá, {{user}}, digite a sua nova senha</p>
                </div>
                <div class="field">
                  <app-input
                  leadingIcon="lock"
                  placeholder="Digite a nova senha"
                  [control]="newPassword"
                  [canClickTrailingIcon]="true"
                  [validation]="commonControlValidation"
                  [type]="!passwordVisibility ? 'password' : 'text'"
                  (handleKeyup)="getStrength()">
                  </app-input>
                </div>
                <div class="field">
                  <app-input
                  leadingIcon="lock"
                  placeholder="Confirme a nova senha"
                  [control]="confirmPassword"
                  [validation]="commonControlValidation"
                  [type]="!passwordVisibility ? 'password' : 'text'">
                  </app-input>
                </div>
                <div class="has-text-left" *ngIf="newPassword.value?.length">
                  <div
                    class="bar mt-3"
                    [ngClass]="{
                      'one': passwordEstimation === 0,
                      'two': passwordEstimation === 1,
                      'three': passwordEstimation === 2,
                      'four': passwordEstimation === 3,
                      'five': passwordEstimation === 4,
                      'has-background-danger': passwordEstimation < 2,
                      'has-background-warning': passwordEstimation === 2,
                      'has-background-success': passwordEstimation > 2
                    }">
                  </div>
                  <div class="mt-3 mb-3 is-size-5 has-text-weight-bold">
                    <div *ngIf="passwordEstimation === 0">Muito Fraca</div>
                    <div *ngIf="passwordEstimation === 1">Fraca</div>
                    <div *ngIf="passwordEstimation === 2">Média</div>
                    <div *ngIf="passwordEstimation === 3">Forte</div>
                    <div *ngIf="passwordEstimation === 4">Muito Forte</div>
                  </div>
                  <div class="requirements mt-3 is-size-5">
                    <div class="mb-2 item" [class.isCorrect]="validation.hasUppercase"><span class="material-icons mr-2">{{validation.hasUppercase ? 'done' : 'clear'}}</span>1 letra maiúscula</div>
                    <div class="mb-2 item" [class.isCorrect]="validation.hasLowercase"><span class="material-icons mr-2">{{validation.hasLowercase ? 'done' : 'clear'}}</span>1 letra minúscula</div>
                    <div class="mb-2 item" [class.isCorrect]="validation.hasNumber"><span class="material-icons mr-2">{{validation.hasNumber ? 'done' : 'clear'}}</span>1 número</div>
                    <div class="mb-2 item" [class.isCorrect]="validation.hasEightDigits"><span class="material-icons mr-2">{{validation.hasEightDigits ? 'done' : 'clear'}}</span>8 caracteres</div>
                  </div>
                </div>
                <div class="is-flex is-justify-content-space-between is-align-items-center mt-6">
                  <div>
                    <app-button [title]="passwordVisibility ? 'Ocultar senhas' : 'Exibir senhas'" type="submit" variant="outlined"
                    (handleClickEmitter)="passwordVisibility = !passwordVisibility">
                    </app-button>
                  </div>
                  <div>
                    <app-button title="Enviar" type="submit" [disabled]="form.invalid"
                    (handleClickEmitter)="resetPassword()">
                    </app-button>
                  </div>
                </div>
              </ng-container>
              <div *ngIf="resetedPassword && !hasTokenError && !validatingToken">
                <div class="field is-pulled-left">
                  <i class="material-icons has-text-primary is-large">check_circle</i>&nbsp;
                  <span class="is-size-4">
                    Sua senha foi redefinida com sucesso, você será redirecionado para o login. Aguarde...
                  </span>
                </div>
              </div>
            </fieldset>
          </form>
        </main>
      </section>
    </div>
  </app-side-image>
</div>
