export interface MenuItem {
    label: string;
    isActive: boolean;
    icon?: string;
    path?: string;
    toggled?: boolean;
    onClick?(): void;
  }
  
export type Menu = Array<MenuItem & { items?: MenuItem[] }>;

export const items = [
    { label: 'Home', icon: 'home', path: 'home', isActive: true },
    {
      label: 'Contratação',
      icon: 'local_shipping',
      isActive: false,
      items: [
        {
          label: 'Transportadoras',
          path: 'contratacao',
          isActive: false,
        },
        {
          label: 'Formulários técnicos',
          path: 'processos-de-homologacao',
          isActive: false,
        },
        {
          label: 'Processos de homologação',
          path: 'em-homologacao',
          isActive: false,
        },
      ]
    },
    {
      label: 'Atribuição de cargas',
      icon: 'all_inbox',
      isActive: false,
      items: [
        {
          label: 'Tabela de frete',
          path: 'tabela-frete',
          isActive: false
        },
        {
          label: 'Leilão de cargas',
          path: 'leilao',
          isActive: false
        },
        {
          label: 'Conferência de leilões',
          path: 'leilao/conferencia',
          isActive: false
        },
        {
          label: 'Repasse de resinas',
          path: 'repasse/resinas',
          isActive: false
        },
        {
          label: 'Repasse de químicos',
          path: 'repasse/quimicos',
          isActive: false
        },
      ]
    },
    {
      label: 'Monitoramento',
      icon: 'pin_drop',
      isActive: false,
      items: [
        {
          label: 'Dashboard',
          path: 'dashboard',
          isActive: false
        },
        {
          label: 'Relatórios',
          path: 'relatorios',
          isActive: false
        },
        {
          label: 'Minha frota',
          path: 'meus-caminhoes',
          isActive: false
        },
        {
          label: 'Tracking',
          path: 'tracking',
          isActive: false
        }
      ]
    },
    {
      label: 'Descarga',
      icon: 'inventory_2',
      isActive: false,
      items: [
        {
          label: 'Agendamento de descarga',
          path: 'etanol',
          isActive: false
        },
        {
          label: 'Janelas de descarga',
          path: 'janelas',
          isActive: false
        },
      ]
    },
    {
      label: 'Configurações',
      icon: 'settings',
      isActive: false,
      items: [
        {
          label: 'Configurações do sistema',
          path: 'sistema',
          isActive: false
        },
        {
          label: 'Minha conta',
          path: 'minha-conta',
          isActive: false
        },
        {
          label: 'Processos de homologação',
          path: 'homologacoes',
          isActive: false
        },
        {
          label: 'Perfil',
          path: 'perfil/transportadora',
          isActive: false
        },
        {
          label: 'Módulos',
          path: 'modulos',
          isActive: false
        },
      ]
    },
    {
      label: 'Ajuda',
      icon: 'help_center',
      isActive: false,
      items: [
        {
          label: 'Abra seu chamado',
          path: null,
          onClick: () => window.open('https://loadshark.topdesk.net/tas/public/login/form','_blank'),
          isActive: false
        },
        {
          label: 'Manual de abertura de chamado',
          path: null,
          onClick: () => {},
          isActive: false
        },
        // {
        //   label: 'FAQ do repasse',
        //   path: 'faq/repasse',
        //   isActive: false
        // },
        // {
        //   label: 'Manual do usuário',
        //   path: null,
        //   onClick: () => window.open('https://sites.google.com/view/central-de-ajuda-loadshark/p%C3%A1gina-inicial','_blank'),
        //   isActive: false
        // },
        {
          label: 'Política de privacidade de dados',
          path: 'privacy-police',
          isActive: false
        }
      ]
    },
  ];

export const getTitle = (path: string) => {
    const defaultTitle = 'Pacey';
    let title = defaultTitle;

    const foundItem = items.find(i => i.path === path);
    if (foundItem) {
        title = foundItem.label + ` | ${defaultTitle}`;
    }

    items.forEach(i => {
        const found = i?.items?.find(s => s.path === path);
        if (found) {
            title = found.label + ` | ${defaultTitle}`;
        }
    });

    return title;
};
