import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Tab } from '@shared/components/tabs/tabs.component';
import { AuthService } from '../core/auth/auth.service';
import { DarkModeService } from '@services/dark-mode.service';

@Component({
  selector: 'app-tab-menu',
  templateUrl: './tab-menu.component.html',
  styleUrls: ['./tab-menu.component.scss']
})
export class TabMenuComponent implements OnInit {

  menuTabs: Tab[] = [
    { 
      title: 'Home',
      icon: 'home',
      route: 'home',
      isActive: false,
    },
    { 
      title: 'Leilão',
      icon: 'gavel',
      route: 'leilao',
      isActive: false,
    },
    { 
      title: 'Tema',
      icon: this.isDarkMode ? 'light_mode' : 'dark_mode',
      value: 'darkMode',
      isActive: false,
    },
    { 
      title: 'Logout',
      icon: 'logout',
      value: 'logout',
      isActive: false,
    },
  ];

  menuTabsActions = {};
  menuTabVisible = false;

  constructor(private router: Router, private authService: AuthService, private darkMode: DarkModeService) {
    this.menuTabsActions['logout'] = () => this.authService.logout();
    this.menuTabsActions['darkMode'] = () => this.darkMode.toggleDarkMode();
  }

  get activeTabItem(): string {
    this.menuTabs.forEach(item => item.isActive = item?.route && this.router.url.includes(item?.route));
    return this.menuTabs.find(item => item.isActive)?.title;
  }

  get isDarkMode(): boolean {
    return this.darkMode.isDark;
  }

  ngOnInit() {
    this.darkMode.toggled$.subscribe({
      next: isDarkMode => {
        const idx = this.menuTabs.findIndex(mt => mt.value === 'darkMode');
        if (idx !== -1) {
          this.menuTabs[idx].icon = isDarkMode ? 'light_mode' : 'dark_mode';
        }
      }
    });
  }

  handleMenuTab(tab: Tab) {
    if (tab.route) {
      this.router.navigate([tab.route]);
    }
    if (tab.value) {
      const action = this.menuTabsActions[tab.value];
      if (action) {
        action();
      }
    }
  }

  toggleMenuTab() {
    this.menuTabVisible = !this.menuTabVisible;
  }

}
