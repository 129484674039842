import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { AuctionRedirectComponent } from './auction-redirect/auction-redirect.component';
import { AuctionConsentTermComponent } from './auction-consent-term/auction-consent-term.component';
import { HeaderModule } from 'src/app/core/header/header.module';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { AuctionDividerComponent } from './components/auction-divider/auction-divider.component';
import { AuctionRowComponent } from './components/auction-row/auction-row.component';
import { AuctionActionsComponent } from './components/auction-actions/auction-actions.component';
import { AuctionCategoryComponent } from './components/auction-category/auction-category.component';
import { AuctionIdTagComponent } from './components/auction-id-tag/auction-id-tag.component';
import { AuctionDetailsBidsListComponent } from './components/auction-details-bids-list/auction-details-bids-list.component';
import { AuctionShippingRulesComponent } from './components/auction-shipping-rules/auction-shipping-rules.component';
import { AuctionDetailsContainerComponent } from './components/auction-details-container/auction-details-container.component';
import { AuctionStatusComponent } from './components/auction-status/auction-status.component';
import { AuctionEmptyStateComponent } from './components/auction-empty-state/auction-empty-state.component';
import { AuctionHeaderComponent } from './components/auction-header/auction-header.component';
import { AuctionListComponent } from './components/auction-list/auction-list.component';
import { AuctionAutobidToggleComponent } from './components/auction-autobid-toggle/auction-autobid-toggle.component';
import { AuctionSkeletonComponent } from './components/auction-skeleton/auction-skeleton.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

const components = [
  AuctionDividerComponent,
  AuctionRowComponent,
  AuctionActionsComponent,
  AuctionCategoryComponent,
  AuctionIdTagComponent,
  AuctionDetailsBidsListComponent,
  AuctionShippingRulesComponent,
  AuctionDetailsContainerComponent,
  AuctionStatusComponent,
  AuctionEmptyStateComponent,
  AuctionHeaderComponent,
  AuctionListComponent,
  AuctionAutobidToggleComponent,
  AuctionSkeletonComponent
];

@NgModule({
  declarations: [
    EditProfileComponent,
    AuctionRedirectComponent,
    AuctionConsentTermComponent,
    ...components,
  ],
  exports: [
    ...components,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HeaderModule,
    SharedModule,
    PipesModule,
    NgxSkeletonLoaderModule
  ]
})
export class AuctionModule { }
