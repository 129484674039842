<!-- First level -->
<app-timeline-content
[data]="data">
</app-timeline-content>

<!-- Second level -->
<ng-container *ngFor="let subItem of data.subitems">
  <app-timeline-subitem
  [subItem]="true"
  [data]="subItem">
  </app-timeline-subitem>
</ng-container>

