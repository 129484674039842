import { OnInit, Component, Input } from '@angular/core';
import { uniqueId } from 'lodash';

declare const MarkerClusterer: any;
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  // Style props
  @Input() hasBorderRadius: boolean;

  // Component props
  id: string;
  coordinates = [];

  // Map props
  map = null;
  markers = [];
  rawMarkers = [];
  clusters = [];
  isClustered = false;

  constructor() {
    this.id = uniqueId();
  }

  ngOnInit(): void {
    this.mount();
  }

  public createMarker(rawMarker, icon?, add = true) {
    if (rawMarker.lat && rawMarker.lng) {
      const location = new google.maps.LatLng(typeof rawMarker.lat === 'function' ? rawMarker.lat() : rawMarker.lat, typeof rawMarker.lng === 'function' ? rawMarker.lng() : rawMarker.lng);

      const markerOptions = Object.assign({
        position: location,
        map: this.map,
        title: rawMarker?.title,
        clusterType: rawMarker?.clusterType
      },
      icon && { icon });

      const marker = new google.maps.Marker(markerOptions);
      this.markers.push(marker);

      if (add) {
        this.rawMarkers.push({ ...rawMarker, icon });
      }
    }
  }

  public cluster(markers, calculatorCallback, options?) {
    if (markers && markers.length) {
      const markerClusterer = new MarkerClusterer(this.map, markers, options);
      markerClusterer.setCalculator(calculatorCallback);
      this.clusters.push(markerClusterer);
      this.isClustered = true;
    }
  }

  public clearClusters() {
    this.clusters.forEach(cluster => cluster.clearMarkers());
    this.clusters = [];
    this.markers = [];

    this.rawMarkers.forEach(marker => this.createMarker(marker, marker.icon, false));
    this.isClustered = false;
  }

  private async mount() {
    this.coordinates = await this.getCurrentPosition();
    const styles: object[] = this.getMapStyles();
    this.map = new google.maps.Map(document.getElementById(`map${this.id}`), {
      zoom: 3,
      minZoom: 3,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP
      },
      center: new google.maps.LatLng(this.coordinates[0], this.coordinates[1]),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      streetViewControl: false,
      styles
    });
  }

  private getMapStyles() {
    const styles = [
      { stylers: [{ saturation: -20 }, { lightness: 25 }] },
      {
        featureType: 'administrative',
        elementType: 'geometry',
        stylers: [{ visibility: 'off' }]
      },
      {
        featureType: 'administrative.land_parcel',
        stylers: [{ visibility: 'off' }]
      },
      {
        featureType: 'administrative.neighborhood',
        stylers: [{ visibility: 'off' }]
      },
      {
        featureType: 'landscape',
        stylers: [{ saturation: -20 }]
      },
      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }]
      },
      {
        featureType: 'poi',
        elementType: 'labels.text',
        stylers: [{ visibility: 'off' }]
      },
      {
        featureType: 'road',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }]
      },
      {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }]
      },
      {
        featureType: 'transit',
        stylers: [{ visibility: 'off' }]
      },
      {
        featureType: 'water',
        stylers: [{ color: '#def1f5' }]
      },
      {
        featureType: 'water',
        elementType: 'labels.text',
        stylers: [{ visibility: 'off' }]
      }
    ];

    return styles;
  }

  private getCurrentPosition = async () => {
    let coordinates = null;
    const userCoordinates = localStorage.getItem('userCoordinates');
    if (userCoordinates) {
      coordinates = JSON.parse(userCoordinates);
    }
    if (navigator.geolocation && !userCoordinates) {
      coordinates = await new Promise((resolve) => {
        navigator.geolocation.getCurrentPosition(({ coords: { latitude, longitude } }) => {
          resolve([latitude, longitude]);
          localStorage.setItem('userCoordinates', JSON.stringify([latitude, longitude]));
        }, () => { resolve([-15.826691, -47.921822]); });
      });
    }
    return coordinates;
  };

 }
