import { Component, Input, OnInit } from '@angular/core';
import { translatedScore } from 'src/app/enums/transporterScore';

@Component({
  selector: 'app-profile-with-score',
  templateUrl: './profile-with-score.component.html',
  styleUrls: ['./profile-with-score.component.scss']
})
export class ProfileWithScoreComponent implements OnInit {

  @Input() score: number;
  @Input() backgroundImage: string;
  @Input() strokeWidth = '12';
  @Input() isVerified;
  @Input() width = '150';

  constructor() { }

  get background() {
    if (this.backgroundImage) {
      return 'background-image: url(' + this.backgroundImage + ')';
    }
    return '';
  }

  get color() {
    if (this.score) {
      const translated = translatedScore(this.score);
      const colorMap = {
        'var(--load-success-light)': translated === 'Ótimo',
        'var(--load-orange-light)': translated === 'Regular',
        'var(--load-red-light)': translated === 'Insuficiente'
      };

      const colorKey = Object.entries(colorMap).filter(([,value]) => value).map(([key]) => key);
      if (colorKey && colorKey.length) {
        return colorKey[0];
      }
      return 'var(--load-grey)';
    }
    return '';
  }

  get styles() {
    return `--p:${this.score};--b:${this.strokeWidth}px;--c:${this.color};--w:${this.width}px`;
  }

  ngOnInit(): void {
  }


}
