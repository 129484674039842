<div class="card wrapper">
  <div *ngIf="title" class="card-title">
    {{ title }}
  </div>
  <div class="card-content is-size-6" [ngClass]="{'p-0': noPadding}">
    <div class="table-actions is-striped" *ngIf="showOptions">
      <div class="is-flex is-align-items-center">
        <app-button
          title="Selecionar tudo"
          icon="check_box"
          variant="link"
          size="small"
          (handleClickEmitter)="selectAll()"
        ></app-button>
      </div>
      <div class="is-flex is-align-items-center ml-2" *ngIf="deleteAllButton">
        <app-button
          title="Excluir selecionado(s)"
          icon="delete"
          variant="link"
          size="small"
          [disabled]="!isSomeChecked"
          (handleClickEmitter)="deleteAll()"
        ></app-button>
      </div>
    </div>

    <table class="table is-fullwidth">
      <thead>
        <tr class="col-data">
          <!-- Expand e check all button (th for correct spacing) -->
          <th *ngIf="showSelectBox" colspan="1">
            <input type="checkbox" disabled [checked]="isAllChecked" />
          </th>
          <th *ngFor="let key of colData" colspan="1">
            <div
            class="is-flex is-align-items-center"
            [ngClass]="{'is-clickable': key.sort}"
            (click)="handleSort(key)"
            >
              {{ key.header }}
              <i *ngIf="key.sort"
              class="sort-icon material-icons is-size-5 ml-2"
              [ngClass]="{'active': key.sort.active}"
              >
                {{ key.sort.active === 'desc' ? 'arrow_downward' : 'arrow_upward' }}
              </i>
            </div>
          </th>
          <th
            *ngIf="showEditOption || showDeleteOption"
            class="is-flex is-justify-content-center"
          >
            Ações
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="gridData">
          <tr *ngFor="let record of gridData; let i = index" class="grid-data">
            <td *ngIf="showSelectBox" (click)="select($event, i)">
              <input type="checkbox" [checked]="record.checked" />
            </td>
            <td
              [ngClass]="{ warning: record.shipmentSent === 'Sim' }"
              *ngFor="let key of colData"
            >
              <ng-container
                  *ngIf="record | getValueFromField : key.field as result"
                >
                  <span
                    [ngClass]="{
                      'has-text-primary has-text-weight-medium is-underlined is-clickable': result.action
                    }"
                    (click)="handleAction(result.action, record)"
                  >
                    {{ result.value }}
                  </span>
                </ng-container>
            </td>
            <td *ngIf="showOptions">
              <div class="is-flex is-justify-content-center">
                <app-button
                  *ngIf="showEditOption"
                  icon="edit"
                  size="small"
                  variant="link"
                  color="warning"
                  (handleClickEmitter)="edit(record)"
                >
                </app-button>
                <app-button
                  *ngIf="showDeleteOption"
                  size="small"
                  variant="link"
                  color="danger"
                  icon="delete"
                  (handleClickEmitter)="delete(record)"
                >
                </app-button>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
