import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-registration-complete',
  templateUrl: './registration-complete.component.html',
  styleUrls: ['./registration-complete.component.scss']
})
export class RegistrationCompleteComponent implements OnInit {

  isRegistrationComplete = true;
  constructor(private userService: UserService,private router: Router) { }

  ngOnInit(): void {
    this.verifyCanShow();
    this.closeModal();
  }

  verifyCanShow() {
    const user = this.userService.getLoggedUser();

    if (user && user.transporter && !this.userService.isUserAdmin() && !this.userService.isUserEmbarcador()) {
      this.isRegistrationComplete = user.transporter.registrationComplete;
    }
  }

  closeModal() {
    const currentLocation = window.location.pathname;

    if (currentLocation === '/perfil/transportadora') {
      this.isRegistrationComplete = true;
    }
  }

  onRedirect() {
    this.router.navigate(['/perfil/transportadora']);
  }

}
