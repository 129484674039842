import { Component, Input, OnInit } from '@angular/core';
import { Dropdown, IDropdown } from 'src/app/interfaces/Dropdown';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent extends Dropdown implements OnInit, IDropdown {

  @Input() initialText = null;
  @Input() isLarge = false;
  @Input() isRounded = false;
  @Input() isRight = true;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.initDropdown();
  }

  initDropdown = () => {
    this.handleChange.emit(this.currentItem);
  }

  selectValue = item => {
    this.currentItem = item;
    this.handleChange.emit(this.currentItem);
    if (this.isOpen) { this.handleDropdown(); }
  }

  handleDropdown = () => this.isOpen = !this.isOpen;

}
