import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
@Component({
  selector: 'app-notifications-collapsible',
  templateUrl: './notifications-collapsible.component.html',
  styleUrls: ['./notifications-collapsible.component.scss']
})
export class NotificationsCollapsibleComponent implements OnInit {

  @Input() module;
  @Input() count = 0;
  @Input() notifications;
  @Input() total;
  @Input() showNotifications = false;
  @Output() toggleEmitter = new EventEmitter();
  @Output() paginationEmitter = new EventEmitter();
  @Output() deleteEmitter = new EventEmitter();
  @Output() redirectEmitter = new EventEmitter();

  page = 0;

  constructor() { }

  ngOnInit(): void {
  }

  toggleNotifications() {
    this.toggleEmitter.emit();
  }

  nextPage(page) {
    this.page = page > 0 ? page - 1 : 0;
    this.paginationEmitter.emit({ module: this.module, page: this.page });
  }

  previousPage(page) {
    this.page = page > 0 ? page - 1 : 0;
    this.paginationEmitter.emit({ module: this.module, page: this.page });
  }

  deleteAll() {
    this.deleteEmitter.emit({ module: this.module });
  }

  delete(notificationId) {
    this.deleteEmitter.emit({ id: [notificationId], module: this.module });
  }

  goToRoute(route) {
    this.redirectEmitter.emit(route);
  }

  getNotificationTimeLabel(notification) {
    const now = moment(new Date());
    const notificationDate = moment(notification.createdAt);
    let diff = now.diff(notificationDate, 'hours');
    if (diff < 1) {
      diff = now.diff(notificationDate, 'minutes');
      if (diff === 0) return 'agora';
      return diff === 1 ? `${diff} min atrás` : `${diff} mins atrás`;
    } else if (diff > 0 && diff < 24) {
      return diff === 1 ? `${diff} hr atrás` : `${diff} hrs atrás`;
    } else {
      diff = now.diff(notificationDate, 'days');
      return diff === 1 ? `${diff} dia atrás` : `${diff} dias atrás`;
    }
  }
}
