import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-auction-id-tag',
  templateUrl: './auction-id-tag.component.html',
  styleUrls: ['./auction-id-tag.component.scss']
})
export class AuctionIdTagComponent {
  @Input() _id: string;

  constructor() { }
}
