import { Component, Input, OnChanges } from '@angular/core';

type WayPoint = {
  partnerCity: string;
  partnerCode: string;
  partnerName: string;
  partnerUF: string;
};

type RouteData = {
  ots: string[];
  wayPoint: WayPoint[];
  destination?: string;
  origin?: string;
};

@Component({
  selector: 'app-ot-details-route',
  templateUrl: './ot-details-route.component.html',
  styleUrls: ['./ot-details-route.component.scss']
})
export class OtDetailsRouteComponent implements OnChanges {
  @Input() data: RouteData[];
  @Input() type: string;

  constructor() { }

  ngOnChanges(): void {
    this.cleanData();
  }

  cleanData() {
    this.data = this.data.map(item => ({
      ...item,
      wayPoint: item?.wayPoint?.filter(i => i !== null) || []
    }));
  }
}
