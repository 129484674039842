import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';
import { CarouselComponent } from '../carousel/carousel.component';

@Component({
  selector: 'app-welcome-modal',
  templateUrl: './welcome-modal.component.html',
  styleUrls: ['./welcome-modal.component.scss']
})
export class WelcomeModalComponent implements OnInit {

  ctaTitle = 'Próximo';

  @ViewChild('carousel') carousel: CarouselComponent;

  slides = [
    { src: '../../../assets/img/auction/1.svg', legend: 'Nova funcionabilidade no módulo do leilão! Entenda como funciona:' },
    { src: '../../../assets/img/auction/2.svg', legend: 'Ative a opção de "Lances automáticos".' },
    { src: '../../../assets/img/auction/3.svg', legend: 'Insira o valor do piso e o valor de decremento.' },
    { src: '../../../assets/img/auction/4.svg', subtitle: 'E pronto! fácil, né?', legend: 'Você pode editar ou desativar um lance automático quando quiser! Agora é só participar do leilão.' }
  ];

  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit() {
  }

  isUserFirstAccess = () => this.userService.isUserFirstAccess();

  navigateOnPlatform() {
    this.router.navigate(['/leilao']);
  }

  closeModal() {
    this.router.navigate(['/dashboard']);
  }

  handlePrevious() {
    this.carousel.onPreviousClick();
    if (this.ctaTitle !== 'Próximo') {
      this.ctaTitle = 'Próximo';
    }
  }

  handleNext() {
    if (this.carousel.currentSlide === (this.slides.length - 1)) {
      this.navigateOnPlatform();
    } else {
      this.carousel.onNextClick();
      if (this.carousel.currentSlide === (this.slides.length - 1)) {
        this.ctaTitle = 'Ir para o leilão';
      }
    }
  }

}
