import { Component, Input, Output, OnChanges, EventEmitter, SimpleChanges } from '@angular/core';
import { ColDataProps, GridDataProps, SortColData, SortColDataWithoutBoth } from './table.types';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnChanges {

  @Input() gridData: GridDataProps[];
  @Input() colData: ColDataProps[];
  @Input() title: string;
  @Input() showOptions = true;
  @Input() showEditOption = true;
  @Input() showSelectBox = true;
  @Input() showDeleteOption = true;
  @Input() showToggleOption = false;
  @Input() showActions = true;
  @Input() isLoading = false;
  @Input() emptyStateMessage = 'Não há dados para exibir';
  @Output() editEmitter = new EventEmitter();
  @Output() deleteEmitter = new EventEmitter();
  @Output() deleteAllEmitter = new EventEmitter();
  @Output() toggleEmitter = new EventEmitter();
  @Output() sortEmitter = new EventEmitter<{field: string; sort: SortColDataWithoutBoth}>();

  constructor() {}

  get isAllChecked(): boolean {
    return this.gridData?.every(d => d.checked);
  }

  get isSomeChecked(): boolean {
    return this.gridData?.some(d => d.checked);
  }

  get hasItems(): boolean {
    return !!this.gridData?.length;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.gridData?.currentValue) {
      this.gridData?.forEach(d => d.checked = false);
    }
  }

  edit(row) {
    this.editEmitter.emit(row);
  }

  delete(row) {
    this.deleteEmitter.emit(row);
  }

  deleteAll() {
    this.deleteAllEmitter.emit(this.gridData?.filter(d => d.checked));
  }

  select(event, idx: number) {
    if (this.gridData && this.gridData[idx]) {
      this.gridData[idx]['checked'] = event.target.checked;
    }
  }

  selectAll() {
    const checked = this.isAllChecked;
    this.gridData?.forEach(d => d.checked = !checked);
  }

  getValueFromField(register: GridDataProps, colData: ColDataProps) {
    const field = colData.field;
    const key = field?.includes('.') ? field.replace('.', '?.') : field;
    const rawValue = eval(`register.${key}`);

    if (!rawValue) {
      return '-';
    }
    const isObj = typeof rawValue === 'object';
    const parsedValue = isObj ? rawValue.value : rawValue;

    if (!parsedValue) {
      return '-';
    }

    let returnedValue = parsedValue;

    if (colData.format) {
      returnedValue = this.formatValue(colData.format, returnedValue);
    }

    return returnedValue;
  }

  handleToggle(register, event) {
    this.toggleEmitter.emit({ register, event });
  }

  formatValue(format, value) {
    return format(value);
  }

  getIconFromField(register: GridDataProps, colData: ColDataProps) {
    const field = colData.field?.includes('.') ? colData.field.replace('.', '?.') : colData.field;

    if (!field) {
      return null;
    }

    const isObj = typeof eval(`register.${field}`) === 'object';

    if (!isObj) {
      return null;
    }

    if (!field.includes('.')) {
      const hasIcon = register[field]?.icon;

      return !hasIcon ? null : {
        icon: register[field].icon,
        iconColor: register[field].iconColor
      };
    } else {
      const obj = eval(`register.${field}`);

      return !obj?.icon ? null : {
        icon: obj.icon,
        iconColor: obj.iconColor,
      };
    }
  }

  handleAction = (data: GridDataProps, cbFn: (value) => void) => cbFn(data);

  handleSort(key: ColDataProps) {
    const sortOptions = key.sort?.options;

    if (!sortOptions) {
      return;
    }

    const isActive = key.sort?.active;
    if (!isActive) {
      this.handleInitialSort(key, sortOptions);
    } else {
      this.handleNextSort(key, sortOptions);
    }
    this.sortEmitter.emit({ field: key.field, sort: key.sort.active });
  }

  handleInitialSort(key: ColDataProps, sortOptions: SortColData) {
    if (sortOptions === 'both') {
      return;
    }
    key.sort.active = sortOptions;
  }

  handleNextSort(key: ColDataProps, sortOptions: SortColData) {
    const currentSortValue = key.sort.active;

    if (sortOptions === 'both') {
      key.sort.active = currentSortValue === 'asc' ? 'desc' : 'asc';
    }
  }

}
