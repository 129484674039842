<div darkMode class="accordion-container p-4">
  <input type="checkbox" [checked]="!isOpen" />
  <i darkMode></i>
  <b class="is-size-5" [ngStyle]="{ color: colorLabel ?? '#606060' }">{{
    label
  }}</b>

  <div class="body-container">
    <hr>

    <div class="body-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
