import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-popover-filter',
  templateUrl: './popover-filter.component.html',
  styleUrls: ['./popover-filter.component.scss']
})
export class PopoverFilterComponent {

  @Input() hasSomeActiveFilter = false;
  @Input() isFilterOpened = false;
  @Input() text = 'Filtros';

  @Output() isFilterOpenedChange = new EventEmitter();

  constructor() { }

  openOrCloseFilter() {
    this.isFilterOpened = !this.isFilterOpened;
    this.isFilterOpenedChange.emit(this.isFilterOpened);
  }
}
