<app-modal class="window-modal" [noHeader]="true" (isOpenChanged)="closeModal()" *ngIf="displayModal">
  <div class="window-modal_content is-flex is-flex-direction-column is-justify-content-center is-align-items-center">

    <ng-container *ngIf="StepModal.INFORMATION === step">
      <!-- Titulo -->
      <p class="is-size-3 pb-4 has-text-weight-bold">Atenção!</p>

      <!-- Corpo -->
      <b class="is-size-5 pb-4">
        O cadastro para prestação de serviços para a <span class="has-text-primary">Braskem</span>  migrou para o Loadshark!
        <br>
        Além disso, com o perfil cadastrado, em breve você terá <span class="has-text-primary">acesso à cargas de novas empresas</span>
      </b>

      <hr class="full-width">

      <p class="is-size-5 pb-4">
        Para continuar tendo acesso a plataforma e seus serviços, preencha o cadastro
      </p>

      <div class="window-modal-footer is-flex mt-3">
        <app-button title="Mais tarde" [isDefault]="true" [isSecondary]="true" (click)="closeModal()"></app-button>
        <app-button title="Preencher" class="ml-5" [isDefault]="true" (click)="nextStep(StepModal.CONFIRM_DATA_CARRIER)"></app-button>
      </div>
    </ng-container>

    <ng-container *ngIf="StepModal.CONFIRM_DATA_CARRIER === step">
      <app-confirm-data-carrier (confirm)="nextStep(StepModal.ACTIVE_CARRIER_USERS)"></app-confirm-data-carrier>
    </ng-container>

    <ng-container *ngIf="StepModal.ACTIVE_CARRIER_USERS === step">
      <app-active-carrier-users></app-active-carrier-users>
    </ng-container>
  </div>
</app-modal>
