export const testLocal = false;

export const environment = {
  environment: 'staging',
  production: false,
  allowMirroring: false,
  googleAnalytics: 'G-MQ125DTNK2',
  googleMapsApiKey: 'AIzaSyAWvhwqGpSkmwPM9wNL7vDCtHbq8I3fMts',
  openweatherApi: 'fcaebc063e6e201a1e375ef7775262b7',
  mapDelimiters: [
    [5, -75],
    [5, -28],
    [-37, -28],
    [-37, -75],
    [5, -75]
  ],
  urgentNotificationTemplatedId: '5f0869ed1c17d6002708897e',
  urgentNotificationInterval: 1, // minutes
  websocket: {
    url: 'wss://m196dsd778.execute-api.us-east-1.amazonaws.com/staging',
    timeoutInterval: 300000 // ms
  },
  pendo: {
    enabled: true
  },
  api: {
    meta: {
      url: 'https://proxy-staging.loadshark.io',
      gateway: 'https://devgateway.loadshark.io',
      expire: 1 // 3600000 - 1 hora / 300000 - 5 minutos / 60000 - 1 minuto
    },
    client: '/order/v1/client/',
    company: '/user/v1/company/',
    integration: '/integration/v1/',
    transporter: '/user/v1/transporter/',
    importations: '/order/v1/importation/0/500',
    mirroring: '/maintenance/v1/mirroring',
    modal: '/truck/v1/',
    modalsMain: '/truck/v1/main/0/500',
    modalsMainSearch: '/truck/v1/main/search',
    order: '/order/v1/order',
    userOrder: '/order/v1/userOrder',
    orderMain: '/order/v1/',
    orderHash: '/order/v1',
    orders: '/order/v1/order/0/5000',
    planning: '/planning/v1/planning',
    planningSendIntegration: '/planning/v1/planning/send/integration',
    plannings: '/planning/v1/planning/',
    planningExport: '/planning/v1/planning/export',
    transportationApi: '/transportation/v1/',
    transportation: '/transportation/v1/transportation',
    unweightOrders: '/transportation/v1/transportations',
    transportations: '/transportation/v1/transportations/0/500',
    transportationStatus: '/transportation/v1/transportationStatus',
    transportationsFindByModal: '/transportation/v1/transportation/findByModal/tractorUnit',
    tractorUnit: '/truck/v1/tractorUnit',
    tractorUnitMain: '/truck/v1/main',
    tractorUnits: '/truck/v1/tractorUnit/0/500',
    tractorUnitsFilter: '/truck/v1/tractorUnit/filter/0/50',
    semiTrailers: '/truck/v1/semiTrailer/0/500',
    locations: '/truck/v1/locations/0/500',
    semiTruck: '/truck/v1/semiTruck/0/500',
    shippingCompany: '/user/v1/shippingCompany',
    truckMain: '/truck/v1/',
    user: '/user/v1/main',
    userMain: '/user/v1',
    users: '/user/v1/main/',
    preference: '/user/v1/preference/',
    profile: '/user/v1/profile/',
    profiles: '/user/v1/profile/0/500',
    permission: '/user/v1/permission/',
    permissions: '/user/v1/permission/',
    organization: '/user/v1/organization/',
    organizations: '/user/v1/organization/0/500',
    organizationConfiguration: '/user/v1/organizationConfiguration/',
    extraLoads: '/extraload/v1/',
    ses: '/ses/v1/',
    auction: '/auction/v1/auction/',
    auctionLot: '/auction/v1/lot/',
    auctionMain: '/auction/v1/',
    unloadMain: '/unload/v1/',
    unloadv2: '/unload/v2/',
    vehicle: '/user/v1/vehicle/',
    cargoType: '/order/v1/cargotype/',
    cargoTypeUser: '/user/v1/cargotype/',
    distributionCenter: '/order/v1/distributionCenter/',
    auth: '/auth/v1/',
  },
  elasticApiUrl: 'https://loadshark-ba122e.apm.us-east-1.aws.found.io:443'
};
