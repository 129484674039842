import { Directive, TemplateRef, ViewContainerRef, OnInit, Input, ElementRef } from '@angular/core';
import { FeatureToggleService } from './feature-toggle.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[featureToggle]'
})
export class FeatureToggleDirective implements OnInit {
  @Input() featureToggle: string;

  features = null;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureToggleService: FeatureToggleService
  ) { }

  async ngOnInit() {
    await this.saveFeaturesConfiguration();
    if (this.isEnabled()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  async saveFeaturesConfiguration() {
    this.features = await this.featureToggleService.getFeaturesConfig();
  }

  isEnabled() {
    try {
      const featureNodes = this.featureToggle.split('.');
      if (this.configHasFeature(featureNodes)) {
        return this.getFeatureConfig(featureNodes);
      }
      return false;
    } catch {
      return false;
    }
  }

  configHasFeature(featureNodes) {
    const hasFeature = true;
    let objNode = {...this.features };
    for (const node of featureNodes) {
      if (node in objNode) {
        objNode = objNode[node];
      } else {
        return false;
      }
    }
    return hasFeature;
  }

  getFeatureConfig(featureNodes) {
    let objNode = {...this.features };
    for (const node of featureNodes) {
      if (node in objNode) {
        objNode = objNode[node];
      } else {
        return false;
      }
    }
    return objNode;
  }

}
