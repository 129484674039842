import { Pipe, PipeTransform } from '@angular/core';
import { AuctionTypes } from '../../enums/auctionTypes';

@Pipe({
  name: 'auctionType'
})
export class AuctionTypePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) { return '-'; }
    const result = AuctionTypes[value] || value;
    return result;
  }

}
