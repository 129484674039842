import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnInit {

  @Input() isExtraSmall = false;
  @Input() isSmall = false;
  @Input() toggleText = '';
  @Input() disabled = false;
  @Input() isChecked = false;
  @Input() canCheck = true;
  @Output() handleCheck = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onCheck = (target) => {
    if (this.canCheck) {
      this.toggle();
      this.handleCheck.emit(target.checked);
    }
  }

  toggle = () => this.isChecked = !this.isChecked;

}
