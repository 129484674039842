const FormErrors = {
  required: 'Campo obrigatório',
  min: 'Não atingiu o valor mínimo',
  max: 'Atingiu o valor máximo permitido',
  email: 'Digite um e-mail válido',
  minlength: 'Não atingiu a quantidade de caracteres mínima',
  maxlength: 'Atingiu o valor máximo de caracteres permitido',
  mask: 'Digite um formato válido',
  pattern: 'Digite um formato válido',
};

export const translateCommonErrors = (errors: Record<string, boolean>) => {
  let errorMessage = '';
  const errorsKey = Object.keys(errors);

  if (!errorsKey.length) {
    return errorMessage;
  }

  if (errorsKey.length === 1) {
    return FormErrors[errorsKey[0]];
  }

  errorsKey.filter(k => FormErrors[k]).forEach((key, idx, arr) => {
    const len = arr.length;
    const error = FormErrors[key];
    if (error) {
      errorMessage += `${(error as string)}${(idx < len - 1) ? ' / ' : ''}`;
    }
  });
  return errorMessage;
};

export const addError = (newError: Record<string, string>) => {
  const [key] = Object.keys(newError);
  const [value] = Object.values(newError);
  if (!FormErrors[key]) {
    FormErrors[key] = value;
  }
};


