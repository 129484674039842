import { Pipe, PipeTransform } from '@angular/core';
import { ethanolStatus } from 'src/app/enums/ethanolStatus';

@Pipe({
  name: 'unloadStatus'
})
export class UnloadStatusPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    return ethanolStatus[value] ? ethanolStatus[value] : '-';
  }

}
