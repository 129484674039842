import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Dropdown, IDropdown } from '../../../interfaces/Dropdown';

@Component({
  selector: 'app-dropdown-menu-link',
  templateUrl: './dropdown-menu-link.component.html',
  styleUrls: ['./dropdown-menu-link.component.scss']
})
export class DropdownMenuLinkComponent extends Dropdown implements OnInit, IDropdown {

  @Input() label = '';
  @Input() isRight = false;
  @Input() isSingleSelect = false;
  @Input() icons: string[];
  @Input() link: string;
  @Output() handleButton = new EventEmitter();
  @Output() handleLink = new EventEmitter();

  toggleIcon: 'expand_more' | 'expand_less' = 'expand_more';

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.initDropdown();
  }

  initDropdown(): void {
    this.handleChange.emit(this.dropdownItems);
  }

  handleDropdown(): void {
    this.isOpen = !this.isOpen;
  }

  selectValue(item, event): void {
    this.checkValues(item, event);
    this.mountCurrentItemSelected(item);
    this.handleChange.emit(this.dropdownItems);
    this.handleDropdown();
  }

  selectAllValues(event): void {
    this.currentItem = event ? {label: 'Todos', value: null} : null;
    if (this.dropdownItems) {
      this.dropdownItems.forEach(item => item.checked = event);
    }
    this.handleChange.emit(this.dropdownItems);
  }

  checkValues(item, event) {
    if (!this.isMulticheck && this.dropdownItems) {
      this.dropdownItems.forEach(i => i.checked = false);
    }
    item.checked = event;
  }

  mountCurrentItemSelected(item) {
    if (!this.isMulticheck) { return this.currentItem = item; }
    const label = this.dropdownItems && this.dropdownItems.filter(i => i.checked).map(i => i.label);
    this.currentItem = label && label.length > 0 ? { label, value: null } : null;
  }

  changeToggleIcon() {
    this.toggleIcon = this.toggleIcon === 'expand_more' ? 'expand_less' : 'expand_more';
  }

  clearCurrentItem() {
    delete this.currentItem;
  }

  clickIcon(i, j) {
    this.handleButton.emit({ index: i, buttonIndex: j });
  }

  clickLink() {
    this.handleLink.emit();
  }

}
