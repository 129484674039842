import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TimelineItem } from '../../models/timeline';

@Component({
  selector: 'app-timeline-item',
  templateUrl: './timeline-item.component.html',
  styleUrls: ['./timeline-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineItemComponent implements OnInit {

  @Input() data: TimelineItem;

  constructor() { }

  ngOnInit(): void {
  }

}
