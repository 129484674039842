import { Injectable } from '@angular/core';
import { BehaviorSubject, debounceTime, interval } from 'rxjs';
import { webSocket } from 'rxjs/webSocket';
import { TrackingService } from 'src/app/features/tracking/tracking.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';
import { AuctionService } from '../../features/auction/services/auction.service';
import { GlobalNotificationService } from './global-notification.service';

export type WebSocketStatus = 'connected' | 'reconnected' | 'disconnectedByError' | 'disconnected';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  private socket;
  private pingInterval = null;
  private _state: WebSocketStatus = null;
  private connectionStatus$ = new BehaviorSubject<WebSocketStatus | null>(null);

  private _queue: WebSocketStatus[] = [];

  constructor(
    private notificationService: GlobalNotificationService,
    private auctionService: AuctionService,
    private trackingService: TrackingService,
    private userService: UserService
  ) {
    this.processStatusChanges();
  }

  get connectionStatusChanges() {
    return this.connectionStatus$.pipe(debounceTime(2000));
  }

  get connectionStatus() {
    return this._state;
  }

  set connectionStatus(val: WebSocketStatus) {
    this._state = val;
  }

  public initSocket(): void {
    this.socket = webSocket({
      url: environment.websocket.url,
      openObserver: {
        next: () => {
          console.log('[WS] Connected');
          this.updateConnectionStatus(this.connectionStatus ? 'reconnected' : 'connected');
        }
      }
    });
    this.socket.subscribe(
      msg => this.processMessage(msg), // Called whenever there is a message from the server.
      err => {
        // Called if at any point WebSocket API signals some kind of error.
        console.error('[WS] Error occurred', err);
        this.updateConnectionStatus('disconnectedByError');
      },
      () => {
        // Called when connection is closed (for whatever reason).
        this.clearPingInterval();
        console.log('[WS] Connection closed');
        if (['connected', 'reconnected'].includes(this.connectionStatus)) {
          this.updateConnectionStatus('disconnectedByError');
        }
        if (this.connectionStatus === 'disconnectedByError') {
          console.log('[WS] Reconnecting...');
          this.reconnect();
        }
      }
    );
    this.pingInterval = setInterval(async () => {
      this.ping();
    }, 1000 * 60 * 4);
  }

  public disconnect() {
    if (this.socket) {
      this.updateConnectionStatus('disconnected');
      this.socket.complete();
    }
    this.clearPingInterval();
  }

  public kill() {
    if (this.socket) {
      this.socket.complete();
    }
  }

  public setUserConnection(userId) {
    if (this.socket) {
      this.socket.next({ action: 'setuser', userId });
    }
  }

  public reconnect() {
    const user = this.userService.getLoggedUser();
    if (user) {
      this.initSocket();
      this.setUserConnection(user._id);
    }
  }

  public crashConnection() {
    this.socket.error({ code: 4000, reason: 'I think our app just broke!' });
  }

  private updateConnectionStatus(status: WebSocketStatus) {
    this.connectionStatus = status;
    this.toQueue(status);
  }

  private processStatusChanges() {
    interval(2000).subscribe({
      next: _ => {
        const status = this.dequeue();
        if (status) {
          this.connectionStatus$.next(status);
        }
      }
    });
  }

  private toQueue(newStatus: WebSocketStatus) {
    this._queue.push(newStatus);
  }

  private dequeue() {
    return this._queue.shift();
  }

  private processMessage(msg) {
    if (msg.code) {
      return;
    }
    if ('action' in msg) {
      if (['auction', 'routeAuction'].includes(msg.action)) {
        this.auctionService.pushMessage(msg);
        // this.getAuctionStats();
      }
      if (msg.action === 'tracking') {
        this.trackingService.pushMessage(msg);
      }
    } else {
      this.queueNotification(msg);
    }
  }

  private queueNotification(message) {
    if (message && !message.requestId) {
      this.notificationService.pushNotification(message);
    }
  }

  private ping() {
    this.socket.next({ action: 'ping', data: 'pong' });
  }

  private getAuctionStats() {
    const token = localStorage.getItem('token');
    this.socket.next({ action: 'auctionstats', token });
  }

  private clearPingInterval() {
    clearInterval(this.pingInterval);
  }

}
