<div *ngIf="user" class="page">
    <div class="modal is-active">
        <div class="modal-background"></div>
        <div class="modal-content modal-width card is-radius-large is-shadowed-small">
            <div class="card-content has-text-centered">
                <div class="full-width modal-row">
                    <div class="columns welcome-text">
                        <div class="column is-12">
                            <div class="description">
                                <p>
                                    <span class="important-info">{{user.name}}</span>, por motivos de segurança, sua sessão expirou.
                                </p>
                                <p>
                                    Clique abaixo para fazer o login novamente utilizando seu email <span class="important-info">{{user.email}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="full-width modal-row">
                    <button class="modal-btn" (click)="goToLogin()">
                        Ir para o login
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
