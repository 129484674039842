import { Component, OnInit } from '@angular/core';
import { CheckAuctionService } from 'src/app/features/auction/check-auctions/check-auction.service';
@Component({
  selector: 'app-check-auctions',
  templateUrl: './check-auctions.component.html',
  styleUrls: ['./check-auctions.component.scss']
})
export class CheckAuctionsComponent implements OnInit {

  auctions = [];
  cachedResponse = [];
  countTotalAuctions = 0;
  page = 0;
  auctionsAmount = 0;

  isCheckedAllLoads = false;
  isLoading = false;
  isGettingData = false;

  order = 'desc';
  orderType = '';
  searchOtInput = null;
  query = '?';

  constructor(private checkAuctionService: CheckAuctionService) { }

  orderOptions = [
    { label: 'Data', value: 'end' },
    { label: 'Valor do lance', value: 'lastBid' }
  ];

  sortBy = {
    end: {
      order: 'desc',
      asc: 'new Date(a.endDate) - new Date(b.endDate)',
      desc: 'new Date(b.endDate) - new Date(a.endDate)'
    },
    lastBid: {
      order: 'desc',
      asc: 'a.lastBid - b.lastBid',
      desc: 'b.lastBid - a.lastBid'
    },
    shippingDate: {
      order: 'desc',
      asc: 'new Date(a.shippingDate) - new Date(b.shippingDate)',
      desc: 'new Date(b.shippingDate) - new Date(a.shippingDate)'
    }
  };

  async ngOnInit() {
    await this.loadData();
  }

  async loadData() {
    await this.getAuctions();
  }

  async getAuctions(query?) {
    this.isLoading = true;
    this.isGettingData = true;
    try {
      const response = await this.checkAuctionService.getAuctionConference(this.page, 50, query || this.query);
      if (response) {
        this.auctions = response.dataConference;
        if (response && 'totalRecords' in response) {
          this.countTotalAuctions = response.totalRecords;
        } else {
          this.countTotalAuctions = response.records.length;
        }
        this.cachedResponse = this.auctions;
      }
    } catch (e) {
      console.error(e);
    }
    this.auctionsAmount = this.calculateTotalPages();
    this.setCheckedAttribute();
    this.isLoading = false;
    this.isGettingData = false;
    this.query = '?';
  }

  setCheckedAttribute() {
    if (this.auctions) {
      this.auctions.map(a => a.checked = false);
    }
  }

  toggleAllAuctions(event) {
    this.auctions.map(a => a.checked = event.target.checked);
  }

  validateCheckedAllAuctions(checkData: { _id: string, checked: boolean }) {
    const auctionIdx = this.auctions.findIndex(a => a._id === checkData._id);
    if (this.auctions[auctionIdx]) {
      this.auctions[auctionIdx].checked = checkData.checked;
      this.isCheckedAllLoads = this.auctions.every(a => a.checked);
    }
  }

  async loadMore() {
    this.page++;
    await this.getAuctions();
    await this.handleOrder();
  }

  handleOrder(type?) {
    this.orderType = type ? type : this.orderType;
    if (this.sortBy[this.orderType] && this.auctions) {
      this.auctions.sort((a, b) => eval(this.sortBy[this.orderType][this.order]));
      this.sortBy[this.orderType].order = this.order === 'asc' ? 'asc' : 'desc';
    }
  }

  handleReverseOrder() {
    this.order = this.order === 'desc' ? 'asc' : 'desc';
    this.handleOrder(this.orderType);
  }

  handleClearOrder() {
    this.order = 'desc';
    this.orderType = 'shippingDate';
    this.handleOrder();
  }

  calculateTotalPages() {
    if (this.page === 0) {
      return this.auctions.length;
    } else {
      return this.cachedResponse.length + this.auctions.length;
    }
  }

  async downloadCheckedAuctions() {
    const checkedAuctions = this.auctions.filter(a => a.checked);
    await this.checkAuctionService.downloadAuctions(checkedAuctions, 'conferencia');
  }

  hasSomeCheckedAuctions() {
    return this.auctions && this.auctions.length > 0 && this.auctions.some(a => a.checked);
  }

  nextPage(page) {
    this.page = page > 0 ? page - 1 : 0;
    this.getAuctions();
    this.handleOrder();
  }

  previousPage(page) {
    this.page = page > 0 ? page - 1 : 0;
    this.getAuctions();
    this.handleOrder();
  }

  filterByOt(otNumber) {
    this.query += `otNumber=${otNumber}`;
    this.getAuctions(this.query);
  }

  clearFilter() {
    this.auctions = [];
    this.searchOtInput = null;
    this.getAuctions();
  }

}
