import { Component } from '@angular/core';

@Component({
  selector: 'app-auction-actions',
  templateUrl: './auction-actions.component.html',
  styleUrls: ['./auction-actions.component.scss']
})
export class AuctionActionsComponent {
  constructor() { }
}
