import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateAgo',
  pure: true
})
export class DateAgoPipe implements PipeTransform {
  transform(value: any): any {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29) { // less than 30 seconds ago will show as 'Just now'
        return 'Alguns instantes';
      }
      const intervals = {
        ano: 31536000,
        mês: 2592000,
        semana: 604800,
        dia: 86400,
        hora: 3600,
        minuto: 60,
        secondo: 1
      };
      let counter: number;
      for (const index in intervals) {
        if (intervals.hasOwnProperty(index)) {
          counter = Math.floor(seconds / intervals[index]);
          if (counter) {
            const pluralModifier = (counter > 1) ? 's' : '';
            return counter + ' ' + index + pluralModifier;
          }
        }
      }

      return value;
    }
  }
}
