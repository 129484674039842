import { UntypedFormControl } from '@angular/forms';
import * as moment from 'moment';
import { trackingStatus } from '../enums/trackingStatus';

export function calculateDeliveryStatus(plannedEnd, eta, endTrip, startTrip) {

  const minimumInterval = new Date(plannedEnd);
  minimumInterval.setUTCMinutes(new Date(plannedEnd).getUTCMinutes() - 30);
  const maximumInterval = new Date(plannedEnd);
  maximumInterval.setUTCMinutes(new Date(plannedEnd).getUTCMinutes() + 30);

  if (!startTrip) { return 'default'; }
  if (endTrip) {
    return 'default';
  } else {
    if (eta && eta >= maximumInterval.toISOString()) {
      return 'delayed';
    } else if (eta && eta <= minimumInterval.toISOString()) {
      return 'onTime';
    } else {
      return 'possibleDelay';
    }
  }
}

export const getTruckStatus = args => {
  const statusEnum = ['loading', 'unloading', 'loadWaiting'];
  const { startTrip, endTrip, status } = args;

  let statusTruck = null;

  if (!startTrip) { return 'scheduled'; }
  if (endTrip || (status.subStatus && status.subStatus === 'ended')) { return 'finished'; }

  if (status && status.subStatus) {
    statusTruck = statusEnum.includes(status.subStatus) ? status.subStatus : 'trucks';
  }

  if (!statusTruck && 'fullLoadStatus' in status) {
    statusTruck = status.fullLoadStatus ? 'full' : 'empty';
  }

  if (!statusTruck && 'type' in status) {
    statusTruck = status.type;
  }

  return statusTruck;

};

export const getTruckStatusMonitoringLabel = args => {
  const statusEnum = ['loading', 'unloading', 'loadWaiting', 'ended'];
  const { startTrip, endTrip, status } = args;

  const mainStatus = status?.type;
  let secondaryStatus = null;

  if (mainStatus === 'no signal') { return mainStatus; }

  if (!startTrip) { secondaryStatus = 'scheduled'; }
  if (endTrip) { secondaryStatus = 'finished'; }

  if (!secondaryStatus && 'subStatus' in status) {
    if (mainStatus === 'stopped') { secondaryStatus = status?.subStatus; }
  }

  if (!secondaryStatus && 'fullLoadStatus' in status) {
    secondaryStatus = status?.fullLoadStatus ? 'full' : 'empty';
  }

  if (!statusEnum.includes(status?.subStatus)) { secondaryStatus = null; }

  return secondaryStatus ? `${mainStatus}_${secondaryStatus}` : mainStatus;
};

export const getCurrentStatusIcon = (args) => {
  const returnDefault = ['default', 'loading', 'trucks', 'finished'];
  const { startTrip, endTrip, plannedEnd, status, trackerData: { eta } } = args;

  if (endTrip || !startTrip) { return 'ic_default.svg'; }
  if (status.type === 'no signal') { return 'ic_default.svg'; }
  const loadState = getTruckStatus({ startTrip, endTrip, status });

  const deliveryStatus = calculateDeliveryStatus(plannedEnd, eta, endTrip, startTrip);

  if ((!deliveryStatus || !loadState) || (returnDefault.includes(loadState) || returnDefault.includes(deliveryStatus))) {
    return 'ic_default.svg';
  }

  return `ic_${loadState}_${deliveryStatus}.svg`;
};

export const getCurrentStatusIconTracking = args => {
  const { tracking } = args;

  const deliveryStatus = trackingStatus[tracking.statusTypeDeliveryLoadShark];

  if (!deliveryStatus) {
    return 'ic_default.svg';
  }

  return `ic_stopped_${deliveryStatus}.svg`;
};

export const copyClipboard = (value, title, message, notify) => {
  if (!value) { return; }
  const selBox = document.createElement('textarea');
  selBox.style.position = 'fixed';
  selBox.style.left = '0';
  selBox.style.top = '0';
  selBox.style.opacity = '0';
  selBox.value = value;
  document.body.appendChild(selBox);
  selBox.focus();
  selBox.select();
  document.execCommand('copy');
  document.body.removeChild(selBox);
  notify.notification$.next({
    title,
    type: 'success',
    message
  });
};

// Remove duplicações de array simples (raw values)
export const removeDuplicates = (arr: any[]) => {
  return arr.reduce((acc, curr) => {
    const hasInsert = acc.indexOf(curr) !== -1;
    if (!hasInsert) { return [...acc, curr]; } else { return [...acc]; }
  }, []);
};

export const removeNullValues = (arr: any[]) => arr.filter(i => i !== null && i !== 'null');

export const inputTimeFormat = (date: any = new Date()) => moment(date).format('YYYY-MM-DDTHH:mm');

export const inputDateFormat = (date: any = new Date()) => moment(date).format('YYYY-MM-DD');

export const inputTimeFormatToISO = date => date + ':00.000Z';

export const inputDateFormatToISO = date => moment(date, 'YYYY-MM-DD').toISOString();

export const inputEndDateFormatToISO = date => moment(date, 'YYYY-MM-DD').add(1, 'days').toISOString();

export const inputDateTimeToISO = date => moment(date, 'YYYY-MM-DDTHH:mm').toISOString();

export const inputDateTimeFormatToISO = date => moment(date).format('YYYY-MM-DDTHH:mm') + ':00.000Z';

export const downloadFile = (data: any, type: string, name: string, utfBOM = false) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  const blob = utfBOM ? new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), data], { type }) : new Blob([data], { type });
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = name;
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
};

export const databases = () => {
  return {
    LoadSharkAuctionsDB: ['auctions', 'bids', 'deals', 'loads', 'logs', 'lots', 'regions', 'vehicles'],
    LoadSharkDeliveriesDB: ['deliveries', 'deliveryloadingdatas', 'LoadSharkExtraLoadsDB', 'generalimportations', 'transfers'],
    LoadSharkNotificationsDB: ['notifications', 'notificationsettings', 'templates', 'wssconnections'],
    LoadSharkOrdersDB: ['cargotypes', 'clients', 'configurationservers', 'coordinates', 'customerlocations', 'distances',
      'distributioncenters', 'generalimportations', 'importationitems', 'importations', 'logs', 'orders', 'queueitems', 'shippingcompanies',
      'spotbids', 'userorders', 'users'],
    LoadSharkPlanningsDB: ['planningdatas', 'planninglogs', 'planningmatches', 'plannings', 'users'],
    LoadSharkTransportationsDB: ['integrations', 'locationtransportations', 'transportations', 'transportationstatuses', 'users'],
    LoadSharkTrucksDB: ['locations', 'semitrailers', 'semitrucks', 'slots', 'tractorunits', 'trucks', 'userfollowedroutes', 'users'],
    LoadSharkUsersDB: ['organizations', 'permissionprofiles', 'permissions', 'profiles', 'shippingcompanies', 'shippingcompanyusers',
      'userprofiles', 'users']
  };

};
export const states = () => {
  return [
    { state: { UF: 'AC', name: 'Acre' } },
    { state: { UF: 'AL', name: 'Alagoas' } },
    { state: { UF: 'AP', name: 'Amapá' } },
    { state: { UF: 'AM', name: 'Amazonas' } },
    { state: { UF: 'BA', name: 'Bahia' } },
    { state: { UF: 'CE', name: 'Ceará' } },
    { state: { UF: 'DF', name: 'Distrito Federal' } },
    { state: { UF: 'ES', name: 'Espírito Santo' } },
    { state: { UF: 'GO', name: 'Goías' } },
    { state: { UF: 'MA', name: 'Maranhão' } },
    { state: { UF: 'MT', name: 'Mato Grosso' } },
    { state: { UF: 'MS', name: 'Mato Grosso do Sul' } },
    { state: { UF: 'MG', name: 'Minas Gerais' } },
    { state: { UF: 'PA', name: 'Pará' } },
    { state: { UF: 'PB', name: 'Paraíba' } },
    { state: { UF: 'PR', name: 'Paraná' } },
    { state: { UF: 'PE', name: 'Pernambuco' } },
    { state: { UF: 'PI', name: 'Piauí' } },
    { state: { UF: 'RJ', name: 'Rio de Janeiro' } },
    { state: { UF: 'RN', name: 'Rio Grande do Norte' } },
    { state: { UF: 'RS', name: 'Rio Grande do Sul' } },
    { state: { UF: 'RO', name: 'Rondônia' } },
    { state: { UF: 'RR', name: 'Roraíma' } },
    { state: { UF: 'SC', name: 'Santa Catarina' } },
    { state: { UF: 'SP', name: 'São Paulo' } },
    { state: { UF: 'SE', name: 'Sergipe' } },
    { state: { UF: 'TO', name: 'Tocantins' } },
  ];
};

export const repeatEvery = (func, interval) => {
  const now: any = new Date();
  const delay = interval - now % interval;

  function start() {
    func();
    setInterval(func, interval);
  }

  setTimeout(start, delay);
};

export const months = () => {
  return [
    {
      arrPos: 0,
      value: 1,
      label: 'Janeiro'
    },
    {
      arrPos: 1,
      value: 2,
      label: 'Fevereiro'
    },
    {
      arrPos: 2,
      value: 3,
      label: 'Março'
    },
    {
      arrPos: 3,
      value: 4,
      label: 'Abril'
    },
    {
      arrPos: 4,
      value: 5,
      label: 'Maio'
    },
    {
      arrPos: 5,
      value: 6,
      label: 'Junho'
    },
    {
      arrPos: 6,
      value: 7,
      label: 'Julho'
    },
    {
      arrPos: 7,
      value: 8,
      label: 'Agosto'
    },
    {
      arrPos: 8,
      value: 9,
      label: 'Setembro'
    },
    {
      arrPos: 9,
      value: 10,
      label: 'Outubro'
    },
    {
      arrPos: 10,
      value: 11,
      label: 'Novembro'
    },
    {
      arrPos: 11,
      value: 12,
      label: 'Dezembro'
    },
  ];
};

export const getOffsetInHours = () => {
  const current = new Date();
  const offset = current.getTimezoneOffset() / 60;
  return offset;
};

export const setHoursByOffset = (hours: string | number, asString = true): string | number => {
  let newDate = Number(hours) + getOffsetInHours();
  if (newDate === 24) {
    return asString ? '00' : 0;
  }
  if (newDate > 24) {
    newDate = newDate % 24;
  }
  return asString ? String(newDate) : newDate;
};

export const noWhiteSpace = (control: UntypedFormControl) => {
  const isWhitespace = (control.value || '').trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : { whitespace: true };
};

export const validateCNPJ = (cnpj: string) => {

  cnpj = cnpj ? cnpj.replace(/[^\d]+/g,'') : '';

  if(cnpj == '') return false;

  if (cnpj.length != 14)
      return false;

  if (cnpj == '00000000000000' ||
      cnpj == '11111111111111' ||
      cnpj == '22222222222222' ||
      cnpj == '33333333333333' ||
      cnpj == '44444444444444' ||
      cnpj == '55555555555555' ||
      cnpj == '66666666666666' ||
      cnpj == '77777777777777' ||
      cnpj == '88888888888888' ||
      cnpj == '99999999999999')
      return false;

  // Valida DVs
  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0,tamanho) as any;
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2)
          pos = 9;
  }
  let resultado = (soma % 11 < 2 ? 0 : 11 - soma % 11) as any;
  if (resultado != digitos.charAt(0))
      return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0,tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2)
          pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != digitos.charAt(1))
        return false;

  return true;
};

export const validateMatriz = (cnpj: string) => {

  cnpj = cnpj ? cnpj.replace(/[^\d]+/g,'') : '';

  if(cnpj == '') return false;

  if(cnpj.substring(8,12) !== '0001')
      return false;

  if (cnpj.length != 14)
      return false;

  if (cnpj == '00000000000000' ||
      cnpj == '11111111111111' ||
      cnpj == '22222222222222' ||
      cnpj == '33333333333333' ||
      cnpj == '44444444444444' ||
      cnpj == '55555555555555' ||
      cnpj == '66666666666666' ||
      cnpj == '77777777777777' ||
      cnpj == '88888888888888' ||
      cnpj == '99999999999999')
      return false;

  // Valida DVs
  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0,tamanho) as any;
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2)
          pos = 9;
  }
  let resultado = (soma % 11 < 2 ? 0 : 11 - soma % 11) as any;
  if (resultado != digitos.charAt(0))
      return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0,tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2)
          pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (resultado != digitos.charAt(1))
        return false;

  return true;
};

export const toBase64 = file => new Promise((resolve, reject) => {
  if (!file) { return; }
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const toFindDuplicates = (array) => {
  const uniqueElements = new Set(array);
  const filteredElements = array.filter(item => {
      if (uniqueElements.has(item)) {
          uniqueElements.delete(item);
      } else {
          return item;
      }
  });

  return [...new Set(filteredElements)];
};
