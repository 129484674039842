import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';

export interface ISideImage {
  src: string;
  subtitle?: string;
  legend?: string;
}

@Component({
  selector: 'app-side-image',
  templateUrl: './side-image.component.html',
  styleUrls: ['./side-image.component.scss']
})
export class SideImageComponent implements OnInit {

  @Input() pathImages: ISideImage[];
  @Input() animation: string;

  size = '100vh';

  constructor(private breakpointObserver: BreakpointObserver) { }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(min-width: 768px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.size = '100vh';
        } else {
          this.size = '50vh';
        }
      });
  }

}
