<button
ls-button
[id]="id"
[icon]="icon"
[size]="size"
[color]="color"
[variant]="variant"
[iconShape]="iconShape"
[onlyIcon]="onlyIcon"
[loading]="loading"
[disabled]="disabled"
(click)="handleClick()">
  {{ title || '' }}
</button>

