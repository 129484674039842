import { Directive, EventEmitter, Input, Output } from '@angular/core';

@Directive()
export class ButtonOld {
  @Input() type = 'button';
  @Input() icon: string;
  @Input() title: string;
  @Input() imageIcon: string;
  @Input() textHelper: string;
  @Input() isRound: boolean;
  @Input() isDefault: boolean;
  @Input() isLarge: boolean;
  @Input() isSmall: boolean;
  @Input() isPrimaryDark: boolean;
  @Input() isSecondary: boolean;
  @Input() isTransparent: boolean;
  @Input() isBlack: boolean;
  @Input() isGrey: boolean;
  @Input() isDanger: boolean;
  @Input() isSuccess: boolean;
  @Input() isWarning: boolean;
  @Input() isTextBold: boolean;
  @Input() isOutlined: boolean;
  @Input() rotateIcon: boolean;
  @Input() isDisabled: boolean;
  @Input() isMarginless: boolean;
  @Input() isBorderless: boolean;
  @Input() noBackground: boolean;
  @Output() handleClickEmitter = new EventEmitter();
}
