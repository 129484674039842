<div class="side-image">
  <div class="side-image-wrapper" [ngClass]="animation">
    <ng-container *ngIf="pathImages && pathImages.length === 1; else carousel">
      <div class="image" [ngStyle]="{'background-image': 'url(' + pathImages[0].url + ')'}"></div>
      <p *ngIf="pathImages[0].subtitle" class="p-5 has-text-centered full-width">
        <b>{{ pathImages[0].subtitle }}</b>
      </p>
    </ng-container>
    <ng-template #carousel>
      <app-carousel [canLoop]="true" [noControls]="true" [slides]="pathImages" [size]="size" [transition]="10000"></app-carousel>
    </ng-template>
  </div>
  <ng-content></ng-content>
</div>

