import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Profiles } from './../app/models/profiles';
import { AppPreloadingStrategy } from './core/AppPreloadingStrategy';
import { AuthGuard } from './core/guards/auth.guard';
import { NotAuthenticatedGuard } from './core/guards/not-authenticated.guard';
import { AuctionRedirectComponent } from './features/auction/auction-redirect/auction-redirect.component';
import { AuthByEmailComponent } from './features/auth/auth-by-email/auth-by-email.component';
import { ForgotPasswordComponent } from './features/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './features/auth/reset-password/reset-password.component';
import { EthanolGuardService } from './features/ethanol/ethanol-guard.service';
import { NotFoundComponent } from './features/not-found/not-found.component';
import { TrackingGuardService } from './features/tracking/tracking-guard.service';
import { RedirectGuardService } from './guards/redirect.guard';
import { ExpiredTokenModalComponent } from './shared/components/expired-token-modal/expired-token-modal.component';
import { SuggestPasswordGuardService } from './shared/components/suggest-password-modal/suggest-password-guard.service';
import { SuggestPasswordModalComponent } from './shared/components/suggest-password-modal/suggest-password-modal.component';
import { UnloadWindowsGuardService } from './features/unload-windows/unload-windows-guard.service';
import { ExpiredTokenGuard } from './shared/components/expired-token-modal/expired-token.guard';
import { environment } from 'src/environments/environment';
import { getTitle } from './menu/menu-items';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '**',
    data: {
      allowedProfiles: ['*'],
      exclude: true
    }
  },
  {
    path: '404',
    title: getTitle('404'),
    canActivate: [AuthGuard],
    component: NotFoundComponent,
    data: {
      allowedProfiles: ['*'],
      exclude: true
    }
  },
  {
    path: 'tabela-frete',
    title: getTitle('tabela-frete'),
    loadChildren: () => import('./features/shipping-table/shipping-table.module').then(m => m.ShippingTableModule),
    canActivate: [AuthGuard],
    data: {
      allowedProfiles: [
        Profiles.Administrador,
        Profiles.EmbarcadorLeilao,
        Profiles.EmbarcadorRepasse,
        Profiles.ConferenciaLeilao,
        Profiles.TransportadoraRepasseResinas,
        Profiles.TransportadoraLeilao,
        Profiles.TransportadoraLeilaoRotas,
      ]
    }
  },
  {
    path: 'leilao/conferencia',
    title: getTitle('leilao/conferencia'),
    loadChildren: () => import('./features/auction/check-auctions/check-auctions.module').then(m => m.CheckAuctionsModule),
    canActivate: [AuthGuard],
    data: {
      allowedProfiles: [
        Profiles.Administrador,
        Profiles.ConferenciaLeilao,
        Profiles.EmbarcadorLeilao
      ]
    }
  },
  {
    path: 'leilao/embarcador',
    title: getTitle('leilao'),
    loadChildren: () => import('./features/auction/shipper-auction/shipper-auction.module').then(m => m.ShipperAuctionModule),
    canActivate: [AuthGuard],
    data: {
      allowedProfiles: [
        Profiles.Administrador,
        Profiles.EmbarcadorLeilao,
        Profiles.EmbarcadorLeilaoRotas
      ]
    }
  },
  {
    path: 'leilao/transportadora',
    title: getTitle('leilao'),
    loadChildren: () => import('./features/auction/carrier-auction/carrier-auction.module').then(m => m.CarrierAuctionModule),
    canActivate: [AuthGuard],
    data: {
      allowedProfiles: [
        Profiles.Administrador,
        Profiles.TransportadoraLeilao,
        Profiles.TransportadoraLeilaoRotas
      ]
    }
  },
  {
    path: 'leilao',
    title: getTitle('leilao'),
    component: AuctionRedirectComponent,
    canActivate: [AuthGuard],
    data: {
      allowedProfiles: [
        Profiles.Administrador,
        Profiles.EmbarcadorLeilao,
        Profiles.TransportadoraLeilao,
        Profiles.EmbarcadorLeilaoRotas,
        Profiles.TransportadoraLeilaoRotas,
      ]
    }
  },
  {
    path: 'repasse/faq',
    title: getTitle('repasse/faq'),
    loadChildren: () => import('./features/faq/faq.module').then(m => m.FaqModule),
    canActivate: [AuthGuard],
    data: {
      allowedProfiles: [
        Profiles.Administrador,
        Profiles.Embarcador,
        Profiles.Transportadora,
        Profiles.AdministradorTransportadora,
        Profiles.EmbarcadorAdmin,
        Profiles.EmbarcadorPlanejador,
        Profiles.EmbarcadorRepasse,
        Profiles.EmbarcadorMonitoramento,
        Profiles.EmbarcadorGestaoDedicado,
        Profiles.TransportadoraRepasseResinas,
        Profiles.TransportadoraOperador,
        Profiles.CustomerServicesMonitoramento
      ]
    }
  },
  {
    path: 'privacy-police',
    title: getTitle('privacy-police'),
    loadChildren: () => import('./features/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
    canActivate: [AuthGuard],
    data: {
      allowedProfiles: [
        Profiles.Administrador,
        Profiles.Embarcador,
        Profiles.Transportadora,
        Profiles.AdministradorTransportadora,
        Profiles.EmbarcadorAdmin,
        Profiles.EmbarcadorPlanejador,
        Profiles.EmbarcadorRepasse,
        Profiles.EmbarcadorMonitoramento,
        Profiles.EmbarcadorGestaoDedicado,
        Profiles.TransportadoraRepasseResinas,
        Profiles.TransportadoraOperador,
        Profiles.CustomerServicesMonitoramento
      ]
    }
  },
  { 
    path: 'expired-token',
    title: getTitle('expired-token'),
    component: ExpiredTokenModalComponent,
    canActivate: [ExpiredTokenGuard, AuthGuard],
    data: { 
      allowedProfiles: [], 
      exclude: true 
    } 
  },
  { 
    path: 'suggest-password',
    title: getTitle('suggest-password'),
    component: SuggestPasswordModalComponent,
    canActivate: [SuggestPasswordGuardService],
    data: { 
      allowedProfiles: ['*'], 
      exclude: true 
    } 
  },
  {
    path: 'tracking',
    title: getTitle('tracking'),
    canActivate: [TrackingGuardService],
    children: [],
    data: {
      allowedProfiles: [
        Profiles.Administrador,
        Profiles.EmbarcadorTracking,
        Profiles.TransportadoraTracking
      ]
    }
  },
  {
    path: 'tracking/embarcador',
    title: getTitle('tracking'),
    loadChildren: () => import('./features/tracking/shipper-tracking-view/shipper-tracking-view.module').then(m => m.ShipperTrackingViewModule),
    canActivate: [AuthGuard],
    data: {
      allowedProfiles: [
        Profiles.Administrador,
        Profiles.EmbarcadorTracking
      ]
    }
  },
  {
    path: 'tracking/transportadora',
    title: getTitle('tracking'),
    loadChildren: () => import('./features/tracking/carrier-tracking-view/carrier-tracking-view.module').then(m => m.CarrierTrackingViewModule),
    canActivate: [AuthGuard],
    data: {
      allowedProfiles: [
        Profiles.Administrador,
        Profiles.TransportadoraTracking
      ]
    }
  },
  {
    path: 'etanol',
    title: getTitle('etanol'),
    children: [],
    canActivate: [EthanolGuardService],
    data: {
      allowedProfiles: [
        Profiles.Administrador,
        Profiles.EmbarcadorEtanol,
        Profiles.TransportadoraEtanol,
        Profiles.OperadorEtanol,
        Profiles.GerenteOperadorEtanol,
      ]
    }
  },
  {
    path: 'janelas',
    title: getTitle('janelas'),
    children: [],
    canActivate: [UnloadWindowsGuardService],
    data: {
      allowedProfiles: [
        Profiles.Administrador,
        Profiles.TransportadoraAgendamento,
        Profiles.EmbarcadorAgendamento,
        Profiles.EmbarcadorAgendamentoLeitura,
        Profiles.FornecedorAgendamento,
      ]
    }
  },
  {
    path: 'cadastro',
    title: 'Cadastro | Pacey',
    canActivate: [NotAuthenticatedGuard],
    loadChildren: () => import('./features/register/register.module').then(m => m.RegisterModule),
    data: {
      allowedProfiles: [],
      exclude: true
    }
  },
  {
    path: 'contratacao',
    title: getTitle('contratacao'),
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/carrier-contracts/carrier-contracts.module').then(m => m.CarrierContractsModule),
    data: {
      allowedProfiles: [
        Profiles.Administrador,
        Profiles.EmbarcadorAdmin,
        Profiles.Embarcador
      ]
    }
  },
  {
    path: 'processos-de-homologacao',
    title: getTitle('processos-de-homologacao'),
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/approval-process/shipper/approval-process/approval-process.module').then(m => m.ApprovalProcessModule),
    data: {
      allowedProfiles: [
        Profiles.Administrador,
        Profiles.EmbarcadorAdmin,
        Profiles.Embarcador
      ]
    }
  },
  {
    path: 'em-homologacao',
    title: getTitle('em-homologacao'),
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/approval-process-history/approval-process-history.module').then(m => m.ApprovalProcessHistoryModule),
    data: {
      allowedProfiles: [
        Profiles.Administrador,
        Profiles.EmbarcadorAdmin,
        Profiles.Embarcador
      ],
      visibleOnlyWithSSO: environment.environment === 'production'
    },

  },
  {
    path: 'homologacoes',
    title: getTitle('homologacoes'),
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/approval-process/carrier/approval-process-list/approval-process-list.module').then(m => m.ApprovalProcessListModule),
    data: {
      allowedProfiles: [
        Profiles.Administrador,
        Profiles.Transportadora,
        Profiles.TransportadoraAdminPerfil
      ]
    }
  },
  {
    path: 'contratacao/detalhes',
    title: getTitle('contratacao/detalhes'),
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/approval-process/both/approval-process-details/approval-process-details.module').then(m => m.ApprovalProcessDetailsModule),
    data: {
      allowedProfiles: [
        Profiles.Administrador,
        Profiles.Transportadora,
        Profiles.TransportadoraAdminPerfil,
        Profiles.EmbarcadorAdmin,
        Profiles.Embarcador
      ]
    }
  },
  {
    path: 'janelas/embarcador',
    title: getTitle('janelas'),
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/unload-windows/shipper/shipper.module').then(m => m.ShipperModule),
    data: {
      allowedProfiles: [
        Profiles.Administrador,
        Profiles.EmbarcadorAgendamento,
        Profiles.EmbarcadorAgendamentoLeitura
      ]
    }
  },
  {
    path: 'janelas/transportadora',
    title: getTitle('janelas'),
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/unload-windows/carrier/carrier.module').then(m => m.CarrierModule),
    data: {
      allowedProfiles: [
        Profiles.Administrador,
        Profiles.TransportadoraAgendamento
      ]
    }
  },
  {
    path: 'janelas/fornecedor',
    title: getTitle('janelas'),
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/unload-windows/supplier/supplier.module').then(m => m.SupplierModule),
    data: {
      allowedProfiles: [
        Profiles.Administrador,
        Profiles.FornecedorAgendamento
      ]
    }
  },
  {
    path: 'etanol/embarcador',
    title: getTitle('etanol'),
    loadChildren: () => import('./features/ethanol/ethanol-shipper/ethanol-shipper.module').then(m => m.EthanolShipperModule),
    canActivate: [AuthGuard],
    data: {
      allowedProfiles: [
        Profiles.Administrador,
        Profiles.EmbarcadorEtanol
      ]
    }
  },
  {
    path: 'etanol/transportadora',
    title: getTitle('etanol'),
    loadChildren: () => import('./features/ethanol/ethanol-carrier/ethanol-carrier.module').then(m => m.EthanolCarrierModule),
    canActivate: [AuthGuard],
    data: {
      allowedProfiles: [
        Profiles.Administrador,
        Profiles.TransportadoraEtanol
      ]
    }
  },
  {
    path: 'etanol/terceirizado',
    title: getTitle('etanol'),
    loadChildren: () => import('./features/ethanol/ethanol-outsourced/ethanol-outsourced.module').then(m => m.EthanolOutsourcedModule),
    canActivate: [AuthGuard],
    data: {
      allowedProfiles: [
        Profiles.Administrador,
        Profiles.OperadorEtanol,
        Profiles.GerenteOperadorEtanol,
      ]
    }
  },
  { 
    path: 'login', 
    title: 'Login | Pacey',
    component: AuthByEmailComponent, 
    data: { 
      allowedProfiles: [], 
      exclude: true 
    }, 
    canActivate: [RedirectGuardService] },
  { 
    path: 'forgot-password', 
    title: 'Esqueci a senha | Pacey',
    component: ForgotPasswordComponent, 
    data: { 
      allowedProfiles: [], 
      exclude: true 
    } 
  },
  { 
    path: 'reset-password/:token', 
    title: 'Alterar senha | Pacey',
    component: ResetPasswordComponent, 
    data: { 
      allowedProfiles: [], 
      exclude: true 
    } 
  },
  // {
  //   path: 'repasse',
  //   loadChildren: () => import('./features/extra-loads/extra-loads.module').then(m => m.ExtraLoadsModule),
  //   canActivate: [AuthGuard],
  //   data: {
  //     allowedProfiles: [
  //       Profiles.Administrador,
  //       Profiles.Embarcador,
  //       Profiles.AdministradorTransportadora,
  //       Profiles.EmbarcadorAdmin,
  //       Profiles.EmbarcadorRepasse,
  //       Profiles.TransportadoraRepasseResinas,
  //       Profiles.TransportadoraRepasseLG,
  //       Profiles.TransportadoraOperador,
  //     ]
  //   }
  // },
  {
    path: 'repasse/resinas',
    title: getTitle('repasse'),
    loadChildren: () => import('./features/extra-loads/extra-loads-resin/extra-loads-resin.module').then(m => m.ExtraLoadsResinModule),
    canActivate: [AuthGuard],
    data: {
      allowedProfiles: [
        Profiles.Administrador,
        Profiles.TransportadoraRepasseResinas,
        Profiles.EmbarcadorRepasse,
      ]
    }
  },
  {
    path: 'repasse/quimicos',
    title: getTitle('repasse'),
    loadChildren: () => import('./features/extra-loads/extra-loads-chemical/extra-loads-chemical.module').then(m => m.ExtraLoadsChemicalModule),
    canActivate: [AuthGuard],
    data: {
      allowedProfiles: [
        Profiles.Administrador,
        Profiles.TransportadoraRepasseLG,
        Profiles.EmbarcadorRepasse,
      ]
    }
  },
  {
    path: 'relatorios',
    title: getTitle('relatorios'),
    loadChildren: () => import('./features/reports/reports.module').then(m => m.ReportsModule),
    canActivate: [AuthGuard],
    data: {
      allowedProfiles: [
        Profiles.Administrador,
        Profiles.Embarcador,
        Profiles.EmbarcadorAdmin,
        Profiles.EmbarcadorRepasse
      ]
    }
  },
  {
    path: 'sistema',
    title: getTitle('sistema'),
    loadChildren: () => import('./features/system-configuration/system-configuration.module').then(m => m.SystemConfigurationModule),
    canActivate: [AuthGuard],
    data: {
      allowedProfiles: [
        Profiles.Administrador,
        Profiles.EmbarcadorAdmin,
        Profiles.EmbarcadorAgendamento,
      ]
    }
  },
  {
    path: 'modulos',
    title: getTitle('modulos'),
    loadChildren: () => import('./features/modules/modules.module').then(m => m.ModulesModule),
    canActivate: [AuthGuard],
    data: {
      allowedProfiles: [
        Profiles.Administrador,
        Profiles.AdministradorTransportadora,
        Profiles.EmbarcadorAdmin,
        Profiles.TransportadoraRepasseResinas,
      ]
    }
  },
  {
    path: 'minha-conta',
    title: getTitle('minha-conta'),
    loadChildren: () => import('./features/my-account/my-account.module').then(m => m.MyAccountModule),
    canActivate: [AuthGuard],
    data: {
      allowedProfiles: ['*']
    }
  },
  {
    path: 'perfil',
    title: getTitle('perfil'),
    loadChildren: () => import('./features/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard],
    data: {
      allowedProfiles: [
        Profiles.TransportadoraAdminPerfil,
      ]
    }
  },
  {
    path: 'perfil/transportadora',
    title: getTitle('perfil/transportadora'),
    loadChildren: () => import('./features/profile/carrier/carrier.module').then(m => m.CarrierModule),
    canActivate: [AuthGuard],
    data: {
      allowedProfiles: [
        Profiles.Transportadora,
      ]
    }
  },
  {
    path: 'home',
    title: getTitle('home'),
    loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuard],
    data: {
      allowedProfiles: ['*']
    }
  },
  { path: '**', children: [], pathMatch: 'full', canActivate: [RedirectGuardService], data: { allowedProfiles: ['*'], exclude: true } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: AppPreloadingStrategy,
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
