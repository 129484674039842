<div
class="timeline-content"
[ngClass]="{'sub-item': subItem}"
>
  <ng-container *ngIf="data?.icon">
    <i class="is-size-4" [ngClass]="iconClass">{{ iconName }}</i>
  </ng-container>

  <div class="data-wrapper">
    <label
    *ngIf="data?.shortDescription"
    class="is-size-7 is-uppercase has-text-grey"
    >
      {{ data?.createdAt | date: 'dd/MM/YYYY' }}
    </label>

    <div class="status">
      <div class="grid">
        <span class="is-size-6 has-text-weight-medium is-uppercase">
          {{ data?.state }}
        </span>

        <span class="is-size-7 has-text-weight-medium is-uppercase">
          {{ data?.caseNumber }}
        </span>
      </div>

      <div class="description">
        <span class="is-size-6">
          {{ data?.shortDescription }}
        </span>
      </div>
    </div>
  </div>
  <hr />
</div>
