<ng-container>
    <div class="input-text-control">
        <input darkMode type="text" placeholder="Selecione ou digite" (keyup)="filterOptions($event)">
    </div>
    <div darkMode class="multiple-select">
        <div class="options">
            <div *ngIf="!isRadio" class="option">
                <input class="is-clickable" type="checkbox" (change)="checkOption($event, 'TODOS')" [checked]="isCheckedAll">
                <label class="is-clickable">TODOS</label>
            </div>
            <div class="option" *ngFor="let option of filteredOptions; let i=index">
                <input class="is-clickable" type="checkbox" (change)="checkOption($event, option)" [disabled]="option.disabled" [(ngModel)]="option.checked">
                <label class="is-clickable">{{ option.label }}</label>
            </div>
        </div>
    </div>
</ng-container>
