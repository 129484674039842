import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
  user: any = {
    meta: {
      domain: '',
      endpoint: '',
      params: '',
      filters: {},
      collection: '',
      created: null,
      state: 'empty',
      status: 'blank',
      code: 204,
    }
  };

  users: any = {
    meta: {
      domain: '',
      endpoint: '',
      params: '',
      filters: {},
      collection: '',
      created: null,
      state: 'empty',
      status: 'blank',
      code: 204,
    },
    data: {}
  };

  client: any = {
    meta: {
      domain: '',
      endpoint: '',
      params: '',
      filters: {},
      collection: '',
      created: null,
      state: 'empty',
      status: 'blank',
      code: 204,
    }
  };

  clients: any = {
    meta: {
      domain: '',
      endpoint: '',
      params: '',
      filters: {},
      collection: '',
      created: null,
      state: 'empty',
      status: 'blank',
      code: 204,
    },
    data: {}
  };

  distributionCenter: any = {
    meta: {
      domain: '',
      endpoint: '',
      params: '',
      filters: {},
      collection: '',
      created: null,
      state: 'empty',
      status: 'blank',
      code: 204,
    }
  };

  distributionCenters: any = {
    meta: {
      domain: '',
      endpoint: '',
      params: '',
      filters: {},
      collection: '',
      created: null,
      state: 'empty',
      status: 'blank',
      code: 204,
    },
    data: {}
  };

  modal: any = {
    meta: {
      domain: '',
      endpoint: '',
      params: '',
      filters: {},
      collection: '',
      created: null,
      state: 'empty',
      status: 'blank',
      code: 204,
    }
  };

  modals: any = {
    meta: {
      domain: '',
      endpoint: '',
      params: '',
      filters: {},
      collection: '',
      created: null,
      state: 'empty',
      status: 'blank',
      code: 204,
    },
    data: {}
  };

  tractorUnit: any = {
    meta: {
      domain: '',
      endpoint: '',
      params: '',
      filters: {},
      collection: '',
      created: null,
      state: 'empty',
      status: 'blank',
      code: 204,
    }
  };

  tractorUnits: any = {
    meta: {
      domain: '',
      endpoint: '',
      params: '',
      filters: {},
      collection: '',
      created: null,
      state: 'empty',
      status: 'blank',
      code: 204,
    },
    data: {}
  };

  order: any = {
    meta: {
      domain: '',
      endpoint: '',
      params: '',
      filters: {},
      collection: '',
      created: null,
      state: 'empty',
      status: 'blank',
      code: 204,
    }
  };

  orders: any = {
    meta: {
      domain: '',
      endpoint: '',
      params: '',
      filters: {},
      collection: '',
      created: null,
      state: 'empty',
      status: 'blank',
      code: 204,
    },
    data: {}
  };

  transportation: any = {
    meta: {
      domain: '',
      endpoint: '',
      params: '',
      filters: {},
      collection: '',
      created: null,
      state: 'empty',
      status: 'blank',
      code: 204,
    }
  };

  transportations: any = {
    meta: {
      domain: '',
      endpoint: '',
      params: '',
      filters: {},
      collection: '',
      created: null,
      state: 'empty',
      status: 'blank',
      code: 204,
    },
    data: {}
  };

  importation: any = {
    meta: {
      domain: '',
      endpoint: '',
      params: '',
      filters: {},
      collection: '',
      created: null,
      state: 'empty',
      status: 'blank',
      code: 204,
    }
  };

  importations: any = {
    meta: {
      domain: '',
      endpoint: '',
      params: '',
      filters: {},
      collection: '',
      created: null,
      state: 'empty',
      status: 'blank',
      code: 204,
    },
    data: {}
  };

  planning: any = {
    meta: {
      domain: '',
      endpoint: '',
      params: '',
      filters: {},
      collection: '',
      created: null,
      state: 'empty',
      status: 'blank',
      code: 204,
    }
  };

  plannings: any = {
    meta: {
      domain: '',
      endpoint: '',
      params: '',
      filters: {},
      collection: '',
      created: null,
      state: 'empty',
      status: 'blank',
      code: 204,
    },
    data: {}
  };

  match: any = {
    meta: {
      domain: '',
      endpoint: '',
      params: '',
      filters: {},
      collection: '',
      created: null,
      state: 'empty',
      status: 'blank',
      code: 204,
    }
  };

  matches: any = {
    meta: {
      domain: '',
      endpoint: '',
      params: '',
      filters: {},
      collection: '',
      created: null,
      state: 'empty',
      status: 'blank',
      code: 204,
    },
    data: {}
  };

  semiTruck: any = {
    meta: {
      domain: '',
      endpoint: '',
      params: '',
      filters: {},
      collection: '',
      created: null,
      state: 'empty',
      status: 'blank',
      code: 204,
    }
  };

  semiTrucks: any = {
    meta: {
      domain: '',
      endpoint: '',
      params: '',
      filters: {},
      collection: '',
      created: null,
      state: 'empty',
      status: 'blank',
      code: 204,
    },
    data: {}
  };

  semiTrailer: any = {
    meta: {
      domain: '',
      endpoint: '',
      params: '',
      filters: {},
      collection: '',
      created: null,
      state: 'empty',
      status: 'blank',
      code: 204,
    }
  };

  semiTrailers: any = {
    meta: {
      domain: '',
      endpoint: '',
      params: '',
      filters: {},
      collection: '',
      created: null,
      state: 'empty',
      status: 'blank',
      code: 204,
    },
    data: {}
  };

  profile: any = {
    meta: {
      domain: '',
      endpoint: '',
      params: '',
      filters: {},
      collection: '',
      created: null,
      state: 'empty',
      status: 'blank',
      code: 204,
    }
  };

  profiles: any = {
    meta: {
      domain: '',
      endpoint: '',
      params: '',
      filters: {},
      collection: '',
      created: null,
      state: 'empty',
      status: 'blank',
      code: 204,
    },
    data: {}
  };

  permission: any = {
    meta: {
      domain: '',
      endpoint: '',
      params: '',
      filters: {},
      collection: '',
      created: null,
      state: 'empty',
      status: 'blank',
      code: 204,
    }
  };

  permissions: any = {
    meta: {
      domain: '',
      endpoint: '',
      params: '',
      filters: {},
      collection: '',
      created: null,
      state: 'empty',
      status: 'blank',
      code: 204,
    },
    data: {}
  };

  organization: any = {
    meta: {
      domain: '',
      endpoint: '',
      params: '',
      filters: {},
      collection: '',
      created: null,
      state: 'empty',
      status: 'blank',
      code: 204,
    }
  };

  organizations: any = {
    meta: {
      domain: '',
      endpoint: '',
      params: '',
      filters: {},
      collection: '',
      created: null,
      state: 'empty',
      status: 'blank',
      code: 204,
    },
    data: {}
  };

  map: any = {
    markers: {
      trucks: {
        // '_id': truckData
      },
      plants: {
        // '_id: plantData
      }
    }
  };

  localPlanning: any = {
    planningDate: {},
    trucks: {},
    removedTrucks: {},
    dedicated: {},
    spots: {},
    transportations: {}
  };

  weather: any = {
    meta: {
      domain: '',
      endpoint: '',
      params: '',
      filters: {},
      collection: '',
      created: null,
      state: 'empty',
      status: 'blank',
      code: 204,
    },
    data: {}
  };

 }

declare global {
  interface Window {
    Math: any;
  }
}

window.Math = window.Math;
