import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss']
})
export class TimepickerComponent implements OnInit {

  @Input() disabled = false;
  @Input() value;
  @Input() initialTime;
  @Input() min;
  @Input() control: AbstractControl = new UntypedFormControl();

  @Output() handleTimeChange = new EventEmitter();

  @ViewChild('timepicker') timepicker: ElementRef;

  constructor() { }

  ngOnInit(): void {
  }

  getTime = event => {
    let time = event.target.value;
    if (event.target.validationMessage) {
      this.timepicker.nativeElement.value = this.value;
      time = this.timepicker.nativeElement.value;
    }
    this.value = time;
    this.handleTimeChange.emit(this.value);
  };

}
