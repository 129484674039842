<app-tooltip
  *ngIf="TYPE_MAPS[type]"
  [text]="TYPE_LABEL[type] + data?.partnerCity + '/' + data?.partnerUF"
>
  <div darkMode class="wrapper">
    <img *ngIf="!isDarkMode" src="assets/map-point.svg" alt="" />
    <img *ngIf="isDarkMode" src="assets/map-point-white.svg" alt="" />
    <p>{{TYPE_MAPS[type]}}</p>
  </div>
</app-tooltip>
