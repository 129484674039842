import { EventEmitter, Input, Output, Directive } from '@angular/core';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class Dropdown {
    @Input() isOpen = false;
    @Input() isMulticheck = false;
    @Input() dropdownItems: any[];
    @Input() currentItem: { label: string | string[]; value: any };
    @Output() handleChange = new EventEmitter();
}

export interface IDropdown {
    initDropdown(): void;
    handleDropdown(): void;
    selectValue(...args): void;
}
