import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';
import { OrderService } from 'src/app/services/order/order.service';

@Component({
  selector: 'app-menu-search-ot',
  templateUrl: './menu-search-ot.component.html',
  styleUrls: ['./menu-search-ot.component.scss']
})
export class MenuSearchOtComponent implements OnInit {

  @Input() userOrdersCount: any;
  @Output() updateUserOrdersCount = new EventEmitter<any>();
  @ViewChild('inputSearchOT') inputSearchOT: any;

  searchOtNumber = null;

  isOrderSaved = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private orderService: OrderService
  ) { }

  ngOnInit() {
    setTimeout(() => this.inputSearchOT?.nativeElement?.focus());
  }

  async saveOt() {
    if (this.searchOtNumber && !isNaN(this.searchOtNumber)) {
      const result = await this.orderService.addUserOrder(this.searchOtNumber);
      if (result === this.searchOtNumber) {
        localStorage.setItem('lastOtSearch', this.searchOtNumber);
      }
      this.goToSearchOtPage();
    }
  }

  async onlySaveOt() {
    if (this.searchOtNumber && !isNaN(this.searchOtNumber))  {
      await this.orderService.addUserOrder(this.searchOtNumber);
      this.isOrderSaved = true;
      this.updateUserOrdersCount.emit();
    }
  }

  goToSearchOtPage() {
    this.router.navigate(['/buscar-ot']);
  }

  changeOtNumber() {
    if (this.isOrderSaved) {
      this.isOrderSaved = false;
    }
  }

}
